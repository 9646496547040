import { ListVariableInfo, PhysicalQuantity, SiteInfo } from '@dametis/core';

import { getVariableKind } from 'functions/getVariableKind';
import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { clearVariablesStore, setAvailableVariableFlags, setIsFetchingVariables, setVariablesById } from 'store/slices/variables';
import { AllVariableKind, VariableFlags } from 'types/variables';

import { displaySdkErrorToast } from './toasts';

const getVariableFlags = (variables: ListVariableInfo[], sites: SiteInfo[]): VariableFlags => {
  const flags = {
    physicalQuantities: new Set<PhysicalQuantity>(),
    kinds: new Set<AllVariableKind>(),
    sites: new Set<string>(),
  };
  const collator = new Intl.Collator();

  variables.forEach(variable => {
    if (variable.physicalQuantity as string) {
      flags.physicalQuantities.add(variable.physicalQuantity);
    }

    flags.kinds.add(getVariableKind(variable));

    if (variable.siteId) {
      flags.sites.add(variable.siteId);
    }
  });

  return {
    kinds: Array.from(flags.kinds),
    physicalQuantities: Array.from(flags.physicalQuantities).sort((a, b) => collator.compare(a, b)),
    sites: flags.sites.size > 0 ? Array.from(flags.sites) : sites.map(site => site.uuid),
  };
};

export const fetchVariables = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const {
    auth: {
      selectedSite,
      selectedGroup: { uuid: selectedGroupId, sites },
    },
  } = getState();
  const selectedSiteId = selectedSite?.uuid;
  dispatch(setIsFetchingVariables(true));
  try {
    const { data: variables } = selectedSiteId
      ? await sdk.variable.ListOfSite(selectedSiteId)
      : await sdk.corporate.ListVariables(selectedGroupId);

    const tags = getVariableFlags(variables, sites);

    const variablesById = variables.reduce((byId, variable) => {
      byId[variable.uuid] = variable;
      return byId;
    }, {});

    dispatch(setVariablesById(variablesById));
    dispatch(setAvailableVariableFlags(tags));
  } catch (err) {
    dispatch(displaySdkErrorToast(err));
    dispatch(clearVariablesStore());
  } finally {
    dispatch(setIsFetchingVariables(false));
  }
};
