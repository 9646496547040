import { Close } from '@mui/icons-material';
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInputProps,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, FocusEventHandler, MouseEventHandler, MutableRefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PhysicalQuantity } from '@dametis/core';
import { UnitConverter } from '@dametis/unit';

import PhysicalQuantityAvatar from '../PhysicalQuantity/PhysicalQuantityAvatar';

import { useUnitPickerContext } from './UnitPickerContext';
import PickerInput from './styled/PickerInput';

export interface UnitPickerButtonProps {
  inputRef: MutableRefObject<HTMLInputElement>;
  parsedBaseUnit: UnitConverter;
  label: boolean | string;
  editing: boolean;
  clearable: boolean;
  formControlProps: FormControlProps;
  inputProps: OutlinedInputProps;
  physicalQuantity: PhysicalQuantity;
}

const UnitPickerButton: FC<UnitPickerButtonProps> = ({
  label,
  editing,
  clearable,
  inputRef,
  parsedBaseUnit,
  formControlProps,
  inputProps,
  physicalQuantity,
}) => {
  const { t } = useTranslation('unit');

  const { setOpen, value, onChange, open } = useUnitPickerContext();

  const displayedPlaceholder = useMemo(() => {
    if (value?.trim().length === 0) {
      return t('text.noUnitElement');
    }
    if (parsedBaseUnit.raw?.trim().length > 0) {
      return t('text.unitUndefinedWithBase', { base: parsedBaseUnit.raw });
    }
    return t('text.unitUndefined');
  }, [value, t, parsedBaseUnit]);

  const displayedValue = useMemo(() => {
    if (value?.trim().length > 0) {
      return value.trim();
    }
    return '';
  }, [value]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const handleClickClear: MouseEventHandler<HTMLButtonElement> = useCallback(
    event => {
      event.stopPropagation();
      onChange(null);
    },
    [onChange],
  );

  const handleMouseDownClear: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleInputFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    event => {
      event.target.blur();
      handleOpen();
    },
    [handleOpen],
  );

  return (
    <FormControl fullWidth {...formControlProps}>
      {label && <InputLabel>{label === true ? t('text.inputLabel') : label}</InputLabel>}
      {editing ? (
        <>
          <PickerInput
            {...inputProps}
            ref={inputRef}
            endAdornment={
              clearable && value?.trim().length >= 0 ? (
                <InputAdornment position="end">
                  <Tooltip title={t('tooltip.clear')}>
                    <IconButton edge="end" onClick={handleClickClear} onMouseDown={handleMouseDownClear}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ) : undefined
            }
            placeholder={displayedPlaceholder}
            popoverOpen={open}
            startAdornment={
              physicalQuantity && <PhysicalQuantityAvatar physicalQuantity={physicalQuantity} size="medium" sx={{ ml: -0.75 }} />
            }
            value={displayedValue}
            onClick={handleOpen}
            onFocus={handleInputFocus}
          />
        </>
      ) : (
        <Stack alignItems="center" direction="row" gap={1} mt={label ? 3 : 0}>
          {physicalQuantity && <PhysicalQuantityAvatar physicalQuantity={physicalQuantity} size="medium" />}
          <Typography>{displayedValue}</Typography>
        </Stack>
      )}
    </FormControl>
  );
};

export default UnitPickerButton;
