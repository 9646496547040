import { RuleOperator } from '@dametis/core';

export default {
  tooltip: {
    deleteRule: 'Supprimer la règle',
    concat: 'Concaténer le texte de remplacement quand plusieurs règles sont satisfaites',
    concatShort: 'Concaténer le texte',
    resetStyledRuleStyle: 'Réinitialiser le style',
    duplicateStyledRule: 'Dupliquer la règle',
    deleteStyledRule: 'Supprimer la règle',
    fontColor: 'Couleur du texte',
    borderColor: 'Couleur de la bordure',
    fillColor: 'Couleur de fond',
  },
  title: {
    styleConfigurationModal: 'Listes de règles',
  },
  text: {
    emptySelect: 'Règle non définie.',
    value: 'Valeur',
    valueWithUnit: 'Valeur ({{unit}})',
    operator: 'Opérateur',
    altText: 'Texte de remplacement',
    when: 'Quand...',
    do: 'Appliquer...',
    noRule: 'Aucune règle',
    rulesLength_zero: 'Aucune règle',
    rulesLength_one: '1 règle',
    rulesLength_other: '{{count}} règles',
    otherRulesLength_one: '+{{count}} règle',
    otherRulesLength_other: '+{{count}} règles',
    withContent: 'contenu on',
    withoutContent: 'contenu off',
    withStyle: 'style on',
    withoutStyle: 'style off',
    noStyleConfiguration: 'Aucune liste de règles enregistrée sur ce site',
    orderDescription: "L'ordre de priorité des règles de style est de haut en bas. Vous pouvez les organiser par glisser-déposer.",
    shortOrderDescription: "L'ordre de priorité des règles de style est de haut en bas.",
    noOperatorSymbol: '∅',
  },
  rule: {
    [RuleOperator.EQUAL_TO]: {
      label: 'égal à',
      label_withValue: 'égal à {{value}}',
    },
    [RuleOperator.NOT_EQUAL_TO]: {
      label: 'non égal à',
      label_withValue: 'non égal à {{value}}',
    },
    [RuleOperator.GREATER_THAN]: {
      label: 'supérieur à',
      label_withValue: 'supérieur à {{value}}',
    },
    [RuleOperator.GREATER_THAN_OR_EQUAL_TO]: {
      label: 'supérieur ou égal à',
      label_withValue: 'supérieur ou égal à {{value}}',
    },
    [RuleOperator.LESS_THAN]: {
      label: 'inférieur à',
      label_withValue: 'inférieur à {{value}}',
    },
    [RuleOperator.LESS_THAN_OR_EQUAL_TO]: {
      label: 'inférieur ou égal à',
      label_withValue: 'inférieur ou égal à {{value}}',
    },
    [RuleOperator.BIT_IS_SET]: {
      label: 'bit set à la position',
      label_withValue: 'bit à la position {{value}} est set',
    },
    [RuleOperator.BIT_IS_UNSET]: {
      label: 'bit unset à la position',
      label_withValue: 'bit à la position {{value}} est unset',
    },
  },
  button: {
    close: 'Fermer',
    previous: 'Précédent',
    next: 'Suivant',
    submit: 'Valider',
    cancel: 'Annuler',
    downloadFileTemplate: 'Modèle {{type}}',
  },
  stepper: {
    creation: 'Création',
    selection: 'Sélection',
    settings: 'Réglages',
  },
  label: {
    withStyle: 'Utiliser le style (italique, gras, souligné, couleurs)',
    withContent: 'Utiliser le contenu (texte, icône)',
  },
};
