import deepEqual from 'deep-equal';
import { AxisTypeValue, Series, SeriesLineOptions } from 'highcharts';
import { FC, PropsWithChildren, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { CalculationVariable, IsCalculationVariable, IsPointsVariable, PointsVariable, Format } from '@dametis/core';
import { UglyCalculation } from '@dametis/mathjs';

import { calculationToString } from 'functions/calculationToString';
import { getCalculationUnit } from 'functions/getCalculationUnit';

import { useDaChartContext } from '../DaChartContext';
import { useThresholdContext } from '../Threshold/ThresholdContext';
import { useXAxisContext } from '../XAxis/XAxisContext';
import { useYAxisContext } from '../YAxis/YAxisContext';
import { areTimeDataEqual } from '../helpers/areTimeDataEqual';
import { getNextColor } from '../helpers/getNextColor';
import { LineSeriesCustomOptions, ThresholdDirection } from '../types';

import LineSeriesProvider from './LineSeriesContext';
import { getLineSeriesOptions } from './lineSeriesOptions';

const dataDefaultProp: [number, number][] = [];
const lineSeriesOptionsDefaultProp: Partial<SeriesLineOptions> = {};

export interface LineSeriesProps {
  calculation?: CalculationVariable | PointsVariable;
  data?: [number, number][];
  lineSeriesOptions?: Partial<SeriesLineOptions>;
  color?: string;
  xAxisType?: AxisTypeValue;
  format?: Format;
}

const LineSeries: FC<PropsWithChildren<LineSeriesProps>> = ({
  calculation = undefined,
  color = undefined,
  data = dataDefaultProp,
  lineSeriesOptions = lineSeriesOptionsDefaultProp,
  xAxisType = 'datetime',
  format = undefined,
  children = undefined,
}) => {
  const { highcharts } = useDaChartContext();
  const { axis: xAxis } = useXAxisContext();
  const { direction: thresholdDirection, value: thresholdValue, color: thresholdColor } = useThresholdContext();
  const { axis: yAxis, unit: userUnit } = useYAxisContext();

  const [series, setSeries] = useState<Series | null>(null);

  const seriesRef = useRef<Series | null>(null);

  const uuid = useMemo(() => uuidv4(), []);
  const usedColors: string[] = useMemo(() => xAxis?.series.map(xAxisSeries => (xAxisSeries.color as string) ?? '') ?? [], [xAxis?.series]);
  const parsedColor = useMemo(() => color ?? getNextColor(usedColors), [color, usedColors]);
  const name = useMemo(() => lineSeriesOptions.name || calculationToString(calculation), [calculation, lineSeriesOptions.name]);
  const options = useMemo(
    () => getLineSeriesOptions({ ...lineSeriesOptions, name, color: parsedColor }),
    [lineSeriesOptions, name, parsedColor],
  );
  const baseUnit = useMemo(() => getCalculationUnit(calculation), [calculation]);

  const custom: LineSeriesCustomOptions = useMemo(
    () => ({
      baseUnit,
      userUnit,
      originalColor: options?.color,
      format,
    }),
    [baseUnit, userUnit, options?.color, format],
  );

  const thresholdOptions = useMemo(
    () =>
      thresholdValue
        ? {
            threshold: thresholdValue,
            color: thresholdDirection === ThresholdDirection.DOWN ? custom?.originalColor : thresholdColor,
            negativeColor: thresholdDirection === ThresholdDirection.DOWN ? thresholdColor : custom?.originalColor,
          }
        : {},
    [thresholdValue, thresholdDirection, custom?.originalColor, thresholdColor],
  );

  const initSeries = useCallback(() => {
    if (!highcharts || !xAxis || !yAxis || series) return;
    const newSeries = highcharts.chart.addSeries({
      id: uuid,
      name,
      xAxis: xAxis.options.id,
      yAxis: yAxis.options.id,
      type: 'line',
      color,
    });
    seriesRef.current = newSeries;
    setSeries(newSeries);
  }, [highcharts, xAxis, yAxis, series, uuid, name, color]);

  useEffect(() => {
    initSeries();
  }, [initSeries]);

  useEffect(() => {
    if (series) {
      series.setData([]);
      series.setData(data);
    }
  }, [data, series]);

  useEffect(() => {
    if (series) {
      series.update({ ...options, ...(thresholdValue ? thresholdOptions : {}), custom, type: 'line' });
    }
  }, [options, custom, series, thresholdOptions, thresholdValue]);

  useEffect(() => {
    if (xAxis) {
      xAxis.update({ type: xAxisType });
    }
  }, [xAxisType, xAxis]);

  useEffect(
    () => () => {
      if (seriesRef.current?.chart) {
        seriesRef.current?.remove();
      }
    },
    [],
  );

  // useEffect(() => {
  //   if (axis) {
  //     axis.series.forEach(series => {
  //       if (series.type === 'line') {
  //         const seriesColor = (series.options.custom as LineSeriesCustomOptions).originalColor ?? series.color;
  //         (series as LineSeries).update({
  //           type: 'line',
  //           threshold: value,
  //           color: direction === ThresholdDirection.DOWN ? seriesColor : options.color,
  //           negativeColor: direction === ThresholdDirection.DOWN ? options.color : seriesColor,
  //         });
  //       }
  //     });
  //   }
  // }, [value, axis, direction, options.color]);

  return <LineSeriesProvider series={series}>{children}</LineSeriesProvider>;
};

export default memo(
  LineSeries,
  (
    {
      calculation: oldCalculation,
      color: oldColor,
      format: oldFormat,
      data: oldData,
      lineSeriesOptions: oldLineSeriesOptions,
      xAxisType: oldXAxisType,
    },
    {
      calculation: nextCalculation,
      color: nextColor,
      format: nextFormat,
      data: nextData,
      lineSeriesOptions: nextLineSeriesOptions,
      xAxisType: nextXAxisType,
    },
  ) =>
    ((IsCalculationVariable(oldCalculation) &&
      IsCalculationVariable(nextCalculation) &&
      UglyCalculation(oldCalculation) === UglyCalculation(nextCalculation)) ||
      IsPointsVariable(oldCalculation) ||
      IsPointsVariable(nextCalculation)) &&
    oldColor === nextColor &&
    areTimeDataEqual(oldData, nextData) &&
    oldXAxisType === nextXAxisType &&
    deepEqual(oldLineSeriesOptions, nextLineSeriesOptions) &&
    deepEqual(oldFormat, nextFormat),
);
