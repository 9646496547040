import { NotifyType, SendNotificationBody, SerializedEditorState } from '@dametis/core';

import {
  AtSignMentionsRegex,
  AtSignMentionsRegexAliasRegex,
  CapitalizedNameMentionsRegex,
  QueryMatch,
} from '../config/MentionPluginConfig';

export const checkForCapitalizedNameMentions = (text: string, minMatchLength: number): QueryMatch | null => {
  const match = CapitalizedNameMentionsRegex.exec(text);
  if (match !== null) {
    const maybeLeadingWhitespace = match[1];

    const matchingString = match[2];
    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      };
    }
  }
  return null;
};

export const checkForAtSignMentions = (text: string, minMatchLength: number): QueryMatch | null => {
  let match = AtSignMentionsRegex.exec(text);
  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  } else {
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[3];
    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2],
      };
    }
  }
  return null;
};

export const getPossibleQueryMatch = (text: string): QueryMatch | null => {
  const match = checkForAtSignMentions(text, 0);
  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
};

export const generateNotifications = (message: SerializedEditorState): SendNotificationBody | undefined => {
  const userIds = [];
  message.root.children.forEach(node => {
    (node as any).children?.forEach(child => {
      if (child.type === 'mention') userIds.push(child.user.uuid);
    });
  });
  return userIds.length > 0 ? { userIds, emails: [], comment: '', type: NotifyType.MENTION } : undefined;
};
