import { makeStyles } from '@mui/styles';

import { theme } from 'theme';

const useChartSegmentLabelStyles = makeStyles(() => ({
  container: {
    // color: theme.palette.white,
    // backgroundColor: theme.palette.grey[2000],
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    // boxShadow: '0 1px 2px 1px #9daeb830',
    // backgroundColor: 'rgba(20, 24, 28, 0.70)',
    padding: '4px 8px',
    margin: theme.spacing(1),
    minWidth: '150px',
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: `0 10px 15px -3px ${theme.palette.grey[900]}40, 0 4px 6px -2px ${theme.palette.grey[900]}60`,
  },
  actionButton: {
    marginLeft: 'auto',
    position: 'relative',
    marginTop: theme.spacing(2),
  },
  actionButton__iconButton: {
    // color: theme.palette.white,
    position: 'absolute',
    right: '-5px',
    bottom: 0,
  },
  title: {
    fontWeight: 600,
    color: theme.palette.black,
  },
  text: {
    display: 'flex',
    '& p:first-child': {
      marginRight: theme.spacing(1),
    },
  },
}));

export default useChartSegmentLabelStyles;
