import { AlarmInfo, StreamingBodyKind, UUID } from '@dametis/core';

import { sdk } from 'sdk';
import { RootState, useSelector } from 'store';

import { api } from './index';

const activeAlarmsApi = api.injectEndpoints({
  endpoints: build => ({
    readActiveAlarms: build.query<AlarmInfo[], { siteId?: UUID } | void>({
      providesTags: ['ActiveAlarms'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const { socket } = state.auth;
        const userId = state.auth.user?.uuid;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        const data = await new Promise<AlarmInfo[]>(resolve => {
          socket.once(`users:${userId}`, async ({ siteId: siteUuid, activeAlarms }: { siteId: string; activeAlarms: AlarmInfo[] }) => {
            if (siteId === siteUuid) {
              resolve(activeAlarms);
            } else {
              try {
                if (siteId === undefined) {
                  resolve([]);
                  return;
                }
                const { data: alarms } = await sdk.alarm.ListActive(siteId);
                resolve(alarms);
              } catch (error) {
                console.error(error);
                resolve([]);
              }
            }
          });
          socket.emit(StreamingBodyKind.REFRESH_ACTIVE_ALARMS, {
            kind: StreamingBodyKind.REFRESH_ACTIVE_ALARMS,
            data: { siteId },
          });
        });
        return { data };
      },
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved, getState, updateCachedData }) {
        const state = getState() as RootState;
        const { socket } = state.auth;
        const userId = state.auth.user?.uuid;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        const listener = ({ siteId: siteUuid, activeAlarms }: { siteId: string; activeAlarms: AlarmInfo[] }) => {
          if (siteId !== siteUuid) {
            return;
          }
          updateCachedData(() => activeAlarms);
        };
        let interval: NodeJS.Timeout | undefined;
        try {
          await cacheDataLoaded;
          socket.on(`users:${userId}`, listener);
          interval = setInterval(() => {
            updateCachedData(activeAlarms => {
              activeAlarms.forEach(alarm => {
                if (alarm.incidents[0].status === 'TRIGGERED') {
                  alarm.incidents[0].triggeredFor += 5;
                }
              });
            });
          }, 5000);
        } catch (error) {
          console.error(error);
        }
        await cacheEntryRemoved;
        socket.off(`users:${userId}`, listener);
        clearInterval(interval);
      },
    }),
  }),
});

export const { useReadActiveAlarmsQuery } = activeAlarmsApi;

export const useActiveAlarms: typeof useReadActiveAlarmsQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadActiveAlarmsQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};
