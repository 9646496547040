import { ProjectTemplateCategoryType, ProjectTemplateFieldType, RatingType, SelectType } from '@dametis/core';

export default {
  title: {
    template: 'Mis modelos de proyectos',
    editForm: 'Modificar el modelo',
    newTemplate: 'Nuevo modelo',
    duplicateTemplate: 'Duplicar el modelo',
    createdAtBy: 'Creado el {{date}} por {{name}}',
    createTemplateModal: 'Nuevo modelo',
    editTemplateModal: 'Modificar el modelo',
  },
  button: {
    newTemplate: 'Modelo',
    create: 'Crear',
    save: 'Guardar',
    share: 'Compartir',
    edit: 'Modificar',
    duplicate: 'Duplicar',
    cancel: 'Cancelar',
    continue: 'Continuar',
    delete: 'Suprimir',
    add: 'Añadir',
    close: 'Cerrar',
    next: 'Siguiente',
    previous: 'Anterior',
    done: 'Validar',
    goToProjects: 'Ver los proyectos asociados',
    addThreshold: 'Umbral',
    addCriteria: 'Criterio',
    addThresholdRange: 'Añadir un rango de umbral.',
  },
  label: {
    name: 'Nombre',
    description: 'Descripción',
    fields: 'Campos',
    categories: 'Secciones',
    fieldType: {
      [ProjectTemplateFieldType.STRING]: 'Texto simple',
      [ProjectTemplateFieldType.TEXTAREA]: 'Cuadro de texto',
      [ProjectTemplateFieldType.NUMBER]: 'Número',
      [ProjectTemplateFieldType.DATE]: 'Fecha',
      [ProjectTemplateFieldType.SELECT]: 'Seleccionador de opciones',
      [ProjectTemplateFieldType.PICTOPICKER]: 'Seleccionador de iconos',
    },
    inputSpecs: {
      min: 'Mínimo',
      max: 'Máximo',
      from: 'Fecha mín.',
      to: 'Fecha máx.',
    },
    ratingOptions: 'Opciones',
    ratingType: {
      [`variant_${RatingType.OPEN_GRADE}`]: 'Calificación libre',
      [`variant_${RatingType.WEIGHTED_CRITERION}`]: 'Criterio ponderado',
    },
    score: 'Puntuación',
    formula: 'Fórmula de cálculo de la puntuación',
    maxScore: 'Puntuación total =',
    selectType: {
      [`variant_${SelectType.DROPDOWN}`]: 'Desplegable',
      [`variant_${SelectType.FLAT}`]: 'Lista exhaustiva',
    },
    multipleChoice: 'Permitir selección múltiple',
    relatedProjects: 'Proyectos asociados ({{count}})',
    thresholds: {
      min: 'De',
      max: 'A',
      thresholdValue: 'Tag del umbral',
    },
  },
  placeholder: {
    name: 'Nombre',
    number: 'Número',
    options: 'Opciones',
    option: 'Opción',
    text: 'Texto',
    statusStep: 'Paso ',
    taskStatus: 'Estado ',
    rating: {
      name: 'Nombre',
      min: 'Mínimo',
      max: 'Máximo',
      importance: 'Peso',
      options: 'Ejemplo: Bajo, Medio, Alto',
    },
  },
  text: {
    addFirstTemplate: 'Añadir un nuevo modelo',
    preview: 'Vista previa',
    options: 'Opciones',
    option: 'Opción',
    value: 'valor',
    settingsDone: 'Configuración finalizada',
    globalGrade: 'Puntuación total =',
    thresholds: 'Umbrales',
    criteria: 'Criterios',
    noCriteria: 'Sin criterios',
    noThreshold: 'Sin umbrales',
    noEditIfProjects: 'No se puede modificar el modelo porque hay proyectos asociados.',
    noDeleteIfProjects: 'No se puede suprimir el modelo porque hay proyectos asociados.',
    overlap: 'Los umbrales se cruzan, este umbral no se tendrá en cuenta.',
  },
  stepper: {
    layout: 'Secciones',
    settings: 'Configuración',
  },
  helperText: {
    [`variant_${ProjectTemplateCategoryType.TASKS}`]:
      'Defina los estados disponibles para seguir el progreso de las tareas de un proyecto.',
    [`variant_${ProjectTemplateCategoryType.STATUS}`]: 'Defina los pasos clave de sus proyectos.',
    [`variant_${ProjectTemplateCategoryType.RATING}`]: 'Defina criterios de puntuación para sus proyectos.',
    [`variant_${RatingType.WEIGHTED_CRITERION}`]: 'Defina las opciones, desde la más baja hasta la más impactante, separadas por comas.',
  },
  tooltip: {
    datePick: 'Seleccionar una fecha',
    clear: 'Suprimir',
    deleteCustomField: 'Vaciar el campo',
    deleteOption: 'Vaciar la opción',
    deleteCriteria: 'Vaciar el criterio',
    deleteThreshold: 'Vaciar el umbral',
    deleteStatusStep: 'Vaciar el paso',
    statusLimit: 'El número de estados está limitado a 6.',
    taskStatusLimit: 'El número de estados de tareas está limitado a 4.',
  },
  toast: {
    duplicateSuccess: 'Modelo duplicado con éxito.',
    createSuccess: 'Modelo creado con éxito.',
    editSuccess: 'Modelo modificado con éxito.',
    deleteSuccess: 'Modelo suprimido con éxito.',
  },
};
