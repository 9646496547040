export default {
  // home: {
  //   collaborators: 'Collaborateurs',
  //   activeAlarms: 'Alarmes actives',
  //   triggeredOn: 'Déclenchée le',
  //   seeMore_open: 'Voir {{number}} autres alarmes',
  //   seeMore_close: 'Masquer {{number}} alarmes',
  // },
  title: {
    reports: 'Rapports',
    yourMetrics: 'Mes indicateurs',
  },
  text: {
    noReports: 'Aucun rapport',
    welcomeIdentity: 'Bonjour {{name}}',
    welcomeGroup: 'Vous êtes sur Corporate de',
    defaultSiteReport: 'Rapport par défaut du site',
    defaultGroupReport: 'Rapport par défaut du groupe',
  },
  label: {
    displayReport: 'Afficher un rapport',
  },
  button: {
    setDefault: 'Définir par défaut',
    unselect: 'Désélectionner',
    close: 'Fermer',
    save: 'Enregistrer',
  },
  toast: {
    errorFetchingReports: 'Impossible de récupérer les rapports.',
    errorUploadingPreview: "Erreur pendant le téléchargement de l'image.",
    errorReadingFilePreview: 'Impossible de lire le fichier.',
    errorFileTypePreview: 'Mauvais type de fichier.',
    errorDeletingPreview: "Erreur pendant la suppression de l'image.",
    errorSelectingReport: 'Erreur pendant la sauvegarde du rapport.',
  },
  tooltip: {
    editReport: 'Changer de rapport',
    unselectReport: 'Désélectionner le rapport',
    editPhoto: `Changer d'image`,
    deletePhoto: `Supprimer l'image`,
    removeDefault: 'Supprimer le rapport par défaut',
    setDefaultGroup: 'Définir comme le rapport par défaut de {{group}}',
    setDefaultSite: 'Définir comme le rapport par défaut de {{site}}',
    unsetDefaultGroup: 'Supprimer le rapport par défaut de {{group}}',
    unsetDefaultSite: 'Supprimer le rapport par défaut de {{site}}',
  },
};
