import { cloneDeep } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

import {
  CalculationVariable,
  CreatePlaygroundBody,
  DataVariable,
  DefaultSharings,
  HasCustomPeriod,
  Operator,
  Period,
  PlaygroundInfo,
  RawSharings,
  TabType,
  Unit,
} from '@dametis/core';

import { DateToUrlParam } from 'functions/dateInUrl';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { TypedThunk } from 'store';

import { getNextColor } from '../../../functions/color';
import { HasDaChart, IPlayground, ITab, ITyVariable } from '../types';

import { createTyChart } from './TY_CHART/TyChart';
import { createTyGroup } from './TY_CHART/TyGroup';
import { createTyVariable } from './TY_CHART/TyVariable';
import { createTab, exportTab } from './Tab';
import { getChartDataFromType } from './shared';

export const createPlayground = ({
  uuid = '',
  name = '',
  tabs = [],
  sharings = DefaultSharings,
  shared = true,
  canEdit = true,
  canEditSharings = true,
  tags = [],
  createdAt = new Date(),
  updatedAt = new Date(),
  site = undefined,
  group = undefined,
  owner = undefined,
}: Partial<PlaygroundInfo> = {}): IPlayground => ({
  uuid,
  name,
  tabs: tabs.map(createTab),
  sharings,
  shared,
  canEdit,
  canEditSharings,
  tags,
  createdAt,
  updatedAt,
  site,
  group,
  owner,
});

export const exportPlayground = async ({
  tabs,
  name,
  uuid,
  sharings,
  shared,
  canEdit,
  canEditSharings,
  createdAt,
  updatedAt,
  site,
  group,
  owner,
}: IPlayground): Promise<CreatePlaygroundBody> => ({
  tabs: await Promise.all(tabs.map(exportTab)),
  name,
  uuid,
  sharings: RawSharings(sharings),
  shared,
  canEdit,
  canEditSharings,
  createdAt,
  updatedAt,
  site,
  group,
  owner,
});

export const getComputation = (badComputation: CalculationVariable<DataVariable>): CalculationVariable<DataVariable> => {
  const goodComputation: CalculationVariable<DataVariable> = cloneDeep(badComputation);
  Object.entries(goodComputation.vars).forEach(([variableUuid, variable]) => {
    if (variable.operator === Operator.LAST || variable.operator === Operator.FIRST) {
      goodComputation.vars[variableUuid].operator = Operator.MEAN;
    }
  });
  return goodComputation;
};

export const openTyChartPlayground = async (
  computations: { calculation: CalculationVariable; forcedUnit?: Unit }[],
  navigate: NavigateFunction,
  from?: Date,
  to?: Date,
): Promise<void> => {
  const chart = createTyChart();
  const groups: Record<string, ITyVariable[]> = {};
  const generatedVariables = [];
  computations.forEach(computation => {
    const unit = computation.forcedUnit ? computation.forcedUnit : getCalculationUnit(computation.calculation) ?? 'NO_UNIT';
    const variable = createTyVariable({
      expression: { ...computation.calculation, slate: undefined } as any,
      unit,
      color: getNextColor(generatedVariables),
    });
    generatedVariables.push(variable);
    groups[unit] = [...(groups[unit] ?? []), { ...variable, unit: unit === 'NO_UNIT' ? null : unit }];
  });
  Object.entries(groups).forEach(([unit, groupVariables]) => {
    const group = createTyGroup({ variables: groupVariables, unit: unit === 'NO_UNIT' ? null : unit });
    chart.groups.push(group);
  });
  const tab = createTab({ type: TabType.TY_CHART, chart });
  const playground = await exportPlayground(createPlayground({ tabs: [tab] }));
  const searchParams = new URLSearchParams();
  if (from) {
    searchParams.append('from', DateToUrlParam(from));
  }
  if (to) {
    searchParams.append('to', DateToUrlParam(to));
  }
  const dateParam = searchParams.toString();
  navigate(`/playgrounds/new${dateParam.length > 0 ? '?' : ''}${dateParam}`, { state: playground });
};

export const createEmptyTyChartPlayground = (): Partial<PlaygroundInfo> => {
  const chart = createTyChart();
  const tab = createTab({ type: TabType.TY_CHART, chart });
  const playground = createPlayground({ tabs: [tab] });
  return playground;
};

export const refreshData =
  (tab: ITab, period: Period): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      // éviter de faire un refresh dans sur le premier load (daChart encore à null)
      if (HasDaChart(tab) && tab.chart.daChart === null) return;
      if (!HasCustomPeriod(tab)) {
        await dispatch(getChartDataFromType(tab.type, tab));
        if (HasDaChart(tab) && tab.chart.daChart) {
          if ([TabType.TY_CHART].includes(tab.type)) {
            tab.chart.daChart.setPeriod(period);
          }
          tab.chart.daChart.redraw();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };
