export default {
  title: {
    debuggus: 'Debuggus',
  },
  text: {
    dependencies: 'Variables de dependencia',
    dependants: 'Variables dependientes',
    toInspect: 'Variable a inspeccionar',
    general: 'General',
    properties: 'Propiedades',
    expression: 'Expresión',
    graph: 'Gráfico',
    unknown: 'Desconocido',
    noVariables: 'No has seleccionado ninguna variable todavía.',
    name: 'Nombre:',
    uuid: 'UUID:',
    owner: 'Propietario:',
    createdAt: 'Creado el:',
    updatedAt: 'Actualizado el:',
    progress: 'Progreso:',
    chunks: 'Chunks:',
    lastSeen: 'Visto:',
    lastValue: 'Último valor:',
    raw: 'Bruto:',
    syncMad: 'Diferencia mediana absoluta (regularidad)',
    version: 'Versión',
    tree: 'Árbol',
  },
  toast: {
    readError: 'Error al leer los datos desde la API',
    copyToClipboard: 'El UUID de la variable ha sido copiado al portapapeles',
    copyToClipboardJSON: 'Se ha copiado el JSON al portapapeles',
  },
  buttons: {
    vnc: 'VNC',
    json: 'JSON',
    close: 'Cerrar',
    copy: 'Copiar',
  },
  tooltip: {
    disabledJSON: 'No hay datos para mostrar',
    buttonJSON: 'Mostrar datos en formato JSON',
    copy: 'Copiar UUID',
  },
  input: {
    label: {
      message: 'Mensaje',
    },
  },
};
