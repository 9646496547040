import axios, { AxiosError } from 'axios';
import { FC, useEffect } from 'react';

import { useDispatch } from 'store';
import { clearAuth } from 'store/actions/auth';

const AxiosInterceptor: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    axios.interceptors.response.use(
      response => response,
      (error: AxiosError) => {
        if (
          error.response?.status === 401 &&
          window.location.pathname !== '/login' &&
          window.location.pathname !== '/login/forgotPassword' &&
          window.location.pathname !== '/login/lostPassword' &&
          window.location.pathname !== '/login/invitation' &&
          window.location.pathname !== '/login/oauthcookie' &&
          window.location.pathname !== '/login/twofa' &&
          window.location.pathname !== '/login/enrollment' &&
          (window.location.pathname !== '/account' || error.response.config.url !== '/api/v1/login/password')
        ) {
          console.log('error', error);
          dispatch(clearAuth());
        }
        return Promise.reject(error);
      },
    );
  }, [dispatch]);
  return null;
};

export default AxiosInterceptor;
