import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Operator, BatchInfo } from '@dametis/core';

export type BatchFilterQuery = { type: string; content: BatchInfo | string };

export interface BatchState {
  currentBatch: BatchInfo | null;
  fetching: boolean;
  allBatches: BatchInfo[];
  flattenBatches: BatchInfo[] | null;
  filters: {
    focusedInput: number;
    focusedResult: number;
    query: BatchFilterQuery[];
    expressionIsValid: boolean;
    operator: Operator | undefined;
    operatorOptions: ['AND', 'OR'];
  };
}

const initialState: BatchState = {
  currentBatch: null,
  fetching: false,
  allBatches: [],
  flattenBatches: [],
  filters: {
    focusedInput: 0,
    focusedResult: 0,
    query: null,
    expressionIsValid: false,
    operator: undefined,
    operatorOptions: ['AND', 'OR'],
  },
};

export const batchSlice = createSlice({
  name: 'batch',
  initialState,
  reducers: {
    setBatches: (state, action: PayloadAction<{ allBatches: BatchInfo[]; flattenBatches: BatchInfo[] }>) => {
      state.allBatches = action.payload.allBatches;
      state.flattenBatches = action.payload.flattenBatches;
    },
    setCurrentBatch: (state, action: PayloadAction<BatchInfo>) => {
      state.currentBatch = action.payload;
    },
    setFilterQuery: (state, action: PayloadAction<BatchFilterQuery[]>) => {
      state.filters.query = action.payload;
    },
    setFilterInput: (state, action: PayloadAction<number>) => {
      state.filters.focusedInput = action.payload;
    },
    setFilterOperator: (state, action: PayloadAction<Operator>) => {
      state.filters.operator = action.payload;
    },
    setBatchFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    clearQuery: state => {
      state.currentBatch = null;
      state.filters = initialState.filters;
    },
    clearBatchStore: () => initialState,
  },
});

export const {
  setBatches,
  setCurrentBatch,
  setFilterQuery,
  setFilterInput,
  setFilterOperator,
  setBatchFetching,
  clearQuery,
  clearBatchStore,
} = batchSlice.actions;

export default batchSlice.reducer;
