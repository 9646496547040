import { drawerClasses, InputAdornment, OutlinedTextFieldProps, TextField, useTheme } from '@mui/material';
import { motion } from 'framer-motion';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationVariable } from '@dametis/core';

interface Props {
  nickname: CalculationVariable['nickname'];
  onNicknameChange: (nickname: CalculationVariable['nickname']) => unknown;
}

const NicknameTextField: FC<Props> = ({ nickname, onNicknameChange }) => {
  const { t } = useTranslation('vnc');
  const theme = useTheme();

  const changeNickname = useCallback<OutlinedTextFieldProps['onChange']>(
    event => {
      onNicknameChange(event.target.value);
    },
    [onNicknameChange],
  );

  return (
    <motion.div
      animate={nickname != null ? 'in' : 'out'}
      initial={false}
      style={{ overflow: 'hidden', position: 'relative' }}
      transition={{ duration: theme.transitions.duration.short / 1000 }}
      variants={{
        in: { height: 38, opacity: 1, marginTop: -theme.shape.borderRadius - 4 },
        out: { height: 0, opacity: 0 },
      }}
    >
      <TextField
        fullWidth
        InputProps={{
          startAdornment: <InputAdornment position="start">{t('input.adornment.nickname')}</InputAdornment>,
          disableUnderline: true,
          size: 'small',
          sx: {
            pt: `${theme.shape.borderRadius}px`,
          },
        }}
        sx={{
          position: 'absolute',
          bottom: 0,
          zIndex: 0,
          [`.${drawerClasses.paper} &`]: {
            zIndex: theme.zIndex.drawer,
          },
        }}
        value={nickname ?? ''}
        variant="filled"
        onChange={changeNickname}
      />
    </motion.div>
  );
};

export default NicknameTextField;
