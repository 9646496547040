import { TodayOutlined } from '@mui/icons-material';
import { Alert, Button, Grid, IconButton, InputAdornment } from '@mui/material';
import { DateValidationError, LocalizationProvider, PickerChangeHandlerContext, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import clsx from 'clsx';
import { Duration, formatDuration, isValid, startOfDay } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocale } from 'localization';
import { localizedAdd } from 'localization/localizedDateFns';

import useDateTimePickerStyles from './DateTimeRangePanel.styles';

interface Props {
  from: Date;
  to: Date;
  format: string;
  customPeriodsDuration?: Duration;
  onChange: ([from, to]: Array<Date>) => void;
  setOpenPanel: (open: boolean) => void;
}

const CalendarTabLite: FC<Props> = ({
  from: propsFrom,
  to: propsTo,
  format,
  customPeriodsDuration = undefined,
  onChange,
  setOpenPanel,
}) => {
  const { t } = useTranslation('dateTimePicker');

  const [from, setFrom] = useState<Date>(new Date());
  const [to, setTo] = useState<Date>(new Date());

  const classes = useDateTimePickerStyles();

  const handleFrom = useCallback(
    (newDate: Date, context: PickerChangeHandlerContext<DateValidationError>) => {
      if (!context.validationError) {
        setFrom(startOfDay(newDate));
        setTo(localizedAdd(newDate, customPeriodsDuration));
      }
    },
    [customPeriodsDuration],
  );

  const handleSubmit = useCallback(() => {
    onChange([from, to]);
    setOpenPanel(false);
  }, [from, to, setOpenPanel, onChange]);

  useEffect(() => {
    setFrom(propsFrom);
    setTo(propsTo);
  }, [propsFrom, propsTo]);

  return (
    <>
      <Grid container>
        <Grid item className={clsx(classes.popper__element)} xs={12}>
          {customPeriodsDuration && (
            <Alert severity="info" sx={{ width: 260 }}>
              {t('text.fixedPeriodDuration', {
                duration: formatDuration(customPeriodsDuration, { locale: getLocale() }),
              })}
            </Alert>
          )}
          <LocalizationProvider adapterLocale={getLocale()} dateAdapter={AdapterDateFns}>
            <div className={classes.dateRange}>
              <DatePicker
                disableFuture
                format={format}
                label={t('input.from.label')}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    margin: 'dense',
                  },
                }}
                value={from}
                onChange={handleFrom}
              />
              <DatePicker
                disabled
                format={format}
                label={t('input.to.label')}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    fullWidth: true,
                    disabled: true,
                    margin: 'dense',
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton disabled className={classes.dateRange__iconButton} size="large">
                            <TodayOutlined />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                }}
                value={to}
                onChange={() => null}
              />
              <Button color="secondary" disabled={!isValid(from)} size="small" variant="contained" onClick={handleSubmit}>
                {t('button.submit')}
              </Button>
            </div>
          </LocalizationProvider>
        </Grid>
      </Grid>
    </>
  );
};

export default CalendarTabLite;
