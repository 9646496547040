import { ButtonBase, Card, CardContent, Paper, Popover } from '@mui/material';
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CommentInfo, UpdateCommentBody } from '@dametis/core';

import Comment from 'components/UI/Comments/Comment/Comment';
import CommentMessage from 'components/UI/Comments/Comment/Display/CommentMessage';
import CommentProvider from 'components/UI/Comments/Comment/context/CommentContext';
import CommentsProvider from 'components/UI/Comments/context/CommentsContext';
import { useDispatch, useSelector } from 'store';
import { editComment, openComment } from 'store/slices/playground';
import { ITab, ITyChart } from 'types';
import { EntityType } from 'types/comment';

import useChartCommentStyles from './ChartComment.styles';

interface Props {
  comment: CommentInfo;
  handleDelete: () => Promise<void>;
}

const ChartComment: FC<Props> = ({ comment, handleDelete }) => {
  const [expand, setExpand] = useState<boolean>(false);

  const anchorEl = useRef(null);
  const dispatch = useDispatch();
  const selectedTab = useSelector(state => state.playground.tabs.find(tab => tab.uuid === state.playground.selectedTab));
  const oneLineMessage = useMemo(() => {
    const oneLine = cloneDeep(comment.message);
    const nb = oneLine.root.children.length;
    oneLine.root.children.splice(1, nb);
    return oneLine;
  }, [comment.message]);

  useEffect(() => {
    dispatch(openComment(false));
    setExpand(false);
  }, [dispatch, selectedTab]);

  const handleClick = useCallback(() => {
    setExpand(true);
  }, [setExpand]);

  const handleClose = useCallback(() => {
    setExpand(false);
  }, [setExpand]);

  const deleteComment = useCallback(async () => {
    try {
      await handleDelete();
      handleClose();
    } catch (error) {
      console.error(error);
    }
  }, [handleDelete, handleClose]);

  const changeComment = useCallback(
    async (newComment: UpdateCommentBody) => {
      setExpand(false);
      await (selectedTab as ITab<ITyChart>).chart.daChart.updateComment(newComment);
      dispatch(editComment(newComment));
    },
    [dispatch, selectedTab],
  );

  const classes = useChartCommentStyles();

  return (
    <>
      <CommentsProvider entity={EntityType.VARIABLE} entityUuid={comment.variableId}>
        <CommentProvider>
          <Popover
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            open={expand}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={handleClose}
          >
            <Card className={classes.container__popover}>
              <CardContent>
                <Comment displayActions comment={comment} onDeleteCb={deleteComment} onUpdateCb={changeComment} />
              </CardContent>
            </Card>
          </Popover>
          <ButtonBase ref={anchorEl} id={comment.uuid} sx={{ borderRadius: theme => theme.spacing(0.75) }} onDoubleClick={handleClick}>
            <Paper className={clsx(classes.container__comment, expand && classes['container__comment--hidden'])} elevation={0}>
              <CommentMessage inline backgroundColor="transparent" editorState={oneLineMessage} />
            </Paper>
          </ButtonBase>
        </CommentProvider>
      </CommentsProvider>
    </>
  );
};

export default ChartComment;
