import i18next from 'i18next';

import { Format } from '@dametis/core';
import { MultipleName, MultipleSymbol, UnitConverter } from '@dametis/unit';

import { formatValueFromFormat } from 'components/UI/FormatPicker/functions/formatValueFromFormat';

import { isExistingUnit } from './isExistingUnit';

export const getFormattedValueUncomposed = ({
  value,
  userUnit,
  baseUnit = '',
}: {
  value: number;
  userUnit?: string;
  baseUnit?: string;
}): { value: number; unit: UnitConverter; multiple?: MultipleSymbol | MultipleName } => {
  const parsedBaseUnit = UnitConverter.Parse(baseUnit?.trim() ?? '');
  const parsedUserUnit = UnitConverter.Parse(userUnit?.trim(), !isExistingUnit(parsedBaseUnit.unitName) ? parsedBaseUnit.raw : undefined);

  try {
    // Si l'unité de base existe ou est vide, on prend celle-ci pour convertir
    if (parsedBaseUnit.raw === '' || isExistingUnit(parsedBaseUnit.unitName)) {
      const convertedValue = parsedBaseUnit.ConvertValueToUnit(value, parsedUserUnit);
      return { value: convertedValue, unit: parsedUserUnit };
    }
    // si notre unité de base n'existe pas, on prend l'unité définie par l'utilisateur si possible ainsi que son multiple si elle est identique a l'unité de base
    const parsedUnit = parsedUserUnit.raw === undefined ? parsedBaseUnit : parsedUserUnit;
    const multiple = parsedUserUnit.unitName === parsedBaseUnit.unitName ? parsedUnit.multiple.symbol : undefined;
    const convertedValue = parsedBaseUnit.ConvertValueToMultipleSymbol(value, multiple);
    return { value: convertedValue, unit: parsedUnit, multiple };
  } catch (err) {
    // Si la conversion échoue, on prend simplement l'unité définie par l'utilisateur si possible
    const parsedUnit = parsedUserUnit.raw === undefined ? parsedBaseUnit : parsedUserUnit;
    return { value, unit: parsedUnit };
  }
};

export const getFormattedValue = ({
  value,
  userUnit,
  baseUnit = '',
  format,
}: {
  value: number;
  userUnit?: string;
  baseUnit?: string;
  format?: Format;
}): string => {
  if (!Number.isFinite(value)) {
    return '-';
  }
  const { value: parsedValue, unit: parsedUnit, multiple } = getFormattedValueUncomposed({ value, userUnit, baseUnit });

  if (format) {
    if (!format.hideUnit) {
      return `${formatValueFromFormat(parsedValue, format)} ${parsedUnit.raw}`;
    }
    return formatValueFromFormat(parsedValue, format);
  }

  return parsedUnit.Format({
    value: parsedValue,
    ...(multiple ? { multiple } : { rawTargetUnit: parsedUnit.raw }),
    locale: i18next.language,
  });
};
