import { CreateProjectTemplateBody, ProjectTemplateInfo, UUID, UpdateProjectTemplateBody } from '@dametis/core';

import { sdk } from '../../sdk';
import { RootState, useSelector } from '../index';

import { api } from './index';

const projectTemplatesApi = api.injectEndpoints({
  endpoints: build => ({
    readProjectTemplates: build.query<ProjectTemplateInfo[], { siteId?: UUID } | void>({
      providesTags: ['ProjectTemplates'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        try {
          const { data } = await sdk.projectTemplate.List(siteId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createProjectTemplate: build.mutation<ProjectTemplateInfo, CreateProjectTemplateBody>({
      invalidatesTags: ['ProjectTemplates'],
      queryFn: async (body, { getState }) => {
        const {
          auth: { selectedSite },
        } = getState() as RootState;
        try {
          const { data } = await sdk.projectTemplate.Create(selectedSite.uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteProjectTemplate: build.mutation<void, UUID>({
      invalidatesTags: ['ProjectTemplates'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.projectTemplate.Delete(uuid);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateProjectTemplate: build.mutation<ProjectTemplateInfo | null, { uuid: UUID; updates: UpdateProjectTemplateBody }>({
      invalidatesTags: ['ProjectTemplates'],
      queryFn: async ({ uuid, updates }) => {
        try {
          const { data } = await sdk.projectTemplate.Update(uuid, updates);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadProjectTemplatesQuery,
  useCreateProjectTemplateMutation,
  useDeleteProjectTemplateMutation,
  useUpdateProjectTemplateMutation,
  endpoints: projectTemplatesEndpoints,
} = projectTemplatesApi;

export const useProjectTemplates: typeof useReadProjectTemplatesQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadProjectTemplatesQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};
