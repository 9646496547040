import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsBoost from 'highcharts/modules/boost';
import HighchartsNetworkgraph from 'highcharts/modules/networkgraph';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsSankey from 'highcharts/modules/sankey';
import HighchartsReact, { HighchartsReactRefObject } from 'highcharts-react-official';
import { forwardRef, PropsWithChildren, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DaChartProvider from './DaChartContext';
import { getDaChartOptions } from './daChartOptions';

HighchartsMore(Highcharts);
HighchartsSankey(Highcharts);
HighchartsNetworkgraph(Highcharts);
NoDataToDisplay(Highcharts);
HighchartsBoost(Highcharts);

export interface DaChartProps {
  daChartOptions?: Partial<Highcharts.Options>;
}

const DaChart = forwardRef<HighchartsReactRefObject, PropsWithChildren<DaChartProps>>(({ daChartOptions, children }, ref) => {
  const chartRef = useRef<HighchartsReactRefObject>(null);
  useImperativeHandle(ref, () => {
    return chartRef.current;
  }, []);
  const { t } = useTranslation('dachart');

  const [highcharts, setHighcharts] = useState<HighchartsReactRefObject | null>(null);

  const options = useMemo(() => getDaChartOptions({}, t), [t]);

  const intervalRef = useRef<ReturnType<typeof setTimeout>>(null);
  const resizeObserver = useRef<ResizeObserver>(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (chartRef.current) {
        setHighcharts(chartRef.current);
        clearInterval(intervalRef.current);
      }
    }, 100);
  }, []);

  useEffect(() => {
    if (highcharts?.chart) {
      highcharts.chart.update(daChartOptions, true);
    }
  }, [daChartOptions, highcharts?.chart]);

  useEffect(() => {
    if (highcharts?.container) {
      highcharts.container.current.style.height = '100%';
    }
  }, [highcharts?.container]);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver(() => {
      if (highcharts?.chart) {
        highcharts.chart.reflow();
      }
    });
    resizeObserver.current.observe(chartRef.current.container.current);
    return () => {
      resizeObserver.current.disconnect();
    };
  }, [highcharts?.chart]);

  return (
    <DaChartProvider highcharts={highcharts}>
      <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
      {children}
    </DaChartProvider>
  );
});

DaChart.defaultProps = {
  daChartOptions: {},
};

DaChart.displayName = 'DaChart';

export default DaChart;

// export default memo(DaChart, ({ daChartOptions: oldDaChartOptions }, { daChartOptions: nextDaChartOptions }) =>
//   deepEqual(oldDaChartOptions, nextDaChartOptions),
// );
