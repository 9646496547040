import {
  AccordionProps,
  AccordionSummary,
  Box,
  CheckboxProps,
  Chip,
  ListItem,
  ListItemButton,
  ListItemText,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TagInfo, UUID } from '@dametis/core';

import Counter from 'components/UI/Counter/Counter';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import { PropsContext } from 'components/VNC/context';
import { useDispatch } from 'store';
import { useTags } from 'store/api/tags';
import { useVncStore } from 'zustand/stores/vnc';

import { FilterElementProps } from './Filters';
import { FilterAccordion, FilterAccordionDetails, FilterAccordionSummaryStack, FilterList, FilterListSubheader } from './styled';

const emptyArray: TagInfo[] = [];

interface TagFilter {
  key: UUID;
  tag: TagInfo;
  enabled: boolean;
}

const TagsFilters: FC<FilterElementProps> = ({ expanded, setExpanded, accordionsNumber }) => {
  const { t } = useTranslation('vnc');
  const dispatch = useDispatch();

  const disabled = useContext(PropsContext).disableFilters.includes('tags');

  const tagsFilters = useVncStore(state => state.filters.tags);
  const setFilterValue = useVncStore(state => state.setFilterValue);
  const getResults = useVncStore(state => state.getResults);

  const { data: tags = emptyArray } = useTags();

  const [search, setSearch] = useState<string>('');
  const [limit, setLimit] = useState<number>(1);

  const trueTagsCount = useMemo(() => Object.values(tagsFilters).filter(Boolean).length, [tagsFilters]);

  const sortedTagFilters = useMemo<TagFilter[]>(() => {
    return tags
      .map(tag => ({ key: tag.uuid, tag, enabled: Boolean(tagsFilters[tag.uuid]) }), {})
      .sort((tagA, tagB) => tagA.tag.name.toLocaleLowerCase().localeCompare(tagB.tag.name.toLocaleLowerCase()));
  }, [tags, tagsFilters]);

  const searchedTagFilters = useMemo<TagFilter[]>(() => {
    if (search.trim().length === 0) return sortedTagFilters;
    const fuse = new Fuse(sortedTagFilters, {
      keys: ['tag.name'],
    });
    return fuse.search(search).map(result => result.item);
  }, [sortedTagFilters, search]);

  const displayedTagFilters = useMemo<TagFilter[]>(
    () => sortedTagFilters.filter(u => u.enabled).slice(0, limit),
    [sortedTagFilters, limit],
  );

  const notDisplayedTagFilters = useMemo<TagFilter[]>(
    () => sortedTagFilters.filter(u => u.enabled).slice(limit),
    [sortedTagFilters, limit],
  );

  const handleChangeExpanded = useCallback<AccordionProps['onChange']>(
    (event, isExpanded) => {
      setExpanded(isExpanded ? 'tags' : false);
      setLimit(1);
    },
    [setExpanded],
  );

  const handleToggleTag = useCallback(
    (tagFilter: TagFilter) => async () => {
      setFilterValue('tags', tagFilter.key, !tagFilter.enabled);
      setLimit(1);
      await dispatch(getResults());
    },
    [dispatch, getResults, setFilterValue],
  );

  const handleChangeTag = useCallback(
    (tagFilter: TagFilter): CheckboxProps['onChange'] =>
      async (event, checked) => {
        setFilterValue('tags', tagFilter.key, checked);
        setLimit(1);
        await dispatch(getResults());
      },
    [dispatch, getResults, setFilterValue],
  );

  const handleSearch = useCallback((newSearch: string) => {
    setSearch(newSearch);
  }, []);

  const removeTagFilter = useCallback(
    (tagFilter: TagFilter) => async () => {
      setFilterValue('tags', tagFilter.key, false);
      await dispatch(getResults());
    },
    [dispatch, getResults, setFilterValue],
  );

  const displayAllTagFilters = useCallback(() => {
    setLimit(Infinity);
  }, []);

  return (
    <FilterAccordion expanded={expanded} filterAccordionsNumber={accordionsNumber} onChange={handleChangeExpanded}>
      <AccordionSummary>
        <FilterAccordionSummaryStack>
          <FilterListSubheader>{t('accordion.tags')}</FilterListSubheader>
          {trueTagsCount > 0 && <Counter count={trueTagsCount} />}
        </FilterAccordionSummaryStack>
      </AccordionSummary>
      <FilterAccordionDetails>
        <Box px={1}>
          <SearchInput extendable={false} placeholder={t('input.placeholder.searchTag')} value={search} onChange={handleSearch} />
        </Box>
        <Box p={1} sx={[limit === 1 && { height: 40 }]}>
          {displayedTagFilters.length === 0 && <Typography variant="subtitle2">{t('text.noDisplayedTagFilters')}</Typography>}
          {displayedTagFilters.map(tagFilter => (
            <Chip
              key={tagFilter.key}
              label={tagFilter.tag?.name}
              size="small"
              sx={{ fontSize: '12px', mr: 0.5, mb: 0.5 }}
              onDelete={removeTagFilter(tagFilter)}
            />
          ))}
          {Boolean(notDisplayedTagFilters.length) && (
            <Tooltip placement="right" title={notDisplayedTagFilters.map(u => u.key).join(', ')}>
              <Chip
                label={`+${notDisplayedTagFilters.length}`}
                size="small"
                sx={{ fontSize: '12px', mr: 0.5, mb: 0.5 }}
                onClick={displayAllTagFilters}
              />
            </Tooltip>
          )}
        </Box>
        <FilterList>
          {searchedTagFilters.map(tagFilter => (
            <ListItem
              key={tagFilter.key}
              disablePadding
              secondaryAction={
                <Switch
                  checked={tagFilter.enabled}
                  color="primary"
                  disabled={disabled}
                  edge="end"
                  size="small"
                  tabIndex={-1}
                  onChange={handleChangeTag(tagFilter)}
                />
              }
            >
              <ListItemButton disabled={disabled} selected={tagFilter.enabled} onClick={handleToggleTag(tagFilter)}>
                <ListItemText
                  primary={<Chip label={tagFilter.tag.name} size="small" sx={{ fontSize: 'inherit' }} />}
                  primaryTypographyProps={{ component: 'div' }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </FilterList>
        {searchedTagFilters.length === 0 && (
          <Typography align="center" p={1} pt={0} variant="subtitle2">
            {t('text.noTagFilter')}
          </Typography>
        )}
      </FilterAccordionDetails>
    </FilterAccordion>
  );
};

export default TagsFilters;
