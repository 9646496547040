import { ClearAll } from '@mui/icons-material';
import { Box, List, ListItem, ListItemButton, ListSubheader, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Format } from '@dametis/core';

import { clearFormatHistory, getFormatHistory } from 'functions/formatHistory';
import { StorageFormat } from 'types/format';

import ActionButton from '../Buttons/ActionButton/ActionButton';

import FormatItem from './FormatItem';
import { useFormatPickerContext } from './FormatPickerContext';

const FormatHistory: FC = () => {
  const { t } = useTranslation('format');

  const { setPickerValue, onChange } = useFormatPickerContext();

  const [historyItems, setHistoryItems] = useState<StorageFormat[]>([]);

  const handleFormatClick = useCallback(
    (format: Format): MouseEventHandler<HTMLDivElement> =>
      () => {
        setPickerValue(format);
        onChange(format);
      },
    [setPickerValue, onChange],
  );

  const handleClearHistory = useCallback(() => {
    clearFormatHistory();
    setHistoryItems([]);
  }, []);

  useEffect(() => {
    setHistoryItems(getFormatHistory());
  }, []);

  return (
    <List sx={{ height: 300, position: 'relative', overflow: 'auto', '& ul': { padding: 0 } }}>
      <li>
        <ul>
          <ListSubheader disableGutters>
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Box>{t('subheader.history')}</Box>
              <ActionButton disabled={historyItems.length === 0} startIcon={<ClearAll />} onClick={handleClearHistory}>
                {t('button.clearHistory')}
              </ActionButton>
            </Stack>
          </ListSubheader>
          {historyItems.map(historyItem => (
            <ListItem key={historyItem.uuid} disablePadding>
              <ListItemButton sx={{ py: 1.5 }} onClick={handleFormatClick(historyItem.format)}>
                <FormatItem format={historyItem.format} />
              </ListItemButton>
            </ListItem>
          ))}
          {historyItems.length === 0 && (
            <ListItem>
              <Typography sx={{ width: '100%' }} textAlign="center" variant="subtitle2">
                {t('text.noElement')}
              </Typography>
            </ListItem>
          )}
        </ul>
      </li>
    </List>
  );
};

export default FormatHistory;
