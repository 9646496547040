import Papa, { ParseResult, ParseConfig } from 'papaparse';

const readStringAsync = async <T>(
  csvString: string | NodeJS.ReadableStream,
  config: ParseConfig<T> & { download?: false; worker?: boolean },
): Promise<ParseResult<T>> => {
  const result = await new Promise<ParseResult<T>>(resolve => {
    Papa.parse(csvString as NodeJS.ReadableStream, {
      ...config,
      complete: results => {
        resolve(results);
      },
    });
  });
  return result;
};

export default readStringAsync;
