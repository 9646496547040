export default {
  // home: {
  //   collaborators: 'Collaborators',
  //   activeAlarms: 'Actives alarms',
  //   triggeredOn: 'Triggered on',
  //   seeMore_close: 'See {{number}} other alarms',
  //   seeMore_open: 'Hide {{number}} alarms',
  // },
  title: {
    reports: 'Reports',
    yourMetrics: 'Your metrics',
  },
  text: {
    noReports: 'No reports',
    welcomeIdentity: 'Hello {{name}}',
    welcomeGroup: 'You are in the group interface of',
    defaultSiteReport: 'Default site report',
    defaultGroupReport: 'Default group report',
  },
  label: {
    displayReport: 'Display a report',
  },
  button: {
    setDefault: 'Set as default',
    unselect: 'Unselect',
    close: 'Close',
    save: 'Save',
  },
  toast: {
    errorFetchingReports: 'Unable to fetch reports.',
    errorUploadingPreview: 'Error while file upload.',
    errorReadingFilePreview: 'Unable to read file.',
    errorFileTypePreview: 'Bad file type.',
    errorDeletingPreview: 'Error while file deletion.',
    errorSelectingReport: 'Error while saving report.',
  },
  tooltip: {
    editReport: 'Change report',
    unselectReport: 'Unselect report',
    editPhoto: 'Change image',
    deletePhoto: 'Delete image',
    removeDefault: 'Remove default report',
    setDefaultGroup: 'Set as default report for {{group}}',
    setDefaultSite: 'Set as default report for {{site}}',
    unsetDefaultGroup: 'Remove default report from {{group}}',
    unsetDefaultSite: 'Remove default report from {{site}}',
  },
};
