import { subMinutes } from 'date-fns';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { IsDataVariable, Period, TadaApiResponse, UUID } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import DaLineSeries from 'classes/DaCharts/DaLineSeries';
import { updateLiveChart } from 'components/Playground/functions/LIVE_CHART/LiveChart';
import { getStoreTab, getStoreVariable } from 'components/Playground/functions/shared';
import { parseTadaErrors } from 'errors/parseErrors';
import { calculationToString } from 'functions/calculationToString';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { getSocket, isSocketConnected, subscribeToDataRooms, unsubscribeFromDataRooms } from 'functions/socketIo';
import { Tada } from 'functions/tada/tada';
import { TypedThunk } from 'store';
import { displaySdkErrorToast, displayTadaErrorToasts, hasTadaErrors } from 'store/actions/toasts';
import { livechartUpdateVariable } from 'store/slices/playground';

import { chartColors } from '../../../../functions/color';
import { ILiveVariable, ILiveVariableProps, IsLiveChartTab } from '../../types';
import { createVariable } from '../Variable';

// ACTIONS

export const updateLiveVariable =
  (tabUuid: UUID, variableUuid: UUID, options: ILiveVariableProps): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsLiveChartTab(tab)) return;
    const variable = dispatch(getStoreVariable<ILiveVariable>(tabUuid, variableUuid));
    dispatch(livechartUpdateVariable({ tabUuid, variableUuid, options }));
    if (options.min !== undefined || options.max !== undefined) {
      variable.daSeries.yAxis.setExtremes(options.min, options.max);
    }
    if (options.color) {
      variable.daSeries.setColor(options.color);
    }
    if (options.unit != null) {
      variable.daSeries.setUnit(options.unit);
    }
    if (options.expression) {
      await dispatch(
        updateLiveVariable(tab.uuid, variable.uuid, {
          name: options.expression.nickname?.length ? options.expression.nickname : calculationToString(options.expression),
        }),
      );
      await dispatch(getLiveVariableData(tab.uuid, variable.uuid));
    }
    if (options.name) {
      variable.daSeries.setName(options.name);
    }
    variable.daSeries?.chart.redraw();
  };

// OTHERS

export const createLiveVariable = ({
  uuid = uuidv4(),
  name = '',
  expression = createCalculationVariable(),
  daSeries = null,
  unit = null,
  color = chartColors[0],
  min = null,
  max = null,
  hidden = false,
}: ILiveVariableProps = {}): ILiveVariable => ({
  ...createVariable({ uuid, name: name || expression.nickname || calculationToString(expression) }),
  expression,
  daSeries,
  unit,
  color,
  min,
  max,
  socketRoomId: null,
  socketHandler: null,
  hidden,
});

export const exportLiveVariable = (variable: ILiveVariable): ILiveVariable => {
  if (!variable) return null;
  return { ...variable, daSeries: null, socketRoomId: null, socketHandler: null };
};

export const getLiveVariableData =
  (tabUuid: UUID, variableUuid: UUID): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsLiveChartTab(tab)) return;
    const variable = dispatch(getStoreVariable<ILiveVariable>(tabUuid, variableUuid));
    const {
      variables: { byId },
    } = getState();
    const getData = async (period: Period) => {
      const calculation = cloneDeep({
        period: period.Raw(),
        ...variable.expression,
      });
      try {
        const { data } = await Tada([calculation], undefined, { rawMode: true, fill: 'AUTO' });
        if (hasTadaErrors(data)) {
          console.error(data[0].errors);
          dispatch(displayTadaErrorToasts(parseTadaErrors(data)));
          return null;
        }
        return data;
      } catch (err) {
        console.error(err);
        dispatch(displaySdkErrorToast(err));
        return null;
      }
    };
    const period = new Period({ from: tab.chart.customTimeRange.from, to: tab.chart.customTimeRange.to });
    const data = await getData(period);
    variable.daSeries.addData(
      DaLineSeries.convertDataFromApiToHighcharts(data?.[0]?.results).filter(point => point[1] !== null),
      period,
    );
    const firstVar = getSingleVariableCalculation(variable.expression);
    if (variable.unit == null && IsDataVariable(firstVar)) {
      try {
        const { unit } = byId[firstVar.variableUuid];
        await dispatch(updateLiveVariable(tab.uuid, variable.uuid, { unit }));
      } catch (err) {
        //
      }
    }
    if (!isSocketConnected()) return;
    const handleData = (sData: TadaApiResponse) => {
      if (!sData?.results?.at(-1)) return;
      variable.daSeries.addPoint([new Date(sData.results.at(-1).time).getTime(), sData.results.at(-1).value]);
      dispatch(
        updateLiveChart(tab.uuid, {
          customTimeRange: {
            from: subMinutes(new Date(), 5),
            to: new Date(),
          },
        }),
      );
    };
    if (variable.socketRoomId) {
      await unsubscribeFromDataRooms([{ id: variable.socketRoomId, listener: variable.socketHandler }]);
    }
    const roomIds = await subscribeToDataRooms([variable.expression]);
    const roomId = roomIds.at(0);
    if (!roomId) return;
    await dispatch(updateLiveVariable(tab.uuid, variable.uuid, { socketRoomId: roomId, socketHandler: handleData }));
    const socket = getSocket();
    socket.on(roomId, handleData);
  };
