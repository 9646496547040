import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export enum RecomputeFromMethod {
  START = 'start',
  // DATE = 'date',
  NOW = 'now',
}

export interface RecomputeFromPickerProps {
  value: RecomputeFromMethod | undefined;
  onChange: (newValue: RecomputeFromMethod | undefined) => void;
}

const RecomputeFromPicker: FC<RecomputeFromPickerProps> = ({ value, onChange }) => {
  const { t } = useTranslation('variables');

  const [recomputeFromMethod, setRecomputeFromMethod] = useState<RecomputeFromMethod>(undefined);
  // const [recomputeFrom, setRecomputeFrom] = useState<RecomputeFromMethod>(RecomputeFromMethod.START);

  const handleChangeFunction: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newValue = event.target.value as RecomputeFromMethod;
      setRecomputeFromMethod(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  useEffect(() => {
    if (value === RecomputeFromMethod.NOW || value === RecomputeFromMethod.START) {
      setRecomputeFromMethod(value);
    } else {
      setRecomputeFromMethod(undefined);
    }
  }, [value]);

  return (
    <>
      <FormControl sx={{ width: 1 }}>
        <InputLabel>{t('label.recomputeFromMethod')}</InputLabel>
        <Select
          fullWidth
          MenuProps={{ sx: { maxWidth: 500 } }}
          renderValue={inputValue => t(`recomputeFromMethod.${inputValue}`)}
          value={recomputeFromMethod ?? ''}
          onChange={handleChangeFunction}
        >
          <MenuItem value={RecomputeFromMethod.START}>
            <ListItemText
              primary={t(`recomputeFromMethod.${RecomputeFromMethod.START}`)}
              secondary={t(`recomputeFromLabel.${RecomputeFromMethod.START}`)}
              secondaryTypographyProps={{ whiteSpace: 'normal' }}
            />
          </MenuItem>
          <MenuItem value={RecomputeFromMethod.NOW}>
            <ListItemText
              primary={t(`recomputeFromMethod.${RecomputeFromMethod.NOW}`)}
              secondary={t(`recomputeFromLabel.${RecomputeFromMethod.NOW}`)}
              secondaryTypographyProps={{ whiteSpace: 'normal' }}
            />
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default RecomputeFromPicker;
