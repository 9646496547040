import { DrawerActionName } from 'types/drawer';

export default {
  menu: {
    item: {
      mostRecent_zero: 'Ninguna visita reciente',
      mostRecent_one: 'Visita reciente',
      mostRecent_other: 'Visitas recientes',
      actions_zero: 'Ninguna acción rápida',
      actions_one: 'Acción rápida',
      actions_other: 'Acciones rápidas',
    },
    actions: {
      [DrawerActionName.NEW_PLAYGROUND_WITH_TY_CHART]: 'Gráfico temporal',
      [DrawerActionName.NEW_REPORT_CUSTOM_PERIOD]: 'Informe personalizado',
    },
  },
  toast: {
    responsiveDrawerDisabled: 'Menú adaptativo desactivado.',
    resetResponsiveDrawer: 'Restablecer',
  },
  tooltip: {
    removeEntity: 'Vaciar de la lista',
  },
  home: {
    button: 'Inicio',
  },
  energies: {
    button: 'Energías',
  },
  analysis: {
    button: 'Análisis',
  },
  synoptics: {
    button: 'Sinópticos',
  },
  lego: {
    button: 'Diagrama de bloques',
    button_short: 'Bloques',
    blockTypes: 'Tipos de bloques',
    blocks: 'Bloques',
  },
  alarms: {
    button: 'Alarmas',
    alarms: 'Registro',
    activeAlarms: 'Alarmas activas',
    alarmsHistory: 'Historial',
    settings: 'Configuración',
  },
  reports: {
    button: 'Informes',
  },
  playgrounds: {
    button: 'Playgrounds',
  },
  projects: {
    button: 'Proyectos',
    projects: 'Lista de proyectos',
    template: 'Modelos de proyectos',
    tasks: 'Tareas',
  },
  acquisition: {
    button: 'Adquisición',
    box: 'Módulos',
    settings: 'Configuración',
  },
  configuration: {
    button: 'Configuración',
    button_short: 'Config',
    variables: 'Variables',
    macros: 'Macros',
    architecture: 'Arquitectura',
    boxes: 'Cajas',
    group: 'Grupo',
    site: 'Sitio',
    import: 'Entrada manual',
    manualEntry: 'Entrada manual',
    advancedEntry: 'Corrección de datos',
    fileImport: 'Importar datos',
    export: 'Exportar',
    batches: 'Batchs',
    models: 'Modelado',
  },
  billing: {
    button: 'Facturación',
    bills: 'Facturas',
    contracts: 'Contratos',
  },
  activities: {
    button: 'Actividades',
  },
  debuggus: {
    button: 'Debuggus',
  },
  editData: {
    button: 'Edición de datos',
    button_short: 'Edición',
    editData: 'Edición de datos',
    dataHistory: 'Historial de datos',
  },
  misc: {
    button: 'Varios',
  },
  monitoring: {
    button: 'Monitoring',
    beta: 'Beta',
  },
  boxes: {
    button: 'Cajas',
  },
  users: {
    button: 'Usuarios',
  },
  groups: {
    button: 'Grupos',
  },
  authentications: {
    button: 'Autenticaciones',
    button_short: 'Auth',
  },
  steamMixes: {
    button: 'Mezcla de vapor',
  },
};
