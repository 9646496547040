import Tracker, { SanitizeLevel } from '@openreplay/tracker';

import { GroupInfo, SiteInfo, UserInfo } from '@dametis/core';

import { OpenReplayEvent } from '../components/VNC/types';

export const openReplayTracker = new Tracker({
  projectKey: window.location.hostname === 'demo.dametis.com' ? 'dE6klkyk8Fky5xGsKE5e' : 'j4Dv6UDzKSGXJBNpfBjZ',
  ingestPoint: `https://${window.location.hostname}/ingest`,
  __DISABLE_SECURE_MODE: window.location.hostname === 'localhost',
  defaultInputMode: 0,
  obscureInputEmails: false,
  obscureTextEmails: false,
  obscureInputDates: false,
  obscureInputNumbers: false,
  domSanitizer: (node: Element) => {
    const type = node.getAttribute('type');
    const elementClassNames = node.classList;

    if (type === 'password' || elementClassNames.contains('password')) {
      return SanitizeLevel.Hidden;
    }

    return SanitizeLevel.Plain;
  },
});

const shouldStartOpenReplay = ['my.dametis.com', 'demo.dametis.com'].includes(window.location.hostname);

export const openReplayEvent = (event: OpenReplayEvent, payload?: Record<string, any>): void => {
  if (!openReplayTracker.isActive()) return;
  openReplayTracker.event(event, payload);
};

export const openReplay = async (user: UserInfo, selectedGroup: GroupInfo, selectedSite: SiteInfo): Promise<void> => {
  if (!shouldStartOpenReplay) return;
  if (!openReplayTracker.isActive()) {
    await openReplayTracker.start();
  }
  if (user !== null && user !== undefined) {
    openReplayTracker.setUserID(user.email);
    openReplayTracker.setMetadata('uuid', user.uuid);
  }
  if (selectedGroup !== null && selectedGroup !== undefined) {
    openReplayTracker.setMetadata('groupName', selectedGroup.name);
  }
  if (selectedSite !== null && selectedSite !== undefined) {
    openReplayTracker.setMetadata('siteName', selectedSite.name);
  }
};
