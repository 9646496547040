import { MenuItem, Select } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReferencedBy, SourceColumn } from '@dametis/core';

import { useMappingSettingsContext } from '../../Home/CreateFileImportModal/MappingSettings/MappingSettingsContext';
import { areSameSourceColumns } from '../../helpers/areSameSourceColumns';
import { getColumnSourceValue } from '../../helpers/getColumnSourceValue';
import { getSourceValueLabel } from '../../helpers/getSourceValueLabel';

export enum SourceValue {
  DEFAULT = 'default',
  DATE_TIME = 'dateTime',
  REFERENCE = 'reference',
  VALUE = 'value',
  SKIPPED_COLUMN = 'skippedColumn',
  FIXED_VARIABLE = 'fixedVariable',
}

export interface SourceValuePickerProps {
  column: SourceColumn;
  onChange?: (newSourceValue: SourceValue) => void;
}

const SourceValuePicker: FC<SourceValuePickerProps> = ({ column, onChange = undefined }) => {
  const { t } = useTranslation('fileImport');

  const {
    fixedVariables,
    referencedBy,
    dateTimeSource,
    referenceSource,
    valueSource,
    skippedColumns,
    setFixedVariables,
    useHeader,
    setDateTimeSource,
    setReferenceSource,
    setValueSource,
    setSkippedColumns,
  } = useMappingSettingsContext();

  const [value, setValue] = useState<SourceValue>(SourceValue.DEFAULT);

  const availableSourceValues = useMemo(
    () => [
      SourceValue.DEFAULT,
      SourceValue.DATE_TIME,
      ...(referencedBy === ReferencedBy.ROWS ? [SourceValue.REFERENCE] : []),
      ...(referencedBy === ReferencedBy.ROWS ? [SourceValue.VALUE] : []),
      ...(referencedBy === ReferencedBy.COLUMNS && useHeader ? [SourceValue.SKIPPED_COLUMN] : []),
      ...(referencedBy === ReferencedBy.COLUMNS && !useHeader ? [SourceValue.FIXED_VARIABLE] : []),
    ],
    [referencedBy, useHeader],
  );

  const clearOldValue = useCallback(() => {
    if (value === SourceValue.DATE_TIME) {
      setDateTimeSource(null);
    }
    if (value === SourceValue.REFERENCE) {
      setReferenceSource(null);
    }
    if (value === SourceValue.VALUE) {
      setValueSource(null);
    }
    if (value === SourceValue.SKIPPED_COLUMN) {
      setSkippedColumns(state => state.filter(skippedColumn => !areSameSourceColumns(skippedColumn, column)));
    }
    if (value === SourceValue.FIXED_VARIABLE) {
      setFixedVariables(state => state.filter(fixedVariable => !areSameSourceColumns(fixedVariable.source, column)));
    }
  }, [value, column, setDateTimeSource, setReferenceSource, setValueSource, setSkippedColumns, setFixedVariables]);

  const handleChangeSourceValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      clearOldValue();
      const newValue = event.target.value as SourceValue;
      if (newValue === SourceValue.DATE_TIME) {
        setDateTimeSource(column);
      }
      if (newValue === SourceValue.REFERENCE) {
        setReferenceSource(column);
      }
      if (newValue === SourceValue.VALUE) {
        setValueSource(column);
      }
      if (newValue === SourceValue.SKIPPED_COLUMN) {
        setSkippedColumns(state => [...state, column]);
      }
      if (newValue === SourceValue.FIXED_VARIABLE) {
        setFixedVariables(state => [...state, { variableId: null, source: column }]);
      }
      if (onChange) {
        onChange(newValue);
      }
    },
    [clearOldValue, column, setDateTimeSource, setReferenceSource, setValueSource, setSkippedColumns, setFixedVariables, onChange],
  );

  useEffect(() => {
    setValue(getColumnSourceValue(column, dateTimeSource, referenceSource, valueSource, skippedColumns, fixedVariables));
  }, [column, dateTimeSource, referenceSource, valueSource, skippedColumns, fixedVariables]);

  return (
    <Select fullWidth value={value} onChange={handleChangeSourceValue}>
      {availableSourceValues.map((sourceValue, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MenuItem key={index} value={sourceValue}>
          {getSourceValueLabel(sourceValue, referencedBy, useHeader, t)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SourceValuePicker;
