export default {
  login: 'Login',
  changePassword: 'Change your password',
  activeAccount: 'Activate your account',
  home: 'Home',
  account: 'Account',
  alarms: 'Alarms',
  batches: 'Batches',
  batch: 'Batch',
  box: 'Box',
  groupBoxes: 'Boxes',
  activeAlarms: 'Active alarms',
  alarmsHistory: 'History',
  alarmsSettings: 'Settings',
  alarm: 'Alarm',
  boxes: 'Devices state',
  configuration: 'Configuration',
  corporate: 'Corporate',
  dashboards: 'Dashboards',
  dataHistory: 'Data History',
  device: 'Device',
  group: 'Group',
  lego: 'Block Diagram',
  blockTypes: 'Types catalog',
  blocks: 'Blocks',
  playgrounds: 'Playgrounds',
  playground: 'Playground',
  reports: 'Reports',
  report: 'Report',
  site: 'Site',
  synoptics: 'Synoptics',
  synoptic: 'Synoptic',
  projects: 'Projects',
  project: 'Project',
  energies: 'Energies',
  architecture: 'Architecture',
  variables: 'Variables',
  settings: 'Settings',
  fileImport: 'Data import',
  dataImport: 'Import',
  dataExport: 'Export',
  manualEntry: 'Manual entry',
  advancedEntry: 'Data correction',
  models: 'Models',
  model: 'Model',
  admin: {
    activities: 'Activities',
    backup: 'Backups',
    boxes: 'Boxes',
    debuggus: 'Debuggus',
    roles: 'Roles',
    users: 'Users',
    variables: 'Variables',
    groups: 'Groupes',
    sites: 'Sites',
    authentications: 'Authentications',
    editData: 'Edit Data',
    dataHistory: 'Data History',
  },
  macros: 'Macros',
  billing: 'Billing',
  bills: 'Bills',
  bill: 'Bill',
  contract: 'Contract',
  contracts: 'Contracts',
  tags: 'Tags',
  steamMixes: 'Steam mixes',
  404: 'Page not found',
};
