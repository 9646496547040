import { CalculationChartMenu } from 'components/UI/CalculationMenu/CalculationChart/CalculationChart';
import { FromPeriod, ToPeriod } from 'store/slices/calculationMenu';

export default {
  label: {
    from: 'Depuis',
    fromPeriod: 'Depuis...',
    to: "jusqu'à",
    to_other: "jusqu'au",
    toPeriod: "Jusqu'à...",
    format: 'Format',
    playground: 'Créer un playground',
    addToCart: 'Ajouter au panier',
    alreadyInCart: 'Déjà dans le panier',
    configuration: 'Voir dans Configuration',
    dataVariableConfiguration: 'Voir dans Configuration',
    blockConfiguration: 'Voir dans Schéma-bloc',
    addComment: 'Ajouter un commentaire',
    addCommentToVar: 'Ajouter un commentaire à la variable « {{var}} »',
    copyVariableName: 'Copier le nom',
    copyCalculation: "Copier l'équation",
    calculation: 'Calcul',
    variables: 'Variables ({{count}})',
    addCalculation: 'Ajouter le calcul',
    addVariables: 'Ajouter les variables ({{count}})',
  },
  tooltip: {
    menu: {
      [CalculationChartMenu.GRAPH]: 'Courbe',
      [CalculationChartMenu.STATISTICS]: 'Statistiques',
      [CalculationChartMenu.SETTINGS]: 'Réglages',
    },
    statisticsDisabled: 'Les statistiques ne sont pas disponibles pour un calcul',
    disableConfiguration: "Le configurateur n'est disponible que pour les variables acquises et les schéma-blocs",
    disableAddComment: "Ajouter un commentaire n'est disponible que pour les variables acquises",
  },
  fromPeriod: {
    [FromPeriod.LAST_HOUR]: '1 heure',
    [FromPeriod.LAST_DAY]: '1 jour',
    [FromPeriod.LAST_WEEK]: '7 jours',
  },
  toPeriod: {
    [ToPeriod.NOW]: 'maintenant',
    [ToPeriod.LAST_POINT]: 'dernier point',
  },
  text: {
    errorStatistics: "Impossible d'avoir les statistiques",
    noFormatting: 'Aucun format',
    mean: 'Moyenne',
    min: 'Min',
    max: 'Max',
    integral: 'Intégrale',
    stddev: 'Écart type',
    gap: 'Écart',
    noData: 'Aucune donnée à afficher.',
    lastPointDate: 'Date du dernier point :',
    noLastPointDate: 'Aucune valeur',
  },
  button: {
    goToLastPointDate: 'Aller au dernier point',
  },
  toast: {
    copyVariableName: 'Nom de la variable copié dans le presse-papier',
    commentPostedWithSuccess: 'Commentaire créé avec succès',
    commentPostedError: 'Impossible de créer le commentaire',
  },
};
