import { ConnectionType, MetadataType, MetricCategory, OrderBy } from '@dametis/core';

// import { BlockDiagramView } from 'components/Lego/BlockDiagram/BlockDiagramTopBar';
import { SortedBy as BlockSortedBy } from 'components/Lego/BlockDiagram/BlockList/BlockList';
import { SortedBy as FolderSortedBy } from 'components/Lego/BlockDiagram/FolderList/FolderList';
import { BlockTypePanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/BlockTypeRightPanel/BlockTypeRightPanel';
import { StandardBlockPanelTab } from 'components/Lego/LegoCatalog/CatalogRightPanel/StandardBlockRightPanel/StandardBlockRightPanel';
import { MenuTab } from 'components/Lego/LegoCatalog/LegoCatalog';
import { MetadataListName } from 'components/Lego/UI/BlockType/CreateBlockTypeModal/MetadataListsStep';
import { TabBlockFiltersElements } from 'types/lego';

export default {
  title: {
    lego: 'Block diagram',
    blockTypes: 'Block types',
    typesCatalog: 'Types catalog',
    createBlockTypeModal: 'New block type',
    createStandardBlockModal: 'New standard block',
    editBlockTypeModal: 'Edit block type',
    blockTypePanel: 'Block type',
    blocks: 'Blocks',
    folders: 'Folders',
    createBlockModal: 'New block',
    createFolderModal: 'New folder',
    updateBlockModal: 'Edit block',
    updateFolderModal: 'Edit folder',
    blockPanel: 'Block',
    folderPanel: 'Folder',
    blockFilters: 'Filter parameters and metrics',
    nextAction: 'You have just created a block. What do you want to do now?',
    setBlockParameters: 'Block "{{blockName}}" parameters',
    moveTo: 'Move to...',
    chooseBlock: 'Choose a block',
    chooseBlockFromBlockType: 'Choose a « {{blockTypeName}} » block',
    navigation: 'Navigation',
    editFolder: 'Edit folder',
    tools: 'Tools',
  },
  subtitle: {
    folders: 'Folders',
    blockTypes: 'Types',
    standardBlocks: 'Standard blocks',
  },
  button: {
    add: 'Add',
    addBlock: 'Add block',
    addBlocks: 'Add blocks',
    newBlockType: 'Block type',
    newBlock: 'Block',
    newFolder: 'Folder',
    close: 'Close',
    cancel: 'Cancel',
    create: 'Create',
    update: 'Edit',
    duplicate: 'Duplicate',
    setParameters: 'Parameters',
    edit: 'Edit',
    submit: 'Submit',
    delete: 'Delete',
    previous: 'Previous',
    next: 'Next',
    blocks: 'Blocks',
    blocks_zero: '0 block',
    blocks_one: '1 block',
    blocks_other: '{{count}} blocks',
    goToBlocks: 'Go to blocks',
    open: 'Open',
    openInPlayground: 'Open in playground',
    createAnOtherBlock: 'Create an other block',
    setBlockParameters: 'Define parameters',
    save: 'Save',
    sortedBy: 'Sorted by: $t(lego:label.sortedBy.{{value}})',
    new: 'New',
    createBlock: 'Create a block',
    createFolder: 'Create a folder',
    moveTo: 'Move to',
    move: 'Move',
    newStandardBlock: 'New standard block',
  },
  label: {
    name: 'Name',
    description: 'Description',
    parameters: 'Parameters',
    parametersLength_zero: 'No parameter',
    parametersLength_one: '{{count}} parameter',
    parametersLength_other: '{{count}} parameters',
    metrics: 'Metrics',
    metricsLength_zero: 'No metric',
    metricsLength_one: '{{count}} metric',
    metricsLength_other: '{{count}} metrics',
    connections: 'Connections',
    connectionsLength_zero: 'No connection',
    connectionsLength_one: '{{count}} connection',
    connectionsLength_other: '{{count}} connections',
    availableParameters: 'Available parameters',
    blockTypes: 'Block types',
    owners: 'Owners',
    undefinedParameters_one: '{{count}} not assigned',
    undefinedParameters_other: '{{count}} not assigned',
    comment: 'Comment',
    sortedBy: {
      [BlockSortedBy.BLOCK_NAME]: 'Block name',
      [BlockSortedBy.OWNER]: 'Owner',
      [BlockSortedBy.BLOCKTYPE_NAME]: 'Block type name',
      [BlockSortedBy.NB_PARAMETERS]: 'Number of parameters',
      [BlockSortedBy.NB_METRICS]: 'Number of metrics',
      [BlockSortedBy.CREATED_AT]: 'Creation date',
      [BlockSortedBy.UPDATED_AT]: 'Updated date',
      [BlockSortedBy.STATE]: 'State',
      [FolderSortedBy.FOLDER_NAME]: 'Name',
      [FolderSortedBy.NB_BLOCKS]: 'Number of blocks',
      [FolderSortedBy.NB_FOLDERS]: 'Number of folders',
    },
    blocksLength_zero: 'No block',
    blocksLength_one: '1 block',
    blocksLength_other: '{{count}} blocks',
    standardBlocksLength_zero: 'No standard block',
    standardBlocksLength_one: '{{count}} standard block',
    standardBlocksLength_other: '{{count}} standard blocks',
    blockTypesLength_zero: 'No type',
    blockTypesLength_one: '1 type',
    blockTypesLength_other: '{{count}} types',
    availableBlocksLength_zero: 'None available',
    availableBlocksLength_one: '1 available',
    availableBlocksLength_other: '{{count}} available',
    unavailableBlocksLength_zero: 'None incomplete',
    unavailableBlocksLength_one: '1 incomplete',
    unavailableBlocksLength_other: '{{count}} incomplete',
    blockListToolbar:
      '$t(lego:label.blocksLength, { "count": {{blocksLength}} }) ($t(lego:label.availableBlocksLength, { "count": {{availableBlocksLength}} }), $t(lego:label.unavailableBlocksLength, { "count": {{unavailableBlocksLength}} }))',
    foldersLength_zero: 'No folder',
    foldersLength_one: '1 folder',
    foldersLength_other: '{{count}} folders',
    filtersAndSort: 'Filters and sort',
    newBlock: 'New block',
    newBlockType: 'New type',
    newFolder: 'New folder',
    tags: 'Tags',
    metadataList: 'Metadata',
    options: 'Options',
    isVirtual: 'virtual metric',
    folder: 'Folder',
    blockType: 'Block type',
    standardMetadataList: 'Catalog metadata',
    blockMetadataList: 'Block metadata',
    newStandardBlock: 'New standard block',
    metricMetrics: 'Metrics',
    discrepancyMetrics: 'Discrepancies',
    technicalMetrics: 'Technical',
    standardBlock: 'Standard block',
    standardBlocks: 'Standard blocks',
    groupByType: 'Group by type',
    metadata: 'Metadata',
  },
  placeholder: {
    name: 'Name',
    calculation: 'Calculation',
    blockKey: 'Identifier',
    unit: 'Unit',
    blockType: 'Block type',
    defaultValue: 'Default value',
    value: 'Value',
  },
  stepper: {
    general: 'General',
    metrics: 'Metric',
    parameters: 'Parameters',
    blockType: 'Block type',
    informations: 'Informations',
    connections: 'Connections',
    metadataLists: 'Metadata',
    metadataList: 'Metadata',
    parametersAndMetrics: 'Parameters & metrics',
  },
  stepperContent: {
    name: 'Name : {{name}}',
    standardMetadataListLength_zero: 'No catalog',
    standardMetadataListLength_one: '1 catalog',
    standardMetadataListLength_other: '{{count}} catalog',
    blockMetadataListLength_zero: 'No block',
    blockMetadataListLength_one: '1 block',
    blockMetadataListLength_other: '{{count}} block',
    parametersLength_zero: 'No parameter',
    parametersLength_one: '1 parameter',
    parametersLength_other: '{{count}} parameters',
    connectionsLength_zero: 'No connection',
    connectionsLength_one: '1 connection',
    connectionsLength_other: '{{count}} connections',
    metricsLength_zero: 'No metric',
    metricsLength_one: '1 metric',
    metricsLength_other: '{{count}} metrics',
  },
  text: {
    noBlockType: 'No block type',
    noBlock: 'No block',
    noStandardBlock: 'No standard block',
    noParameter: 'No parameter.',
    noMetric: 'No metric.',
    availableParameter: '{{blockKey}} ({{unit}})',
    otherParametersLength: '+{{count}} parameter(s)',
    otherMetricsLength: '+{{count}} metric(s)',
    metricsCount_zero: 'No metric',
    metricsCount_one: 'One metric',
    metricsCount_other: '{{count}} metrics',
    creationDate: 'Created on {{date}}',
    updateDate: 'Updated on {{date}}',
    addFirstBlockType: 'Add a first block type',
    addFirstBlock: 'Add a first block',
    canNotDeleteMetricReferredInAnOther: 'Cannot delete metric referred in an other.',
    openInPlayground: 'Open in playground.',
    editParameter: 'Edit parameter.',
    blockVariableWithPath: '{{block}} ▸ {{variable}}',
    // blockVariableWithPath: '{{standardBlock}} ▸ {{block}} ▸ {{variable}}'
    unusedParameters: 'Unused parameters:',
    usedParameters: 'Already used parameters:',
    favorite: 'Favorite',
    allSelected: 'Select all',
    chooseBlockType: 'Choose a block type :',
    chooseRequiredParameters: 'Choose required parameters:',
    nbRequiredParametersSelected_zero: 'No selected parameter.',
    nbRequiredParametersSelected_one: 'One selected parameter.',
    nbRequiredParametersSelected_other: '{{count}} parameters selected.',
    metricsAvailable: 'Metrics availability:',
    nbMetricsAvailable_zero: 'No metric available.',
    nbMetricsAvailable_one: 'One metric available.',
    nbMetricsAvailable_other: '{{count}} metrics available.',
    noMetricWithHelper: 'No metric available in this block. You can add parameters to use more metrics.',
    parameterUnset_one: 'This parameter is required for block operation',
    parameterUnset_other: 'These parameters are required for block operation',
    parameterUnsetVnc: 'This parameter is undefined',
    orderBy: {
      [OrderBy.ASC]: 'Ascending',
      [OrderBy.DESC]: 'Descending',
    },
    home: 'Home',
    factory: 'Factory',
    noBlockInFolder: 'You have no block in this folder',
    noFolderInFolder: 'You have no folder',
    noCorrespondingBlock: 'No blocks match your search',
    noCorrespondingFolder: 'No folders match your search',
    noFolder: 'No folder',
    noParameterToDefine: 'No parameter to define.',
    editToAddParameters: 'You have to edit this block to add parameters.',
    copy: 'Copy',
    noConnection: 'No connection',
    noConnectedBlock: 'No connected block',
    noMetadata: 'No metadata',
    connectionName: 'Connection "{{name}}"',
    unknownBlock: 'Unknown block',
    unknownType: 'Unknown type',
    noOption: 'No available option',
    noAvailableBlock: 'No available block',
    noAvailableOption: 'No available option',
    connectedBlocks: 'Connected blocks',
    emptyFolder: 'Empty folder',
    noAvailableFolder: 'No available folder',
    canNotLoadFolders: 'Can not load folders',
    unknownName: 'Unknown name',
    noOptionSelected: 'No selected option',
    noMetricMetrics: 'No metric',
    noDiscrepancyMetrics: 'No discrepancy',
    noTechnicalMetrics: 'No technical',
    noElement: 'No element',
    metadataOptionsLength_zero: 'No option',
    metadataOptionsLength_one: '1 option',
    metadataOptionsLength_other: '{{count}} options',
    deleteBlockTypeWarning_one:
      'This block type is connected to an other block type. Its deletion can affect the proper functionning of the tools.',
    deleteBlockTypeWarning_other:
      'This block type is connected to {{count}} other block types. Its deletion can affect the proper functionning of the tools.',
    deleteBlockWarning_one: 'This block is connected to an other block. Its deletion can affect the proper functionning of the tools.',
    deleteBlockWarning_other:
      'This block is connected to {{count}} other blocks. Its deletion can affect the proper functionning of the tools.',
  },
  toast: {
    notExistingBlockType: 'This block type does not exist',
    notExistingBlock: 'This block does not exist',
    errorDeleteBlockType: 'Cannot delete this block type',
    errorDuplicateBlockType: 'Cannot duplicate this block type',
    errorDeleteBlock: 'Cannot delete this block',
    errorDuplicateBlock: 'Cannot duplicate this block',
    errorCreateBlockType: 'Cannot create this block type',
    errorEditBlockType: 'Cannot edit this block type',
    errorCreateBlock: 'Cannot create this block',
    errorUpdateBlock: 'Cannot edit this block',
    notExistingEntity: 'This entity does not exist',
    notExistingFolder: 'This folder does not exist',
    errorEditBlock: 'Cannot edit this block',
    errorCreateFolder: 'Cannot create this folder',
    errorUpdateFolder: 'Cannot edit this folder',
    deleteBlockFolderSuccess: 'The folder was successfully deleted',
    errorDeleteFolder: 'Cannot delete this folder',
    errorMoveFolderTo: 'Cannot move this folder',
    errorMoveBlockTo: 'Cannot move this block',
    createBlockSuccess: 'The block was successfully created',
    deleteBlockSuccess: 'The block was successfully deleted',
    updateBlockSuccess: 'The block was successfully updated',
    createBlockTypeSuccess: 'The block type was successfully created',
    deleteBlockTypeSuccess: 'The block type was successfully deleted',
    duplicateBlockTypeSuccess: 'The block type was successfully duplicated',
    updateBlockTypeSuccess: 'The block type was successfully updated',
    createStandardBlockSuccess: 'The standard block was successfully created',
    deleteStandardBlockSuccess: 'The standard block was successfully deleted',
    duplicateStandardBlockSuccess: 'The standard block was successfully duplicated',
    updateStandardBlockSuccess: 'The standard block was successfully updated',
  },
  blockFilters: {
    elements: {
      [TabBlockFiltersElements.ALL]: 'All',
      [TabBlockFiltersElements.FAVORITES]: 'Favorites',
      [TabBlockFiltersElements.PARAMETERS]: 'Parameters',
      [TabBlockFiltersElements.METRICS]: 'Metrics',
    },
  },
  tooltip: {
    // view: {
    //   [BlockDiagramView.FOLDERS]: 'Folders',
    //   [BlockDiagramView.ALL_BLOCKS]: 'All blocks',
    // },
    createEntity: 'create an entity',
    seeCalculation: 'See expression',
    createBlock: 'create a block',
    createFolder: 'create a folder',
    createBlockType: 'create a block type',
    addBlock: 'Add block',
    addBlocks: 'Add blocks',
    blockDoesNotExist: 'Unable to find this block',
    edit: 'Edit',
    delete: 'Delete',
    settings: 'Settings',
    unknownBlockType: 'Unknown block type',
    notWorkingStandardBlockMetric: 'This metric needs unselected parameters without default value to work correctly.',
    notWorkingBlockMetric: 'This metric can not be templated. Be sure that its parameters are correctly set.',
    folderContentLength:
      '$t(lego:label.blockTypesLength, { "count": {{blockTypesLength}} }) / $t(lego:label.standardBlocksLength, { "count": {{standardBlocksLength}} }) / $t(lego:label.foldersLength, { "count": {{foldersLength}} })',
  },
  connectionType: {
    [ConnectionType.UNIQUE]: 'Unique',
    [ConnectionType.MULTIPLE]: 'Multiple',
  },
  panelTab: {
    [BlockTypePanelTab.INFORMATIONS]: 'Informations',
    [BlockTypePanelTab.STANDARD_BLOCKS]: 'Standard Blocks',
    [StandardBlockPanelTab.BLOCKS]: 'Blocks',
  },
  menuTab: {
    [MenuTab.FOLDER_CONTENT]: 'All types',
    [MenuTab.NO_FOLDER_CONTENT]: 'Without folder',
  },
  metricCategory: {
    [MetricCategory.METRIC]: 'Metric',
    [MetricCategory.DISCREPANCY]: 'Discrepancy',
    [MetricCategory.TECHNICAL]: 'Technical',
  },
  metadataType: {
    [MetadataType.STRING]: 'String',
    [MetadataType.OPTION]: 'Options list',
    [MetadataType.NUMBER]: 'Value',
  },
  metadataListName: {
    [MetadataListName.STANDARD]: 'Catalog',
    [MetadataListName.BLOCK]: 'Block',
  },
};
