import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tagTypes = [
  'AlarmConnectors',
  'ActiveAlarms',
  'Alarms',
  'Aliases',
  'Blocks',
  'BlockTypes',
  'Comments',
  'Folders',
  'GlobalFolders',
  'Models',
  'Playgrounds',
  'Projects',
  'ProjectTemplates',
  'Reports',
  'StandardBlocks',
  'StyleConfigurations',
  'Synoptics',
  'Tags',
  'TagsDependencies',
  'Teams',
  'Users',
  'SteamMixes',
] as const;

export const api = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: () => ({}),
  tagTypes,
});
