import { createContext, useContext } from 'react';
import { createStore, useStore } from 'zustand';

import { createVncActions, VncActions } from '../actions/vnc';
import { initialVncState, VncState } from '../states/vnc';

export type VncStore = VncState & VncActions;

export const createVncStore = () =>
  createStore<VncStore>((setState, getState) => ({
    ...initialVncState,
    ...createVncActions(setState, getState),
  }));

export const VncStoreContext = createContext(createVncStore());

export const useVncStore = <U>(selector: (state: VncStore) => U) => {
  const store = useContext(VncStoreContext);
  return useStore(store, selector);
};
