import { TFunction } from 'i18next';

import { Shortcut } from '@dametis/core';

import { isRelativeDate, parseTime, relativeToDate, urlParamToDate } from 'functions/dateInUrl';
import { isBefore, localizedFormat } from 'localization/localizedDateFns';

export const getPeriodLabel = (
  shortcut: Shortcut,
  t: TFunction,
  language: string,
  editing = true,
): { periodLabel: string; isPeriodInvalid: boolean } => {
  const formatOptions = {
    en: 'MMM d, yyyy',
    fr: 'd MMM yyyy',
  };

  const dateFormat: string = (formatOptions as { [key: string]: string })[language] || formatOptions.en;

  let from: string = null;
  let to = null;
  let sinceParam: string | null = null;
  let fromParam: string | null = null;
  let toParam: string | null = null;

  if (shortcut && shortcut.url) {
    const urlPeriod = shortcut.url.split('?')[1];
    const searchParams = new URLSearchParams(urlPeriod);

    sinceParam = searchParams.get('since');
    fromParam = searchParams.get('from');
    toParam = searchParams.get('to');

    if (fromParam && toParam) {
      from =
        isRelativeDate(fromParam) && !parseTime(fromParam)
          ? t(`period.${fromParam}`)
          : localizedFormat(urlParamToDate([fromParam, null], language)[0], dateFormat);
      to = isRelativeDate(toParam) ? t(`period.${toParam}`) : localizedFormat(urlParamToDate([null, toParam], language)[1], dateFormat);
    } else if (sinceParam) {
      from = sinceParam;
    }
  }

  const isPeriodInvalid =
    fromParam && toParam && !isBefore(relativeToDate(fromParam, language, toParam), relativeToDate(toParam, language, fromParam));

  let periodLabel = null;

  if (isPeriodInvalid) {
    periodLabel = t('label.invalidPeriod');
  } else {
    let newFrom = from;
    const parsedFrom = parseTime(from);
    if (parsedFrom) {
      newFrom = `${t('period.since')} ${parsedFrom[1]} ${t(`unit.${parsedFrom[2]}`, { count: Number(parsedFrom[1]) })}`;
    }
    if (sinceParam) {
      periodLabel = newFrom || (editing && t('label.setPeriod'));
    } else {
      periodLabel = newFrom && to ? `${newFrom} - ${to}` : editing && t('label.setPeriod');
    }
  }

  return { periodLabel, isPeriodInvalid };
};
