export default {
  button: {
    cancel: 'Cancelar',
    create: 'Crear',
    yesDelete: 'Sí, eliminar',
    updateSteamMixTemplate: 'Actualizar plantilla',
    createSteamMix: 'Crear mezcla de vapor',
    downloadDataEntryTemplate: 'Descargar modelo de archivo de entrada de datos',
    downloadFile: 'Descargar archivo',
    runCalculation: 'Ejecutar cálculo',
    generateReport: 'Generar informe',
    regenerateReport: 'Regenerar informe',
    openReport: 'Abrir informe',
    downloadResults: 'Descargar resultados',
  },
  input: {
    name: 'Nombre',
    year: 'Año',
    pastWeeks: 'Semanas pasadas',
  },
  select: {
    dataEntryTemplate: 'Seleccionar una plantilla precargada',
    empty: 'Vacío',
  },
  text: {
    year: 'Año {{año}}',
    noFilling: 'El archivo de entrada de datos no se rellenará previamente.',
    filling: 'El archivo de entrada de datos se rellenará previamente con los datos reales del {{desde}} al {{hasta}}.',
    noSteamMixes: 'No hay mezclas de vapor',
    steamMixRunning:
      'Cálculo en curso, actualice la página en unos minutos. Para un modelo anual, esto tomará aproximadamente 2 minutos. Para un modelo multianual, esto tomará hasta 15 minutos.',
    steamMixError: 'Se produjo un error al ejecutar el cálculo.',
    confirmSteamMixDeletion: '¿Está seguro de que desea eliminar {{nombre}}?',
    createdAt: 'Creada el {{date}}',
    createdAtBy: 'Creada el {{date}} por {{name}}',
  },
  title: {
    steamMixes: 'Mezclas de vapor',
    addSteamMix: 'Agregar mezcla de vapor',
    downloadDataEntry: 'Descargar archivo de entrada de datos',
    importDataEntry: 'Importar archivo de entrada de datos',
    steamMix: 'Mezcla de vapor',
    deleteSteamMix: 'Eliminar mezcla de vapor',
  },
  tooltip: {
    createSteamMix: 'Crear mezcla de vapor',
    deleteSteamMix: 'Eliminar mezcla de vapor',
    updateSteamMixTemplate: 'Actualizar plantilla',
  },
};
