import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProjectTemplateInfo } from '@dametis/core';

export interface ProjectTemplateState {
  currentProjectTemplate: ProjectTemplateInfo | null;
}

const initialState: ProjectTemplateState = {
  currentProjectTemplate: null,
};

export const projectTemplateSlice = createSlice({
  name: 'projectTemplate',
  initialState,
  reducers: {
    setCurrentProjectTemplate: (state, action: PayloadAction<ProjectTemplateInfo>) => {
      state.currentProjectTemplate = action.payload;
    },
    clearProjectTemplateStore: () => initialState,
  },
});

export const { setCurrentProjectTemplate, clearProjectTemplateStore } = projectTemplateSlice.actions;

export default projectTemplateSlice.reducer;
