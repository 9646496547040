import { Box, BoxProps, styled } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useResizeObserver } from 'usehooks-ts';

const StyledBox = styled(Box, {
  shouldForwardProp: propName => propName !== 'displayBorderTop' && propName !== 'displayBorderBottom',
})<{ displayBorderTop?: boolean; displayBorderBottom?: boolean }>(({ theme, displayBorderTop, displayBorderBottom }) => ({
  flexGrow: 1,
  overflow: 'auto',
  borderStyle: 'solid',
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopColor: displayBorderTop ? theme.palette.divider : 'transparent',
  borderBottomColor: displayBorderBottom ? theme.palette.divider : 'transparent',
}));

export type RightPanelBodyProps = BoxProps<'div'>;

const RightPanelBody: FC<RightPanelBodyProps> = ({ ...props }) => {
  const elementRef = useRef<HTMLDivElement>(null);

  const [displayBorderTop, setDisplayBorderTop] = useState<boolean>(false);
  const [displayBorderBottom, setDisplayBorderBottom] = useState<boolean>(false);

  const toggleElementBorder = useCallback(() => {
    setDisplayBorderTop(elementRef.current.scrollTop !== 0);
    setDisplayBorderBottom(elementRef.current.offsetHeight + elementRef.current.scrollTop < elementRef.current.scrollHeight);
  }, []);

  useResizeObserver({
    ref: elementRef,
    onResize: toggleElementBorder,
  });

  useEffect(() => {
    const element = elementRef.current;
    if (element) {
      element.addEventListener('scroll', toggleElementBorder);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', toggleElementBorder);
      }
    };
  }, [toggleElementBorder]);

  return <StyledBox ref={elementRef} displayBorderBottom={displayBorderBottom} displayBorderTop={displayBorderTop} {...props} />;
};

export default RightPanelBody;
