import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, List, ListItem, ListSubheader, Stack, Tooltip, Typography } from '@mui/material';
import { FC, MouseEventHandler, MutableRefObject, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockVariable, ShortBlockInfo } from '@dametis/core';

import { useBlocks } from 'store/api/blocks';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../../context';
import { useInsertVariable } from '../../../hooks';

import BlockElementMetric from './BlockElementMetric';
import BlockElementParam from './BlockElementParam';

const blocksEmptyArray: ShortBlockInfo[] = [];

export interface BlockElementsListProps {
  containerRef?: MutableRefObject<HTMLDivElement>;
}

const BlockElementsList: FC<BlockElementsListProps> = ({ containerRef = undefined }) => {
  const { t } = useTranslation('vnc');

  const selectedBlockUuid = useVncStore(state => state.lego.selectedBlockUuid);
  const insertVariable = useInsertVariable();

  const { data: blocks = blocksEmptyArray } = useBlocks();

  const { defaultVariableOperator, disableMaths, calculatedVariableMode } = useContext(PropsContext);

  const [calcVisible, setCalcVisible] = useState<boolean>(false);

  const selectedBlock = useMemo(() => blocks.find(block => block.uuid === selectedBlockUuid), [blocks, selectedBlockUuid]);

  const filteredParameters = useMemo(
    () => (selectedBlock?.parameters ?? []).filter(parameter => parameter.isSelected),
    [selectedBlock?.parameters],
  );

  const filteredMetrics = useMemo(() => (selectedBlock?.metrics ?? []).filter(metric => metric.isSelected), [selectedBlock]);

  const handleSelectParameter = useCallback(
    (index: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        const parameter = filteredParameters[index];
        const variable: BlockVariable = {
          blockId: selectedBlock.uuid,
          blockKey: parameter.blockKey,
        };
        if (parameter) {
          insertVariable(variable, disableMaths, defaultVariableOperator, calculatedVariableMode);
        }
        if (containerRef?.current) {
          containerRef.current.scrollLeft = containerRef.current.scrollWidth;
        }
      },
    [filteredParameters, selectedBlock?.uuid, defaultVariableOperator, insertVariable, disableMaths, calculatedVariableMode, containerRef],
  );

  const handleSelectMetric = useCallback(
    (index: number): MouseEventHandler<HTMLDivElement> =>
      () => {
        const metric = filteredMetrics[index];
        const variable: BlockVariable = {
          blockId: selectedBlock.uuid,
          blockKey: metric.blockKey,
        };
        if (metric) {
          insertVariable(variable, disableMaths, defaultVariableOperator, calculatedVariableMode);
        }
        if (containerRef?.current) {
          containerRef.current.scrollLeft = containerRef.current.scrollWidth;
        }
      },
    [filteredMetrics, selectedBlock?.uuid, defaultVariableOperator, insertVariable, disableMaths, calculatedVariableMode, containerRef],
  );

  const onCalcVisibilityChange = useCallback(() => {
    setCalcVisible(prevVisibility => !prevVisibility);
  }, []);

  if (!selectedBlock) {
    return null;
  }
  return (
    <>
      <List subheader={<li />} sx={{ position: 'relative', maxHeight: '100%', overflow: 'auto', '& ul': { p: 0 } }}>
        <li>
          <ul>
            <ListSubheader disableGutters>{t('subtitle.parameters')}</ListSubheader>
            {filteredParameters.map((parameter, index) => (
              <BlockElementParam
                key={parameter.uuid}
                blockId={selectedBlock.uuid}
                disabled={!parameter.calculation}
                element={parameter}
                onClick={handleSelectParameter(index)}
              />
            ))}
            {filteredParameters.length === 0 && (
              <ListItem>
                <Typography variant="subtitle2">{t('text.noParameter')}</Typography>
              </ListItem>
            )}
          </ul>
        </li>
        <li>
          <ul>
            <Stack alignItems="center" direction="row" spacing={0.5}>
              <ListSubheader disableGutters>{t('subtitle.metrics')}</ListSubheader>
              {filteredMetrics.length > 0 && (
                <Tooltip
                  placement="right"
                  title={t(calcVisible ? 'tooltip.metricsCalcOff' : 'tooltip.metricsCalcOn', { count: filteredMetrics.length })}
                >
                  <IconButton size="small" onClick={onCalcVisibilityChange}>
                    {calcVisible ? <VisibilityOutlined fontSize="inherit" /> : <VisibilityOffOutlined fontSize="inherit" />}
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
            {filteredMetrics.map((metric, index) => (
              <BlockElementMetric
                key={metric.uuid}
                blockId={selectedBlock.uuid}
                calcVisible={calcVisible}
                disabled={!metric.templatedCalculation}
                element={metric}
                onClick={handleSelectMetric(index)}
              />
            ))}
            {filteredMetrics.length === 0 && (
              <ListItem>
                <Typography variant="subtitle2">{t('text.noMetric')}</Typography>
              </ListItem>
            )}
          </ul>
        </li>
      </List>
    </>
  );
};

export default BlockElementsList;
