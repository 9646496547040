import { CreatePlaygroundBody, UUID } from '@dametis/core';

import { differenceInHours } from 'localization/localizedDateFns';

import { append, getLocalStorageItem, removeLocalStorageItem } from './localStorage';

export const PLAYGROUND_STORAGE_KEY = 'playgroundRedirectionBody';

export interface PlaygroundRedirectionInfo {
  date: string;
  playground: CreatePlaygroundBody;
}

// export const addItem = (uuid: UUID, playground: CreatePlaygroundBody): void => {
//   setLocalStorageItem(append(PLAYGROUND_STORAGE_KEY, uuid), { playground, date: new Date().toISOString() });
// };

export const removeItem = (uuid: UUID): void => {
  removeLocalStorageItem(append(PLAYGROUND_STORAGE_KEY, uuid));
};

export const getItem = (uuid: UUID): PlaygroundRedirectionInfo =>
  getLocalStorageItem<PlaygroundRedirectionInfo>(append(PLAYGROUND_STORAGE_KEY, uuid)) ?? null;

export const cleanOldItems = () => {
  const keys = Object.keys(localStorage).filter(key => key.startsWith(PLAYGROUND_STORAGE_KEY));
  keys.forEach(key => {
    const uuid = key.replace(`${PLAYGROUND_STORAGE_KEY}_`, '');
    const item = getItem(uuid);
    if (item) {
      const itemDate = new Date(item.date);
      if (differenceInHours(new Date(), itemDate) > 0) {
        removeItem(uuid);
      }
    }
  });
};
