import i18next from 'i18next';

export const durationDisplay = (duration: number): string => {
  if (!duration) return '';
  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration - days * 86400) / 3600);
  const minutes = Math.floor((duration - days * 86400 - hours * 3600) / 60);
  const seconds = Math.floor(duration - days * 86400 - hours * 3600 - minutes * 60);
  if (duration < 60) {
    return `${seconds < 10 ? `0${seconds}` : seconds}s`;
  }
  if (duration < 3600) {
    return `${minutes < 10 ? `0${minutes}` : minutes}m ${seconds < 10 ? `0${seconds}` : seconds}s`;
  }
  if (duration < 86400) {
    return `${hours < 10 ? `0${hours}` : hours}h ${minutes < 10 ? `0${minutes}` : minutes}m ${seconds < 10 ? `0${seconds}` : seconds}s`;
  }
  return `${days}${i18next.t('alarms:text.day')} ${hours < 10 ? `0${hours}` : hours}h ${minutes < 10 ? `0${minutes}` : minutes}m ${
    seconds < 10 ? `0${seconds}` : seconds
  }s`;
};

export const shortDurationDisplay = (duration: number): string => {
  const days = Math.floor(duration / 86400);
  const hours = Math.floor((duration - days * 86400) / 3600);
  const minutes = Math.floor((duration - days * 86400 - hours * 3600) / 60);
  const seconds = Math.floor(duration - days * 86400 - hours * 3600 - minutes * 60);
  let res = '';
  if (duration < 60) {
    res = `${seconds < 10 ? `0${seconds}` : seconds}s`;
  } else if (duration < 3600) {
    res = `${minutes ? `${minutes < 10 ? `0${minutes}` : minutes}m` : ''}`;
    res += `${seconds ? `${seconds < 10 ? ` 0${seconds}` : ` ${seconds}`}s` : ''}`;
  } else if (duration < 86400) {
    res = `${hours ? `${hours < 10 ? `0${hours}` : hours}h` : ''}`;
    res += `${minutes || seconds ? ` ${minutes < 10 ? ` 0${minutes}` : ` ${minutes}`}m` : ''}`;
    res += `${seconds ? `${seconds < 10 ? ` 0${seconds}` : ` ${seconds}`}s` : ''}`;
  } else {
    res = `${days}${i18next.t('alarms:text.day')}`;
    res += `${hours || minutes || seconds ? `${hours < 10 ? ` 0${hours}` : ` ${hours}`}h` : ''}`;
    res += `${minutes || seconds ? ` ${minutes < 10 ? ` 0${minutes}` : ` ${minutes}`}m` : ''}`;
    if (seconds) res += `${seconds < 10 ? ` 0${seconds}` : ` ${seconds}`}s`;
  }
  return res;
};
