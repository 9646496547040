import { List, ListSubheader, Popover, paperClasses } from '@mui/material';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetCalculationVariableStats } from '@dametis/core';

import { PlaygroundOutlined } from 'assets/icons/PlaygroundOutlined';
import { useDispatch, useSelector } from 'store';

import { setMenuPosition } from '../../../../../store/slices/calculationMenu';
import CalculationMenuItem from '../../CalculationMenuItem';

import CalculationBarOption from './CalculationBarOption';
import CalculationTyOption from './CalculationTyOption';
import VariablesTyOption from './VariablesTyOption';

const PlaygroundOption: FC = () => {
  const { t } = useTranslation('calculationMenu');
  const dispatch = useDispatch();

  const calculation = useSelector(state => state.calculationMenu.calculation);

  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<HTMLLIElement | null>(null);

  const subMenuAnchorRef = useRef<HTMLLIElement | null>(null);

  const isSubMenuOpen = useMemo(() => Boolean(subMenuAnchorEl), [subMenuAnchorEl]);
  const statistics = useMemo(() => GetCalculationVariableStats(calculation), [calculation]);
  const variables = useMemo(() => [...statistics.dataVariables, ...statistics.blockVariables, ...statistics.modelVariables], [statistics]);
  const isSingleVariable = useMemo(() => variables.length === 1, [variables]);

  const handleOpenSubMenu = useCallback(() => {
    if (subMenuAnchorRef.current) {
      setSubMenuAnchorEl(subMenuAnchorRef.current);
    }
  }, []);

  const handleCloseSubMenu = useCallback(() => {
    setSubMenuAnchorEl(null);
  }, []);

  const handleGoTo = useCallback(() => {
    void handleCloseSubMenu();
    dispatch(setMenuPosition(null));
  }, [handleCloseSubMenu, dispatch]);

  return (
    <>
      <CalculationMenuItem
        ref={subMenuAnchorRef}
        arrow
        icon={PlaygroundOutlined}
        label={t('label.playground')}
        onMouseOut={handleCloseSubMenu}
        onMouseOver={handleOpenSubMenu}
      />
      <Popover
        anchorEl={subMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSubMenuOpen}
        sx={{
          pointerEvents: 'none',
          [`& .${paperClasses.root}`]: { pointerEvents: 'all' },
          transform: `translateY(-6px) translateX(6px)`,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List sx={{ py: 1 }} onMouseOut={handleCloseSubMenu} onMouseOver={handleOpenSubMenu}>
          {!isSingleVariable && <ListSubheader sx={{ lineHeight: 1, py: 1 }}>{t('label.calculation')}</ListSubheader>}
          <CalculationTyOption onClick={handleGoTo} />
          <CalculationBarOption onClick={handleGoTo} />
          {!isSingleVariable && (
            <>
              <ListSubheader sx={{ lineHeight: 1, py: 1 }}>{t('label.variables', { count: variables.length })}</ListSubheader>
              <VariablesTyOption variables={variables} onClick={handleGoTo} />
            </>
          )}
        </List>
      </Popover>
    </>
  );
};

export default PlaygroundOption;
