import { useTheme } from '@mui/material';
import { Axis, YAxisOptions } from 'highcharts';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';

import { useDaChartContext } from '../DaChartContext';

import YAxisProvider from './YAxisContext';
import { getYAxisOptions } from './yAxisOptions';

const yAxisOptionsDefaultProp: YAxisOptions = {};

export interface YAxisProps {
  unit?: string | null;
  yAxisOptions?: YAxisOptions;
}

const YAxis: FC<PropsWithChildren<YAxisProps>> = ({ unit = null, yAxisOptions = yAxisOptionsDefaultProp, children = undefined }) => {
  // const { t } = useTranslation('dachart');
  const theme = useTheme();

  const { highcharts } = useDaChartContext();

  const [axis, setAxis] = useState<Axis | null>(null);

  const firstLoad = useRef(true);

  const uuid = useMemo(() => uuidv4(), []);
  const options = useMemo(() => getYAxisOptions(yAxisOptions, theme), [yAxisOptions, theme]);

  const initAxis = useCallback(() => {
    if (!highcharts || !firstLoad.current) return;
    firstLoad.current = false;
    setAxis(highcharts.chart.addAxis({ id: uuid }, false));
  }, [highcharts, uuid]);

  useEffect(() => {
    initAxis();
  }, [initAxis]);

  const updateAxis = useCallback(() => {
    if (!axis) return;
    axis.update({ ...options });
  }, [axis, options]);

  useEffect(() => {
    updateAxis();
  }, [updateAxis]);

  useEffect(() => {
    if (axis) {
      axis.update({
        labels: {
          formatter() {
            // eslint-disable-next-line react/no-this-in-sfc
            return `${getFormattedValue({ value: this.value as number, userUnit: unit })}`;
          },
        },
      });
    }
  }, [axis, unit]);

  useEffect(() => {
    if (axis && !firstLoad.current) {
      axis.update(options);
    }
  }, [axis, options]);

  return (
    <YAxisProvider axis={axis} unit={unit}>
      {children}
    </YAxisProvider>
  );
};

export default YAxis;

// export default memo(
//   YAxis,
//   ({ unit: oldUnit, yAxisOptions: oldYAxisOptions }, { unit: nextUnit, yAxisOptions: nextYAxisOptions }) =>
//     oldUnit === nextUnit && deepEqual(oldYAxisOptions, nextYAxisOptions),
// );
