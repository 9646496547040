import { ActivityType, ActivityVerb } from '@dametis/core';

export default {
  title: {
    activityDetails: "Détails de l'activité",
  },
  type: {
    [ActivityVerb.CREATE]: 'a créé',
    [ActivityVerb.RESTORE]: 'a restauré',
    [ActivityVerb.DUPLICATE]: 'a dupliqué',
    [ActivityVerb.TRANSFER]: 'a transféré',
    [ActivityVerb.UPDATE]: 'a modifié',
    [ActivityVerb.DELETE]: 'a supprimé',
    [ActivityVerb.UPLOAD]: 'a publié',
    [ActivityVerb.SIGNIN_PASSWORD]: "s'est connecté avec un mot de passe",
    [ActivityVerb.SIGNIN_INVITATION]: "s'est connecté avec une invitation",
    [ActivityVerb.SIGNIN_SSO]: "s'est connecté avec du SSO",
    [ActivityVerb.LOGOUT]: "s'est déconnecté",
    [ActivityVerb.CREATE_INVITATION]: 'a créé une invitation',
    [ActivityVerb.FINALIZE_INVITATION]: 'a finalisé son invitation',
    [ActivityVerb.LOST_PASSWORD]: 'a perdu son mot de passe',
    [ActivityVerb.RESET_PASSWORD]: 'a redéfini son mot de passe',
  },
  object: {
    [ActivityType.GROUP]: 'le groupe',
    [ActivityType.SITE]: 'le site',
    [ActivityType.BOX]: 'la box',
    [ActivityType.DEVICE]: "l'équipement",
    [ActivityType.PLAYGROUND]: 'le playground',
    [ActivityType.REPORT]: 'le rapport',
    [ActivityType.SYNOPTIC]: 'le synoptique',
    [ActivityType.VARIABLE]: 'la variable',
    [ActivityType.USER]: "l'utilisateur",
    [ActivityType.ALARM]: "l'alarme",
    [ActivityType.PROJECT]: 'le project',
    [ActivityType.ASSET]: 'un document',
    [ActivityType.TASK]: 'une tâche',
    [ActivityType.BATCH]: 'un batch',
    [ActivityType.MODEL]: 'un modèle',
    [ActivityType.COMMENT]: 'un commentaire',
    [ActivityType.BLOCK]: 'un block',
    [ActivityType.BLOCK_TYPE]: 'un type de block',
    [ActivityType.STYLE_CONFIGURATION]: 'une configuration de style',
    [ActivityType.ALIAS]: 'un alias',
    [ActivityType.IMAGE]: 'une image',
    [ActivityType.TAG]: 'un tag',
    [ActivityType.OPCO]: 'un mix vapeur',
  },
  text: {
    activities: 'Activités',
    label: '{{firstName}} {{lastName}} $t(type.{{type}}) $t(object.{{object}})',
    noActivities: 'Aucune activité enregistrée',
    displayOnlyChangedFields: "N'afficher que les champs modifiés.",
    variable: {
      noUpdatedVariableFields: 'Aucune modification',
      updatedVariableFields_zero: ' et $t(configuration:input.{{field}})',
      updatedVariableFields_one: ', $t(configuration:input.{{field}}) et un autre champ',
      updatedVariableFields_other: ', $t(configuration:input.{{field}}) et {{count}} autres champ',
    },
  },
  button: {
    close: 'Fermer',
    restore: 'Restaurer',
    order: {
      newest: 'Des récents aux anciens',
      oldest: 'Des anciens aux récents',
    },
  },
  toast: {
    restoreVariableSuccess: 'Variable restaurée.',
  },
};
