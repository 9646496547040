import { DateRange } from '@mui/x-date-pickers-pro';
import {
  endOfDay,
  endOfHour,
  endOfMinute,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatISO,
  parseISO,
  startOfDay,
  startOfHour,
  startOfMinute,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

export enum RelativePeriod {
  LAST_MONTH_START = 'last_month_start',
  LAST_MONTH_END = 'last_month_end',
  LAST_WEEK_START = 'last_week_start',
  LAST_WEEK_END = 'last_week_end',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  NOW = 'now',
  FULL_NOW = 'fullnow',
}

export const isRelativeDate = (date: Date | RelativePeriod | string | null): date is RelativePeriod => {
  return Object.values(RelativePeriod).includes(date as RelativePeriod) || (date?.constructor === String && date.startsWith('now-'));
};

export const parseTime = (date: string | null): RegExpMatchArray => {
  if (date && typeof date === 'string') {
    const timeRegex = /(\d+)(mo|m|h|d|w|y)/;
    const match = date.match(timeRegex);
    if (match) {
      return match;
    }
  }
  return null;
};

export const groupByToDate = (value: number, unit: string, compareDate: Date | string, format: 1 | 0): Date => {
  const now = new Date();
  switch (unit) {
    case 'm':
      return compareDate === 'fullnow' ? startOfMinute(subMinutes(now, value)) : subMinutes(now, value);
    case 'h':
      return compareDate === 'fullnow' ? startOfHour(subHours(now, value)) : subHours(now, value);
    case 'd':
      return compareDate === 'fullnow' ? startOfDay(subDays(now, value)) : subDays(now, value);
    case 'w':
      return compareDate === 'fullnow' ? startOfWeek(subWeeks(now, value), { weekStartsOn: format }) : subWeeks(now, value);
    case 'mo':
      return compareDate === 'fullnow' ? startOfMonth(subMonths(now, value)) : subMonths(now, value);
    case 'y':
      return compareDate === 'fullnow' ? startOfYear(subYears(now, value)) : subYears(now, value);
    default:
      throw new Error('Unit not supported');
  }
};

const getRoundNow = (date: Date, compareDate: Date | string, format: 1 | 0): Date => {
  if (typeof compareDate === 'string' && compareDate.includes('now-')) {
    const [, , unit] = parseTime(compareDate);
    // const value = Number(val);
    // if (compareDate === RelativePeriod.THIS_WEEK || [TimeUnit.DAY, TimeUnit.WEEK].includes(unit)) {
    //   return startOfHour(date);
    // }
    // if (compareDate === RelativePeriod.THIS_MONTH || [TimeUnit.MONTH, TimeUnit.YEAR].includes(unit)) {
    //   return startOfDay(date);
    // }
    switch (unit) {
      case 'm':
        return endOfMinute(subMinutes(date, 1));
      case 'h':
        return endOfHour(subHours(date, 1));
      case 'd':
        return endOfDay(subDays(date, 1));
      case 'w':
        return endOfWeek(subWeeks(date, 1), { weekStartsOn: format });
      case 'mo':
        return endOfMonth(subMonths(date, 1));
      case 'y':
        return endOfYear(subYears(date, 1));
      default:
        throw new Error('Unit not supported');
    }
  }
  return date;
};

export const relativeToDate = (
  date: Date | RelativePeriod | string,
  language: string,
  compareDate?: Date | RelativePeriod | string,
): Date => {
  if (!date) {
    return null;
  }
  if (date instanceof Date) {
    return date;
  }
  const format = language === 'fr' ? 1 : 0;
  const returnDate = new Date();
  const stringDate = date as string;

  if (stringDate.includes('now-')) {
    const [, value, unit] = parseTime(stringDate);
    return groupByToDate(Number(value), unit, compareDate, format);
  }

  switch (stringDate) {
    case RelativePeriod.LAST_MONTH_START:
      return startOfMonth(returnDate.setMonth(returnDate.getMonth() - 1));
    case RelativePeriod.LAST_MONTH_END:
      return endOfMonth(returnDate.setMonth(returnDate.getMonth() - 1));
    case RelativePeriod.LAST_WEEK_START:
      return startOfDay(startOfWeek(subWeeks(returnDate, 1), { weekStartsOn: format }));
    case RelativePeriod.LAST_WEEK_END:
      return endOfDay(endOfWeek(subWeeks(returnDate, 1), { weekStartsOn: format }));
    case RelativePeriod.THIS_MONTH:
      return startOfMonth(returnDate);
    case RelativePeriod.THIS_WEEK:
      return startOfWeek(returnDate, { weekStartsOn: format });
    case RelativePeriod.NOW:
      return new Date();
    case RelativePeriod.FULL_NOW:
      return getRoundNow(returnDate, compareDate, format);
    default:
  }
  return parseISO(stringDate);
};

export const relativesToDateRange = (dates: (string | Date)[], language: string): DateRange<Date> => {
  return [relativeToDate(dates[0], language, dates[1]), relativeToDate(dates[1], language, dates[0])];
};

export const urlParamToDate = (dates: (string | null)[], language: string): DateRange<Date> => {
  if (isRelativeDate(dates[0]) || isRelativeDate(dates[1])) {
    return relativesToDateRange(dates as RelativePeriod[], language);
  }
  return [parseISO(decodeURI(dates[0])), parseISO(decodeURI(dates[1]))];
};

export const DateToUrlParam = (date: Date | RelativePeriod): string => {
  // if date is a relative period === string
  if (typeof date === 'string') {
    return date;
  }
  if (!date) {
    return null;
  }
  return formatISO(date, { format: 'basic' });
};

export const removeEmptyUrlParams = (searchParams: URLSearchParams): URLSearchParams => {
  // param[1] is the value of a search param
  const params = Array.from(searchParams.entries()).filter(param => param[1].length > 0);
  return new URLSearchParams(params);
};

export const updateUrlPeriod = (from: Date | RelativePeriod, to: Date | RelativePeriod) => {
  const currentSearchParams = new URLSearchParams(window.location.search);
  currentSearchParams.set('from', DateToUrlParam(from));
  currentSearchParams.set('to', DateToUrlParam(to));

  const updatedSearchParams = removeEmptyUrlParams(currentSearchParams);
  const newUrl = `${window.location.pathname}?${updatedSearchParams.toString()}`;

  window.history.pushState({ path: newUrl }, '', newUrl);
};
