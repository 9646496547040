import { CommentInfo, UUID } from '@dametis/core';

import { EntityType } from 'types/comment';

export type EntityFromComment = { uuid: UUID; type: EntityType };

export const getEntityFromComment = (comment: Partial<CommentInfo>): EntityFromComment => {
  const entity = { uuid: null, type: null };
  if (comment.projectId) {
    entity.uuid = comment.projectId;
    entity.type = EntityType.PROJECT;
  } else if (comment.reportId) {
    entity.uuid = comment.reportId;
    entity.type = EntityType.REPORT;
  } else if (comment.variableId) {
    entity.uuid = comment.variableId;
    entity.type = EntityType.VARIABLE;
  } else if (comment.taskId) {
    entity.uuid = comment.taskId;
    entity.type = EntityType.TASK;
  } else if (comment.alarmId) {
    entity.uuid = comment.alarmId;
    entity.type = EntityType.ALARM;
  }
  return entity;
};
