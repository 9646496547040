import { GridSortDirection } from '@mui/x-data-grid-premium';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UUID, VariableListKind, CalculationVariableWithUuid, VariableListInfo } from '@dametis/core';

export type ManualEntryPatch = Record<UUID, Record<string, number | null>>;

export interface ConfigurationState {
  currentVariableList: Partial<VariableListInfo>;
  conflictVariables: Record<string, boolean>;
  fetchLimitWarning: boolean;
  manualEntryPatch: ManualEntryPatch;
  manualEntryOperations: UUID[];
  isFetching: boolean;
  sortDirection: GridSortDirection;
}

const initialState: Omit<ConfigurationState, 'macros'> = {
  currentVariableList: { name: '', kind: VariableListKind.IMPORT, calculations: [], dateFormat: null },
  conflictVariables: {},
  fetchLimitWarning: false,
  manualEntryPatch: {},
  manualEntryOperations: [],
  isFetching: false,
  sortDirection: 'desc',
};

export const manualEntrySlice = createSlice({
  name: 'manualEntry',
  initialState,
  reducers: {
    setCurrentVariableList: (state, action: PayloadAction<VariableListInfo>) => {
      state.currentVariableList = action.payload;
    },
    setFetchLimitWarning: (state, action: PayloadAction<boolean>) => {
      state.fetchLimitWarning = action.payload;
    },
    setCurrentVariableListDateFormat: (state, action: PayloadAction<string | null>) => {
      state.currentVariableList.dateFormat = action.payload;
    },
    clearCurrentVariableList: state => {
      state.currentVariableList = initialState.currentVariableList;
    },
    setCurrentCalculationVariableWithUuids: (state, action: PayloadAction<CalculationVariableWithUuid[]>) => {
      state.currentVariableList.calculations = action.payload;
    },
    addCurrentCalculationVariableWithUuid: (state, action: PayloadAction<CalculationVariableWithUuid[]>) => {
      state.currentVariableList.calculations = state.currentVariableList.calculations.concat(action.payload);
    },
    setConflictVariables: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.conflictVariables = action.payload;
    },
    setManualEntryPatch: (state, action: PayloadAction<ManualEntryPatch>) => {
      state.manualEntryPatch = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setSortDirection: (state, action: PayloadAction<GridSortDirection>) => {
      state.sortDirection = action.payload;
    },
    clearManualEntryStore: () => initialState,
  },
});

export const {
  setCurrentVariableList,
  setFetchLimitWarning,
  setCurrentVariableListDateFormat,
  clearCurrentVariableList,
  setCurrentCalculationVariableWithUuids,
  addCurrentCalculationVariableWithUuid,
  setConflictVariables,
  setManualEntryPatch,
  setIsFetching,
  clearManualEntryStore,
  setSortDirection,
} = manualEntrySlice.actions;

export default manualEntrySlice.reducer;
