import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { VarCalc } from '@dametis/core';

import BatchFilterInput from '../BatchFilter/BatchFilterInput';

export interface DataFilterInputProps {
  filter: VarCalc['filter'];
  onFilterChange: (filter: VarCalc['filter']) => void;
  disabled?: boolean;
  editing?: boolean;
}

const DataFilterInput: FC<DataFilterInputProps> = ({ filter, onFilterChange, disabled = false, editing = true }) => {
  const { t } = useTranslation('dataFilter');

  return (
    <BatchFilterInput
      noOperator
      batch={filter}
      disabled={disabled}
      editing={editing}
      label={t('input.label.dataFilter')}
      setBatch={onFilterChange}
    />
  );
};

export default DataFilterInput;
