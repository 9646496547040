import { styled } from '@mui/material';
import { DefaultTheme } from '@mui/styles';
import i18next, { TFunction } from 'i18next';
import { ReactNode } from 'react';

import { PhysicalQuantity } from '@dametis/core';

import { OneTwoThree } from 'assets/icons/OneTwoThree';
import { theme as defaultTheme } from 'theme';

const SymbolWrapper = styled('span', {
  shouldForwardProp: propName => propName !== 'isLowerCase',
})<{ isLowerCase?: boolean }>(({ isLowerCase }) => ({
  marginTop: isLowerCase ? '-2px' : undefined,
}));

export interface PhysicalQuantityConfig {
  name: string;
  color: string;
  units: string[];
  symbol: ReactNode;
}

export const physicalQuantitiesUnsorted = (
  t: TFunction = i18next.t,
  theme: DefaultTheme = defaultTheme,
): Record<PhysicalQuantity, PhysicalQuantityConfig> => ({
  [PhysicalQuantity.NONE]: {
    name: t('global:physicalQuantity.pQ_none'),
    color: theme.palette.primary.main,
    units: ['', '%'],
    symbol: <OneTwoThree />,
  },
  [PhysicalQuantity.DENSITY]: {
    name: t('global:physicalQuantity.pQ_density'),
    color: theme.palette.picker.charcoal,
    units: [''],
    symbol: <SymbolWrapper>d</SymbolWrapper>,
  },
  [PhysicalQuantity.OTHER]: {
    name: t('global:physicalQuantity.pQ_other'),
    color: theme.palette.primary.main,
    units: ['', '%'],
    symbol: <SymbolWrapper>?</SymbolWrapper>,
  },
  [PhysicalQuantity.PRESSURE]: {
    name: t('global:physicalQuantity.pQ_pressure'),
    color: theme.palette.picker.teal,
    units: ['bar', 'mbar', 'psi', 'Pa', 'hPa', 'kPa'],
    symbol: <SymbolWrapper isLowerCase>p</SymbolWrapper>,
  },
  [PhysicalQuantity.ENERGY]: {
    name: t('global:physicalQuantity.pQ_energy'),
    color: theme.palette.picker.orange,
    units: ['Wh', 'kWh', 'MWh', 'Whe', 'kWhe', 'MWhe', 'Whf', 'kWhf', 'MWhf', 'Whc', 'kWhc', 'MWhc', 'Wh/Nm3', 'varh', 'VAh'],
    symbol: <SymbolWrapper>E</SymbolWrapper>,
  },
  [PhysicalQuantity.SPECIFIC_ENERGY]: {
    name: t('global:physicalQuantity.pQ_specificEnergy'),
    color: theme.palette.picker.orange,
    units: ['J/kg'],
    symbol: <SymbolWrapper isLowerCase>e</SymbolWrapper>,
  },
  [PhysicalQuantity.POWER]: {
    name: t('global:physicalQuantity.pQ_power'),
    color: theme.palette.picker.orange,
    units: ['W', 'kW', 'MW', 'We', 'kWe', 'MWe', 'Wf', 'kWf', 'MWf', 'Wc', 'kWc', 'MWc', 'var', 'VA'],
    symbol: <SymbolWrapper>P</SymbolWrapper>,
  },
  [PhysicalQuantity.DEW_POINT]: {
    name: t('global:physicalQuantity.pQ_dewPoint'),
    color: theme.palette.picker.cyan,
    units: ['°C', '°K', '°F'],
    symbol: <SymbolWrapper>Td</SymbolWrapper>,
  },
  [PhysicalQuantity.TEMPERATURE]: {
    name: t('global:physicalQuantity.pQ_temperature'),
    color: theme.palette.picker.red,
    units: ['°C', '°K', '°F'],
    symbol: <SymbolWrapper>T</SymbolWrapper>,
  },
  [PhysicalQuantity.SPEED]: {
    name: t('global:physicalQuantity.pQ_speed'),
    color: theme.palette.picker.pink,
    units: ['m/s', 'km/h'],
    symbol: <SymbolWrapper isLowerCase>v</SymbolWrapper>,
  },
  [PhysicalQuantity.ACCELERATION]: {
    name: t('global:physicalQuantity.pQ_acceleration'),
    color: theme.palette.picker.pink,
    units: ['m/s2'],
    symbol: <SymbolWrapper>{'a\u20D7'}</SymbolWrapper>,
  },
  [PhysicalQuantity.TIME]: {
    name: t('global:physicalQuantity.pQ_time'),
    color: theme.palette.picker.lime,
    units: ['ms', 's', 'min', 'h'],
    symbol: <SymbolWrapper isLowerCase>t</SymbolWrapper>,
  },
  [PhysicalQuantity.MASS]: {
    name: t('global:physicalQuantity.pQ_mass'),
    color: theme.palette.picker.charcoal,
    units: ['mg', 'g', 'kg', 't'],
    symbol: <SymbolWrapper isLowerCase>m</SymbolWrapper>,
  },
  [PhysicalQuantity.MASS_DENSITY]: {
    name: t('global:physicalQuantity.pQ_massDensity'),
    color: theme.palette.picker.charcoal,
    units: ['g/m³'],
    symbol: <SymbolWrapper>D</SymbolWrapper>,
  },
  [PhysicalQuantity.VOLUME]: {
    name: t('global:physicalQuantity.pQ_volume'),
    color: theme.palette.picker.teal,
    units: ['m3', 'l', 'ml', 'cl', 'Nm3'],
    symbol: <SymbolWrapper>V</SymbolWrapper>,
  },
  [PhysicalQuantity.FLOW]: {
    name: t('global:physicalQuantity.pQ_flow'),
    color: theme.palette.picker.teal,
    units: ['m3/h', 'l/h', 'Nm3/h', 't/h', 'kg/h'],
    symbol: <SymbolWrapper>Q</SymbolWrapper>,
  },
  [PhysicalQuantity.FORCE]: {
    name: t('global:physicalQuantity.pQ_force'),
    color: theme.palette.picker.blue,
    units: ['N'],
    symbol: <SymbolWrapper>F</SymbolWrapper>,
  },
  [PhysicalQuantity.HUMIDITY]: {
    name: t('global:physicalQuantity.pQ_humidity'),
    color: theme.palette.picker.cyan,
    units: ['%', 'g/m3', 'g/kg'],
    symbol: <SymbolWrapper>H</SymbolWrapper>,
  },
  [PhysicalQuantity.THERMAL_CONDUCTIVITY]: {
    name: t('global:physicalQuantity.pQ_thermalConductivity'),
    color: theme.palette.picker.red,
    units: ['W/m/K'],
    symbol: <SymbolWrapper isLowerCase>λ</SymbolWrapper>,
  },
  [PhysicalQuantity.VOLTAGE]: {
    name: t('global:physicalQuantity.pQ_voltage'),
    color: theme.palette.picker.yellow,
    units: ['V'],
    symbol: <SymbolWrapper isLowerCase>U</SymbolWrapper>,
  },
  [PhysicalQuantity.CURRENT]: {
    name: t('global:physicalQuantity.pQ_current'),
    color: theme.palette.picker.yellow,
    units: ['A'],
    symbol: <SymbolWrapper>I</SymbolWrapper>,
  },
  [PhysicalQuantity.FREQUENCY]: {
    name: t('global:physicalQuantity.pQ_frequency'),
    color: theme.palette.picker.pink,
    units: ['Hz'],
    symbol: <SymbolWrapper>f</SymbolWrapper>,
  },
  [PhysicalQuantity.LENGTH]: {
    name: t('global:physicalQuantity.pQ_length'),
    color: theme.palette.picker.pink,
    units: ['m'],
    symbol: <SymbolWrapper>l</SymbolWrapper>,
  },
  [PhysicalQuantity.CURRENCY]: {
    name: t('global:physicalQuantity.pQ_currency'),
    color: theme.palette.picker.pink,
    units: ['€', '$'],
    symbol: <SymbolWrapper>$</SymbolWrapper>,
  },
  [PhysicalQuantity.SURFACE]: {
    name: t('global:physicalQuantity.pQ_surface'),
    color: theme.palette.picker.pink,
    units: ['m²'],
    symbol: <SymbolWrapper>A</SymbolWrapper>,
  },
  [PhysicalQuantity.FILE_SIZE]: {
    name: t('global:physicalQuantity.pQ_fileSize'),
    color: theme.palette.picker.pink,
    units: ['o', 'b'],
    symbol: <SymbolWrapper>b</SymbolWrapper>,
  },
  [PhysicalQuantity.VISCOSITY]: {
    name: i18next.t('global:physicalQuantity.pQ_viscosity'),
    color: theme.palette.picker.green,
    units: ['St'],
    symbol: <SymbolWrapper isLowerCase>μ</SymbolWrapper>,
  },
  [PhysicalQuantity.ELECTRICAL_CONDUCTIVITY]: {
    name: i18next.t(`unit:physicalQuantity.${PhysicalQuantity.ELECTRICAL_CONDUCTIVITY}`),
    color: theme.palette.picker.yellow,
    units: ['S/cm'],
    symbol: <SymbolWrapper isLowerCase>σ</SymbolWrapper>,
  },
  [PhysicalQuantity.ELECTRICAL_CONDUCTANCE]: {
    name: i18next.t(`unit:physicalQuantity.${PhysicalQuantity.ELECTRICAL_CONDUCTANCE}`),
    color: theme.palette.picker.yellow,
    units: ['S'],
    symbol: <SymbolWrapper isLowerCase>G</SymbolWrapper>,
  },
});

export const getPhysicalQuantitiesSorted = (t: TFunction = i18next?.t, theme: DefaultTheme = defaultTheme) =>
  Object.entries(physicalQuantitiesUnsorted(t, theme)).sort(([, val1], [, val2]) => val1.name?.localeCompare(val2.name)) as [
    PhysicalQuantity,
    PhysicalQuantityConfig,
  ][];
/**
 * @deprecated Use getPhysicalQuantitiesSorted(t, theme) instead
 */
export const physicalQuantitiesSorted = getPhysicalQuantitiesSorted();

export const getPhysicalQuantities = (
  t: TFunction = i18next?.t,
  theme: DefaultTheme = defaultTheme,
): Record<PhysicalQuantity, PhysicalQuantityConfig> =>
  getPhysicalQuantitiesSorted(t, theme).reduce((acc, [key, val]) => {
    acc[key] = val;
    return acc;
  }, {} as Record<PhysicalQuantity, PhysicalQuantityConfig>);
/**
 * @deprecated Use getPhysicalQuantities(t, theme) instead
 */
export const physicalQuantities = getPhysicalQuantities();

export default physicalQuantities;

export const getPhysicalQuantitiesArray = (t: TFunction = i18next?.t, theme: DefaultTheme = defaultTheme): PhysicalQuantity[] =>
  Object.keys(getPhysicalQuantities(t, theme)) as PhysicalQuantity[];
/**
 * @deprecated Use getPhysicalQuantitiesArray(t, theme) instead
 */
export const physicalQuantitiesArray = getPhysicalQuantitiesArray();
