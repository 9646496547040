import { ReportProblemOutlined, SignalCellularAltOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios, { CancelTokenSource } from 'axios';
import clsx from 'clsx';
import { subSeconds } from 'date-fns';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'store';
import { getBoxesStatus } from 'store/actions/configuration';
import { getCurrentBox } from 'store/actions/currentBox';

import { BoxStatusLabel } from '../../../types/box';

import BigButton from './BigButton/BigButton';
import BigButtonDescription from './BigButton/BigButtonDescription';
import BigButtonIcon from './BigButton/BigButtonIcon';
import BigButtonTitle from './BigButton/BigButtonTitle';
import useStatusMenuStyles from './StatusMenu.styles';

interface Props {
  className?: string;
}

const StatusMenu: FC<Props> = ({ className = '' }) => {
  const { t } = useTranslation('header');
  const statusClasses = useStatusMenuStyles();
  const dispatch = useDispatch();

  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  const dataDelay = useSelector(state => state.auth.selectedSite?.dataDelay);
  const boxes = useSelector(state => state.boxes.list);

  const [status, setStatus] = useState<BoxStatusLabel | null>(null);
  const boxStatus = useSelector(state => state.configuration.boxesStatus[state.currentBox?.infos?.uuid]);
  const currentBoxInfos = useSelector(state => state.currentBox?.infos);

  const menuAnchorEl = useRef<HTMLButtonElement>(null);
  const interval = useRef<ReturnType<typeof setTimeout>>();
  const axiosSource = useRef<CancelTokenSource>(axios.CancelToken.source());

  useEffect(() => {
    if (!boxStatus) {
      return;
    }
    const timeLimit = subSeconds(new Date(), dataDelay);
    if (new Date(boxStatus.time) > timeLimit && new Date(boxStatus.time) > timeLimit) {
      setStatus(BoxStatusLabel.NORMAL);
    } else {
      setStatus(BoxStatusLabel.CRITICAL);
    }
  }, [boxStatus, dataDelay]);

  useEffect(() => {
    if (boxes && boxes.length > 0) {
      let targetBoxUuid = null;
      if (currentBoxInfos && currentBoxInfos.siteId === boxes[0]?.siteId) {
        targetBoxUuid = currentBoxInfos?.uuid;
      } else {
        targetBoxUuid = boxes[0]?.uuid;
      }
      void dispatch(getCurrentBox(targetBoxUuid));
      interval.current = setInterval(() => dispatch(getBoxesStatus()), 60000);
    }
    return () => clearInterval(interval.current);
  }, [boxes, dispatch, currentBoxInfos]);

  useEffect(() => () => axiosSource.current.cancel(), []);

  if (!siteId) return null;
  return (
    <BigButton ref={menuAnchorEl} className={className} to="/configuration/architecture">
      <BigButtonIcon
        className={clsx(
          (!status || status === BoxStatusLabel.NORMAL) && statusClasses.button__icon__normal,
          status === BoxStatusLabel.WARNING && statusClasses.button__icon__warning,
          status === BoxStatusLabel.CRITICAL && statusClasses.button__icon__critical,
        )}
      >
        {!status && <CircularProgress size={24} />}
        {status === BoxStatusLabel.NORMAL && <SignalCellularAltOutlined />}
        {(status === BoxStatusLabel.CRITICAL || status === BoxStatusLabel.WARNING) && <ReportProblemOutlined />}
      </BigButtonIcon>
      <BigButtonTitle>{t('statusMenu.status')}</BigButtonTitle>
      <BigButtonDescription>{status ? t('statusMenu.msg', { context: status }) : t('global:text.loadingValue')}</BigButtonDescription>
    </BigButton>
  );
};

export default StatusMenu;
