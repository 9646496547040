import { Components, tableRowClasses, Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      [`&.${tableRowClasses.selected}`]: {
        backgroundColor: theme.palette.background.default,
      },
      '&:hover': {
        backgroundColor: theme.palette.background.default,
      },
    }),
  },
} as Components<Theme>['MuiTableRow'];
