import { Add } from '@mui/icons-material';
import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ActionButton from '../../../../UI/Buttons/ActionButton/ActionButton';
import { Hotkeys } from '../../../../UI/Hotkeys/Hotkeys';

import FunctionsPopover from './FunctionsPopover';

export const FUNCTION_HOTKEY = `mod+F`;

const FunctionsButton: FC = () => {
  const { t } = useTranslation('vnc');

  const [open, setOpen] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>();

  const toggleOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <>
      <ActionButton
        ref={buttonRef}
        endIcon={<Hotkeys hotkeys={FUNCTION_HOTKEY} size="small" />}
        startIcon={<Add />}
        sx={{ fontFamily: '"JuliaMono", monospace' }}
        onClick={toggleOpen}
      >
        {t('button.function')}()
      </ActionButton>
      <FunctionsPopover anchorEl={buttonRef.current} open={open} setOpen={setOpen} />
    </>
  );
};

export default FunctionsButton;
