export default {
  button: {
    reset: 'Restablecer',
  },
  option: {
    all: 'Seleccionar todo',
  },
  text: {
    noTagSelected: 'Ningún etiqueta seleccionada',
  },
};
