import { LoadingButton } from '@mui/lab';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Stack, TextField, useTheme } from '@mui/material';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { ColorChangeHandler } from 'react-color';
import { useTranslation } from 'react-i18next';

import { FolderInfo, UpdateFolderBody } from '@dametis/core';

import { getFolderColor } from 'components/Lego/helpers/getFolderColor';
import ColorPicker from 'components/UI/ColorPicker/ColorPicker';
import { useDispatch } from 'store';
import { useUpdateGlobalFolderMutation } from 'store/api/globalFolders';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

export interface EditFolderModalProps {
  folder: FolderInfo;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const EditFolderModal: FC<EditFolderModalProps> = ({ folder, isOpen, setIsOpen }) => {
  const { t } = useTranslation('lego');
  const dispatch = useDispatch();
  const theme = useTheme();

  const [updateGlobalFolder, { isLoading: isUpdating }] = useUpdateGlobalFolderMutation();

  const [name, setName] = useState<string>('');
  const [color, setColor] = useState<string>(theme.palette.gradients.blues[1]);

  const isValid = useMemo(() => name.trim().length > 0, [name]);

  const handleCloseModal = useCallback(() => {
    if (!isUpdating) {
      setIsOpen(false);
    }
  }, [setIsOpen, isUpdating]);

  const handleSubmit = useCallback(async () => {
    try {
      const updateFolderBody: UpdateFolderBody = { name: name.trim(), ui: { ...(folder.ui ?? {}), color } };
      await updateGlobalFolder({ uuid: folder.uuid, body: updateFolderBody }).unwrap();
      setIsOpen(false);
      dispatch(addToast({ message: t('toast.updateBlockFolderSuccess'), severity: ToastSeverity.SUCCESS }));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, folder, setIsOpen, t, updateGlobalFolder, name, color]);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setName(event.target.value);
  }, []);

  const handleChangeColor: ColorChangeHandler = useCallback(newColor => {
    setColor(newColor.hex);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setName(folder.name);
      setColor(getFolderColor(folder, theme));
    }
  }, [isOpen, folder, theme]);

  return (
    <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={handleCloseModal}>
      <DialogTitle>{t('title.editFolder')}</DialogTitle>
      <DialogContent>
        <Stack alignItems="flex-end" direction="row" spacing={1}>
          <TextField autoFocus fullWidth label={t('label.name')} value={name} onChange={handleChangeName} />
          <ColorPicker size="small" value={color} variant="toggle" onColorChange={handleChangeColor} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={isUpdating} variant="text" onClick={handleCloseModal}>
          {t('button.close')}
        </Button>
        <LoadingButton color="secondary" disabled={!isValid} loading={isUpdating} variant="contained" onClick={handleSubmit}>
          {t('button.edit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default EditFolderModal;
