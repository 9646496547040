import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataVariable, EditVariablesDataConditions, EditVariablesDataModifier, ModifierType, RawPoint, UUID } from '@dametis/core';

export enum AdvancedEntryMode {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
}

export enum AdvancedEntryPreviewMode {
  GRID = 'GRID',
  CHART = 'CHART',
}

export interface AdvancedEntryEditPatch {
  mode: AdvancedEntryMode.EDIT;
  conditions: Omit<EditVariablesDataConditions, 'uuids'>;
  modifier: EditVariablesDataModifier;
}
export const isEditPatch = (patch: AdvancedEntryPatch): patch is AdvancedEntryEditPatch => patch?.mode === AdvancedEntryMode.EDIT;

export interface AdvancedEntryDeletePatch {
  mode: AdvancedEntryMode.DELETE;
  conditions: Omit<EditVariablesDataConditions, 'uuids'>;
}
export const isDeletePatch = (patch: AdvancedEntryPatch): patch is AdvancedEntryDeletePatch => patch?.mode === AdvancedEntryMode.DELETE;

export type AdvancedEntryPatch = AdvancedEntryEditPatch | AdvancedEntryDeletePatch | null;

export type AdvancedEntryGridData = Record<UUID, Record<string, number | null>>;

export type AdvancedEntryChartData = Record<UUID, RawPoint[]>;

export interface AdvancedEntryState {
  currentVariables: DataVariable[];
  conflictVariables: Record<string, boolean>;
  previewMode: AdvancedEntryPreviewMode;
  gridVariablesData: AdvancedEntryGridData;
  chartVariablesData: AdvancedEntryChartData;
  advancedEntryPatch: AdvancedEntryPatch;
  isFetching: boolean;
  fetchLimitWarning: boolean;
}

const defaultModifiers: Record<ModifierType, EditVariablesDataModifier> = {
  [ModifierType.MULTIPLIER]: {
    type: ModifierType.MULTIPLIER,
    value: 1,
  },
  [ModifierType.LINEAR_INTERPOLATION]: {
    type: ModifierType.LINEAR_INTERPOLATION,
    value: {
      in1: 0,
      in2: 0,
      out1: 1,
      out2: 1,
    },
  },
  [ModifierType.OFFSET]: {
    type: ModifierType.OFFSET,
    value: 0,
  },
};

const defaultEditPatch: AdvancedEntryEditPatch = {
  mode: AdvancedEntryMode.EDIT,
  conditions: {
    period: { from: null, to: null },
    value: { min: null, max: null },
  },
  modifier: defaultModifiers[ModifierType.MULTIPLIER],
};

const defaultDeletePatch: AdvancedEntryDeletePatch = {
  mode: AdvancedEntryMode.DELETE,
  conditions: {
    period: { from: null, to: null },
    value: { min: null, max: null },
  },
};

const initialState: AdvancedEntryState = {
  currentVariables: [],
  conflictVariables: {},
  previewMode: AdvancedEntryPreviewMode.CHART,
  gridVariablesData: null,
  chartVariablesData: null,
  advancedEntryPatch: defaultDeletePatch,
  isFetching: false,
  fetchLimitWarning: false,
};

export const advancedEntrySlice = createSlice({
  name: 'advancedEntry',
  initialState,
  reducers: {
    setCurrentAdvancedEntryVariables: (state, action: PayloadAction<DataVariable[]>) => {
      state.currentVariables = action.payload;
    },
    addAdvancedEntryVariables: (state, action: PayloadAction<DataVariable[]>) => {
      state.currentVariables = [...state.currentVariables, ...action.payload];
    },
    clearAdvancedEntryVariables: state => {
      state.currentVariables = [];
      state.gridVariablesData = null;
    },
    setConflictVariables: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.conflictVariables = action.payload;
    },
    setAdvancedEntryPreviewMode: (state, action: PayloadAction<AdvancedEntryPreviewMode>) => {
      state.previewMode = action.payload;
    },
    setAdvancedEntryGridData: (state, action: PayloadAction<AdvancedEntryGridData>) => {
      state.gridVariablesData = action.payload;
    },
    setAdvancedEntryChartData: (state, action: PayloadAction<AdvancedEntryChartData>) => {
      state.chartVariablesData = action.payload;
    },
    clearAdvancedEntryData: state => {
      state.gridVariablesData = null;
    },
    setAdvancedEntryMode: (state, action: PayloadAction<AdvancedEntryMode>) => {
      switch (action.payload) {
        case AdvancedEntryMode.EDIT:
          state.advancedEntryPatch = {
            ...defaultEditPatch,
            conditions: state.advancedEntryPatch?.conditions || defaultEditPatch.conditions,
          };
          return;
        case AdvancedEntryMode.DELETE:
          state.advancedEntryPatch = {
            ...defaultDeletePatch,
            conditions: state.advancedEntryPatch?.conditions || defaultDeletePatch.conditions,
          };
          return;
        default:
          state.advancedEntryPatch = null;
      }
    },
    setAdvancedEntryPatch: (state, action: PayloadAction<AdvancedEntryPatch>) => {
      state.advancedEntryPatch = action.payload;
    },
    setAdvancedEntryTransformerType: (state, action: PayloadAction<ModifierType>) => {
      if (isEditPatch(state.advancedEntryPatch)) {
        state.advancedEntryPatch.modifier = defaultModifiers[action.payload];
      }
    },
    setAdvancedEntryTransformer: (state, action: PayloadAction<EditVariablesDataModifier>) => {
      if (isEditPatch(state.advancedEntryPatch)) {
        state.advancedEntryPatch.modifier = action.payload;
      }
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setFetchLimitWarning: (state, action: PayloadAction<boolean>) => {
      state.fetchLimitWarning = action.payload;
    },
    resetAdvancedEntryStore: () => initialState,
  },
});

export const {
  setCurrentAdvancedEntryVariables,
  addAdvancedEntryVariables,
  clearAdvancedEntryVariables,
  setConflictVariables,
  setAdvancedEntryPreviewMode,
  setAdvancedEntryGridData,
  setAdvancedEntryChartData,
  clearAdvancedEntryData,
  setAdvancedEntryMode,
  setAdvancedEntryPatch,
  setAdvancedEntryTransformerType,
  setAdvancedEntryTransformer,
  setIsFetching,
  setFetchLimitWarning,
  resetAdvancedEntryStore,
} = advancedEntrySlice.actions;

export default advancedEntrySlice.reducer;
