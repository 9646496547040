import { createContext } from 'react';

import { vncDefaultProps } from '../props';
import { VncProps } from '../types';

export type IPropsContext = Required<
  Pick<
    VncProps,
    | 'onChange'
    | 'multiple'
    | 'selection'
    | 'onSelectionChange'
    | 'sourceCategory'
    | 'sourceUuid'
    | 'listTab'
    | 'defaultVariableOperator'
    | 'variableOperatorOptions'
    | 'excludeVariableOperatorOptions'
    | 'defaultGlobalOperator'
    | 'globalOperatorOptions'
    | 'excludeGlobalOperatorOptions'
    | 'disableMaths'
    | 'butKeepVariableMenu'
    | 'availableFilters'
    | 'defaultFilters'
    | 'disableFilters'
    | 'calculatedVariableMode'
    | 'standardBlockMetricMode'
    | 'editingBlockTypeMetricUuid'
    | 'disableLego'
    | 'disableModels'
    | 'covarianceVariable'
    | 'unitPicker'
    | 'output'
    | 'GroupByInputProps'
    | 'disableCreateAlias'
  >
>;

const {
  multiple,
  selection,
  onSelectionChange,
  sourceUuid,
  listTab,
  defaultVariableOperator,
  variableOperatorOptions,
  excludeVariableOperatorOptions,
  defaultGlobalOperator,
  globalOperatorOptions,
  excludeGlobalOperatorOptions,
  disableMaths,
  butKeepVariableMenu,
  availableFilters,
  defaultFilters,
  disableFilters,
  calculatedVariableMode,
  standardBlockMetricMode,
  editingBlockTypeMetricUuid,
  disableLego,
  disableModels,
  covarianceVariable,
  unitPicker,
  output,
  GroupByInputProps,
  disableCreateAlias,
} = vncDefaultProps;

export const PropsContext = createContext<IPropsContext>({
  onChange: undefined,
  multiple,
  selection,
  onSelectionChange,
  sourceCategory: undefined,
  sourceUuid,
  listTab,
  defaultVariableOperator,
  variableOperatorOptions,
  excludeVariableOperatorOptions,
  defaultGlobalOperator,
  globalOperatorOptions,
  excludeGlobalOperatorOptions,
  disableMaths,
  butKeepVariableMenu,
  availableFilters,
  defaultFilters,
  disableFilters,
  calculatedVariableMode,
  standardBlockMetricMode,
  editingBlockTypeMetricUuid,
  disableLego,
  disableModels,
  covarianceVariable,
  unitPicker,
  output,
  GroupByInputProps,
  disableCreateAlias,
});
