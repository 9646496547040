import { Descendant, Text } from 'slate';

import {
  CalculationVariable,
  CalculationVariableProps,
  IsCalculationVariable,
  IsExpressionVariable,
  MathFunctionName,
  Operator,
  Symbols,
  TraverseCalculation,
} from '@dametis/core';
import { mathJs, mathJsTypes } from '@dametis/mathjs';

import { createCalculationVariable } from 'functions/createCalculationVariable';

import { createBlockElement } from './block';
import { createFunctionElement } from './function';
import { createParagraph } from './paragraph';
import { createSymbolElement } from './symbol';
import { createEmptyText, createText, startWithEmptyText } from './text';
import { createVariableElement } from './variable';

export const wrapWithSpaces = (str: string) => ` ${str} `;

export const keepProps = ({
  period,
  groupBy,
  timeZone,
  maxPoints,
  deleteFrom,
  nickname,
  operator,
  operator_args,
  fill,
  limit,
  flags,
  timestamp,
  unit,
}: Partial<CalculationVariable>): CalculationVariableProps => ({
  ...(period !== undefined && { period }),
  ...(groupBy !== undefined && { groupBy }),
  ...(timeZone !== undefined && { timeZone }),
  ...(maxPoints !== undefined && { maxPoints }),
  ...(deleteFrom !== undefined && { deleteFrom }),
  ...(nickname !== undefined && { nickname }),
  ...(operator !== undefined && { operator }),
  ...(operator_args !== undefined && { operator_args }),
  ...(fill !== undefined && { fill }),
  ...(limit !== undefined && { limit }),
  ...(flags !== undefined && { flags }),
  ...(timestamp !== undefined && { timestamp }),
  ...(unit !== undefined && { unit }),
});

export const slateToTada = (
  descendants: Descendant[] | string,
  options: { withSlate?: boolean; startIndex?: number; calcVarProps?: CalculationVariableProps } = {},
): CalculationVariable => {
  if (!descendants) return createCalculationVariable();
  const parsedDescendants = Array.isArray(descendants) ? descendants : (JSON.parse(descendants) as Descendant[]);
  const withSlate = options.withSlate ?? true;
  const startIndex = options.startIndex ?? 0;
  const calcVarProps = options.calcVarProps ?? {};
  const calcVar: CalculationVariable = createCalculationVariable(keepProps(calcVarProps));
  let index = startIndex;
  parsedDescendants.forEach(element => {
    if (Text.isText(element)) {
      calcVar.exp += element.text;
    } else if (element.type === 'block') {
      const varN = `var_${index}`;
      calcVar.exp += wrapWithSpaces(varN);
      calcVar.vars[varN] = {
        ...keepProps(element),
        ...slateToTada(element.children, {
          withSlate: false,
        }),
      };
      index += 1;
    } else if (element.type === 'function') {
      const tmp = slateToTada(element.children, { withSlate: false, startIndex: index });
      calcVar.exp += wrapWithSpaces(`${element.fn}(${tmp.exp}`);
      calcVar.vars = {
        ...calcVar.vars,
        ...tmp.vars,
      };
      index += Object.keys(tmp.vars).length;
    } else if (element.type === 'paragraph') {
      const tmp = slateToTada(element.children, { withSlate: false, startIndex: index });
      calcVar.exp += wrapWithSpaces(tmp.exp);
      calcVar.vars = {
        ...calcVar.vars,
        ...tmp.vars,
      };
      index += Object.keys(tmp.vars).length;
    } else if (element.type === 'variable') {
      const varN = `var_${index}`;
      calcVar.exp += wrapWithSpaces(varN);
      calcVar.vars[varN] = element.variable;
      index += 1;
    } else if (element.type === 'symbol') {
      calcVar.exp += wrapWithSpaces(element.symbol);
    } else if (element.type === 'operator') {
      calcVar.exp += wrapWithSpaces(element.operator);
    } else if (element.type === 'batch') {
      const batchN = `batch_${index}`;
      calcVar.exp += wrapWithSpaces(batchN);
      calcVar.vars[batchN] = element.batch;
      index += 1;
    }
  });
  calcVar.exp = calcVar.exp.trim().replace(/\s+/g, ' ');
  if (withSlate) {
    calcVar.slate = JSON.stringify(descendants);
  }
  return calcVar;
};

const mathjsToSlate = (node?: mathJsTypes.MathNode, vars?: CalculationVariable['vars']): Descendant[] => {
  if (mathJs.isConstantNode(node)) {
    const { value } = node;
    if (value === null) return [createSymbolElement(Symbols.NULL), createEmptyText()];
    if (value === undefined) return [];
    if (typeof value === 'string') return [createText(`'${value}'`)];
    if (!Number.isFinite(value)) return [];
    return [createText(value.toString())];
  }
  if (mathJs.isSymbolNode(node)) {
    const variable = vars[node.name];
    if (!variable) {
      return [createText(node.name)];
    }
    if (IsCalculationVariable(variable)) {
      return [
        createBlockElement(
          keepProps(variable),
          startWithEmptyText(mathjsToSlate(variable.exp !== undefined ? mathJs.parse(variable.exp) : undefined, variable.vars)),
        ),
        createEmptyText(),
      ];
    }
    return [createVariableElement(variable), createEmptyText()];
  }
  if (mathJs.isOperatorNode(node)) {
    const descendants: Descendant[] = [];
    if (node.args.length === 2) {
      descendants.push(...mathjsToSlate(node.args[0], vars));
    }
    descendants.push(createSymbolElement(node.op as Symbols), createEmptyText(), ...mathjsToSlate(node.args[node.args.length - 1], vars));
    return descendants;
  }
  if (mathJs.isParenthesisNode(node)) {
    return [
      createSymbolElement(Symbols.LEFT_PARENTHESIS),
      createEmptyText(),
      ...mathjsToSlate(node.content, vars),
      createSymbolElement(Symbols.RIGHT_PARENTHESIS),
      createEmptyText(),
    ];
  }
  if (mathJs.isConditionalNode(node)) {
    return [
      ...mathjsToSlate(node.condition, vars),
      createSymbolElement(Symbols.TERNARY_IF),
      createEmptyText(),
      ...mathjsToSlate(node.trueExpr, vars),
      createSymbolElement(Symbols.TERNARY_ELSE),
      createEmptyText(),
      ...mathjsToSlate(node.falseExpr, vars),
    ];
  }
  if (mathJs.isFunctionNode(node)) {
    return [
      createFunctionElement(node.fn.name as MathFunctionName),
      ...node.args.reduce<Descendant[]>((acc, arg, index) => {
        acc.push(...startWithEmptyText(mathjsToSlate(arg, vars)));
        if (index < node.args.length - 1) {
          acc.push(createSymbolElement(Symbols.COMMA), createEmptyText());
        }
        return acc;
      }, []),
      createSymbolElement(Symbols.RIGHT_PARENTHESIS),
      createEmptyText(),
    ];
  }
  if (mathJs.isAssignmentNode(node)) {
    return [
      createText(node.name),
      createEmptyText(),
      createSymbolElement(Symbols.ASSIGN),
      createEmptyText(),
      ...mathjsToSlate(node.value, vars),
    ];
  }
  if (mathJs.isBlockNode(node)) {
    return node.blocks.reduce((descendants, block, index) => {
      descendants.push(...mathjsToSlate(block.node, vars));
      if (index < node.blocks.length - 1) {
        descendants.push(createSymbolElement(Symbols.SEMICOLON), createEmptyText());
      }
      return descendants;
    }, []);
  }
  return [];
};

export const tadaToSlate = (calcVar: CalculationVariable): Descendant[] => {
  if (!calcVar) return [createParagraph()];
  if (Array.isArray(calcVar.slate)) return calcVar.slate;
  if (typeof calcVar.slate === 'string') return JSON.parse(calcVar.slate);
  if (!IsExpressionVariable(calcVar)) return [createParagraph()];
  const node = mathJs.parse(calcVar.exp);
  return [createParagraph(startWithEmptyText(mathjsToSlate(node, calcVar.vars)))];
};

export const hasForbiddenOperators = (calcVar: CalculationVariable, allowedOperators: Operator[]): Operator[] => {
  const forbiddenOperators: Operator[] = [];
  TraverseCalculation(calcVar, node => {
    if (node.operator && !allowedOperators.includes(node.operator) && !forbiddenOperators.includes(node.operator)) {
      forbiddenOperators.push(node.operator);
    }
  });
  return forbiddenOperators;
};

export const functionsV1ToV2 = (descendants: Descendant[]): Descendant[] => {
  const newDescendants: Descendant[] = [];
  descendants.forEach(element => {
    if (Text.isText(element)) {
      newDescendants.push(element);
    } else if (
      element.type === 'function' &&
      !(element.children.length === 1 && Text.isText(element.children[0]) && element.children[0].text === '')
    ) {
      newDescendants.push(createFunctionElement(element.fn));
      newDescendants.push(...functionsV1ToV2(element.children));
      newDescendants.push(createSymbolElement(Symbols.RIGHT_PARENTHESIS));
    } else if (element.type === 'paragraph' || element.type === 'block') {
      newDescendants.push({
        ...element,
        children: functionsV1ToV2(element.children),
      });
    } else {
      newDescendants.push(element);
    }
  });
  return newDescendants;
};
