import { ListItemNode, ListNode } from '@lexical/list';
import { HeadingNode } from "@lexical/rich-text";

import { MentionNode } from 'components/UI/Comments/Comment/Edit/plugins/MentionPlugin/MentionNode';

export interface Theme {
  paragraph: string;
  text: {
    bold: string;
    italic: string;
    underline: string;
  };
}

export const lexicalTheme: Theme = {
  paragraph: 'editor-paragraph',
  text: {
    bold: 'editor-bold',
    italic: 'editor-italic',
    underline: 'editor-underline',
  },
};

export const onError = (errorMessage: Error): void => {
  throw errorMessage;
};

export interface Config {
  editable?: boolean;
  namespace: string;
  theme: Theme;
  onError: (error: Error) => void;
  nodes: Array<typeof HeadingNode | typeof ListNode | typeof ListItemNode | typeof MentionNode>;
  editorState?: string;
}
