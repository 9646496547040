import { Editor, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';

import { OperatorElement, Symbols } from '@dametis/core';

import { createEmptyText } from 'components/VNC/slate/text';

export const createOperatorElement = (operator: OperatorElement['operator']): OperatorElement => ({
  type: 'operator',
  operator,
  children: [createEmptyText()],
});

export const insertOperatorInSlate = (editor: Editor, operator?: Symbols.AND | Symbols.OR) => {
  let op = operator;
  if (!operator) {
    const { selection } = editor;
    if (!selection) return;
    const { anchor } = selection;
    const block = Editor.leaf(editor, anchor.path);
    const blockText = Editor.string(editor, block?.[1] ?? [])?.trim();
    const found = blockText.match(/(and|or)/);
    const textOperator = found?.[0] as Symbols.AND | Symbols.OR;
    if (!textOperator) return;
    const [beforeText] = blockText.split(textOperator);
    if (beforeText.length && !beforeText.match(/and|or/)) return;
    Transforms.select(editor, anchor.path);
    Transforms.delete(editor);
    op = textOperator;
  }
  const operatorElement = createOperatorElement(op);
  ReactEditor.focus(editor);
  Transforms.insertNodes(editor, operatorElement);
  Transforms.move(editor);
};
