import { createContext } from 'react';

export interface IFunctionArgContext {
  focused: number | null | undefined;
  setFocused: ((arg: number | null) => void) | undefined;
}

export const FunctionArgContext = createContext<IFunctionArgContext>({
  focused: undefined,
  setFocused: undefined,
});
