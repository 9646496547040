import { Tooltip, TooltipProps, Typography, TypographyProps } from '@mui/material';
import { FC, useRef } from 'react';

import useIsEllipsed from 'hooks/useIsEllipsed';

/**
 * @param {number}[width] if not provided, the width of the Typography will be used
 *  -> useful when using in DataGrids
 */

const tooltipPropsDefaultProp: Omit<TooltipProps, 'children' | 'title'> = {};

export interface TypographyEllipseProps extends TypographyProps {
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

const TypographyEllipse: FC<TypographyEllipseProps> = ({
  children = undefined,
  tooltipProps = tooltipPropsDefaultProp,
  width = undefined,
  ...props
}) => {
  const textRef = useRef(null);

  const isEllipsed = useIsEllipsed(textRef, width as number);

  return (
    <Tooltip placement="top-start" title={isEllipsed ? children : ''} {...tooltipProps}>
      <Typography noWrap {...props} ref={textRef}>
        {children}
      </Typography>
    </Tooltip>
  );
};

export default TypographyEllipse;
