import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CommentInfo, CreateCommentBody, TinyUserInfo, UpdateCommentBody, UUID } from '@dametis/core';

import { isAfter, isBefore } from 'localization/localizedDateFns';
import { sdk } from 'sdk';
import { useSelector } from 'store';
import { useCreateCommentMutation, useDeleteCommentMutation, useUpdateCommentMutation, useComments } from 'store/api/comments';
import { CommentFilter, CommentSortBy, CommentsProps, CommentView, EntityType } from 'types/comment';

export type CommentsProviderProps = CommentsProps;

export interface CommentsContextState extends Required<CommentsProviderProps> {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  fullscreen: boolean;
  setFullscreen: Dispatch<SetStateAction<boolean>>;
  filteredComments: CommentInfo[];
  view: CommentView;
  setView: Dispatch<SetStateAction<CommentView>>;
  displayOnlyOnCurrentPeriod: boolean;
  setDisplayOnlyOnCurrentPeriod: Dispatch<SetStateAction<boolean>>;
  sortBy: CommentSortBy;
  setSortBy: Dispatch<SetStateAction<CommentSortBy>>;
  periodFilter: CommentFilter;
  setPeriodFilter: Dispatch<SetStateAction<CommentFilter>>;
  ownersFilter: UUID[];
  setOwnersFilter: Dispatch<SetStateAction<UUID[]>>;
  commentsOwners: TinyUserInfo[];
  createComment: (comment: CreateCommentBody) => Promise<void>;
  editComment: (comment: UpdateCommentBody, commentId: UUID) => Promise<void>;
  removeComment: (commentId: UUID) => Promise<void>;
  collaborators: TinyUserInfo[];
}

export const contextInitialState: CommentsContextState = {
  entity: undefined,
  entityUuid: undefined,
  onCreateCb: undefined,
  onUpdateCb: undefined,
  open: false,
  setOpen: undefined,
  loading: false,
  fullscreen: false,
  setFullscreen: undefined,
  filteredComments: [],
  view: CommentView.LIST,
  setView: undefined,
  displayOnlyOnCurrentPeriod: false,
  setDisplayOnlyOnCurrentPeriod: undefined,
  sortBy: CommentSortBy.PERIOD_FROM_REVERSE,
  setSortBy: undefined,
  periodFilter: CommentFilter.ALL,
  setPeriodFilter: undefined,
  ownersFilter: [],
  setOwnersFilter: undefined,
  commentsOwners: [],
  createComment: undefined,
  editComment: undefined,
  removeComment: undefined,
  collaborators: [],
};

export const CommentsContext = createContext<CommentsContextState>(contextInitialState);

const emptyArray: CommentInfo[] = [];

const CommentsProvider: FC<PropsWithChildren<CommentsProviderProps>> = ({
  entity,
  entityUuid,
  onCreateCb,
  onUpdateCb,
  children = undefined,
  ...props
}) => {
  const period = useSelector(state => state.period.present.period);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  const queryParams = useMemo(() => ({ groupId, siteId, entity, entityUuid }), [entity, entityUuid, groupId, siteId]);
  const { data: comments = emptyArray, isFetching, isSuccess } = useComments(queryParams);

  const [createCommentQuery] = useCreateCommentMutation();
  const [updateComment] = useUpdateCommentMutation();
  const [deleteComment] = useDeleteCommentMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const [view, setView] = useState<CommentView>(CommentView.LIST);
  const [displayOnlyOnCurrentPeriod, setDisplayOnlyOnCurrentPeriod] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<CommentSortBy>(
    {
      [EntityType.REPORT]: CommentSortBy.PERIOD_FROM_REVERSE,
      [EntityType.PLAYGROUND]: CommentSortBy.PERIOD_FROM_REVERSE,
    }[entity] ?? CommentSortBy.CREATION_DATE_REVERSE,
  );
  const [periodFilter, setPeriodFilter] = useState<CommentFilter>(CommentFilter.ALL);

  const commentsOwners = useMemo(
    () =>
      comments?.reduce((acc: TinyUserInfo[], comment) => {
        if (comment.owner && acc.findIndex(owner => owner.uuid === comment?.owner.uuid) === -1) {
          acc.push(comment.owner);
        }
        return acc;
      }, []),
    [comments],
  );

  const [ownersFilter, setOwnersFilter] = useState<UUID[]>([]);
  const [collaborators, setCollaborators] = useState<TinyUserInfo[]>([]);
  const corporate = useSelector(state => !state.auth.selectedSite);

  const createComment = useCallback(
    async (comment: CreateCommentBody) => {
      await createCommentQuery({ comment, entity, entityUuid });
    },
    [entity, entityUuid, createCommentQuery],
  );

  const editComment = useCallback(
    async (comment: UpdateCommentBody, commentId: UUID) => {
      await updateComment({ comment, commentId, entityUuid });
    },
    [entityUuid, updateComment],
  );

  const removeComment = useCallback(
    async (commentId: UUID) => {
      await deleteComment({ commentId, entityUuid });
    },
    [deleteComment, entityUuid],
  );

  const localFilterComments = useMemo(
    () =>
      comments
        ?.filter(
          comment =>
            periodFilter !== CommentFilter.CURRENT_PERIOD ||
            !(
              isAfter(new Date(comment.period?.from), new Date(period.to)) || isBefore(new Date(comment.period?.to), new Date(period.from))
            ),
        )
        ?.reduce((acc: CommentInfo[], comment) => {
          const index = ownersFilter.findIndex(uuid => uuid === comment?.owner.uuid);
          if (index > -1) {
            acc.push(comment);
          }
          return acc;
        }, []),
    [comments, ownersFilter, period.from, period.to, periodFilter],
  );

  const filteredComments = useMemo(
    () =>
      localFilterComments.sort((commentA, commentB) => {
        let dateA: string | Date;
        let dateB: string | Date;
        if (sortBy === CommentSortBy.DATE) {
          dateA = commentB.date;
          dateB = commentA.date;
        } else if (sortBy === CommentSortBy.DATE_REVERSE) {
          dateA = commentA.date;
          dateB = commentB.date;
        } else if (sortBy === CommentSortBy.CREATION_DATE) {
          dateA = commentB.createdAt;
          dateB = commentA.createdAt;
        } else if (sortBy === CommentSortBy.CREATION_DATE_REVERSE) {
          dateA = commentA.createdAt;
          dateB = commentB.createdAt;
        } else if (sortBy === CommentSortBy.PERIOD_TO) {
          dateA = commentB.period?.to;
          dateB = commentA.period?.to;
        } else if (sortBy === CommentSortBy.PERIOD_TO_REVERSE) {
          dateA = commentA.period?.to;
          dateB = commentB.period?.to;
        } else if (sortBy === CommentSortBy.PERIOD_FROM) {
          dateA = commentB.period?.from;
          dateB = commentA.period?.from;
        } else if (sortBy === CommentSortBy.PERIOD_FROM_REVERSE) {
          dateA = commentA.period?.from;
          dateB = commentB.period?.from;
        }
        return isBefore(new Date(dateA), new Date(dateB)) ? 1 : -1;
      }),
    [localFilterComments, sortBy],
  );

  const fetchCollaborators = useCallback(async () => {
    try {
      const { data } = corporate ? await sdk.corporate.ListUsers(groupId) : await sdk.user.ListUsersOfSite(siteId);
      setCollaborators(data);
    } catch (err) {
      console.error(err);
    }
  }, [siteId, corporate, groupId]);

  useEffect(() => {
    void fetchCollaborators();
  }, [fetchCollaborators]);

  useEffect(() => {
    if (isSuccess) {
      setOwnersFilter(commentsOwners.map(user => user.uuid));
    }
  }, [commentsOwners, isSuccess]);

  const contextValues: CommentsContextState = useMemo(
    () => ({
      entity,
      entityUuid,
      onCreateCb,
      onUpdateCb,
      open,
      setOpen,
      fullscreen,
      setFullscreen,
      loading: isFetching,
      filteredComments,
      view,
      setView,
      displayOnlyOnCurrentPeriod,
      setDisplayOnlyOnCurrentPeriod,
      sortBy,
      setSortBy,
      periodFilter,
      setPeriodFilter,
      ownersFilter,
      setOwnersFilter,
      commentsOwners,
      createComment,
      editComment,
      removeComment,
      collaborators,
      ...props,
    }),
    [
      entity,
      entityUuid,
      onCreateCb,
      onUpdateCb,
      open,
      fullscreen,
      isFetching,
      filteredComments,
      view,
      displayOnlyOnCurrentPeriod,
      sortBy,
      periodFilter,
      ownersFilter,
      setOwnersFilter,
      commentsOwners,
      createComment,
      editComment,
      removeComment,
      collaborators,
      props,
    ],
  );

  return <CommentsContext.Provider value={contextValues}>{children}</CommentsContext.Provider>;
};

export const useCommentsContext = () => {
  const context = useContext(CommentsContext);

  if (!context) {
    throw Error('useCommentsContext must be used inside an CommentsProvider');
  }

  return context;
};

export default CommentsProvider;
