import { Folder, FolderOpen } from '@mui/icons-material';
import { Stack, SvgIconProps, Tooltip, Typography } from '@mui/material';
import { TreeItem } from '@mui/x-tree-view';
import { TFunction } from 'i18next';
import { FC, ReactNode } from 'react';

import { BlockTypeInfo, FolderInfo, ShortcutCategory, ShortStandardBlockInfo } from '@dametis/core';

import BlockTypeIcon, { BlockTypeIconProps } from 'components/Lego/UI/BlockType/BlockTypeIcon';
import { getFolderColor } from 'components/Lego/helpers/getFolderColor';

export interface ShortcutTreeItemProps {
  blockType: BlockTypeInfo;
}

export const ShortcutTreeItem: FC<ShortcutTreeItemProps> = ({ blockType }) => (
  <TreeItem
    itemId={blockType.uuid}
    label={blockType?.name}
    slotProps={{ icon: { color: blockType?.content?.color, icon: blockType?.content?.icon, size: 18 } as BlockTypeIconProps }}
    slots={{ icon: BlockTypeIcon }}
  />
);

export const generateTree = (
  folder: FolderInfo,
  t: TFunction,
  blockTypesById: Record<string, BlockTypeInfo> = {},
  standardBlocksById: Record<string, ShortStandardBlockInfo> = {},
): ReactNode => {
  const blockTypeShortcuts = folder.shortcuts.filter(
    shortcut => shortcut.category === ShortcutCategory.BLOCK_TYPE && shortcut.uuid && blockTypesById[shortcut.uuid],
  );
  // .sort((blockTypeA, blockTypeB) => blockTypesById[blockTypeA.uuid].name.localeCompare(blockTypesById[blockTypeB.uuid].name));

  const standardBlockShortcuts = folder.shortcuts.filter(
    shortcut => shortcut.category === ShortcutCategory.STANDARD_BLOCK && shortcut.uuid && standardBlocksById[shortcut.uuid],
  );
  // .sort((standardBlockA, standardBlockB) =>
  //   standardBlocksById[standardBlockA.uuid].name.localeCompare(standardBlocksById[standardBlockB.uuid].name),
  // );

  const sortedFolders = [...folder.folders].sort((folderA, folderB) => folderA.name.localeCompare(folderB.name));

  return (
    <>
      {folder.parentUuid ? (
        <TreeItem
          itemId={folder.uuid}
          label={
            <Stack alignItems="center" direction="row" justifyContent="space-between">
              <Typography>{folder.name}</Typography>
              <Tooltip
                placement="top"
                title={t('tooltip.folderContentLength', {
                  blockTypesLength: blockTypeShortcuts.length,
                  standardBlocksLength: standardBlockShortcuts.length,
                  foldersLength: folder.folders.length,
                })}
              >
                <Typography p={0.5} variant="overline">
                  {folder.folders.length + blockTypeShortcuts.length}
                </Typography>
              </Tooltip>
            </Stack>
          }
          slotProps={{
            collapseIcon: { sx: { color: theme => getFolderColor(folder, theme) } } as SvgIconProps,
            endIcon: { sx: { color: theme => getFolderColor(folder, theme) } } as SvgIconProps,
            expandIcon: { sx: { color: theme => getFolderColor(folder, theme) } } as SvgIconProps,
          }}
          slots={{
            collapseIcon: FolderOpen,
            endIcon: Folder,
            expandIcon: Folder,
          }}
        >
          {sortedFolders.map(child => generateTree(child, t, blockTypesById, standardBlocksById))}
          {/* {blockTypeShortcuts.map(blockTypeShortcut => (
            <ShortcutTreeItem key={blockTypeShortcut.uuid} blockType={blockTypesById[blockTypeShortcut.uuid]} />
          ))} */}
        </TreeItem>
      ) : (
        <>
          {sortedFolders.map(child => generateTree(child, t, blockTypesById, standardBlocksById))}
          {/* {blockTypeShortcuts.map(blockTypeShortcut => (
            <ShortcutTreeItem key={blockTypeShortcut.uuid} blockType={blockTypesById[blockTypeShortcut.uuid]} />
          ))} */}
        </>
      )}
    </>
  );
};
