import { useMemo } from 'react';

import { ModelInfo } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import { useGetVariableName } from 'hooks/useVariableProp';
import { ObjectEntries } from 'types';

interface CategorizedModels {
  others?: ModelInfo[];
  [key: string]: ModelInfo[];
}

export const useCategorizedModels = (models: ModelInfo[], disableSort = false) => {
  const getVariableName = useGetVariableName();

  const categories = useMemo(() => {
    return models.reduce<CategorizedModels>((acc, cur) => {
      const singleVariable = getSingleVariableCalculation(cur.yVar.variable);
      if (!singleVariable) {
        if (!acc.others) {
          acc.others = [];
        }
        acc.others.push(cur);
        return acc;
      }
      const singleVariableName = getVariableName(singleVariable);
      if (!acc[singleVariableName]) {
        acc[singleVariableName] = [];
      }
      acc[singleVariableName].push(cur);
      return acc;
    }, {});
  }, [models, getVariableName]);

  const sortedCategories = useMemo(() => {
    const { others, ...categoriesWithoutOthers } = categories;
    let sorted: ObjectEntries<CategorizedModels> = Object.entries(categoriesWithoutOthers).toSorted(([a], [b]) => a.localeCompare(b));
    if (!disableSort) {
      sorted = sorted.map(([key, value]) => [key, value.toSorted((a, b) => a.name.localeCompare(b.name))]);
    }
    return sorted;
  }, [categories, disableSort]);

  return useMemo(
    () => [...sortedCategories, ...(categories.others ? [['others', categories.others] as const] : [])],
    [sortedCategories, categories.others],
  );
};
