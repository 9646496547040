export default {
  title: {
    noReportSet: 'Seleccionar un informe para mostrar:',
    metrics: 'Mis indicadores de la semana',
    alarms: 'Alarmas activas',
  },
  text: {
    dametis: 'MyDametis.',
    dametisCorporate: 'MyDametis Corporate.',
    welcome: 'Bienvenido a ',
    intro: 'Hola {{name}}',
    onSite: 'Estás en el sitio ',
    ofGroup: ', del grupo ',
    onGroup: 'Estás en el grupo ',
    alarmCount_none: 'Sin alarmas',
    alarmCount_one: '{{count}} alarma',
    alarmCount_other: '{{count}} alarmas',
    active_none: 'no está activa.',
    active_one: 'está activa.',
    active_other: 'están activas.',
    alarmAck: 'Confirmada',
    alarmNotAck: 'No confirmada',
    noReport: 'Sin informe',
    versionStart: {
      v_success: 'Su',
      v_default: 'Su',
      v_warning: 'Su',
      v_danger: 'La sincronización con su',
    },
    versionDametisBox: {
      v_success: 'caja Dametis',
      v_default: 'caja Dametis',
      v_warning: 'caja Dametis',
      v_danger: 'caja Dametis',
    },
    versionEnd: {
      v_success: 'está sincronizada.',
      v_default: 'está en proceso de sincronización.',
      v_warning: 'está actualmente fuera de línea.',
      v_danger: 'ha fallado.',
    },
  },
  button: {
    save: 'Guardar',
    cancel: 'Cancelar',
    seeAll: 'Ver todas las alarmas',
    changeReport: 'Cambiar de informe',
  },
};
