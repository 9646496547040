import { BookmarkBorder, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import {
  Avatar,
  capitalize,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  listItemSecondaryActionClasses,
  ListItemText,
  Stack,
  Tooltip,
} from '@mui/material';
import { VirtualItem } from '@tanstack/react-virtual';
import { forwardRef, memo, useCallback, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { areEqual } from 'react-window';

import { GetCalculationVariableStats, IsShortCorporateAlias, ShortAliasInfo, ShortCorporateAliasInfo } from '@dametis/core';

import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { PropsContext } from 'components/VNC/context';
import { localizedFormatDistance } from 'localization/localizedDateFns';
import { useSelector } from 'store';
import { useDeleteAliasMutation } from 'store/api/aliases';

import CalculationSlate from '../../../../UI/CalculationSlate/CalculationSlate';
import { useInsertVariable } from '../../../hooks';
import { TypographyNoBreak } from '../TypographyNoBreak';

import AliasChip from './AliasChip';
import AliasDialog from './AliasDialog';

interface Props {
  item: ShortAliasInfo | ShortCorporateAliasInfo;
  isLast: boolean;
  row: VirtualItem;
}

const AliasesListItem = forwardRef<HTMLLIElement, Props>(({ item, isLast, row }, ref) => {
  const { t } = useTranslation('vnc');

  const [deleteAlias, { isLoading: isDeleting }] = useDeleteAliasMutation();
  const [dialogOpen, setDialogOpen] = useState(false);

  const isCorpo = useSelector(state => !state.auth.selectedSite);
  const sites = useSelector(state => state.auth.selectedGroup.sites);

  const insertVariable = useInsertVariable();

  const { disableLego, disableMaths, defaultVariableOperator, calculatedVariableMode } = useContext(PropsContext);

  const hasBlocks = useMemo(() => GetCalculationVariableStats(item.calculation).blockVariables.length > 0, [item.calculation]);
  const siteName = useMemo(() => {
    if (IsShortCorporateAlias(item)) return `${t('text.createdIn')} ${sites.find(site => site.uuid === item?.siteId)?.name}`;
    return '';
  }, [item, t, sites]);

  const addItem = useCallback(() => {
    insertVariable({ aliasUuid: item.uuid }, disableMaths, defaultVariableOperator, calculatedVariableMode);
  }, [calculatedVariableMode, defaultVariableOperator, disableMaths, insertVariable, item.uuid]);

  const deleteItem = useCallback(async () => {
    await deleteAlias(item.uuid);
  }, [deleteAlias, item.uuid]);

  const updateItem = useCallback(() => {
    setDialogOpen(true);
  }, []);

  return (
    <ListItem
      ref={ref}
      data-index={row.index}
      divider={!isLast}
      secondaryAction={
        isCorpo || item.canEdit === false ? undefined : (
          <>
            <Tooltip title={t('button.updateAliasItem')}>
              <IconButton onClick={updateItem}>
                <EditOutlined />
              </IconButton>
            </Tooltip>
            <AliasDialog edit alias={item} open={dialogOpen} setOpen={setDialogOpen} />
            <Tooltip title={t('button.deleteAliasItem')}>
              <IconButton disabled={isDeleting} onClick={deleteItem}>
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </>
        )
      }
      sx={{
        p: 0.5,
        [`& .${listItemSecondaryActionClasses.root}`]: { display: 'none' },
        [`&:hover .${listItemSecondaryActionClasses.root}`]: { display: 'block' },
        position: 'absolute',
        transform: `translateY(${row.start}px)`,
        top: 0,
        left: 0,
        width: '100%',
      }}
    >
      <ListItemButton disabled={hasBlocks && disableLego} sx={{ height: 1 }} onClick={addItem}>
        <ListItemAvatar>
          <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
            <Avatar>
              <BookmarkBorder />
            </Avatar>
            <TypographyNoBreak variant="caption">{t('list.text.variable')}</TypographyNoBreak>
          </Stack>
        </ListItemAvatar>
        <ListItemText
          primary={<TypographyEllipse>{item.name}</TypographyEllipse>}
          primaryTypographyProps={{ variant: 'h6' }}
          secondary={
            <>
              <CalculationSlate calculation={item.calculation} />
              {item.createdAt ? capitalize(localizedFormatDistance(new Date(item.createdAt), new Date(), { addSuffix: true })) : undefined}
              {t('text.fromSource')} <AliasChip size="small" source={item.source} sx={{ fontSize: 'inherit' }} />
              {isCorpo && item && IsShortCorporateAlias(item) && siteName}
              {item.owner && (
                <>
                  {t('text.createdBy')} {item.owner?.firstName} {item.owner?.lastName}
                </>
              )}
            </>
          }
          secondaryTypographyProps={{ component: 'div' }}
          sx={{ height: 1 }}
        />
      </ListItemButton>
    </ListItem>
  );
});

AliasesListItem.displayName = 'AliasesListItem';

export default memo(AliasesListItem, areEqual);
