import { IsModbusTcpDevice, IsOpcUaDevice } from '@dametis/core';

import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { setBoxesData } from 'store/slices/boxes';

import { displaySdkErrorToast } from './toasts';

export const fetchEquipment =
  (siteId?: string): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const selectedSiteId = siteId ?? getState().auth.selectedSite?.uuid;
    const groupId = getState().auth.selectedGroup.uuid;
    try {
      const { data: boxes } = selectedSiteId ? await sdk.box.List(selectedSiteId) : await sdk.corporate.ListBoxes(groupId);
      const boxesData = {
        maxLoopTime: 5000,
        list: boxes,
        allIds: [],
        devices: boxes.map(box => box.devices).flat(),
      };
      boxes.forEach(box => {
        boxesData.allIds.push(box.uuid);
        box.devices?.forEach(device => {
          if (IsModbusTcpDevice(device) && device.modbusTcp.loopTime > boxesData.maxLoopTime)
            boxesData.maxLoopTime = device.modbusTcp.loopTime;
          if (IsOpcUaDevice(device) && device.opcUa.loopTime > boxesData.maxLoopTime) boxesData.maxLoopTime = device.opcUa.loopTime;
        });
      });
      dispatch(setBoxesData(boxesData));
    } catch (error) {
      console.error(error);
      dispatch(displaySdkErrorToast(error));
    }
  };
