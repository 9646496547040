import { Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { useVncStore } from 'zustand/stores/vnc';

import ListItem from './ListItem';
import ListItemSkeleton from './ListItemSkeleton';

const skeletonItems = Array.from({ length: 10 }, (v, k) => k);

const VariablesList: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('vnc');

  const variables = useVncStore(state => state.results.variables);
  const loadingResults = useVncStore(state => state.loadingResults);

  if (!variables.length && !loadingResults)
    return (
      <Typography align="center" sx={{ mt: 4 }} variant="subtitle2">
        {t('subtitle.noVariables')}
      </Typography>
    );
  return (
    <AutoSizer>
      {({ width, height }) =>
        loadingResults ? (
          <FixedSizeList
            height={height}
            itemCount={skeletonItems.length}
            itemData={skeletonItems}
            itemKey={(index, data) => data[index]}
            itemSize={parseInt(theme.spacing(11), 10)}
            style={{ overflow: 'hidden' }}
            width={width}
          >
            {ListItemSkeleton}
          </FixedSizeList>
        ) : (
          <FixedSizeList
            height={height}
            itemCount={variables.length}
            itemData={variables}
            itemKey={(index, data) => data[index].uuid}
            itemSize={parseInt(theme.spacing(11), 10)}
            width={width}
          >
            {ListItem}
          </FixedSizeList>
        )
      }
    </AutoSizer>
  );
};

export default VariablesList;
