import { createTheme, Theme } from '@mui/material';
import { CSSProperties } from 'react';

import { components } from './components';
import { others } from './others';
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';

export const theme = createTheme({
  palette,
  typography,
  shape: {
    borderRadius: 6,
  },
  components,
  others,
  shadows,
});

declare module '@mui/material/styles/createTheme' {
  interface Theme {
    others: {
      headerHeight: CSSProperties['height'];
      footerHeight: CSSProperties['height'];
      pagePadding: CSSProperties['padding'];
      pagePaddingTop: CSSProperties['paddingTop'];
      pagePaddingRight: CSSProperties['paddingRight'];
      pagePaddingBottom: CSSProperties['paddingBottom'];
      pagePaddingLeft: CSSProperties['paddingLeft'];
      navBarWidthClosed: CSSProperties['width'];
      navBarWidthOpen: CSSProperties['width'];
      sidePanelWidth: CSSProperties['width'];
      rightPanelWidth: CSSProperties['width'];
      largeRightPanelWidth: CSSProperties['width'];
      extraLargeRightPanelWidth: CSSProperties['width'];
      minRightPanelWidth: CSSProperties['width'];
      maxRightPanelWidth: CSSProperties['width'];
      pageSizeNormal: CSSProperties['width'];
      pageSizeLarge: CSSProperties['width'];
      paperBorderRadius: CSSProperties['borderRadius'];
    };
  }
  interface ThemeOptions {
    others: {
      headerHeight: CSSProperties['height'];
      footerHeight: CSSProperties['height'];
      pagePadding: CSSProperties['padding'];
      pagePaddingTop: CSSProperties['paddingTop'];
      pagePaddingRight: CSSProperties['paddingRight'];
      pagePaddingBottom: CSSProperties['paddingBottom'];
      pagePaddingLeft: CSSProperties['paddingLeft'];
      navBarWidthClosed: CSSProperties['width'];
      navBarWidthOpen: CSSProperties['width'];
      sidePanelWidth: CSSProperties['width'];
      rightPanelWidth: CSSProperties['width'];
      largeRightPanelWidth: CSSProperties['width'];
      extraLargeRightPanelWidth: CSSProperties['width'];
      minRightPanelWidth: CSSProperties['width'];
      maxRightPanelWidth: CSSProperties['width'];
      pageSizeNormal: CSSProperties['width'];
      pageSizeLarge: CSSProperties['width'];
      paperBorderRadius: CSSProperties['borderRadius'];
    };
  }
}

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {
    others: {
      headerHeight: CSSProperties['height'];
      footerHeight: CSSProperties['height'];
      pagePadding: CSSProperties['padding'];
      pagePaddingTop: CSSProperties['paddingTop'];
      pagePaddingRight: CSSProperties['paddingRight'];
      pagePaddingBottom: CSSProperties['paddingBottom'];
      pagePaddingLeft: CSSProperties['paddingLeft'];
      navBarWidthClosed: CSSProperties['width'];
      navBarWidthOpen: CSSProperties['width'];
      sidePanelWidth: CSSProperties['width'];
      rightPanelWidth: CSSProperties['width'];
      largeRightPanelWidth: CSSProperties['width'];
      extraLargeRightPanelWidth: CSSProperties['width'];
      minRightPanelWidth: CSSProperties['width'];
      maxRightPanelWidth: CSSProperties['width'];
      pageSizeNormal: CSSProperties['width'];
      pageSizeLarge: CSSProperties['width'];
      paperBorderRadius: CSSProperties['borderRadius'];
    };
  }
}
