import {
  AcUnit,
  Air,
  ElectricBolt,
  Inventory2Outlined,
  LocalFireDepartmentOutlined,
  QuestionMark,
  Thermostat,
  Waves,
} from '@mui/icons-material';
import { FC } from 'react';

import { Energies, Energy } from '@dametis/core';

import { WaterOutlined } from '../assets/icons/WaterOutlined';
import { theme } from '../theme';

const createEnergy = (type: Energies, primaryColor: string, secondaryColor: string, icon: FC): Energy => ({
  type,
  primaryColor,
  secondaryColor,
  icon,
});

export const energies: Record<Energies, Energy> = {
  [Energies.AIR]: createEnergy(Energies.AIR, '#add8e6', '#ffffff', Air),
  [Energies.COMPRESSED_AIR]: createEnergy(Energies.COMPRESSED_AIR, '#93c2d2', '#ffffff', Air),
  [Energies.WATER]: createEnergy(Energies.WATER, '#1ca3ec', '#ffffff', WaterOutlined),
  [Energies.COLD]: createEnergy(Energies.COLD, '#1ca3ec', '#ffffff', AcUnit),
  [Energies.COOLANT_FLUID]: createEnergy(Energies.COOLANT_FLUID, '#4b95bd', '#ffffff', Thermostat),
  [Energies.ELECTRICITY]: createEnergy(Energies.ELECTRICITY, '#ffdd00', '#2a2a2a', ElectricBolt),
  [Energies.FUEL]: createEnergy(Energies.FUEL, theme.palette.icon.main, '#ffffff', LocalFireDepartmentOutlined),
  [Energies.GAS]: createEnergy(Energies.GAS, '#ffd755', '#ffffff', Waves),
  [Energies.STEAM]: createEnergy(Energies.STEAM, '#f59e33', '#ffffff', Air),
  [Energies.PRODUCT]: createEnergy(Energies.PRODUCT, '#0e945e', '#ffffff', Inventory2Outlined),
  [Energies.OTHER]: createEnergy(Energies.OTHER, theme.palette.icon.main, '#ffffff', QuestionMark),
};
