import { TypographyProps, Stack } from '@mui/material';
import { PropsWithChildren, FC, ReactNode } from 'react';

import TypographyEllipse from '../TypographyEllipse/TypographyEllipse';

interface Props extends TypographyProps {
  endAdornment?: ReactNode;
}

const HeaderPrimaryLeftTitle: FC<PropsWithChildren<Props>> = ({ children = undefined, endAdornment = null, ...props }) => (
  <Stack alignItems="center" direction="row" spacing={1.5} width={1}>
    <TypographyEllipse display="flex" flex={!endAdornment ? 1 : null} variant="h2" {...props}>
      {children}
    </TypographyEllipse>
    {endAdornment}
  </Stack>
);

export default HeaderPrimaryLeftTitle;
