import { Alert, alertClasses } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  message?: string;
}

const MaintenanceBanner: FC<Props> = ({ message = 'msg' }) => {
  const { t } = useTranslation('header');
  return (
    <Alert severity="warning" sx={{ maxHeight: 48, [`& .${alertClasses.message}`]: { p: 0, whiteSpace: 'pre-line' } }}>
      {t(`maintenance.${message}`)}
    </Alert>
  );
};

export default MaintenanceBanner;
