import { styled, Toolbar, ToolbarProps } from '@mui/material';
import { FC } from 'react';

import { editableInputClasses } from '../EditableInput/EditableInput';

export interface HeaderPrimaryProps extends ToolbarProps {
  secondaryToolbar?: boolean;
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  top: 0,
  display: 'flex',
  gap: theme.spacing(2),
  position: 'sticky',
  zIndex: theme.zIndex.appBar,
  padding: '0px !important',
  overflow: 'hidden',
  width: '100%',
}));

const HeaderPrimary: FC<HeaderPrimaryProps> = ({ secondaryToolbar = false, ...props }) => (
  <StyledToolbar className={editableInputClasses.parent} sx={{ ...(secondaryToolbar && { mb: 0 }) }} {...props} />
);

export default HeaderPrimary;
