import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortcutCategory } from '@dametis/core';

import { shortcutConfig } from 'components/UI/ShortcutPanel/shortcutConfig';

export interface ProjectPluginButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const ProjectPluginButton: FC<ProjectPluginButtonProps> = ({ onClick, disabled = false }) => {
  const { t } = useTranslation('comment');

  return (
    <Tooltip title={!disabled ? t('button.joinProject') : ''}>
      <div>
        <IconButton disabled={disabled} size="small" sx={{ height: '30px', width: '30px' }} onClick={onClick}>
          <SvgIcon component={shortcutConfig[ShortcutCategory.PROJECT]?.icon} fontSize="small" />
        </IconButton>
      </div>
    </Tooltip>
  );
};

export default ProjectPluginButton;
