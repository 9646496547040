import { ClickAwayListener, Stack, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider, dateCalendarClasses, multiSectionDigitalClockClasses } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { FC, useCallback, useContext, useMemo, useState } from 'react';

import {
  CommentInfo,
  CreateCommentOnProjectBody,
  CreateCommentOnReportBody,
  CreateCommentOnTaskBody,
  CreateCommentOnVariableBody,
} from '@dametis/core';

import { getLocale } from 'localization';
import { localizedFormat } from 'localization/localizedDateFns';

import DateTimeRangeSetter from '../../DateTimeRangePanel/DateTimeRangeSetter';
import ShortcutChip from '../../ShortcutPanel/ShortcutChip';

import { CommentContext } from './context/CommentContext';

export interface CommentProps {
  comment?: CommentInfo | CreateCommentOnProjectBody | CreateCommentOnReportBody | CreateCommentOnTaskBody | CreateCommentOnVariableBody;
  onDateClick?: (date: Date) => void;
  onPeriodClick?: (period: { from: Date; to: Date }) => void;
  loading?: boolean;
  datePicker?: boolean;
}

const CommentOptionsFooter: FC<CommentProps> = ({
  comment = undefined,
  loading = false,
  onDateClick = undefined,
  onPeriodClick = undefined,
  datePicker = false,
}) => {
  const { currentComment, setCurrentComment } = useContext(CommentContext);
  const editing = useMemo(() => Boolean(currentComment), [currentComment]);
  const shortcut = useMemo(() => ((!editing ? comment : currentComment) as CommentInfo)?.shortcut, [comment, editing, currentComment]);
  const period = useMemo(() => ((!editing ? comment : currentComment) as CommentInfo)?.period, [comment, editing, currentComment]);
  const date = useMemo(() => ((!editing ? comment : currentComment) as CommentInfo)?.date, [comment, editing, currentComment]);
  const [dateTimePickerOpen, setDateTimePickerOpen] = useState<boolean>(false);

  const handleDateClick = useCallback(
    (newDate: Date) => {
      if (editing) {
        setCurrentComment({ ...currentComment, date: newDate });
      } else if (onDateClick && date) {
        onDateClick(new Date(date));
      }
    },
    [editing, onDateClick, date, setCurrentComment, currentComment],
  );

  const handlePeriodClick = useCallback(
    ([from, to]: Date[]) => {
      if (period) {
        if (editing) {
          if (setCurrentComment) setCurrentComment({ ...currentComment, period: { from: from.toISOString(), to: to.toISOString() } });
        } else if (onPeriodClick) {
          onPeriodClick({ from: new Date(period.from), to: new Date(period.to) });
        }
      }
    },
    [currentComment, editing, onPeriodClick, period, setCurrentComment],
  );

  const handleDeleteShortcut = useCallback(
    () => e => {
      e.preventDefault();
      if (setCurrentComment) setCurrentComment({ ...currentComment, shortcut: null });
    },
    [currentComment, setCurrentComment],
  );

  const toggleDatePicker = useCallback(() => setDateTimePickerOpen(oldValue => !oldValue), []);

  const handleClickAway = (e: MouseEvent | TouchEvent) => {
    const check = (elem: any) =>
      [...Object.values(dateCalendarClasses), ...Object.values(multiSectionDigitalClockClasses)].some(className =>
        elem?.classList?.contains(className),
      );
    const chromeCheck = (evt: any) => evt?.path?.some(check);
    const firefoxCheck = (target: any) => {
      if (check(target)) {
        return true;
      }
      if (target?.parentElement) {
        return firefoxCheck(target.parentElement);
      }
      return false;
    };
    if (chromeCheck(e) || firefoxCheck(e.target)) return;
    setDateTimePickerOpen(false);
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      flexWrap="wrap"
      spacing={1}
      sx={{
        p: theme => `0 ${theme.spacing(1)}`,
        maxWidth: '100%',
      }}
    >
      {period ? (
        <DateTimeRangeSetter
          disabled={loading}
          editing={editing}
          from={new Date(period.from)}
          to={new Date(period.to)}
          onChange={handlePeriodClick}
        />
      ) : (
        date && (
          <LocalizationProvider adapterLocale={getLocale()} dateAdapter={AdapterDateFns}>
            {editing && datePicker ? (
              <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
                <DateTimePicker
                  open={dateTimePickerOpen}
                  value={new Date(date)}
                  onChange={handleDateClick}
                  onClose={toggleDatePicker}
                  onOpen={toggleDatePicker}
                />
              </ClickAwayListener>
            ) : (
              <Typography
                sx={{
                  fontSize: '0.9em',
                  fontStyle: 'italic',
                }}
                variant="body1"
              >
                {localizedFormat(new Date(date), 'PPpp')}
              </Typography>
            )}
          </LocalizationProvider>
        )
      )}
      {shortcut && (
        <ShortcutChip deactivated={editing} shortcut={shortcut} size="small" onDelete={editing ? handleDeleteShortcut : undefined} />
      )}
    </Stack>
  );
};

export default CommentOptionsFooter;
