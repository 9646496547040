import { Box, ListSubheader, Typography } from '@mui/material';
import { defaultRangeExtractor, useVirtualizer } from '@tanstack/react-virtual';
import { findIndex } from 'lodash';
import { FC, useCallback, useContext, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortAliasInfo, ShortCorporateAliasInfo } from '@dametis/core';

import Counter from 'components/UI/Counter/Counter';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../../context';

import AliasesListItem from './AliasesListItem';

const byCreatedAt = (a: ShortAliasInfo | ShortCorporateAliasInfo, b: ShortAliasInfo | ShortCorporateAliasInfo) =>
  new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1;

interface Categorized {
  sameEntity: (ShortAliasInfo | ShortCorporateAliasInfo | [string, number])[];
  sameCategory: (ShortAliasInfo | ShortCorporateAliasInfo | [string, number])[];
  others: (ShortAliasInfo | ShortCorporateAliasInfo | [string, number])[];
}

interface AliasesListProps {
  filteredAliases: (ShortAliasInfo | ShortCorporateAliasInfo)[];
}

const AliasesList: FC<AliasesListProps> = ({ filteredAliases }) => {
  const { sourceUuid, sourceCategory } = useContext(PropsContext);
  const search = useVncStore(state => state.search);
  const { t } = useTranslation('vnc');

  const parentRef = useRef<HTMLDivElement>(null);
  const activeStickyIndexRef = useRef(0);

  const isActiveSticky = (index: number) => activeStickyIndexRef.current === index;

  const category: Categorized = {
    sameEntity: [],
    sameCategory: [],
    others: [],
  };

  const orderedAliases = useMemo<(ShortAliasInfo | ShortCorporateAliasInfo | [string, number])[]>(() => {
    const ordered: (ShortAliasInfo | ShortCorporateAliasInfo | [string, number])[] = [];
    filteredAliases.forEach(alias => {
      if (alias.source.uuid && sourceUuid && alias.source.uuid === sourceUuid) {
        category.sameEntity.push(alias);
      } else if (alias.source.category === sourceCategory) {
        category.sameCategory.push(alias);
      } else {
        category.others.push(alias);
      }
    });
    category.sameEntity.sort(byCreatedAt);
    category.sameEntity.unshift(['sameEntity', category.sameEntity.length]);
    category.sameCategory.sort(byCreatedAt);
    category.sameCategory.unshift(['sameCategory', category.sameCategory.length]);
    category.others.sort(byCreatedAt);
    category.others.unshift(['others', category.others.length]);
    ordered.push(
      ...(category.sameEntity.length > 1 ? category.sameEntity : []),
      ...(category.sameCategory.length > 1 ? category.sameCategory : []),
      ...(category.others.length > 1 ? category.others : []),
    );
    return ordered;
  }, [category.others, category.sameCategory, category.sameEntity, filteredAliases, sourceCategory, sourceUuid]);

  const categories = Object.keys(category);

  const stickyIndexes = useMemo(
    () =>
      categories.map(categoryName =>
        findIndex(orderedAliases, alias => {
          if (Array.isArray(alias) && alias[0] === categoryName) {
            return true;
          }
          return false;
        }),
      ),
    [categories, orderedAliases],
  );

  const isSticky = (index: number) => stickyIndexes.includes(index);

  const virtualizer = useVirtualizer({
    count: orderedAliases.length,
    estimateSize: () => 300,
    getScrollElement: () => parentRef.current,
    rangeExtractor: useCallback(
      range => {
        activeStickyIndexRef.current = [...stickyIndexes].reverse().find(index => range.startIndex >= index);
        const next = new Set([activeStickyIndexRef.current, ...defaultRangeExtractor(range)]);
        return [...next].sort((a, b) => a - b);
      },
      [stickyIndexes],
    ),
  });

  const getCategoryLabel = useCallback(
    (label: string, count: number) => {
      switch (label) {
        case 'sameEntity':
          return t('list.subheader.sameEntity', { category: sourceCategory, count });
        case 'sameCategory':
          return t('list.subheader.sameCategory', { category: sourceCategory, count });
        case 'others':
          return t('list.subheader.others', { count });
        default:
          return label;
      }
    },
    [sourceCategory, t],
  );

  if (!filteredAliases.length) {
    return (
      <Typography align="center" sx={{ mt: 4, px: 6 }} variant="subtitle2">
        {t(search.length ? 'subtitle.noAliasesForSearch' : 'subtitle.noAliases', { search })}
      </Typography>
    );
  }
  const items = virtualizer.getVirtualItems();
  return (
    <Box ref={parentRef} height="100%" sx={{ contain: 'strict', overflowY: 'auto' }}>
      <Box height={virtualizer.getTotalSize} position="relative" width="100%">
        {items.map(row => {
          if (!row) return null;
          const item = orderedAliases[row.index];
          if (Array.isArray(item)) {
            return (
              <ListSubheader
                key={row.key}
                ref={virtualizer.measureElement}
                data-index={row.index}
                sx={{
                  ...(isSticky(row.index) ? { background: '#fff', zIndex: 1 } : {}),
                  ...(isActiveSticky(row.index)
                    ? { position: 'sticky' }
                    : { position: 'absolute', transform: `translateY(${row.start}px)` }),
                  top: 0,
                  left: 0,
                  display: 'flex',
                  gap: 0.5,
                  alignItems: 'center',
                  mx: 0,
                  width: '100%',
                }}
              >
                {getCategoryLabel(item[0], item[1])}
                <Counter count={item[1]} />
              </ListSubheader>
            );
          }
          return (
            <AliasesListItem
              key={row.key}
              ref={virtualizer.measureElement}
              isLast={row.index === orderedAliases.length - 1}
              item={item}
              row={row}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default AliasesList;
