import { StandardTaskStatus } from '@dametis/core';

export default {
  title: {
    tasks: 'Tâches',
    redirection: 'Redirection',
  },
  button: {
    cancel: 'Annuler',
    continue: 'Continuer',
    reset: 'Réinitialiser',
  },
  tooltip: {},
  toggle: {},
  input: {
    label: {
      template: 'Modèles de projets',
      project: 'Projet...',
      collaborators: 'Collaborateurs...',
    },
  },
  label: {
    template: 'Template',
  },
  timeUnit: {},
  text: {
    defaultTemplate: 'Modèle par défaut',
    redirection: 'Vous allez être redirigé vers le site',
    unassigned: 'Non assignée',
    noTasks: 'Aucune tâche',
    noOptions: 'Aucune option',
    projectsLength_zero: 'Aucun projet',
    projectsLength_one: '1 projet',
    projectsLength_other: '{{count}} projets',
    noTemplate: 'Projets sans template',
    allTemplates: 'Tous les templates',
    noProject: 'Aucun projet',
  },
  placeholder: {
    allProjects: 'Tous les projets',
  },
  tasks: {
    status: {
      [`variant_${StandardTaskStatus.TODO}`]: 'À faire',
      [`variant_${StandardTaskStatus.ONGOING}`]: 'En cours',
      [`variant_${StandardTaskStatus.TESTING}`]: 'À tester',
      [`variant_${StandardTaskStatus.DONE}`]: 'Terminée',
    },
  },
};
