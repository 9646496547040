import { DateRange } from '@mui/x-date-pickers-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { endOfDay, startOfDay } from 'date-fns';

import {
  MacroInfo,
  UUID,
  RawPoint,
  BrandDeviceInfo,
  ApiVersion,
  BoxVersion,
  VariableListKind,
  GroupBy1,
  CalculationVariableWithUuid,
  VariableListInfo,
} from '@dametis/core';

export interface DevicesLastPoints {
  fetchedAt: Date;
  data: Record<UUID, RawPoint>;
  isFetching: boolean;
}

export interface ConfigurationState {
  lastPoints: DevicesLastPoints;
  brandDevices: BrandDeviceInfo[];
  versions: {
    api: ApiVersion | null;
    box: BoxVersion | null;
  };
  variableLists: VariableListInfo[];
  newRows: Record<string, any>[];
  macros: MacroInfo[];
  boxesStatus: Record<UUID, RawPoint>;
  currentExport: Partial<VariableListInfo>;
  exportDates: DateRange<any>;
  exportGroupBy: GroupBy1;
  exportTransposed: boolean;
}

const resetState: Omit<ConfigurationState, 'macros'> = {
  lastPoints: { isFetching: false, data: {}, fetchedAt: null },
  brandDevices: [],
  versions: { api: null, box: null },
  variableLists: [],
  newRows: [],
  boxesStatus: {},
  currentExport: { name: '', kind: VariableListKind.EXPORT, calculations: [] },
  exportDates: [startOfDay(new Date()), endOfDay(new Date())],
  exportGroupBy: '1h',
  exportTransposed: false,
};

const initialState: ConfigurationState = {
  ...resetState,
  macros: null,
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setLastPoints: (state, action: PayloadAction<{ data: Record<UUID, RawPoint>; fetchedAt: Date }>) => {
      state.lastPoints.data = action.payload.data;
      state.lastPoints.fetchedAt = action.payload.fetchedAt;
    },
    setIsFetchingLastPoints: (state, action: PayloadAction<boolean>) => {
      state.lastPoints.isFetching = action.payload;
    },
    setBrandDevices: (state, action: PayloadAction<BrandDeviceInfo[]>) => {
      state.brandDevices = action.payload;
    },
    setVersions: (state, action: PayloadAction<{ api: ApiVersion; box: BoxVersion }>) => {
      state.versions = action.payload;
    },
    setVariableLists: (state, action: PayloadAction<VariableListInfo[]>) => {
      state.variableLists = action.payload;
    },
    setMacros: (state, action: PayloadAction<MacroInfo[]>) => {
      state.macros = action.payload;
    },
    setBoxesStatuses: (state, action: PayloadAction<Record<UUID, RawPoint>>) => {
      state.boxesStatus = action.payload;
    },
    setCurrentExport: (state, action: PayloadAction<Partial<VariableListInfo>>) => {
      state.currentExport = action.payload;
    },
    clearCurrentExport: state => {
      state.currentExport = initialState.currentExport;
    },
    setCurrentExportVariables: (state, action: PayloadAction<CalculationVariableWithUuid[]>) => {
      state.currentExport.calculations = action.payload;
    },
    addCurrentExportVariable: (state, action: PayloadAction<CalculationVariableWithUuid[]>) => {
      state.currentExport.calculations = state.currentExport.calculations.concat(action.payload);
    },
    setExportDates: (state, action: PayloadAction<DateRange<any>>) => {
      state.exportDates = action.payload;
    },
    setExportGroupBy: (state, action: PayloadAction<GroupBy1>) => {
      state.exportGroupBy = action.payload;
    },
    setExportTransposed: (state, action: PayloadAction<boolean>) => {
      state.exportTransposed = action.payload;
    },
    clearExport: state => {
      state.currentExport = initialState.currentExport;
      state.exportDates = initialState.exportDates;
      state.exportGroupBy = initialState.exportGroupBy;
      state.exportTransposed = initialState.exportTransposed;
    },
    clearConfigurationStore: state => ({ ...state, ...resetState }),
  },
});

export const {
  setLastPoints,
  setIsFetchingLastPoints,
  setBrandDevices,
  setVariableLists,
  setMacros,
  setBoxesStatuses,
  clearConfigurationStore,
  setExportDates,
  setExportGroupBy,
  setCurrentExport,
  clearCurrentExport,
  setCurrentExportVariables,
  addCurrentExportVariable,
  setExportTransposed,
  clearExport,
} = configurationSlice.actions;

export default configurationSlice.reducer;
