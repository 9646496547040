import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockVariable } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { getBlockUrl } from 'components/Lego/helpers/getBlockUrl';
import { useFolders } from 'store/api/folders';

import LinkButton from '../../../../UI/Buttons/LinkButton/LinkButton';

interface Props {
  variable: Partial<BlockVariable>;
}

const BlockVariableDetails: FC<Props> = ({ variable }) => {
  const { t } = useTranslation('vnc');

  const { data: rootFolder = null } = useFolders();

  const url = useMemo(() => getBlockUrl(rootFolder, variable.blockId), [rootFolder, variable.blockId]);

  return (
    <div>
      <LinkButton size="small" startIcon={<BlocksOutlined />} to={url}>
        {t('playground:button.configuration')}
      </LinkButton>
    </div>
  );
};

export default BlockVariableDetails;
