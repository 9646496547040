import { useMemo } from 'react';

import { BlockInfo, CreateBlockBody, FolderInfo, ShortBlockInfo, ShortcutCategory, UUID, UpdateBlockBody } from '@dametis/core';

import { setBlocksById } from 'store/slices/lego';

import { sdk } from '../../sdk';
import store, { RootState, useSelector } from '../index';

import { api } from './index';

export const blocksApi = api.injectEndpoints({
  endpoints: build => ({
    readBlocks: build.query<BlockInfo[] | undefined, { siteId: string; canAccessLego: boolean } | void>({
      providesTags: ['Blocks'],
      queryFn: async (arg, { getState, dispatch }) => {
        const state = getState() as RootState;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        const canAccessLego =
          (arg && arg.canAccessLego) ||
          state.auth.user.acl.HasPermission('canAccessLego', state.auth.selectedGroup?.uuid, state.auth.selectedSite);
        if (!siteId || !canAccessLego) {
          return { data: undefined };
        }
        try {
          const { data } = await sdk.block.List(siteId);
          dispatch(
            setBlocksById(
              data.reduce<Record<UUID, BlockInfo>>((result, block) => {
                result[block.uuid] = block;
                return result;
              }, {}),
            ),
          );
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createBlock: build.mutation<ShortBlockInfo, CreateBlockBody>({
      invalidatesTags: ['Blocks', 'Folders'],
      queryFn: async (block, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        if (!siteId) {
          throw new Error('No site ID');
        }
        try {
          const { data } = await sdk.block.Create(siteId, block);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    moveBlock: build.mutation<FolderInfo, { blockId: UUID; sourceId: UUID; targetId: UUID }>({
      invalidatesTags: ['Blocks', 'Folders'],
      queryFn: async ({ blockId, sourceId, targetId }, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        if (!siteId) {
          throw new Error('No site ID');
        }
        try {
          const { data } = await sdk.folder.Update(siteId, sourceId, {
            operation: {
              name: 'move',
              params: { sourceFolderId: sourceId, targetFolderId: targetId, shortcut: { category: ShortcutCategory.BLOCK, uuid: blockId } },
            },
          });
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateBlock: build.mutation<ShortBlockInfo, { uuid: UUID; body: UpdateBlockBody }>({
      invalidatesTags: ['Blocks'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.block.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteBlock: build.mutation<void, UUID>({
      invalidatesTags: ['Blocks'],
      queryFn: async blockId => {
        try {
          const { data } = await sdk.block.Delete(blockId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    restoreBlock: build.mutation<UUID, { activityId: UUID; blockId: UUID }>({
      invalidatesTags: ['Blocks'],
      queryFn: async ({ activityId, blockId }) => {
        try {
          await sdk.block.Restore(blockId, activityId);
          return { data: blockId };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useCreateBlockMutation, useDeleteBlockMutation, useMoveBlockMutation, useUpdateBlockMutation, useRestoreBlockMutation } =
  blocksApi;

export const selectBlocks: typeof blocksApi.endpoints.readBlocks.select = arg =>
  blocksApi.endpoints.readBlocks.select(
    arg ?? {
      siteId: store.getState().auth.selectedSite?.uuid,
      canAccessLego: store
        .getState()
        .auth.user.acl.HasPermission('canAccessLego', store.getState().auth.selectedGroup?.uuid, store.getState().auth.selectedSite),
    },
  );

export const useBlocks: typeof blocksApi.useReadBlocksQuery = (arg, options) => {
  const acl = useSelector(state => state.auth.user.acl);
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const site = useSelector(state => state.auth.selectedSite);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const canAccessLego = useMemo(() => acl.HasPermission('canAccessLego', groupId, site), [acl, groupId, site]);

  return blocksApi.useReadBlocksQuery(arg ?? { siteId, canAccessLego }, { skip: !siteId || !canAccessLego, ...options });
};
