export default {
  title: {
    editData: 'Edición de datos',
  },
  button: {
    refresh: 'Actualizar',
    send: 'Enviar',
    details: 'Detalles',
    history: 'Historial',
    revert: 'Restaurar',
    retry: 'Reintentar',
  },
  label: {
    totalSize: 'Tamaño Total',
    editMode: 'Modo de edición',
    variableKind: 'Tipo de variable',
    minValue: 'Valor Mínimo',
    maxValue: 'Valor Máximo',
    limit: 'Límite',
    source: 'Fuente',
  },
  toast: {
    RevertOperationSuccess: 'Revertir Operación Exitosa',
    RevertOperationError: 'Revertir Operación con Error',
    RetryOperationSuccess: 'Reintentar Operación Exitosa',
    RetryOperationError: 'Reintentar Operación con Error',
    EditDataSuccess: 'Editar Datos Exitoso',
    EditDataError: 'Editar Datos con Error',
    DeleteDataSuccess: 'Vaciar Datos Exitoso',
    DeleteDataError: 'Vaciar Datos con Error',
  },
};
