import { AutoAwesomeOutlined, DeleteOutlined, EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationVariable, PhysicalQuantity } from '@dametis/core';
import { UnitConverter } from '@dametis/unit';

import { LegoMetric } from 'components/Lego/types';
import ElementContainer, { ElementContainerProps } from 'components/UI/ElementContainer/ElementContainer';
import GoToPlaygroundIconButton from 'components/UI/GoToPlaygroundButton/GoToPlaygroundIconButton';
import PhysicalQuantityAvatar from 'components/UI/PhysicalQuantity/PhysicalQuantityAvatar';
import TypographyEllipse from 'components/UI/TypographyEllipse/TypographyEllipse';
import { getPhysicalQuantities } from 'config';
import { calculationToString } from 'functions/calculationToString';
import { setColorLightness } from 'functions/color';

import CalculationPopover from './CalculationPopover';

export interface LegoMetricPreviewProps<T extends LegoMetric = LegoMetric> extends ElementContainerProps {
  metric: Partial<Omit<T, 'blockKey'>> & Pick<T, 'blockKey'>;
  displayCalculation?: boolean;
  displayCalculationPopper?: boolean;
  playgroundCalculation?: CalculationVariable;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
}

const LegoMetricPreview = <T extends LegoMetric = LegoMetric>({
  metric,
  displayCalculation = false,
  displayCalculationPopper = false,
  playgroundCalculation = undefined,
  onEdit = undefined,
  onDelete = undefined,
  disabled,
  ...props
}: LegoMetricPreviewProps<T>) => {
  const { t } = useTranslation('lego');
  const theme = useTheme();

  const [calculationPopperAnchorEl, setCalculationPopperAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isCalculationPopperOpen = useMemo(() => Boolean(calculationPopperAnchorEl), [calculationPopperAnchorEl]);

  const displayActions = useMemo(
    () => !!onEdit || !!onDelete || displayCalculationPopper || playgroundCalculation !== undefined,
    [onEdit, onDelete, displayCalculationPopper, playgroundCalculation],
  );

  const physicalQuantities = useMemo(() => getPhysicalQuantities(t, theme), [t, theme]);

  const physicalQuantity = useMemo(() => UnitConverter.Parse(metric.unit ?? '')?.physicalQuantity ?? PhysicalQuantity.NONE, [metric.unit]);

  const physicalQuantityColor = useMemo(() => physicalQuantities[physicalQuantity]?.color, [physicalQuantities, physicalQuantity]);

  const title = useMemo(() => {
    if (metric.unit && metric.unit.length > 0) {
      return `${metric.blockKey} (${metric.unit})`;
    }
    return metric.blockKey;
  }, [metric]);

  const calculations = useMemo(() => (playgroundCalculation ? [playgroundCalculation] : null), [playgroundCalculation]);

  const handleOpenCalculationPopper: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setCalculationPopperAnchorEl(event.currentTarget);
  }, []);

  const handleCloseCalculationPopper = useCallback(() => {
    setCalculationPopperAnchorEl(null);
  }, []);

  return (
    <>
      <ElementContainer color={physicalQuantityColor} disabled={disabled} {...props}>
        <Stack alignItems="center" direction="row" spacing={1}>
          <PhysicalQuantityAvatar
            physicalQuantity={physicalQuantity}
            size="small"
            sx={{ bgcolor: disabled ? 'grey.1400' : physicalQuantityColor }}
          />
          <Stack alignItems="center" direction="row">
            <TypographyEllipse sx={{ color: disabled ? 'grey.1400' : setColorLightness(physicalQuantityColor, 30) }} variant="h6">
              {title}
            </TypographyEllipse>
          </Stack>
          {metric.isVirtual && (
            <Stack alignItems="center" justifyContent="center" ml="auto">
              <Tooltip placement="top" title={t('label.isVirtual')}>
                <AutoAwesomeOutlined
                  fontSize="small"
                  sx={{ color: disabled ? 'grey.1400' : setColorLightness(physicalQuantityColor, 30) }}
                />
              </Tooltip>
            </Stack>
          )}
        </Stack>
        {displayCalculation && (
          <TypographyEllipse fontStyle={metric.isVirtual ? 'italic' : undefined}>
            {metric.isVirtual ? t('label.isVirtual') : calculationToString(metric.calculation)}
          </TypographyEllipse>
        )}
        {displayActions && (
          <Stack alignItems="center" className="actions" direction="row" spacing={0.5}>
            {displayCalculationPopper && (
              <Tooltip placement="top" title={t('tooltip.seeCalculation')}>
                <IconButton size="small" onClick={handleOpenCalculationPopper}>
                  <VisibilityOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {calculations && (
              <Tooltip placement="top" title={t('tooltip.seeCalculation')}>
                <GoToPlaygroundIconButton calculations={calculations} tooltipProps={{ placement: 'top' }} />
              </Tooltip>
            )}
            {onEdit && (
              <Tooltip title={t('tooltip.edit')}>
                <IconButton size="small" onClick={onEdit}>
                  <EditOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {onDelete && (
              <Tooltip title={t('tooltip.delete')}>
                <IconButton size="small" onClick={onDelete}>
                  <DeleteOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
      </ElementContainer>
      {metric.calculation && (
        <CalculationPopover
          anchorEl={calculationPopperAnchorEl}
          calculation={metric.calculation}
          open={isCalculationPopperOpen}
          onClose={handleCloseCalculationPopper}
        />
      )}
    </>
  );
};

export default LegoMetricPreview;
