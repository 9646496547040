import { FC, useCallback, useContext, useMemo, useState } from 'react';

import { Shortcut } from '@dametis/core';

import { CommentContext } from 'components/UI/Comments/Comment/context/CommentContext';

import ProjectPluginButton from './ProjectPluginButton';
import ProjectPluginModal from './ProjectPluginModal';

const ProjectPlugin: FC = () => {
  const { currentComment, setCurrentComment } = useContext(CommentContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const disableButton = useMemo<boolean>(() => Boolean(currentComment?.shortcut), [currentComment?.shortcut]);

  const handleOpen = useCallback(() => setOpenModal(true), []);

  const handleClose = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleCreate = useCallback(
    (shortcut: Shortcut) => {
      setCurrentComment({ ...currentComment, shortcut });
    },
    [currentComment, setCurrentComment],
  );

  const handleUpdate = useCallback(
    (shortcut: Shortcut) => {
      setCurrentComment({ ...currentComment, shortcut });
    },
    [currentComment, setCurrentComment],
  );

  return (
    <>
      <ProjectPluginButton disabled={disableButton} onClick={handleOpen} />
      <ProjectPluginModal open={openModal} onClose={handleClose} onCreate={handleCreate} onUpdate={handleUpdate} />
    </>
  );
};

export default ProjectPlugin;
