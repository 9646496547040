import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { addHours, startOfHour } from 'date-fns';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_FILL_OPERATOR, Fill, FillOperators, Timestamp, VarCalc } from '@dametis/core';

import { useLocalStorage } from 'hooks/useLocalStorage';
import { localizedFormat } from 'localization/localizedDateFns';
import { useSelector } from 'store';

import DataFilterInput, { DataFilterInputProps } from '../../../../UI/DataFilterInput/DataFilterInput';

interface Props {
  variable: Partial<VarCalc>;
  onVariableChange: (variable: Partial<VarCalc>) => void;
  global: boolean;
}

const Advanced: FC<Props> = ({ variable, onVariableChange, global }) => {
  const { t } = useTranslation('vnc');

  const userId = useSelector(state => state.auth.user.uuid);

  const [open, setOpen] = useLocalStorage('vncFlagsOpen', false, { userId });

  const toggleOpen = useCallback<AccordionProps['onChange']>(
    (event, expanded) => {
      setOpen(expanded);
    },
    [setOpen],
  );

  const changeFill = useCallback<TextFieldProps['onChange']>(
    event => {
      onVariableChange({ fill: event.target.value as Fill });
    },
    [onVariableChange],
  );

  const changeTimestamp = useCallback<TextFieldProps['onChange']>(
    event => {
      onVariableChange({ timestamp: event.target.value === 'auto' ? undefined : (event.target.value as Timestamp) });
    },
    [onVariableChange],
  );

  const changeAccumulate = useCallback<CheckboxProps['onChange']>(
    (event, checked) => {
      onVariableChange({ flags: { ...(variable.flags ?? {}), accumulate: checked } });
    },
    [onVariableChange, variable.flags],
  );

  const changeFilter = useCallback<DataFilterInputProps['onFilterChange']>(
    filter => {
      onVariableChange({ filter });
    },
    [onVariableChange],
  );

  const now = useMemo(() => new Date(), []);
  const start = useMemo(() => startOfHour(now), [now]);
  const end = useMemo(() => startOfHour(addHours(now, 1)), [now]);
  const middle = useMemo(() => new Date((start.getTime() + end.getTime()) / 2), [start, end]);

  return (
    <div>
      <Accordion expanded={open} onChange={toggleOpen}>
        <AccordionSummary>
          <Typography variant="h6">{t('accordion.advanced')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack spacing={2}>
            {!global && <DataFilterInput filter={variable.filter} onFilterChange={changeFilter} />}
            <TextField fullWidth select label={t('input.label.fill')} value={variable.fill ?? DEFAULT_FILL_OPERATOR} onChange={changeFill}>
              <MenuItem value={FillOperators.PREVIOUS}>{t('input.value.fillPrevious')}</MenuItem>
              <MenuItem value={0}>{t('input.value.fillZero')}</MenuItem>
              <MenuItem value={FillOperators.LINEAR}>{t('input.value.fillLinear')}</MenuItem>
              <MenuItem value={FillOperators.NULL}>
                <ListItemText primary={t('input.value.fillNull')} secondary={t('input.value.fillNull2')} />
              </MenuItem>
              {/* <MenuItem value={FillOperators.NONE}>{t('input.value.fillNone')}</MenuItem> */}
            </TextField>
            <TextField fullWidth select label={t('input.label.timestamp')} value={variable.timestamp ?? 'auto'} onChange={changeTimestamp}>
              <MenuItem value="auto">
                <ListItemText primary={t('input.value.timestampUndefined')} secondary={t('input.value.timestampUndefined2')} />
              </MenuItem>
              <MenuItem value={Timestamp.START}>
                <ListItemText
                  primary={t('input.value.timestampStart')}
                  secondary={`${t('input.value.timestampEx')} ${localizedFormat(start, 'pp')}`}
                />
              </MenuItem>
              <MenuItem value={Timestamp.MIDDLE}>
                <ListItemText
                  primary={t('input.value.timestampMiddle')}
                  secondary={`${t('input.value.timestampEx')} ${localizedFormat(middle, 'pp')}`}
                />
              </MenuItem>
              <MenuItem value={Timestamp.END}>
                <ListItemText
                  primary={t('input.value.timestampEnd')}
                  secondary={`${t('input.value.timestampEx')} ${localizedFormat(end, 'pp')}`}
                />
              </MenuItem>
              <MenuItem value={Timestamp.KEEP}>
                <ListItemText
                  primary={t('input.value.timestampKeep')}
                  secondary={`${t('input.value.timestampEx')} ${localizedFormat(now, 'pp')}`}
                />
              </MenuItem>
            </TextField>
            {global && (
              <FormControl component="fieldset">
                <FormLabel component="legend">{t('input.label.flags')}</FormLabel>
                <FormGroup sx={{ pt: 0 }}>
                  <FormControlLabel
                    control={<Checkbox checked={variable.flags?.accumulate ?? false} onChange={changeAccumulate} />}
                    label={t('input.label.accumulate')}
                  />
                </FormGroup>
              </FormControl>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Advanced;
