import type { DashStyleValue, SeriesArearangeOptions, SeriesOptionsType } from 'highcharts';
import ArearangeSeries from 'highcharts/es-modules/Series/AreaRange/AreaRangeSeries';
import { v4 as uuidv4 } from 'uuid';

import { GroupBy1, Period, TadaApiResponse, UUID } from '@dametis/core';

import { DaAxis } from './DaAxis';
import { BOOST_POINTS_THRESHOLD, DaChart } from './DaChart';
import 'highcharts/es-modules/masters/highcharts-more.src';

interface Props {
  color?: string;
  name?: string;
  unit?: string;
  selectedUnit?: string;
  style?: {
    width?: number;
    dashStyle?: DashStyleValue;
  };
  area?: boolean;
  pretty?: boolean;
  yAxis?: DaAxis;
  uuid?: UUID;
  hidden?: boolean;
  yAxisHidden?: boolean;
  disableBoost?: boolean;
}

export default class DaArearangeSeries extends ArearangeSeries {
  period: Period | undefined;

  groupBy: GroupBy1 | undefined;

  chart: DaChart;

  yAxis: DaAxis;

  uuid: UUID;

  public constructor(
    chart: DaChart,
    {
      color,
      name,
      unit,
      selectedUnit,
      pretty = false,
      yAxis,
      uuid,
      hidden = false,
      area = false,
      style = {},
      disableBoost,
      ...props
    }: Props = {},
  ) {
    const options: SeriesArearangeOptions = {
      ...props,
      connectNulls: true,
      name,
      id: uuidv4(),
      visible: !hidden,
      lineWidth: pretty ? 4 : style.width || 1,
      color,
      type: 'arearange',
      fillOpacity: area ? 0.5 : 0,
      tooltip: {
        valueSuffix: unit,
        valuePrefix: selectedUnit,
      },
      boostThreshold: disableBoost ? 0 : BOOST_POINTS_THRESHOLD,
      dashStyle: style.dashStyle ?? 'Solid',
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
          },
        },
        radius: style.width + 1 || 2,
        symbol: 'circle',
      },
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          lineWidth: pretty ? 4 : style.width || 1,
          lineWidthPlus: 0,
        },
      },
      zIndex: 1,
    };
    const yAxis2 = yAxis;
    options.yAxis = yAxis2.options.id;
    super();
    super.init(chart, options);
    this.uuid = uuid;
  }

  setColor(color: string, changeAxis = true): void {
    this.update({ color } as SeriesOptionsType);
    if (changeAxis) {
      this.yAxis.setColor(color);
    }
  }

  update(options: SeriesOptionsType): void {
    const { uuid } = this;
    super.update(options, false);
    this.uuid = uuid;
  }

  setUnit(unit = null, apiUnit = this.options.tooltip.valueSuffix, updateYaxis: boolean = true): void {
    if (updateYaxis) {
      this.yAxis.setUnit(unit, apiUnit);
    }
    this.update({
      tooltip: {
        valuePrefix: unit ?? apiUnit,
        valueSuffix: apiUnit ?? '',
      },
    } as SeriesOptionsType);
  }

  setVisibility(visibility: boolean): void {
    this.setVisible(visibility, false);
  }

  setName(name): void {
    this.update({ name } as SeriesOptionsType);
  }

  addData(data: number[][], period?: Period, groupBy?: GroupBy1): void {
    super.setData(data, false, false, false);
    this.chart.redraw();
    this.period = period;
    this.groupBy = groupBy;
    if (data.length) super.setData(data, false, false, false);
    // this.stats = findMinMax(data);
  }

  removeData(): void {
    super.setData([]);
  }

  remove(): void {
    if (this.graph !== undefined) this.graph.destroy();
    super.remove(false, false);
  }

  static convertDataFromApiToHighcharts(data: TadaApiResponse['results']): number[][] {
    return data.map(({ value, time }) => [Date.parse(time), value]);
  }
}
