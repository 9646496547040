import { AddPhotoAlternateOutlined, ArrowForwardIosOutlined, HideImageOutlined } from '@mui/icons-material';
import {
  alpha,
  Box,
  BoxProps,
  Button,
  ButtonBase,
  CircularProgress,
  IconButton,
  Input,
  inputClasses,
  Paper,
  Stack,
  styled,
  svgIconClasses,
  Tooltip,
  Typography,
} from '@mui/material';
import { ChangeEventHandler, FC, MutableRefObject, PropsWithChildren, forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { SiteInfo } from '@dametis/core';

const SitePaper = styled(
  (props: PropsWithChildren<{ onClick: () => void }>) => <Paper component={props.onClick ? ButtonBase : undefined} {...props} />,
  {
    shouldForwardProp: propName => propName !== 'selected',
  },
)<{ selected: boolean }>(({ theme, selected, onClick }) => ({
  width: '100%',
  display: 'block',
  boxShadow: selected ? `0 0 0 3px ${theme.palette.secondary.light}` : undefined,
  ...(onClick
    ? {
        [`& .${svgIconClasses.root}`]: {
          opacity: 0,
          pointerEvents: 'none',
          transition: theme.transitions.create(['opacity']),
        },
        [`&:hover .${svgIconClasses.root}`]: {
          pointerEvents: 'default',
          opacity: 1,
        },
      }
    : {}),
}));

const SitePreviewContainer = styled(Stack)(({ theme }) => ({
  aspectRatio: '16 / 7',
  position: 'relative',
  marginBottom: `-${theme.shape.borderRadius}px`,
  paddingBottom: `${theme.shape.borderRadius}px`,
  backgroundColor: theme.palette.grey[200],
  borderRadius: '8px 8px 0 0',
  overflow: 'hidden',
  [`& > div:not(.${inputClasses.root})`]: {
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
  },
  [`&:hover > div:not(.${inputClasses.root})`]: {
    opacity: 1,
  },
}));

const SiteButton = forwardRef<HTMLButtonElement, PropsWithChildren<{ onClick: () => void; tabIndex: number }>>((props, ref) =>
  props.onClick ? <ButtonBase {...props} ref={ref} /> : <Box {...props} ref={ref} />,
);
SiteButton.displayName = 'SiteButton';

const StyledSiteButton = styled(SiteButton)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'left',
  width: '100%',
  borderRadius: theme.spacing(1),
  justifyContent: 'flex-start',
  [`& .${svgIconClasses.root}`]: {
    opacity: 0,
    pointerEvents: 'none',
    transition: theme.transitions.create(['opacity']),
    '&:hover': {
      pointerEvents: 'default',
      opacity: 1,
    },
  },
  '&:focus': {
    backgroundColor: theme.palette.grey[200],
    border: 'transparent',
  },
  '&:focus-visible': {
    outline: 'none',
  },
}));

const SitePreview = styled('img', { shouldForwardProp: propName => propName !== 'blurred' })<{ blurred: boolean }>(({ blurred }) => ({
  objectFit: 'cover',
  width: '100%',
  height: '100%',
  filter: blurred ? 'blur(20px)' : 'unset',
  position: 'absolute',
  left: 0,
  top: 0,
}));

const SitePreviewActions = styled(Stack)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  padding: theme.spacing(1),
  width: '100%',
  height: '100%',
  background: `linear-gradient(${alpha(theme.palette.background.paper, 0.75)}, ${alpha(theme.palette.background.paper, 0)})`,
}));

interface Props {
  site: SiteInfo;
  onSelect: () => void;
  selected?: boolean;
  canEdit: boolean;
  onChangePreview?: ChangeEventHandler<HTMLInputElement>;
  onDeletePreview?: () => Promise<void>;
  loadingPreview?: string;
  tabIndex?: number;
  refIndex?: MutableRefObject<HTMLButtonElement>;
  onKeyDown?: BoxProps['onKeyDown'];
}

const SiteTile: FC<Props> = ({
  site,
  canEdit,
  onSelect,
  onChangePreview = undefined,
  onDeletePreview = undefined,
  loadingPreview = undefined,
  selected = false,
  tabIndex = null,
  refIndex = null,
  onKeyDown = undefined,
}) => {
  const { t } = useTranslation('corporate');

  const handleEnterKey = useCallback<BoxProps['onKeyDown']>(
    event => {
      onKeyDown?.(event);
      if (event.key === 'Enter') {
        onSelect();
      }
    },
    [onKeyDown, onSelect],
  );

  return (
    <Box onKeyDown={handleEnterKey}>
      <SitePaper selected={selected} onClick={!canEdit ? onSelect : undefined}>
        {(site.previewPath || onChangePreview) && (
          <SitePreviewContainer alignItems="center" justifyContent="center">
            {loadingPreview ? (
              <>
                <SitePreview blurred src={loadingPreview} />
                <CircularProgress color="primary" variant="indeterminate" />
              </>
            ) : (
              <>
                {canEdit && (
                  <Input
                    id={`site-${site.uuid}-picture-input`}
                    inputProps={{ accept: 'image/png,image/jpeg' }}
                    sx={{ display: 'none' }}
                    type="file"
                    onChange={onChangePreview}
                  />
                )}
                {canEdit && !site.previewPath && (
                  <Button component="label" htmlFor={`site-${site.uuid}-picture-input`} sx={{ width: 1, height: 1 }}>
                    <AddPhotoAlternateOutlined />
                  </Button>
                )}
                {site.previewPath && (
                  <>
                    <SitePreview blurred={false} src={site.previewPath} />
                    {canEdit && (
                      <SitePreviewActions alignItems="flex-start" direction="row-reverse">
                        <Tooltip title={t('tooltip.deletePhoto')}>
                          <IconButton size="small" onClick={onDeletePreview}>
                            <HideImageOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t('tooltip.editPhoto')}>
                          <IconButton component="label" htmlFor={`site-${site.uuid}-picture-input`} size="small">
                            <AddPhotoAlternateOutlined fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </SitePreviewActions>
                    )}
                  </>
                )}
              </>
            )}
          </SitePreviewContainer>
        )}
        <Paper
          elevation={0}
          sx={{
            position: 'relative',
            backgroundColor: ({ palette }) => alpha(palette.background.paper, 1),
          }}
        >
          <StyledSiteButton ref={refIndex} tabIndex={tabIndex} onClick={canEdit ? onSelect : undefined}>
            <Stack alignItems="center" direction="row" justifyContent="space-between" spacing={1} sx={{ width: 1 }}>
              <Stack sx={{ width: `calc(100% - 24px)` }}>
                <Typography noWrap variant="h4">
                  {site.name}
                </Typography>
                <Typography noWrap variant="subtitle2">
                  {site.location.city}
                  {site.location.city.length && site.location.region.length ? ', ' : ''}
                  {site.location.region}
                </Typography>
                <Stack alignItems="center" direction="row" spacing={1}>
                  {site.location.countryCode && site.location.countryCode.length > 0 && (
                    <Box
                      alt={site.location.country}
                      component="img"
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${site.location.countryCode}.svg`}
                      sx={{ height: 14, border: theme => `1px solid ${theme.palette.divider}`, borderRadius: '4px' }}
                    />
                  )}
                  <Typography variant="subtitle2">{site.location.country}</Typography>
                </Stack>
              </Stack>
              <ArrowForwardIosOutlined sx={{ color: 'link.main' }} />
            </Stack>
          </StyledSiteButton>
        </Paper>
      </SitePaper>
    </Box>
  );
};

export default SiteTile;
