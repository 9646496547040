export interface Options {
  siteId?: string;
  groupId?: string;
  userId?: string;
}

export const append = (str1: string, str2: string): string => (str2 === undefined ? str1 : `${str1}_${str2}`);

export const setLocalStorageItem = <T>(key: string, value: T, { siteId, groupId, userId }: Options = {}) => {
  if (typeof window === 'undefined') {
    console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
  }
  const newKey = append(append(append(key, userId), groupId), siteId);
  try {
    window.localStorage.setItem(newKey, JSON.stringify(value));
    window.dispatchEvent(new Event('local-storage'));
  } catch (error) {
    console.warn(`Error setting localStorage key “${newKey}”:`, error);
  }
};

const parseJSON = <T>(value: string | null): T | undefined => {
  try {
    return value === 'undefined' ? undefined : JSON.parse(value ?? '');
  } catch {
    console.warn('parsing error on', { value });
    return undefined;
  }
};

export const getLocalStorageItem = <T>(key: string, { userId, groupId, siteId }: Options = {}): T => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const newKey = append(append(append(key, userId), groupId), siteId);
  try {
    const item = window.localStorage.getItem(newKey);
    const oldItem = window.localStorage.getItem(key);
    if (item === null) {
      if (oldItem === null || newKey === key) return undefined;
      setLocalStorageItem(newKey, oldItem, { userId, groupId, siteId });
      window.localStorage.removeItem(key);
      return parseJSON<T>(oldItem);
    }
    return parseJSON<T>(item);
  } catch (error) {
    console.warn(`Error reading localStorage key “${newKey}”:`, error);
    return undefined;
  }
};

export const removeLocalStorageItem = (key: string, { userId, groupId, siteId }: Options = {}) => {
  if (typeof window === 'undefined') {
    return;
  }
  const newKey = append(append(append(key, userId), groupId), siteId);
  try {
    window.localStorage.removeItem(newKey);
  } catch (error) {
    console.warn(`Error reading localStorage key “${newKey}”:`, error);
  }
};
