import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { IsDataVariable, UUID } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import { getStoreTab } from 'components/Playground/functions/shared';
import { calculationToString } from 'functions/calculationToString';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { getCalculationUnit } from 'functions/getCalculationUnit';
import { getGroupBy } from 'functions/tada/getGroupBy';
import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { histogramUpdateVariable } from 'store/slices/playground';
import { IHistogramVariable, IHistogramVariableProps, IsHistogramTab } from 'types';

import { chartColors } from '../../../../functions/color';
import { getFormattedValue } from '../../../UI/UnitPicker/functions/getFormattedValue';
import { createVariable } from '../Variable';

// ACTIONS

export const updateHistogramVariable =
  (tabUuid: UUID, variableUuid: UUID, options: IHistogramVariableProps): TypedThunk<Promise<void>> =>
  async dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsHistogramTab(tab)) return;
    dispatch(histogramUpdateVariable({ tabUuid, options }));
    const variable = dispatch(getStoreHistogramVariable(tabUuid));
    if (!variable) return;
    if (options.unit !== undefined) {
      const baseUnit = getCalculationUnit(tab.chart.variable.expression);
      variable.daSeries.chart.setUnit(options.unit, baseUnit);
    }
    if (options.min !== undefined || options.max !== undefined || options.step !== undefined) {
      await dispatch(getHistogramVariableData(tabUuid));
    }
    if (options.expression) {
      await dispatch(
        updateHistogramVariable(tabUuid, variableUuid, {
          name: options.expression.nickname?.length ? options.expression.nickname : calculationToString(options.expression),
        }),
      );
      await dispatch(getHistogramVariableData(tabUuid));
    }
    if (options.name) {
      variable.daSeries.setName(options.name);
    }
    variable.daSeries?.chart.redraw();
  };

// OTHERS

export const createHistogramVariable = ({
  uuid = uuidv4(),
  name = '',
  expression = createCalculationVariable(),
  daSeries = null,
  unit = null,
  color = chartColors[0],
  min = null,
  max = null,
  step = null,
}: IHistogramVariableProps = {}): IHistogramVariable => ({
  ...createVariable({ uuid, name: name || expression.nickname || calculationToString(expression) }),
  expression,
  daSeries,
  unit,
  color,
  min,
  max,
  step,
});

export const exportHistogramVariable = (variable: IHistogramVariable): IHistogramVariable => {
  if (!variable) return null;
  return { ...variable, daSeries: null };
};

export const getHistogramVariableData =
  (tabUuid: UUID): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!IsHistogramTab(tab)) return;
    const variable = dispatch(getStoreHistogramVariable(tabUuid));
    if (!variable) return;
    const { expression, min, max, step, daSeries } = variable;
    const {
      auth: {
        selectedGroup: { uuid: groupId, sites },
        selectedSite,
      },
      variables: { byId },
      boxes: { maxLoopTime },
      period: {
        present: { period },
      },
    } = getState();
    const timeZone = selectedSite ? selectedSite.timeZone : sites?.at(0).timeZone ?? undefined;
    const exp = cloneDeep(expression);
    try {
      const { data } = await sdk.data.Histogram(groupId, {
        ...exp,
        period: { from: period.from.toISOString(), to: period.to.toISOString() },
        timeZone,
        min,
        max,
        step,
        autoGroupByReplacement: getGroupBy(period.from, period.to, null, maxLoopTime, 5000),
      });
      const firstVar = getSingleVariableCalculation(expression);
      if (variable.unit == null && IsDataVariable(firstVar)) {
        try {
          const { unit } = byId[firstVar.variableUuid];
          await dispatch(updateHistogramVariable(tabUuid, variable.uuid, { unit }));
        } catch (err) {
          console.error(err);
        }
      }
      const categories = data.buckets.map(b => [
        `${getFormattedValue({ value: b.minValue })} - ${getFormattedValue({ value: b.maxValue })}`,
        b.percent,
      ]);
      daSeries.addData(categories);
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const getStoreHistogramVariable =
  (tabUuid: UUID): TypedThunk<IHistogramVariable> =>
  (dispatch, getState) => {
    const { tabs } = getState().playground;
    const tab = tabs.find(t => t.uuid === tabUuid);
    if (!IsHistogramTab(tab)) return null;
    return tab.chart.variable ?? null;
  };
