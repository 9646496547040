import {
  BarChartOutlined,
  Grain,
  InsertChartOutlinedOutlined,
  ShowChart,
  SvgIconComponent,
  TableChartOutlined,
  TrendingUp,
} from '@mui/icons-material';
import { TFunction } from 'i18next';

import { PermissionKey, TabType } from '@dametis/core';

interface ITabConfig {
  name: string;
  type: TabType;
  icon?: SvgIconComponent;
  enabled: boolean;
  hidden?: boolean;
  permission?: PermissionKey;
}

export const getTabsConfig = (t: TFunction): Partial<Record<TabType, ITabConfig>> => ({
  [TabType.NONE]: {
    name: t('playground:text.tabTypes', { context: TabType.NONE }),
    type: TabType.NONE,
    icon: null,
    enabled: false,
  },
  [TabType.TY_CHART]: {
    name: t('playground:text.tabTypes', { context: TabType.TY_CHART }),
    type: TabType.TY_CHART,
    icon: ShowChart,
    enabled: true,
  },
  [TabType.LIVE_CHART]: {
    name: t('playground:text.tabTypes', { context: TabType.LIVE_CHART }),
    type: TabType.LIVE_CHART,
    icon: TrendingUp,
    enabled: true,
  },
  [TabType.XY_CHART]: {
    name: t('playground:text.tabTypes', { context: TabType.XY_CHART }),
    type: TabType.XY_CHART,
    icon: Grain,
    enabled: true,
  },
  [TabType.BAR_CHART]: {
    name: t('playground:text.tabTypes', { context: TabType.BAR_CHART }),
    type: TabType.BAR_CHART,
    icon: BarChartOutlined,
    enabled: true,
  },
  [TabType.HISTOGRAM]: {
    name: t('playground:text.tabTypes', { context: TabType.HISTOGRAM }),
    type: TabType.HISTOGRAM,
    icon: InsertChartOutlinedOutlined,
    enabled: true,
  },
  [TabType.TABLE]: {
    name: t('playground:text.tabTypes', { context: TabType.TABLE }),
    type: TabType.TABLE,
    icon: TableChartOutlined,
    enabled: true,
  },
  // [TabType.EXCEL]: {
  //   name: 'Excel',
  //   type: TabType.EXCEL,
  //   icon: TableChartOutlined,
  //   enabled: true,
  //   hidden: (() => !store.getState().auth.WIPeMaaS)(),
  // },
});

export const getTabsConfigArr = (t: TFunction): ITabConfig[] => Object.values(getTabsConfig(t)).filter(({ type }) => type !== TabType.NONE);
