import { ModbusTcpDataType, QueryStrategy, TransformerType, VariableKind } from '@dametis/core';

import { RecomputeFromMethod } from 'components/Configuration/Variables/CreateVariableModal/VariableSettingsForm/CalculatedVariableSettingsForm/RecomputeFromPicker';
import { EntityKey } from 'components/Configuration/Variables/VariablesRightPanel/DependenciesPanel/DependenciesPanel';
import { ModbusTcpFunction } from 'types';
import { BulkEditTagsFunction, FakeVariableKind, PanelTab, VariablesGridColumn, VariablesGridColumnScope } from 'types/variables';

const columns: Record<VariablesGridColumn, string> = {
  [VariablesGridColumn.CHECKBOX]: '',
  [VariablesGridColumn.KIND]: 'Type',
  [VariablesGridColumn.NAME]: 'Name',
  [VariablesGridColumn.SENSOR_NAME]: 'Sensor name',
  [VariablesGridColumn.REFERENCE]: 'Reference',
  [VariablesGridColumn.DESCRIPTION]: 'Description',
  [VariablesGridColumn.PHYSICAL_QUANTITY]: 'Physical quantity',
  [VariablesGridColumn.LAST_POINT_VALUE]: 'Last value',
  [VariablesGridColumn.UNIT]: 'Unit',
  [VariablesGridColumn.LAST_POINT_DATE]: 'Last value date',
  [VariablesGridColumn.LAST_PROGRESS_VALUE]: 'Progress',
  [VariablesGridColumn.PROTOCOL]: 'Protocol',
  [VariablesGridColumn.TAGS]: 'Tags',
  [VariablesGridColumn.DEVICE]: 'Device',
  [VariablesGridColumn.OFFSET]: 'Offset',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER_TYPE]: 'Transformer',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER]: 'Transformer value',
  [VariablesGridColumn.MODBUS_TCP_UID]: 'Unit ID',
  [VariablesGridColumn.MODBUS_TCP_FUNCTION]: 'Function',
  [VariablesGridColumn.MODBUS_TCP_ADDRESS]: 'Address',
  [VariablesGridColumn.MODBUS_TCP_INVERTWORDS]: 'Invert words',
  [VariablesGridColumn.MODBUS_TCP_INVERTBYTES]: 'Invert bytes',
  [VariablesGridColumn.MODBUS_TCP_DATATYPE]: 'Type',
  [VariablesGridColumn.MODBUS_TCP_BIT]: 'Bit',
  [VariablesGridColumn.OPC_UA_NODE_ID]: 'Node ID',
  [VariablesGridColumn.S7_ADDRESS]: 'Address',
  [VariablesGridColumn.SQL_MODE]: 'Mode',
  [VariablesGridColumn.SQL_REQUEST]: 'Request',
  [VariablesGridColumn.SQL_VARIABLES]: 'Variables',
  [VariablesGridColumn.BACNET_ID]: 'ID',
  [VariablesGridColumn.MONITORING_EXPECTED_FREQUENCY]: 'Expected frequency',
  [VariablesGridColumn.MONITORING_ABNORMALITY_DELAY]: 'Delay on abnormal value',
  [VariablesGridColumn.MONITORING_MIN]: 'Minimum value',
  [VariablesGridColumn.MONITORING_MAX]: 'Maximum value',
};

const columnExamples: Partial<Record<VariablesGridColumn, string>> = {
  [VariablesGridColumn.NAME]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SENSOR_NAME]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.REFERENCE]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.DESCRIPTION]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.UNIT]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.TAGS]: 'Tag 1, Tag 2, ...',
  [VariablesGridColumn.OFFSET]: '$t(variables:fieldTypes.number)',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER_TYPE]: Object.values(TransformerType).join(' | '),
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER]:
    '$t(variables:fieldTypes.number) | in1: $t(variables:fieldTypes.number), in2: $t(variables:fieldTypes.number), out1: $t(variables:fieldTypes.number), out2: $t(variables:fieldTypes.number)',
  [VariablesGridColumn.MODBUS_TCP_UID]: '$t(variables:fieldTypes.number) (min: 0 / max: 254)',
  [VariablesGridColumn.MODBUS_TCP_FUNCTION]: '1 | 2 | 3 | 4',
  [VariablesGridColumn.MODBUS_TCP_ADDRESS]: '$t(variables:fieldTypes.number) (min: 0 / max: 65535)',
  [VariablesGridColumn.MODBUS_TCP_INVERTWORDS]: '$t(variables:fieldTypes.boolean)',
  [VariablesGridColumn.MODBUS_TCP_INVERTBYTES]: '$t(variables:fieldTypes.boolean)',
  [VariablesGridColumn.MODBUS_TCP_DATATYPE]: Object.values(ModbusTcpDataType).join(' | '),
  [VariablesGridColumn.MODBUS_TCP_BIT]: '$t(variables:fieldTypes.number) (min: 1 / max: 15)',
  [VariablesGridColumn.OPC_UA_NODE_ID]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.S7_ADDRESS]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SQL_MODE]: Object.values(QueryStrategy).join(' | '),
  [VariablesGridColumn.SQL_REQUEST]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SQL_VARIABLES]:
    '$t(variables:fieldTypes.string): $t(variables:fieldTypes.string), $t(variables:fieldTypes.string): $t(variables:fieldTypes.string), ...',
  [VariablesGridColumn.BACNET_ID]: '$t(variables:fieldTypes.number)',
};

const columnScopes: Record<VariablesGridColumnScope, string> = {
  [VariablesGridColumnScope.COMMON]: 'General',
  [VariablesGridColumnScope.ACQUISITION]: 'Acquisition',
  [VariablesGridColumnScope.DATA_TRANSFORMER]: 'Data',
  [VariablesGridColumnScope.MCO]: 'Monitoring',
  [VariablesGridColumnScope.MODBUS_TCP]: 'Modbus TCP',
  [VariablesGridColumnScope.OPC_UA]: 'OPC UA',
  [VariablesGridColumnScope.S7]: 'Siemens S7',
  [VariablesGridColumnScope.SQL]: 'SQL',
  [VariablesGridColumnScope.BACNET]: 'BACnet',
  [VariablesGridColumnScope.MONITORING]: 'Monitoring',
};

const variableUsesEntity: Record<EntityKey, string> = {
  alarms: 'Alarms',
  batches: 'Batches',
  blockTypes: 'Block types',
  calculatedVariables: 'Variables',
  models: 'Models',
  playgrounds: 'Playgrounds',
  reports: 'Reports',
  synoptics: 'Synoptics',
  variableLists: 'Variables lists',
  blocks: 'Blocks',
  aliases: 'Aliases',
};

const queryStrategies: Record<QueryStrategy, string> = {
  [QueryStrategy.DEFAULT]: 'Default',
  [QueryStrategy.TEMPLATE]: 'Template',
  [QueryStrategy.BULK]: 'Bulk',
};

export default {
  title: {
    variables: 'Variables',
    createVariableModal: 'Create a new variable',
    duplicateVariableModal: 'Duplicate "{{name}}"',
    // rightPanel: '{{count}} selected variables',
    rightPanel_one: 'Variable details',
    rightPanel_other: 'Variables details',
    rightPanelSubtitle: '{{count}} selected variables',
    acquisitionSettings: 'Acquisition',
    dataTransformerSettings: 'Data transformer',
    mcoSettings: 'Monitoring',
    importRealVariablesModal: 'Import acquired variables',
    importCalculatedVariablesModal: 'Import calculated variables',
    promptBeforeClosing: 'Are you sure?',
  },
  button: {
    variable: 'Variable',
    create: 'Create',
    close: 'close',
    next: 'Next',
    previous: 'Previous',
    columns: 'Columns',
    edit: 'Edit',
    duplicate: 'Duplicate',
    delete: 'Delete',
    cancel: 'Cancel',
    save: 'Save',
    addComment: 'Add comment',
    export: 'Export',
    exportAsCsv: 'Export as CSV',
    exportAsExcel: 'Excport as Excel file',
    refresh: 'Refresh',
    new: 'New',
    import: 'Import',
    downloadTemplateCsv: 'example.csv',
    recompute: 'Recompute',
    yesClose: 'Close without saving',
    noClose: 'Cancel',
    debug: 'Debug',
  },
  label: {
    uuid: 'UUID',
    name: 'Name',
    enabled: 'Enabled',
    favorite: 'Favorite',
    unit: 'Unit',
    sensorName: 'Sensor name',
    reference: 'Reference',
    description: 'Description',
    kind: 'Variable type',
    kindShort: 'Type',
    tags: 'Tags',
    device: 'Device',
    address: 'Address',
    uid: 'Unit ID',
    id: 'ID',
    function: 'Fonction',
    dataType: 'Type',
    bit: 'Bit',
    transformer: 'Value',
    transformerType: 'Transformer',
    invertWords: 'Invert words',
    invertBytes: 'Invert bytes',
    in1: 'In1',
    out1: 'Out1',
    in2: 'In2',
    out2: 'Out2',
    nodeId: 'Node ID',
    params: 'Expression',
    accumulate: 'Accumulate',
    delay: 'Delay',
    value: 'Value',
    physicalQuantity: 'Physical quantity',
    protocol: 'Protocol',
    request: 'Request',
    variables: 'Variables',
    lastPointValue: 'Last value',
    lastPointDate: 'Last value date',
    offset: 'Offset',
    prefix: 'Prefix',
    suffix: 'Suffix',
    tagsFunction: {
      [BulkEditTagsFunction.REPLACE_TAGS]: 'Replace',
      [BulkEditTagsFunction.MERGE_TAGS]: 'Add to existing',
    },
    dependencies: 'Dependencies',
    withoutTag: 'Without tag',
    field: 'Column nº{{index}}',
    settings: 'Settings',
    columns: 'Columns',
    preview: 'Preview',
    notSelected: 'Not selected',
    gridUseHeader: 'This file contains header',
    valuesStart: 'Values start',
    valuesEnd: 'Values end',
    mode: 'Mode',
    recomputeFromMethod: 'Edit data',
    progress: 'Progress',
  },
  kind: {
    [VariableKind.CALCULATED]: 'Calculated',
    [VariableKind.CONSTANT]: 'Constant',
    [VariableKind.MANUAL]: 'Manual',
    [VariableKind.NONE]: 'Unknown',
    [VariableKind.REAL]: 'Acquired',
    [`${VariableKind.CALCULATED}_one`]: 'Calculated',
    [`${VariableKind.CALCULATED}_other`]: 'Calculated',
    [`${VariableKind.CONSTANT}_one`]: 'Constants',
    [`${VariableKind.CONSTANT}_other`]: 'Constants',
    [`${VariableKind.MANUAL}_one`]: 'Manual',
    [`${VariableKind.MANUAL}_other`]: 'Manual',
    [`${VariableKind.NONE}_one`]: 'Unknown',
    [`${VariableKind.NONE}_other`]: 'Unknown',
    [`${VariableKind.REAL}_one`]: 'Acquired',
    [`${VariableKind.REAL}_other`]: 'Acquired',
    [FakeVariableKind.ALARM]: 'Alarm',
    [FakeVariableKind.BATCH]: 'Batch',
    [FakeVariableKind.VAPOR_MIX]: 'Vapor mix',
  },
  alert: {
    kind: {
      [VariableKind.CALCULATED]: 'A calculated variable is a variable based on other variables, and whose expression determines its value',
      [VariableKind.CONSTANT]: 'A constant variable is a variable whose value does not change',
      [VariableKind.MANUAL]: 'A manual variable is a variable whose values will be defined following a manual import',
      [VariableKind.NONE]: '',
      [VariableKind.REAL]: 'An acquired variable is a variable whose data is received from a device',
    },
  },
  stepper: {
    common: 'General',
    settings: 'Settings',
    importing: 'Importing',
    creating: 'Creating',
    file: 'File',
    data: 'Data',
  },
  modbusTcp: {
    noFunctionSelected: 'No function selected',
    [`function_${ModbusTcpFunction.READCOILS_1}`]: '1 - Read Coils',
    [`function_${ModbusTcpFunction.READDISCRETEINPUTS_2}`]: '2 - Read Discrete Inputs',
    [`function_${ModbusTcpFunction.READHOLDINGREGISTERS_3}`]: '3 - Read Holding Registers',
    [`function_${ModbusTcpFunction.READINPUTREGISTERS_4}`]: '4 - Read Input Registers',
  },
  transformerType: {
    [TransformerType.MULTIPLIER]: 'Multiplier',
    [TransformerType.LINEAR_INTERPOLATION]: 'Linear interpolation',
  },
  text: {
    allDevices: 'All devices',
    searchingVariables: 'Searching variables...',
    displayAll: 'Display all',
    noSelection: 'Nothing selected',
    exportFileName: 'dametis_{{group}}_{{site}}_{{date}}',
    noDependency: 'No dependency',
    fetchedAt: 'Last values fetched on {{date}}',
    realVariable: 'Real variable',
    true: 'Yes',
    false: 'No',
    createVariable: 'Create variable',
    importVariables: 'Import variables',
    importRealVariables: 'Acquired variables',
    importCalculatedVariables: 'Calculated variables',
    selectFile: 'Drop a file or click here to choose one',
    dropFile: 'Drop the file here',
    fileNotAllowed: 'This type of file is not allowed',
    successCreateCalculatedVariable: 'Calculated variables imported with success',
    errorCreateCalculatedVariable: 'An error occured during import',
    errors_one: '1 error',
    errors_other: '{{count}} errors',
    lineNumber: 'line nº',
    promptBeforeClosing: 'You are closing edit mode.',
    notExistingVariable_one: 'This variable does not exist.',
    notExistingVariable_other: 'These variables do not exist.',
    deleteVariableWarning_one:
      'Deleting this variable can affect the proper functionning of the tools (reports, synoptics, playgrounds...) using it. Please check this variable’s uses before deleting it.',
    deleteVariableWarning_other:
      'Deleting those variables can affect the proper functionning of the tools (Reports, Synoptics, Playgrounds, ...) using it. Please check those variables’ uses before deleting them.',
    selectAll: 'Select all',
  },
  fieldTypes: {
    string: 'String',
    number: 'Number',
    boolean: 'Boolean',
  },
  columns,
  columnScopes,
  columnExamples,
  tooltip: {
    importVariables: 'import variables',
    createVariable: 'create a variable',
    editVariable: 'edit variable',
    duplicateVariable: 'duplicate variable',
    deleteVariable: 'delete variable',
    copyToClipboard: 'copy to clipboard',
    recompute: 'recompute',
    cannotDuplicateRealVariable: 'unable to duplicate acquired variable',
  },
  toast: {
    copyToClipboard: "Variable's UUID added to clipboard",
    fileReadingAborted: 'File reading aborted',
    fileReadingFailed: 'File reading failed',
    csvInvalid: 'Invalid CSV file',
    successRecompute: 'Your request has been received, recalculation in progress...',
  },
  panelTab: {
    [PanelTab.INFORMATIONS]: 'Informations',
    [PanelTab.COMMENTS]: 'Comments',
    [PanelTab.ACTIVITIES]: 'Activities',
    [PanelTab.DEPENDENCIES]: 'Dependencies',
  },
  variableUsesEntity,
  queryStrategies,
  dataGrid: {
    noRowsLabel: 'No variable',
    footerTotalRows: 'Total variables :',
  },
  recomputeFromMethod: {
    [RecomputeFromMethod.START]: 'From the beginning',
    [RecomputeFromMethod.NOW]: 'From now',
    // [RecomputeFromMethod.DATE]: '',
  },
  recomputeFromLabel: {
    [RecomputeFromMethod.START]: 'All the data will be recalculated with the new expression.',
    [RecomputeFromMethod.NOW]:
      'Data already calculated until now will stay unchanged (old expression). The new expression will take effect for all new data.',
    // date: 'Data already calculated until the chosen date will stay unchanged (old expression). The new expression will take effect for all new data from this date.',
  },
  notDefined: 'Undefined',
  mco: {
    title: {
      settings: 'Monitoring',
    },
    label: {
      expectedFrequencyEnabled: 'Watch expected frequency',
      expectedFrequencyAuto: 'Device frequency ({{inheritedValue}})',
      expectedFrequencyCustom: 'This frequency',
      abnormalityDelayEnabled: 'Watch value out of threshold',
      minimumThreshold: 'Minimum threshold',
      maximumThreshold: 'Maximum threshold',
      abnormalityDelayCustom: 'Exceeding delay',
    },
    link: {
      notifications: 'Handle notifications',
    },
  },
};
