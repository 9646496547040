import { capitalize, Chip, ChipProps, styled, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PhysicalQuantity } from '@dametis/core';

import physicalQuantities, { getPhysicalQuantities } from 'config/physicalQuantities';
import { setColorLightness } from 'functions/color';

import PhysicalQuantityAvatar from './PhysicalQuantityAvatar';

interface Props extends ChipProps {
  physicalQuantity: PhysicalQuantity;
  unit?: string;
  disableAvatar?: boolean;
}

const PhysicalQuantityChip = styled(({ physicalQuantity, unit = '', disableAvatar = false, ...props }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const pQ = useMemo(() => {
    const pQs = getPhysicalQuantities(t, theme);
    return pQs[physicalQuantity] ?? pQs[PhysicalQuantity.OTHER];
  }, [physicalQuantity, t, theme]);

  return (
    <Chip
      icon={disableAvatar ? undefined : <PhysicalQuantityAvatar disableTooltip physicalQuantity={physicalQuantity} size="small" />}
      label={`${capitalize(pQ.name)}${unit ? ` (${unit})` : ''}`}
      size="small"
      {...props}
    />
  );
})(({ physicalQuantity }) => {
  const pQ = physicalQuantities[physicalQuantity] ?? physicalQuantities[PhysicalQuantity.OTHER];
  return {
    cursor: 'inherit',
    borderRadius: 4,
    backgroundColor: setColorLightness(pQ.color, 95),
    color: setColorLightness(pQ.color, 45),
  };
});

export default PhysicalQuantityChip;
