import {
  add,
  differenceInCalendarWeeks,
  eachWeekOfInterval,
  endOfWeek,
  format,
  formatDistance,
  formatDistanceStrict,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  formatDuration,
  formatRelative,
  getWeek,
  getWeekOfMonth,
  getWeeksInMonth,
  getWeekYear,
  isMatch,
  isSameWeek,
  isThisWeek,
  lastDayOfWeek,
  parse,
  setDay,
  setWeek,
  setWeekYear,
  startOfWeek,
  startOfWeekYear,
  sub,
  Locale,
} from 'date-fns';
import { enUS, es, fr } from 'date-fns/locale';

export * from 'date-fns';

const defaultLocale: { current: Locale } = { current: enUS };

export const availableLocales = {
  fr,
  es,
  en: enUS,
  enUS,
};

export const setDefaultLocale = (next: string): void => {
  defaultLocale.current = availableLocales[next] ?? availableLocales.en;
};

export const resetDefaultLocale = (): void => {
  setDefaultLocale('en');
};

const wrap = <T extends (...args: any[]) => any>(fn: T): T => {
  const wrapped = (...args: Parameters<T>) => {
    const { length } = fn;
    const newArgs = new Array(length);
    for (let i = 0; i < length; i += 1) {
      if (i === length - 1) {
        newArgs[i] = { locale: defaultLocale.current, ...args[i] };
      } else {
        newArgs[i] = args[i];
      }
    }
    return fn(...newArgs);
  };
  return wrapped as T;
};

export const localizedAdd = wrap(add);
export const localizedDifferenceInCalendarWeeks = wrap(differenceInCalendarWeeks);
export const localizedEachWeekOfInterval = wrap(eachWeekOfInterval);
export const localizedEndOfWeek = wrap(endOfWeek);
export const localizedFormat = wrap(format);
export const localizedFormatDistance = wrap(formatDistance);
export const localizedFormatDistanceStrict = wrap(formatDistanceStrict);
export const localizedFormatDistanceToNow = wrap(formatDistanceToNow);
export const localizedFormatDistanceToNowStrict = wrap(formatDistanceToNowStrict);
export const localizedFormatDuration = wrap(formatDuration);
export const localizedFormatRelative = wrap(formatRelative);
export const localizedGetWeek = wrap(getWeek);
export const localizedGetWeekOfMonth = wrap(getWeekOfMonth);
export const localizedGetWeeksInMonth = wrap(getWeeksInMonth);
export const localizedGetWeekYear = wrap(getWeekYear);
export const localizedIsMatch = wrap(isMatch);
export const localizedIsSameWeek = wrap(isSameWeek);
export const localizedIsThisWeek = wrap(isThisWeek);
export const localizedLastDayOfWeek = wrap(lastDayOfWeek);
export const localizedParse = wrap(parse);
export const localizedSetDay = wrap(setDay);
export const localizedSetWeek = wrap(setWeek);
export const localizedSetWeekYear = wrap(setWeekYear);
export const localizedStartOfWeek = wrap(startOfWeek);
export const localizedStartOfWeekYear = wrap(startOfWeekYear);
export const localizedSub = wrap(sub);
