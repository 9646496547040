import { Components, paperClasses, Theme } from '@mui/material';

import { LIST_PADDING } from './MuiList';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: 4,
      paddingLeft: 8 + LIST_PADDING,
      paddingRight: 8 + LIST_PADDING,
      [`.${paperClasses.elevation0} &`]: {
        backgroundColor: theme.palette.background.paper,
      },
      [`.${paperClasses.elevation} &`]: {
        backgroundColor: theme.palette.background.default,
      },
    }),
    gutters: {
      paddingLeft: 8,
      paddingRight: 8,
      marginLeft: LIST_PADDING,
      marginRight: LIST_PADDING,
      width: `calc(100% - 2 * ${LIST_PADDING}px)`,
    },
  },
} as Components<Theme>['MuiListSubheader'];
