import 'react-medium-image-zoom/dist/styles.css';

import { LicenseInfo } from '@mui/x-license-pro';
import DOMPurify from 'dompurify';
import { createRoot } from 'react-dom/client';

import { Normalize } from '@dametis/core';

import { i18nInit } from 'localization';

import App from './App';
import { matomoInit } from './functions/matomo';

if ((module as any).hot) {
  (module as any).hot.accept('./App.tsx');
  (module as any).hot.accept('./localization');
}

Object.assign(String.prototype, {
  searchable() {
    return Normalize(this);
  },
});

matomoInit();
LicenseInfo.setLicenseKey(process.env.XGRID_LICENSE_KEY);
DOMPurify.setConfig({ ALLOWED_TAGS: [] });

i18nInit
  .then(() => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
  })
  .catch(() => {
    console.error('Failed to load translations');
  });
