import { TFunction } from 'i18next';

import { IsSourceColumnRealByHeader, IsSourceColumnRealByIndex, IsSourceColumnVirtual, SourceColumn } from '@dametis/core';

export const getSourceColumnName = (source: SourceColumn, t: TFunction): string => {
  if (IsSourceColumnRealByIndex(source)) {
    return t('text.realByIndexColumn', { index: source.value + 1 });
  }
  if (IsSourceColumnRealByHeader(source)) {
    return t('text.realByHeaderColumn', { name: source.value });
  }
  if (IsSourceColumnVirtual(source)) {
    return t('text.virtualColumn', { name: source.value });
  }
  return '';
};
