import { TFunction } from 'i18next';

import { isExistingUnit } from './isExistingUnit';

export const getUnitName = (unit: string, t: TFunction): string => {
  if (isExistingUnit(unit)) {
    return t(`unit:unit.${unit}`);
  }
  return unit;
};
