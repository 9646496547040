import { Theme } from '@mui/material';
import deepmerge from 'deepmerge';
import { XAxisOptions } from 'highcharts';
import ReactDOMServer from 'react-dom/server';

import DateTick from './DateTick';

export const getXAxisOptions = (options: XAxisOptions, theme: Theme): XAxisOptions =>
  deepmerge(
    {
      // title: {
      //   text: null,
      // },
      lineColor: theme.palette.grey[200],
      tickColor: theme.palette.grey[200],
      alignTicks: true,
      labels: {
        style: {
          color: theme.palette.grey[1200],
          fontSize: '10px',
          textOverflow: 'none',
          whiteSpace: 'nowrap',
        },
        useHTML: true,
        formatter() {
          if (this.isFirst || this.isLast) {
            return ReactDOMServer.renderToStaticMarkup(<DateTick {...this} />);
          }
          return this.axis.defaultLabelFormatter.call(this);
        },
      },
      crosshair: { snap: false, width: 1, color: theme.palette.grey[600], dashStyle: 'Dash' },
    },
    options,
  );
