import { ArrowRight } from '@mui/icons-material';
import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PhysicalQuantity } from '@dametis/core';
import { Multiple, MultiplesByName, PhysicalUnitByUnitNames, UnitConverter } from '@dametis/unit';

import { ListItemButtonWithArrow } from 'components/VNC/components/List/LegoList/ListItemButtonWithArrow';

import PhysicalQuantityAvatar from '../PhysicalQuantity/PhysicalQuantityAvatar';

import { useUnitPickerContext } from './UnitPickerContext';
import { getMultipleList } from './functions/getMultipleList';
import { getUnitName } from './functions/getUnitName';

const UnitColumns: FC = () => {
  const { t } = useTranslation('unit');

  const {
    setOpen,
    onChange,
    baseUnit,
    columnsPhysicalQuantities: physicalQuantities,
    setColumnsPhysicalQuantities: setPhysicalQuantities,
    columnsUnits: units,
    setColumnsUnits: setUnits,
    columnsMultiples: multiples,
    setColumnsMultiples: setMultiples,
    columnsSelectedPhysicalQuantity: selectedPhysicalQuantity,
    setColumnsSelectedPhysicalQuantity: setSelectedPhysicalQuantity,
    columnsSelectedUnit: selectedUnit,
    setColumnsSelectedUnit: setSelectedUnit,
    columnsSelectedMultiple: selectedMultiple,
    setColumnsSelectedMultiple: setSelectedMultiple,
  } = useUnitPickerContext();

  const parsedBaseUnit = useMemo(() => (baseUnit !== null ? UnitConverter.Parse(baseUnit) : undefined), [baseUnit]);

  const unitsGroupedByPhysicalQuantities = useMemo(() => {
    const record: Partial<Record<PhysicalQuantity, string[]>> = {};
    Object.entries(PhysicalUnitByUnitNames).forEach(([unit, physicalQuantity]) => {
      if (record[physicalQuantity]) {
        record[physicalQuantity].push(unit);
      } else {
        record[physicalQuantity] = [unit];
      }
    });
    return record;
  }, []);

  const selectedPhysicalQuantityEl = useRef<HTMLLIElement | null>(null);
  const selectedUnitEl = useRef<HTMLLIElement | null>(null);
  const selectedMultipleEl = useRef<HTMLLIElement | null>(null);

  const handleSelectPhysicalQuantity = useCallback(
    (physicalQuantity: PhysicalQuantity) => () => {
      setSelectedUnit(null);
      setSelectedMultiple(null);
      setSelectedPhysicalQuantity(state => (state === physicalQuantity ? null : physicalQuantity));
    },
    [setSelectedUnit, setSelectedMultiple, setSelectedPhysicalQuantity],
  );

  const handleSelectUnit = useCallback(
    (unit: string) => () => {
      setSelectedMultiple(null);
      setSelectedUnit(state => (state === unit ? null : unit));
    },
    [setSelectedMultiple, setSelectedUnit],
  );

  const handleSelectMultiple = useCallback(
    (multiple: Multiple) => () => {
      const value = `${multiple.symbol}${selectedUnit}`;
      onChange(value);
      setOpen(false);
    },
    [onChange, setOpen, selectedUnit],
  );

  useEffect(() => {
    const column = Object.keys(unitsGroupedByPhysicalQuantities).sort((physicalQuantityA, physicalQuantityB) =>
      t(`physicalQuantity.${physicalQuantityA}`).localeCompare(t(`physicalQuantity.${physicalQuantityB}`)),
    );
    if (parsedBaseUnit && column.includes(parsedBaseUnit.physicalQuantity)) {
      setPhysicalQuantities([parsedBaseUnit.physicalQuantity, ...column.filter(elem => elem !== parsedBaseUnit.physicalQuantity)]);
    } else {
      setPhysicalQuantities(column);
    }
  }, [setPhysicalQuantities, unitsGroupedByPhysicalQuantities, t, parsedBaseUnit]);

  useEffect(() => {
    const column = unitsGroupedByPhysicalQuantities[selectedPhysicalQuantity] ?? [];
    if (parsedBaseUnit && column.includes(parsedBaseUnit.unitName)) {
      setUnits([parsedBaseUnit.unitName, ...column.filter(elem => elem !== parsedBaseUnit.unitName)]);
    } else {
      setUnits(column);
    }
  }, [setUnits, unitsGroupedByPhysicalQuantities, selectedPhysicalQuantity, parsedBaseUnit]);

  useEffect(() => {
    const column = getMultipleList().map(multiple => multiple.name);
    if (parsedBaseUnit && column.includes(parsedBaseUnit.multiple.name)) {
      setMultiples([parsedBaseUnit.multiple.name, ...column.filter(elem => elem !== parsedBaseUnit.multiple.name)]);
    } else {
      setMultiples(column);
    }
  }, [setMultiples, parsedBaseUnit]);

  useEffect(() => {
    selectedPhysicalQuantityEl?.current?.scrollIntoView({ block: 'nearest' });
  }, [selectedPhysicalQuantity]);

  useEffect(() => {
    selectedUnitEl?.current?.scrollIntoView({ block: 'nearest' });
  }, [selectedUnit]);

  useEffect(() => {
    selectedMultipleEl?.current?.scrollIntoView({ block: 'nearest' });
  }, [selectedMultiple]);

  return (
    <>
      <Grid container sx={{ width: 600, height: 300 }}>
        <Grid item sx={{ overflow: 'auto', height: '100%', scrollBehavior: 'smooth' }} xs={4}>
          <List>
            {physicalQuantities.map(physicalQuantity => (
              <ListItem
                key={physicalQuantity}
                ref={physicalQuantity === selectedPhysicalQuantity ? selectedPhysicalQuantityEl : undefined}
                disablePadding
              >
                <ListItemButtonWithArrow
                  selected={physicalQuantity === selectedPhysicalQuantity}
                  onClick={handleSelectPhysicalQuantity(physicalQuantity as PhysicalQuantity)}
                >
                  <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
                    <PhysicalQuantityAvatar physicalQuantity={physicalQuantity as PhysicalQuantity} size="small" />
                  </ListItemIcon>
                  <ListItemText>{t(`physicalQuantity.${physicalQuantity}`)}</ListItemText>
                  <ArrowRight />
                </ListItemButtonWithArrow>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item sx={{ overflow: 'auto', height: '100%', scrollBehavior: 'smooth' }} xs={4}>
          {selectedPhysicalQuantity && (
            <List>
              {units.map(unit => (
                <ListItem key={unit} ref={unit === selectedUnit ? selectedUnitEl : undefined} disablePadding>
                  <ListItemButtonWithArrow selected={unit === selectedUnit} onClick={handleSelectUnit(unit)}>
                    <ListItemText>{t(`unit.${unit}`)}</ListItemText>
                    <ArrowRight />
                  </ListItemButtonWithArrow>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
        <Grid item sx={{ overflow: 'auto', height: '100%', scrollBehavior: 'smooth' }} xs={4}>
          {selectedUnit && (
            <List>
              {multiples.map(multiple => (
                <ListItem
                  key={MultiplesByName[multiple].name}
                  ref={multiple === selectedMultiple ? selectedMultipleEl : undefined}
                  disablePadding
                >
                  <ListItemButton selected={multiple === selectedMultiple} onClick={handleSelectMultiple(MultiplesByName[multiple])}>
                    <ListItemText
                      primary={`${MultiplesByName[multiple].symbol}${selectedUnit}`}
                      secondary={`${MultiplesByName[multiple].symbol !== '' ? MultiplesByName[multiple].name : ''}${getUnitName(
                        selectedUnit,
                        t,
                      )}`}
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default UnitColumns;
