/* eslint-disable */
import store from '../store';

export function matomoInit() {
  let _paq = (window._paq = window._paq || []);
  if (window.location.hostname === 'my.dametis.com') {
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = '//www.dametis.com/';
      _paq.push(['disableAlwaysUseSendBeacon']);
      _paq.push(['setTrackerUrl', u + 'zbihrlnmtshm']);
      _paq.push(['setSiteId', '5']);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u + 'zbihrlnmtshm.js';
      s.parentNode.insertBefore(g, s);
    })();
  }
}

export function matomoTrack() {
  if (window.location.hostname !== 'my.dametis.com') return;
  const { user, selectedGroup, selectedSite } = store.getState().auth;
  if (user !== null && user !== undefined) {
    _paq.push(['setUserId', user.email]);
  }
  if (selectedGroup !== null && selectedGroup !== undefined) {
    _paq.push(['setCustomDimension', 1, selectedGroup.name]);
  }
  if (selectedSite !== null && selectedSite !== undefined) {
    _paq.push(['setCustomDimension', 2, selectedSite.name]);
  }
  _paq.push(['setCustomUrl', window.location]);
  _paq.push(['setDocumentTitle', document.title]);
  _paq.push(['trackPageView']);
}
