import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  Button,
  Chip,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  FilterOptionsState,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Normalize, ShortTagInfo, TagInfo } from '@dametis/core';

import { useTags, useCreateTagMutation } from 'store/api/tags';
import { useSelector } from 'store/index';

const tagsEmptyArray: TagInfo[] = [];

interface Props
  extends Omit<
    AutocompleteProps<TagInfo, true, boolean, boolean>,
    'value' | 'onChange' | 'options' | 'renderTags' | 'getOptionLabel' | 'renderInput' | 'filterOptions' | 'open' | 'multiple'
  > {
  /**
   * Array of selected tags.
   */
  value: ShortTagInfo[];

  /**
   * Callback fired when the value is changed.
   */
  onChange: (newValue: ShortTagInfo[]) => void;

  /**
   * Array of availables tags.
   * "default" value fill the array with all site's tags
   * @default 'default'
   */
  options?: 'default' | TagInfo[];

  /**
   * Allow tag creation.
   * @default true
   */
  canAddTag?: boolean;

  /**
   * The label content.
   */
  label?: TextFieldProps['label'];

  /**
   * The short hint displayed in the `input` before the user enters a value.
   */
  placeholder?: TextFieldProps['placeholder'];

  /**
   * maximum number of tags
   * @default 'none'
   */
  max?: 'none' | number;

  /**
   * Enable autoFocus on TextField
   */
  autoFocus?: boolean;
}

const TagAutocomplete: FC<Props> = ({
  value,
  onChange,
  options = 'default',
  canAddTag = true,
  label = undefined,
  placeholder = '',
  max = 'none',
  autoFocus = false,
  ...props
}) => {
  const { t } = useTranslation('tags');

  const { data: siteTags = tagsEmptyArray } = useTags();
  const [createTag] = useCreateTagMutation();

  const corporate = useSelector(state => !state.auth.selectedSite);

  const [maxError, setMaxError] = useState<boolean>(false);

  const availableTags = useMemo(() => (options === 'default' ? siteTags : options), [options, siteTags]);

  const filterOptions = useMemo(() => createFilterOptions<TagInfo>({ trim: true }), []);

  const tagNameExists = useCallback(
    (tag: TagInfo) => value.some(existingTag => Normalize(existingTag.name) === Normalize(tag.name)),
    [value],
  );

  const isTagSaved = useCallback((tag: ShortTagInfo) => tag.uuid !== '', []);

  const handleNewTag = useCallback(
    async (tags: ShortTagInfo[], newTag: ShortTagInfo) => {
      if (max === 'none' || tags.length < max) {
        if (!isTagSaved(newTag)) {
          const newTagSaved = await createTag({ name: newTag.name }).unwrap();
          onChange([...tags, newTagSaved]);
        } else {
          onChange([...tags, newTag]);
        }
      } else {
        setMaxError(true);
      }
    },
    [isTagSaved, max, onChange, createTag],
  );

  const handleChange = useCallback(
    async (e: SyntheticEvent<Element, Event>, v: any, reason: AutocompleteChangeReason, details: AutocompleteChangeDetails<TagInfo>) => {
      if (reason === 'selectOption') {
        await handleNewTag(value, details.option);
      }
      if (reason === 'removeOption' && details.option.uuid !== '') {
        onChange(value.filter(tag => tag.uuid !== details.option.uuid));
      }
      if (reason === 'clear') {
        onChange([]);
      }
    },
    [value, handleNewTag, onChange],
  );

  if (corporate) return null;
  return (
    <>
      <Autocomplete
        clearOnBlur
        filterSelectedOptions
        handleHomeEndKeys
        multiple
        selectOnFocus
        filterOptions={(opts: TagInfo[], params: FilterOptionsState<TagInfo>) => {
          const filtered = filterOptions(opts, params).filter(tag => value.every(v => v.uuid !== tag.uuid));
          const { inputValue } = params;
          if (canAddTag && Normalize(inputValue) !== '' && opts.every(option => Normalize(inputValue) !== Normalize(option.name))) {
            filtered.push({
              name: inputValue,
              normalizedName: Normalize(inputValue),
              uuid: '',
              canEdit: true,
              createdAt: new Date(),
              updatedAt: new Date(),
            });
          }
          return filtered;
        }}
        getOptionLabel={(option: TagInfo) =>
          !tagNameExists(option) && (isTagSaved(option) ? option.name : `${option.name} (${t('tagAutocomplete.newTag')})`)
        }
        isOptionEqualToValue={(option, tag) => option.uuid === tag.uuid}
        noOptionsText={!canAddTag && t('text.noOptions')}
        options={availableTags}
        renderInput={params => <TextField {...params} fullWidth autoFocus={autoFocus} label={label} placeholder={placeholder} />}
        renderTags={(selectedTags, getTagProps) =>
          selectedTags.map((option, index) => (
            <Chip key={option.uuid ?? `newTag${index}`} label={option.name} size="small" {...getTagProps({ index })} />
          ))
        }
        value={value}
        onChange={handleChange}
        {...props}
      />
      <Dialog open={maxError}>
        <DialogContent>{`${max} ${t('error.maxTagAllowed')}`}</DialogContent>
        <DialogActions>
          <Button color="primary" variant="text" onClick={() => setMaxError(false)}>
            {t('button.close')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagAutocomplete;
