import { Components, filledInputClasses, Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      color: theme.palette.black,
      [`& .${filledInputClasses.input}`]: {
        paddingTop: 8,
        'label + &': {
          paddingTop: 25,
        },
      },
    }),
  },
} as Components<Theme>['MuiFilledInput'];
