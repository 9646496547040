import { cloneDeep } from 'lodash';
import { ReactNode } from 'react';

import { CalculationVariable, TraverseCalculation } from '@dametis/core';

import { TypedThunk } from 'store';
import {
  MenuPosition,
  setApiCalculation,
  setCalculation,
  setChartDisabled,
  setItems,
  setLastPointDate,
  setMenuPosition,
  setPeriod,
} from 'store/slices/calculationMenu';

export const openCalculationMenu =
  ({
    position,
    calculation,
    items,
    chartDisabled,
  }: {
    position: MenuPosition;
    calculation: CalculationVariable;
    items?: ReactNode[];
    chartDisabled?: boolean;
  }): TypedThunk<void> =>
  dispatch => {
    dispatch(setCalculation(calculation));
    const newCalculation = cloneDeep(calculation);
    TraverseCalculation(newCalculation, node => {
      delete node.operator;
      delete node.groupBy;
    });
    dispatch(setApiCalculation(newCalculation));
    dispatch(setPeriod(null));
    dispatch(setLastPointDate(null));
    dispatch(setMenuPosition({ ...position, mouseY: position.mouseY - (!chartDisabled ? 275 : 0) }));
    dispatch(setItems(items ?? []));
    dispatch(setChartDisabled(!!chartDisabled));
  };

export const closeCalculationMenu = (): TypedThunk<void> => dispatch => {
  dispatch(setMenuPosition(null));
};
