import { Clear } from '@mui/icons-material';
import { Divider, IconButton, ListItem, ListItemText, Tooltip } from '@mui/material';
import deepmerge from 'deepmerge';
import { FC, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { VarCalc } from '@dametis/core';

import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../context';
import Variable, { VariableProps } from '../Title/Textarea/Variable';

interface Props {
  varCalc: VarCalc;
  isLast: boolean;
  index: number;
}

const SelectionItem: FC<Props> = ({ varCalc, isLast, index }) => {
  const { t } = useTranslation('vnc');

  const removeFromSelection = useVncStore(state => state.removeFromSelection);
  const editSelectionItem = useVncStore(state => state.editSelectionItem);

  const { disableMaths, butKeepVariableMenu } = useContext(PropsContext);

  const remove = useCallback(() => {
    removeFromSelection(varCalc);
  }, [removeFromSelection, varCalc]);

  const changeVariable = useCallback<VariableProps['onVariableChange']>(
    variable => {
      editSelectionItem(index, deepmerge(varCalc, variable));
    },
    [editSelectionItem, index, varCalc],
  );

  return (
    <>
      <ListItem
        secondaryAction={
          <Tooltip placement="bottom-start" title={t('tooltip.removeFromSelection')}>
            <IconButton size="small" onClick={remove}>
              <Clear fontSize="small" />
            </IconButton>
          </Tooltip>
        }
      >
        <ListItemText
          primary={
            <Variable
              disableVariableMenu={disableMaths && !butKeepVariableMenu}
              sx={{ wordBreak: 'break-word' }}
              variable={varCalc}
              onVariableChange={changeVariable}
            />
          }
        />
      </ListItem>
      {!isLast && <Divider component="li" sx={{ ml: 2 }} variant="inset" />}
    </>
  );
};

export default SelectionItem;
