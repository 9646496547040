import { ArrowForwardIosOutlined, ArrowRight, NavigateNext, SettingsOutlined, Star, StarOutline } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  breadcrumbsClasses,
  Button,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  styled,
  svgIconClasses,
  Tooltip,
  Typography,
} from '@mui/material';
import Fuse from 'fuse.js';
import {
  FC,
  MouseEventHandler,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  KeyboardEventHandler,
  KeyboardEvent,
  MouseEvent,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import { GroupInfo, SiteInfo, UUID } from '@dametis/core';

import GroupSiteMenuHelper from 'components/Home/GroupSiteMenuHelper';
import SearchInput from 'components/UI/SearchInput/SearchInput';
import { OpenReplayEvent } from 'components/VNC/types';
import getHighlightedText from 'functions/getHighlightedText';
import { getLocalStorageItem, setLocalStorageItem } from 'functions/localStorage';
import { stopPropagation } from 'functions/stopPropagation';
import { usePermission } from 'hooks/usePermission';
import { sdk } from 'sdk';
import { useDispatch, useSelector } from 'store';
import { updateSiteAndGroup } from 'store/actions/auth';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { setFavoriteGroups } from 'store/slices/auth';

import { openReplayEvent } from '../../../../functions/openReplay';
import SiteTile from '../../../Home/SiteTile';
import LinkButton from '../../../UI/Buttons/LinkButton/LinkButton';
import PopperPanel from '../../../UI/PopperPanel/PopperPanel';

const FUNCTION_HOTKEY = `mod+K`;

const GroupButton = styled(ListItemButton, {
  shouldForwardProp: propName => propName !== 'current' && propName !== 'chosen',
})<{ current: boolean; chosen: boolean }>(({ theme, current, chosen }) => ({
  cursor: 'default',
  boxShadow: current ? `0 0 0 3px ${theme.palette.secondary.light}` : undefined,
  [`& .${svgIconClasses.root}`]: {
    opacity: chosen ? 1 : 0,
    pointerEvents: 'none',
    transition: theme.transitions.create(['opacity']),
  },
  [`&:hover .${svgIconClasses.root}`]: {
    // opacity: 1,
    pointerEvents: undefined,
  },
}));

const GroupSiteMenu: FC = () => {
  const { t } = useTranslation('header');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedGroup = useSelector(state => state.auth.selectedGroup);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const groups = useSelector(state => state.auth.groups);
  const acl = useSelector(state => state.auth.user.acl);
  const userId = useSelector(state => state.auth.user.uuid);
  const favoriteGroups = useSelector(state => state.auth.user.favorites.group);
  const canAccessCorporate = useMemo(() => acl.HasPermissionOnGroup('canAccessCorporate', selectedGroup.uuid), [acl, selectedGroup.uuid]);
  const canConfiguration = usePermission('canAccessConfiguration');

  const [menuOpen, setMenuOpen] = useState(false);
  const [displayedGroup, setDisplayedGroup] = useState<GroupInfo>(null);
  const [displayedGroups, setDisplayedGroups] = useState<GroupInfo[]>([]);
  const [search, setSearch] = useState<string>('');
  const [isFocusSite, setIsFocusSite] = useState(false);
  const [chosenGroup, setChosenGroup] = useState(undefined);
  const [selectedSiteIndex, setSelectedSiteIndex] = useState(0);

  const anchor = useRef();
  const searchInputRef = useRef(null);
  const groupRefs = useRef([]);
  const siteRefs = useRef([]);

  const corporate = useMemo(() => !selectedSite, [selectedSite]);
  const sortedGroups = useMemo(
    () =>
      [...groups].sort((groupA, groupB) => {
        if (favoriteGroups.includes(groupA.uuid) === favoriteGroups.includes(groupB.uuid)) {
          return groupA.name.localeCompare(groupB.name);
        }
        return favoriteGroups.includes(groupA.uuid) ? -1 : 1;
      }),
    [groups, favoriteGroups],
  );
  const initialSelectedGroupIndex = useMemo(() => {
    return sortedGroups.findIndex(group => group.uuid === selectedGroup.uuid);
  }, [sortedGroups, selectedGroup]);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(initialSelectedGroupIndex);

  const fuse = useMemo(
    () =>
      new Fuse(sortedGroups, {
        keys: ['name', 'normalizedName', 'sites.name', 'sites.location.city'],
      }),
    [sortedGroups],
  );

  const getLocalStorageFavoriteGroups = useCallback(async () => {
    const localStorageFavoriteGroups: UUID[] = getLocalStorageItem('favoriteGroups', { userId }) ?? [];
    if (Array.isArray(localStorageFavoriteGroups) && localStorageFavoriteGroups.length > 0) {
      try {
        await sdk.user.Update(userId, { favorites: { group: localStorageFavoriteGroups } });
        dispatch(setFavoriteGroups(localStorageFavoriteGroups));
        setLocalStorageItem('favoriteGroups', [], { userId });
      } catch (err) {
        console.error(err);
      }
    }
  }, [userId, dispatch]);

  const navToSettings = useCallback((e: MouseEvent) => {
    stopPropagation(e);
    openReplayEvent(OpenReplayEvent.OPEN_GROUP_SITE_MENU);
  }, []);

  useEffect(() => {
    setDisplayedGroup(groups.find(group => group.uuid === selectedGroup.uuid));
  }, [groups, selectedGroup.uuid]);

  const open = useCallback(() => {
    setMenuOpen(true);
  }, []);

  const close = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const goToGroupAndSite = useCallback(
    (site: SiteInfo | null) => () => {
      requestAnimationFrame(async () => {
        await dispatch(updateSiteAndGroup({ group: displayedGroup, site }));
      });
      navigate('', { state: { goToGroupAndSite: true } });
      close();
    },
    [dispatch, displayedGroup, navigate, close],
  );

  const displayGroup = useCallback(
    (group: GroupInfo) => () => {
      setDisplayedGroup(group);
    },
    [],
  );

  const filtering = useCallback(() => {
    const rows = search.trim().length > 0 ? fuse.search(search).map(fuseResult => fuseResult.item) : sortedGroups;
    setDisplayedGroups(rows);
  }, [fuse, search, sortedGroups]);

  const handleSearch = useCallback((newSearch: string) => {
    setSearch(newSearch);
  }, []);

  const handleAddFavoriteGroup = useCallback(
    (groupId: UUID): MouseEventHandler<HTMLButtonElement> =>
      async () => {
        let newGroups: string[];

        if (favoriteGroups.includes(groupId)) {
          newGroups = favoriteGroups.filter(group => group !== groupId);
        } else {
          newGroups = [...favoriteGroups, groupId];
        }

        try {
          await sdk.user.Update(userId, { favorites: { group: newGroups } });
          dispatch(setFavoriteGroups(newGroups));
        } catch (err) {
          console.error(err);
          dispatch(displaySdkErrorToast(err));
        }
      },
    [favoriteGroups, userId, dispatch],
  );

  useEffect(() => {
    filtering();
  }, [filtering]);

  useHotkeys(
    FUNCTION_HOTKEY,
    () => {
      if (menuOpen) {
        close();
      } else {
        setSelectedGroupIndex(0);
        setSelectedSiteIndex(0);
        setIsFocusSite(false);
        setSearch('');
        setDisplayedGroup(selectedGroup);
        open();
      }
    },
    { enableOnFormTags: ['INPUT'], preventDefault: true },
    [menuOpen, setMenuOpen, open, selectedGroup, displayGroup, close, FUNCTION_HOTKEY],
  );

  useEffect(() => {
    groupRefs.current = groups.map(() => createRef());
    siteRefs.current = displayedGroups.map(group => group.sites.map(() => createRef()));
  }, [groups, displayedGroups]);

  const handleEnterKey = useCallback(
    (event: KeyboardEvent, group?: GroupInfo) => {
      const [firstDisplayedGroup] = displayedGroups;
      const groupToSelect = group || firstDisplayedGroup;
      if (event.key === 'Enter') {
        if (!isFocusSite) {
          event.preventDefault();
          displayGroup(groupToSelect)();
          setChosenGroup(selectedGroupIndex);
          setIsFocusSite(true);
          setSelectedSiteIndex(0);
        }
      }
    },
    [displayGroup, displayedGroups, isFocusSite, selectedGroupIndex],
  );

  const handleKeyArrowDownUp = useCallback<KeyboardEventHandler>(
    event => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        if (!isFocusSite) {
          setSelectedGroupIndex(prevIndex => {
            const newIndex = event.key === 'ArrowDown' ? prevIndex + 1 : prevIndex - 1;
            if (newIndex < 0) {
              return displayedGroups.length - 1;
            }
            if (newIndex >= displayedGroups.length) {
              return 0;
            }
            return newIndex;
          });
        } else {
          setSelectedSiteIndex((prevIndex: number) => {
            const newIndex = event.key === 'ArrowDown' ? prevIndex + 1 : prevIndex - 1;
            if (newIndex < 0) {
              return displayedGroup.sites.length - 1;
            }
            if (newIndex >= displayedGroup.sites.length) {
              return 0;
            }
            return newIndex;
          });
        }
      }
    },
    [displayedGroups.length, displayedGroup?.sites.length, isFocusSite],
  );

  useEffect(() => {
    if (groupRefs.current[selectedGroupIndex]) {
      const selectedListItem = groupRefs.current[selectedGroupIndex].current;
      if (groupRefs.current[selectedGroupIndex].current && selectedGroupIndex >= 0) {
        selectedListItem.focus();
      }
    } else if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [selectedGroupIndex]);

  useEffect(() => {
    if (siteRefs.current[selectedSiteIndex]) {
      const selectedSiteItem = siteRefs.current[selectedSiteIndex].current;
      if (selectedSiteItem) {
        selectedSiteItem.focus();
      }
    }
  }, [selectedSiteIndex]);

  useHotkeys('ArrowRight', () => {
    if (!isFocusSite) {
      displayGroup(displayedGroups[selectedGroupIndex])();
      setChosenGroup(selectedGroupIndex);
      setIsFocusSite(true);
      setSelectedSiteIndex(0);
    }
  });

  useHotkeys('ArrowLeft', () => {
    if (isFocusSite) {
      setIsFocusSite(false);
    }
  });

  useEffect(() => {
    if (groupRefs.current[selectedGroupIndex]) {
      const selectedElement = isFocusSite
        ? siteRefs?.current?.[selectedGroupIndex]?.[selectedSiteIndex]?.current
        : groupRefs?.current?.[selectedGroupIndex]?.current;
      if (selectedElement) {
        selectedElement.focus();
      }
    }
  }, [selectedGroupIndex, selectedSiteIndex, isFocusSite]);

  useEffect(() => {
    void getLocalStorageFavoriteGroups();
  }, [getLocalStorageFavoriteGroups]);

  return (
    <>
      <Button
        ref={anchor}
        endIcon={
          corporate || !canConfiguration ? undefined : (
            <Tooltip title={t('tooltip.settings')}>
              <IconButton component={NavLink} size="small" to="/configuration/site" onClick={navToSettings} onMouseDown={stopPropagation}>
                <SettingsOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          )
        }
        sx={{ whiteSpace: 'nowrap' }}
        onClick={open}
      >
        <Breadcrumbs separator={<NavigateNext fontSize="small" />} sx={{ [`& .${breadcrumbsClasses.ol}`]: { flexWrap: 'nowrap' } }}>
          <Typography color={!corporate && 'textSecondary'} sx={{ fontWeight: 600 }} variant="h6">
            {selectedGroup.name}
          </Typography>
          {!corporate && (
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              {selectedSite.name}
            </Typography>
          )}
        </Breadcrumbs>
      </Button>
      <PopperPanel anchorEl={anchor.current} open={menuOpen} onClickAway={close}>
        <Stack direction="row" height={1}>
          {groups.length > 1 && (
            <>
              <Stack pt={2} spacing={1.5} width={300}>
                <GroupSiteMenuHelper />
                <Box px={1.5}>
                  <Typography variant="overline">{t('siteSelect.groups')}</Typography>
                </Box>
                {sortedGroups.length > 1 && (
                  <Box px={1.5}>
                    <SearchInput
                      autoFocus
                      extendable={false}
                      inputRef={searchInputRef}
                      value={search}
                      onChange={handleSearch}
                      onKeyDown={e => handleEnterKey(e)}
                    />
                  </Box>
                )}
                <List sx={{ flexGrow: 1, overflowY: 'auto', px: 1.5, pt: '3px', pb: 2 }} onKeyDown={e => handleKeyArrowDownUp(e)}>
                  {displayedGroups.map((group, index) => (
                    <GroupButton
                      key={group.uuid}
                      ref={groupRefs.current[index]}
                      disableRipple
                      chosen={chosenGroup === index}
                      current={group.uuid === selectedGroup?.uuid}
                      selected={index === selectedGroupIndex}
                      tabIndex={0}
                      onClick={() => {
                        displayGroup(group);
                        setSelectedGroupIndex(index);
                        setChosenGroup(index);
                      }}
                      onKeyDown={e => handleEnterKey(e, group)}
                      onMouseDown={displayGroup(group)}
                    >
                      <ListItemText
                        primary={
                          <>
                            {favoriteGroups.includes(group.uuid) && (
                              <Star sx={{ color: theme => theme.palette.picker.yellow, fontSize: 12, opacity: '1!important' }} />
                            )}
                            {getHighlightedText(group.name, search)}
                          </>
                        }
                        secondary={getHighlightedText(group.sites.map(s => s.name).join(', '), search)}
                        secondaryTypographyProps={{ noWrap: true }}
                      />
                      <ArrowRight color="action" />
                    </GroupButton>
                  ))}
                </List>
              </Stack>
              <div>
                <Divider orientation="vertical" />
              </div>
            </>
          )}
          <Stack overflow="hidden" pt={2} spacing={1.5} width={300}>
            <Box px={1.5}>
              <Typography variant="overline">{t('siteSelect.group')}</Typography>
            </Box>
            <Box px={1.5}>
              <Typography mb={0.5} variant="h3">
                {displayedGroup?.name}
              </Typography>
              <Stack alignItems="center" direction="row">
                {groups.length > 1 && (
                  <Tooltip title={favoriteGroups.includes(displayedGroup?.uuid) ? t('tooltip.unfavorite') : t('tooltip.favorite')}>
                    <IconButton size="small" onClick={handleAddFavoriteGroup(displayedGroup?.uuid)}>
                      {favoriteGroups.includes(displayedGroup?.uuid) ? (
                        <Star sx={{ color: theme => theme.palette.picker.yellow }} />
                      ) : (
                        <StarOutline sx={{ color: theme => theme.palette.icon.main }} />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
                {canAccessCorporate && (
                  <LinkButton
                    disabled={corporate && displayedGroup?.uuid === selectedGroup.uuid}
                    endIcon={<ArrowForwardIosOutlined fontSize="small" />}
                    size="small"
                    sx={{ display: 'flex', ml: 'auto' }}
                    onClick={goToGroupAndSite(null)}
                  >
                    {t('siteSelect.corporate')}
                  </LinkButton>
                )}
              </Stack>
            </Box>
            <Box px={1.5}>
              <Divider />
            </Box>
            <Box px={1.5}>
              <Typography variant="overline">{t('siteSelect.sites')}</Typography>
            </Box>
            <Stack spacing={1} sx={{ px: 1.5, pt: '3px', pb: 2, flexGrow: 1, overflow: 'auto' }}>
              {displayedGroup?.sites.map((site, index) => (
                <SiteTile
                  key={site.uuid}
                  canEdit={false}
                  refIndex={siteRefs?.current?.at(selectedGroupIndex)?.[index]}
                  selected={site.uuid === selectedSite?.uuid}
                  site={site}
                  tabIndex={0}
                  onKeyDown={e => handleKeyArrowDownUp(e)}
                  onSelect={goToGroupAndSite(site)}
                />
              ))}
            </Stack>
          </Stack>
        </Stack>
      </PopperPanel>
    </>
  );
};

export default GroupSiteMenu;
