import { HighchartsReactRefObject } from 'highcharts-react-official';
import { createContext, FC, PropsWithChildren, useContext, useMemo } from 'react';

export interface DaChartContextState {
  highcharts: HighchartsReactRefObject;
}

export const initialState = {
  highcharts: null,
};

export const DaChartContext = createContext<DaChartContextState>(initialState);

interface DaChartProviderProps {
  highcharts: HighchartsReactRefObject;
}

const DaChartProvider: FC<PropsWithChildren<DaChartProviderProps>> = ({ highcharts, children = undefined }) => {
  const contextValues: DaChartContextState = useMemo(() => ({ highcharts }), [highcharts]);

  return <DaChartContext.Provider value={contextValues}>{children}</DaChartContext.Provider>;
};

export const useDaChartContext = () => {
  const context = useContext(DaChartContext);

  if (!context) {
    throw Error('useDaChartContext must be used inside a DaChartProvider');
  }

  return context;
};

export default DaChartProvider;
