import { v4 as uuidv4 } from 'uuid';

import { Format, UUID } from '@dametis/core';

import store from 'store';
import { StorageFormat } from 'types/format';

import { areSameFormats } from './areSameFormats';
import { getLocalStorageItem, setLocalStorageItem } from './localStorage';

export const getFormatHistory = (): StorageFormat[] => {
  const userId = store.getState().auth.user.uuid;
  return getLocalStorageItem('formatHistory', { userId }) ?? [];
};

export const setFormatHistoryStorage = (newItems: StorageFormat[]) => {
  const userId = store.getState().auth.user.uuid;
  setLocalStorageItem('formatHistory', newItems, { userId });
};

export const addFormatHistoryItem = (newFormat: Format) => {
  const oldItems = getFormatHistory();
  const storageItem: StorageFormat = {
    uuid: uuidv4(),
    createdAt: new Date(),
    format: newFormat,
  };
  const filteredOldItems = oldItems.filter(oldItem => !areSameFormats(oldItem.format, newFormat));
  const newItems: StorageFormat[] = [storageItem, ...filteredOldItems];
  setFormatHistoryStorage(newItems);
};

export const removeFormatHistoryItem = (itemUuid: UUID) => {
  const oldItems = getFormatHistory();
  const newItems: StorageFormat[] = oldItems.filter(item => item.uuid !== itemUuid);
  setFormatHistoryStorage(newItems);
};

export const clearFormatHistory = () => {
  setFormatHistoryStorage([]);
};

export default {
  getFormatHistory,
  setFormatHistoryStorage,
  addFormatHistoryItem,
  removeFormatHistoryItem,
  clearFormatHistory,
};
