import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { CommentInfo, CreateCommentBody } from '@dametis/core';

import { EntityFromComment, getEntityFromComment } from 'components/UI/Comments/fonction/getEntityFromComment';

export interface CommentContextState {
  currentComment: CommentInfo | CreateCommentBody | null;
  setCurrentComment: Dispatch<SetStateAction<CommentInfo | CreateCommentBody>>;
  entity: EntityFromComment;
  setEntity: Dispatch<SetStateAction<EntityFromComment>>;
  submitting: boolean;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
}

export const contextInitialState: CommentContextState = {
  currentComment: null,
  setCurrentComment: undefined,
  entity: { type: null, uuid: null },
  setEntity: undefined,
  submitting: false,
  setSubmitting: undefined,
};

export const CommentContext = createContext<CommentContextState>(contextInitialState);

const CommentProvider: FC<PropsWithChildren> = ({ children = undefined }) => {
  const [entity, setEntity] = useState<EntityFromComment>({ type: null, uuid: null });
  const [currentComment, setCurrentComment] = useState<CommentInfo | CreateCommentBody | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (currentComment) setEntity(getEntityFromComment(currentComment));
  }, [currentComment]);

  const contextValues: CommentContextState = useMemo(
    () => ({
      currentComment,
      setCurrentComment,
      entity,
      setEntity,
      submitting,
      setSubmitting,
    }),
    [currentComment, entity, submitting],
  );

  return <CommentContext.Provider value={contextValues}>{children}</CommentContext.Provider>;
};

export const useCommentContext = () => {
  const context = useContext(CommentContext);

  if (!context) {
    throw Error('useCommentContext must be used inside an CommentProvider');
  }

  return context;
};

export default CommentProvider;
