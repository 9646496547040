import { Done } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { cloneDeep } from 'lodash';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';

import { ProjectInfo, ShortProjectInfo, UUID } from '@dametis/core';

import ProjectTypeIcon from 'components/Project/UI/ProjectTypeIcon';
import { useProjects } from 'store/api/projects';

interface ProjectListProps {
  value: UUID | null;
  onChange: (newValue: UUID | null) => void;
}

const projectsEmptyArray: ShortProjectInfo[] = [];

const ProjectList: FC<ProjectListProps> = ({ value, onChange }) => {
  const { data: projects = projectsEmptyArray } = useProjects();

  const sortedProjects = useMemo(
    () => (cloneDeep(projects) as ProjectInfo[]).sort((projectA, projectB) => projectA.name.localeCompare(projectB.name)),
    [projects],
  );

  const handleSelectProject = useCallback(
    (newValue: UUID): MouseEventHandler<HTMLDivElement> =>
      () => {
        onChange(value === newValue ? null : newValue);
      },
    [value, onChange],
  );

  return (
    <List sx={{ maxHeight: 400 }}>
      {sortedProjects.map(project => (
        <ListItem
          key={project.uuid}
          disablePadding
          secondaryAction={value === project.uuid ? <Done fontSize="small" sx={{ pointerEvents: 'none' }} /> : undefined}
        >
          <ListItemButton selected={value === project.uuid} onClick={handleSelectProject(project.uuid)}>
            <ProjectTypeIcon sx={{ mr: 1 }} type={project.type} />
            <ListItemText primary={project.name} primaryTypographyProps={{ noWrap: true }} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default ProjectList;
