export default {
  button: {
    reset: 'Reset',
  },
  option: {
    all: 'Select all',
  },
  text: {
    noTagSelected: 'No tag selected',
  },
};
