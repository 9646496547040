import i18next from 'i18next';
import { v4 as uuidv4 } from 'uuid';

import { ProjectType, UUID } from '@dametis/core';

export interface ProjectTasksTemplate {
  uuid: UUID;
  name: string;
  types: ProjectType[];
  project?: {
    tasks: { description: string }[];
  };
}

export const projectTasksTemplates: ProjectTasksTemplate[] = [
  {
    uuid: uuidv4(),
    name: i18next.t('projects:template.name.optimisationCip'),
    types: [ProjectType.OPTIMISATION],
    project: {
      tasks: [
        {
          description: i18next.t('projects:template.tasks.traceCleanProfile'),
        },
        {
          description: i18next.t('projects:template.tasks.analyse'),
        },
        {
          description: i18next.t('projects:template.tasks.defineNewTime'),
        },
        {
          description: i18next.t('projects:template.tasks.preValidationQuality'),
        },
        {
          description: i18next.t('projects:template.tasks.editAutomatism'),
        },
        {
          description: i18next.t('projects:template.tasks.postValidationQuality'),
        },
      ],
    },
  },
  {
    uuid: uuidv4(),
    name: i18next.t('projects:template.name.optimisationEnvironment'),
    types: [ProjectType.OPTIMISATION],
    project: {
      tasks: [
        {
          description: i18next.t('projects:template.tasks.technicalValidation'),
        },
        {
          description: i18next.t('projects:template.tasks.financialValidation'),
        },
        {
          description: i18next.t('projects:template.tasks.environmentalValidation'),
        },
      ],
    },
  },
];
