import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ProjectInfo,
  TaskInfo,
  AssetInfo,
  ProjectSaving,
  ProjectReferencePeriod,
  Energies,
  ProjectReferenceConsumption,
  ImageInfo,
  UUID,
  SharingsInfo,
  Rights,
} from '@dametis/core';

export type ProjectStatsByEnergy = Partial<Record<Energies, { financial: number; carbon: number; energy: number }>>;
export interface ProjectStatsTotal {
  financial: number;
  carbon: number;
}

interface PaybackPeriod {
  value: number;
  valueWithoutSubsidies: number;
}
interface ProjectStats {
  consumptionByYear: ProjectReferenceConsumption[];
  totalConsumption: ProjectStatsTotal;
  totalConsumptionByYear: ProjectStatsTotal;
  totalForecastedSavings: ProjectStatsTotal;
  totalForecastedImpact: number;
  totalSavings: ProjectStatsTotal;
  totalImpact: number;
  savingPercentage: ProjectStatsByEnergy;
  totalSavingPercentage: ProjectStatsTotal;
  totalCosts: number;
  forecastedPaybackPeriod: PaybackPeriod;
  paybackPeriod: PaybackPeriod;
  totalSubsidies: number;
}

export interface ProjectState {
  project: ProjectInfo | null;
  editedTask: string | null;
  isEditingTask: boolean;
  taskLoading: boolean;
  fetching: boolean;
  stats: ProjectStats | null;
}

const initialState: ProjectState = {
  project: null,
  editedTask: null,
  isEditingTask: false,
  taskLoading: false,
  fetching: false,
  stats: null,
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProject: (state, action: PayloadAction<ProjectInfo | null>) => {
      state.project = action.payload;
    },
    setProjectAssets: (state, action: PayloadAction<AssetInfo[]>) => {
      state.project.assets = action.payload;
    },
    updateProjectSharings: (state, action: PayloadAction<SharingsInfo<Rights>>) => {
      state.project.sharings = action.payload;
    },
    setProjectImages: (state, action: PayloadAction<ImageInfo[]>) => {
      state.project.images = action.payload;
    },
    setProjectTask: (state, action: PayloadAction<{ taskId: string; body: TaskInfo }>) => {
      state.project.tasks = state.project.tasks.map(task => (task.uuid === action.payload.taskId ? action.payload.body : task));
    },
    setProjectTasks: (state, action: PayloadAction<TaskInfo[]>) => {
      state.project.tasks = action.payload;
    },
    deleteProjectTask: (state, action: PayloadAction<UUID>) => {
      state.project.tasks = state.project.tasks.filter(task => task.uuid !== action.payload);
    },
    setProjectForecastedSavings: (state, action: PayloadAction<ProjectSaving[]>) => {
      state.project.forecastedSavings = action.payload;
    },
    setProjectSavings: (state, action: PayloadAction<ProjectSaving[]>) => {
      state.project.savings = action.payload;
    },
    setProjectReferencePeriod: (state, action: PayloadAction<ProjectReferencePeriod>) => {
      state.project.referencePeriod = action.payload;
    },
    setEditedTask: (state, action: PayloadAction<string | null>) => {
      state.editedTask = action.payload;
    },
    setTaskLoading: (state, action: PayloadAction<boolean>) => {
      state.taskLoading = action.payload;
    },
    setProjectFetching: (state, action: PayloadAction<boolean>) => {
      state.fetching = action.payload;
    },
    setProjectStats: (state, action: PayloadAction<ProjectStats>) => {
      state.stats = action.payload;
    },
    setIsEditingTask: (state, action: PayloadAction<boolean>) => {
      state.isEditingTask = action.payload;
    },
    clearProjectStore: () => initialState,
  },
});

export const {
  setProject,
  setProjectAssets,
  updateProjectSharings,
  setProjectImages,
  setProjectTask,
  setProjectTasks,
  deleteProjectTask,
  setProjectSavings,
  setProjectForecastedSavings,
  setProjectReferencePeriod,
  setEditedTask,
  setTaskLoading,
  setProjectFetching,
  setProjectStats,
  setIsEditingTask,
  clearProjectStore,
} = projectSlice.actions;

export default projectSlice.reducer;
