import { PointOptionsObject } from 'highcharts/highcharts.d';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { AUTO_GROUPBY, CalculationVariable, IsDataVariable, LinearRegressionResult, NewUUID, UUID, VarCalc } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import DaArearangeSeries from 'classes/DaCharts/DaArearangeSeries';
import { calculationToString } from 'functions/calculationToString';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { getGroupBy } from 'functions/tada/getGroupBy';
import { MAX_POINTS } from 'functions/tada/tada';
import { localizedFormat } from 'localization/localizedDateFns';
import { sdk } from 'sdk';
import store, { TypedThunk } from 'store';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { xychartUpdateXvariable, xychartUpdateYvariable } from 'store/slices/playground';

import { chartColors } from '../../../../functions/color';
import { ITab, IXyChart, IXyVariable, IXyVariableProps, IsXyChartTab, XyAreaRangeParams, isXVariableIndex } from '../../types';
import { createVariable } from '../Variable';
import { getStoreTab } from '../shared';

import { fetchXyChartData } from './XyChart';

// ACTIONS

export const addAreaToXyVariable =
  (tab: ITab<IXyChart>, variable: IXyVariable, area: XyAreaRangeParams): TypedThunk<Promise<void>> =>
  async dispatch => {
    const storeTab = dispatch(getStoreTab(tab.uuid));
    if (!IsXyChartTab(storeTab)) return;
    const storeVariable = dispatch(getStoreYXyVariable(variable, tab.uuid));
    const areaRangeSerie = storeTab.chart.daChart.addSeries({
      style: {
        type: 'arearange',
      },
      uuid: area.uuid,
      name: area.name,
      color: area.color,
      data: [
        [storeTab.chart.daChart.xAxis[0].getExtremes().dataMin, Number(area.left.min), Number(area.left.max)],
        [storeTab.chart.daChart.xAxis[0].getExtremes().dataMax, Number(area.right.min), Number(area.right.max)],
      ],
      area: true,
      hidden: !storeVariable.daSeries.visible,
      yAxis: storeVariable.daSeries.yAxis,
      disableBoost: true,
    }) as DaArearangeSeries;
    await dispatch(
      updateYXyVariable(
        storeVariable,
        {
          areaRangeParams: [...(storeVariable?.areaRangeParams ?? []), area],
          areaRangeSeries: [...(storeVariable?.areaRangeSeries ?? []), areaRangeSerie],
        },
        storeTab,
      ),
    );
    storeTab.chart.daChart.redraw();
  };

export const updateXyVariableArea =
  (tab: ITab<IXyChart>, variable: IXyVariable, area: XyAreaRangeParams): TypedThunk<Promise<void>> =>
  async dispatch => {
    const storeTab = dispatch(getStoreTab(tab.uuid));
    if (!IsXyChartTab(storeTab)) return;
    const { uuid, color, name, left, right } = area;
    const storeVariable = dispatch(getStoreYXyVariable(variable, tab.uuid));
    const areaRangeSerie = storeVariable.areaRangeSeries.find(s => {
      return s.uuid === uuid;
    });
    if (areaRangeSerie) {
      areaRangeSerie.addData([
        [storeTab.chart.daChart.xAxis[0].getExtremes().dataMin, Number(left.min), Number(left.max)],
        [storeTab.chart.daChart.xAxis[0].getExtremes().dataMax, Number(right.min), Number(right.max)],
      ]);
      areaRangeSerie.setName(name);
      areaRangeSerie.setColor(color, false);
      storeTab.chart.daChart.redraw();
    }
    await dispatch(
      updateYXyVariable(
        storeVariable,
        {
          areaRangeParams: [...storeVariable.areaRangeParams.map(oldArea => (oldArea.uuid === area.uuid ? area : oldArea))],
        },
        storeTab,
      ),
    );
  };

export const deleteAreaFromXyVariable =
  (tab: ITab<IXyChart>, variable: IXyVariable, area: XyAreaRangeParams): TypedThunk<Promise<void>> =>
  async dispatch => {
    const storeTab = dispatch(getStoreTab(tab.uuid));
    if (!IsXyChartTab(storeTab)) return;
    const storeVariable = dispatch(getStoreYXyVariable(variable, tab.uuid));
    const areaRangeSerie = storeVariable.areaRangeSeries.find(s => s.uuid === area.uuid);
    areaRangeSerie.remove();
    await dispatch(
      updateYXyVariable(
        storeVariable,
        {
          areaRangeParams: [...storeVariable.areaRangeParams.filter(oldArea => oldArea.uuid !== area.uuid)],
          areaRangeSeries: [...storeVariable.areaRangeSeries.filter(oldArea => oldArea.uuid !== area.uuid)],
        },
        storeTab,
      ),
    );
  };

export const redrawAreaAfterReorder =
  (tab: ITab<IXyChart>, variable: IXyVariable, areas: XyAreaRangeParams[]): TypedThunk<Promise<void>> =>
  async dispatch => {
    const storeTab = dispatch(getStoreTab(tab.uuid));
    if (!IsXyChartTab(storeTab)) return;
    const storeVariable = dispatch(getStoreYXyVariable(variable, tab.uuid));
    storeVariable.areaRangeSeries.forEach(s => s.remove());
    const newAreaSeries = areas.map(
      area =>
        storeTab.chart.daChart.addSeries({
          style: {
            type: 'arearange',
          },
          uuid: area.uuid,
          name: area.name,
          color: area.color,
          data: [
            [storeTab.chart.daChart.xAxis[0].getExtremes().dataMin, Number(area.left.min), Number(area.left.max)],
            [storeTab.chart.daChart.xAxis[0].getExtremes().dataMax, Number(area.right.min), Number(area.right.max)],
          ],
          area: true,
          hidden: !storeVariable.daSeries.visible,
          yAxis: storeVariable.daSeries.yAxis,
          disableBoost: true,
        }) as DaArearangeSeries,
    );
    await dispatch(
      updateYXyVariable(
        storeVariable,
        {
          areaRangeParams: areas,
          areaRangeSeries: newAreaSeries,
        },
        storeTab,
      ),
    );
  };

export const updateYXyVariable =
  (variable: IXyVariable, options: IXyVariableProps, tab: ITab<IXyChart>): TypedThunk<Promise<void>> =>
  async dispatch => {
    dispatch(xychartUpdateYvariable({ tabUuid: tab.uuid, variableUuid: variable.uuid, options }));
    if (options.color) {
      variable.daSeries.setColor(options.color);
    }
    if (options.unit != null) {
      variable.daSeries.setUnit(options.unit, false);
      variable.regressionSeries.update({
        type: 'line',
        tooltip: {
          valueSuffix: options.unit,
        },
      });
      if (variable?.areaRangeSeries) {
        variable.areaRangeSeries.forEach(areaRangeSerie => {
          areaRangeSerie.update({
            type: 'arearange',
            tooltip: {
              valueSuffix: options.unit,
            },
          });
        });
      }
    }
    if (options.min !== undefined && options.max !== undefined) {
      variable.daSeries.yAxis.setExtremes(options.min, options.max);
    }
    if (options.regression !== undefined) {
      variable.regressionSeries.setVisible(Boolean(options.regression));
      await getXyVariableData(variable, tab);
    }
    if (options.expression) {
      await dispatch(
        updateYXyVariable(
          variable,
          { name: options.expression.nickname?.length ? options.expression.nickname : calculationToString(options.expression) },
          tab,
        ),
      );
      if (isXVariableIndex(tab.chart.xVariable)) {
        await dispatch(fetchXyChartData(tab));
      } else {
        await getXyVariableData(variable, tab);
      }
    }
    if (options.name) {
      variable.daSeries.setName(options.name);
    }
    if (options.styledRules) {
      variable.daSeries.setStyledRules(options.styledRules);
    }
    if (options.visible !== undefined) {
      variable.daSeries.setVisibility(options.visible);
      variable.regressionSeries.setVisibility(Boolean(options.visible && variable.regression));
      if (variable?.areaRangeSeries) {
        variable.areaRangeSeries.forEach(areaSerie => areaSerie.setVisibility(options.visible));
      }
      // variable.daSeries.yAxis.update({
      //   visible: options.visible,
      // });
    }
    variable.daSeries?.chart.redraw();
  };

export const updateXXyVariable =
  (variable: IXyVariable, options: IXyVariableProps, tab: ITab<IXyChart>): TypedThunk<Promise<void>> =>
  async dispatch => {
    dispatch(xychartUpdateXvariable({ tabUuid: tab.uuid, options }));
    // if (options.unit != null) {
    tab.chart.daChart.setUnit(options.unit);
    // }
    if (options.expression) {
      await dispatch(
        updateXXyVariable(
          variable,
          { name: options.expression.nickname?.length ? options.expression.nickname : calculationToString(options.expression) },
          tab,
        ),
      );
      await dispatch(fetchXyChartData(tab));
    }
  };

// OTHERS

export const createXyArea = ({
  uuid = NewUUID(),
  name = '',
  color = '#777c7e',
  left = null,
  right = null,
}: Partial<XyAreaRangeParams> = {}): XyAreaRangeParams => ({
  uuid,
  name,
  color,
  left,
  right,
});

export const createXyVariable = ({
  uuid = uuidv4(),
  name = '',
  expression = createCalculationVariable(),
  daSeries = null,
  unit = null,
  min = null,
  max = null,
  color = chartColors[0],
  visible = true,
  regression = false,
  regressionSeries = null,
  regressionParams = null,
  areaRangeParams = null,
  areaRangeSeries = null,
  styledRules = [],
}: IXyVariableProps = {}): IXyVariable => ({
  ...createVariable({ uuid, name: name || expression.nickname || calculationToString(expression) }),
  expression,
  daSeries,
  unit,
  min,
  max,
  color,
  visible,
  regression,
  regressionSeries,
  regressionParams,
  areaRangeParams,
  areaRangeSeries,
  styledRules,
});

export const exportXyVariable = (variable: IXyVariable): IXyVariable => {
  if (!variable) return null;
  return { ...variable, daSeries: null, regressionSeries: null, regressionParams: null, areaRangeSeries: null };
};

export const getXyVariableData = async (currentVariable: IXyVariable, currentTab: ITab<IXyChart>): Promise<void> => {
  const { dispatch, getState } = store;
  const tab = dispatch(getStoreTab(currentTab.uuid));
  const variable = dispatch(getStoreYXyVariable(currentVariable, currentTab.uuid));
  if (!variable || !IsXyChartTab(tab)) return;
  const { expression, regression } = variable;
  const { groupBy, xVariable } = tab.chart;
  const {
    auth: {
      selectedGroup: { uuid: groupId, sites },
      selectedSite,
    },
    variables: { byId },
    period: {
      present: { period },
    },
  } = getState();
  if (!xVariable) return;
  const timeZone = selectedSite ? selectedSite.timeZone : sites?.at(0).timeZone ?? undefined;
  let autoGroupByReplacement = groupBy;
  if (autoGroupByReplacement === undefined || autoGroupByReplacement === AUTO_GROUPBY) {
    autoGroupByReplacement = getGroupBy(new Date(period.from), new Date(period.to), null, MAX_POINTS, MAX_POINTS);
  }
  const exp = cloneDeep({ ...expression, autoGroupByReplacement, period: period.Raw(), timeZone });
  let xExp: CalculationVariable<VarCalc>;
  if (isXVariableIndex(xVariable)) {
    xExp = cloneDeep({
      ...{
        exp: 'index',
        vars: {
          var_0: {
            exp: tab.chart.yVariables.map((_, i) => `var_${i}`).join(' + '),
            vars: tab.chart.yVariables.reduce((acc, xVar, index) => {
              return { ...acc, [`var_${index}`]: xVar.expression };
            }, {}),
          },
        },
        autoGroupByReplacement,
      },
      period: period.Raw(),
      timeZone,
    });
  } else {
    xExp = cloneDeep({ ...xVariable.expression, autoGroupByReplacement, period: period.Raw(), timeZone });
  }
  try {
    const { data } = await sdk.data.XY(groupId, {
      x: xExp,
      y: [exp],
    });
    const regData: LinearRegressionResult = regression
      ? (
          await sdk.data.LinearRegression(groupId, {
            period: period.Raw(),
            x: xExp,
            y: exp,
            regressionType: regression,
          })
        ).data
      : {
          formula: '',
          points: [],
          coefficients: [],
          r2: null,
        };

    const firstYVar = getSingleVariableCalculation(exp);
    if (variable.unit == null && IsDataVariable(firstYVar)) {
      try {
        const { unit: yUnit } = byId[firstYVar.variableUuid];
        await dispatch(updateYXyVariable(variable, { unit: yUnit }, tab));
      } catch (err) {
        //
      }
    }
    const firstXVar = getSingleVariableCalculation(xExp);
    if (!isXVariableIndex(xVariable) && xVariable.unit == null && IsDataVariable(firstXVar)) {
      try {
        const { unit: xUnit } = byId[firstXVar.variableUuid];
        await dispatch(updateXXyVariable(variable, { unit: xUnit }, tab));
      } catch (err) {
        //
      }
    }
    const allPoints: PointOptionsObject[] = [];
    let dataMin: number;
    let dataMax: number;
    data.points.forEach(result => {
      result.forEach(p => {
        if (dataMin === undefined || p.x < dataMin) dataMin = p.x;
        if (dataMax === undefined || p.x > dataMax) dataMax = p.x;
        allPoints.push({ x: p.x, y: p.y, custom: { time: localizedFormat(new Date(p.time), 'eee PPpp') } });
      });
    });
    const updatedVariable = dispatch(getStoreYXyVariable(currentVariable, currentTab.uuid));
    updatedVariable.daSeries.addData(allPoints);
    updatedVariable.regressionSeries.addData(regData.points);
    updatedVariable.areaRangeSeries.forEach(areaRangeSerie => {
      const areaParam = updatedVariable.areaRangeParams?.find(p => p.uuid === areaRangeSerie.uuid);
      if (areaParam) {
        areaRangeSerie.addData([
          [dataMin, Number(areaParam.left.min), Number(areaParam.left.max)],
          [dataMax, Number(areaParam.right.min), Number(areaParam.right.max)],
        ]);
      }
    });
    await dispatch(
      updateYXyVariable(
        updatedVariable,
        {
          regressionParams: {
            formula: regData.formula,
            r2: regData.r2,
            coefficients: regData.coefficients,
          },
          daSeries: updatedVariable.daSeries,
          regressionSeries: updatedVariable.regressionSeries,
          areaRangeSeries: updatedVariable.areaRangeSeries,
        },
        tab,
      ),
    );
  } catch (err) {
    console.error(err);
    dispatch(displaySdkErrorToast(err));
  }
};

export const getStoreYXyVariable =
  (variable: IXyVariable, tabUuid?: UUID): TypedThunk<IXyVariable> =>
  (dispatch, getState) => {
    const { tabs, selectedTab } = getState().playground;
    const tabIndex = tabs.findIndex(t => t.uuid === (tabUuid ?? selectedTab));
    const tab = tabs[tabIndex];
    if (IsXyChartTab(tab)) {
      return tab.chart.yVariables.find(v => v.uuid === variable.uuid) as IXyVariable;
    }
    return null;
  };

export const getStoreXXyVariable =
  (tabUuid?: UUID): TypedThunk<IXyChart['xVariable']> =>
  (dispatch, getState) => {
    const { tabs, selectedTab } = getState().playground;
    const tabIndex = tabs.findIndex(t => t.uuid === (tabUuid ?? selectedTab));
    const tab = tabs[tabIndex];
    if (IsXyChartTab(tab) && tab.chart.xVariable) {
      return tab.chart.xVariable;
    }
    return null;
  };
