import { TabType } from '@dametis/core';

import { ThresholdDirection } from '../../types';

export default {
  title: {
    playground: 'Playground',
    playground_withName: 'Playground "{{name}}"',
    template: 'Playground template',
    template_withName: 'Playground template "{{name}}"',
    xAxes: 'X-axes',
    yAxes: 'Y-axes',
    xAxis: 'X-axis',
    yAxis: 'Y-axis',
    responseVariable: 'Response variable',
    explanatoryVar: 'Explanatory variables',
    drawArea: 'Areas',
    maths: 'Maths',
    create: 'Create',
    home: 'Choose a first tab for your playground:',
    noTabs: 'No tabs',
    variableStats: 'Statistics',
    variableSettings: 'Settings',
    variableStyle: 'Style',
    variableThreshold: 'Threshold',
    variableModelisation: 'Modelling',
    variableCovariances: 'Influencing variables',
    regression: 'Regression',
    regression_NONE: 'Aucune',
    regression_LINEAR: 'Linear regression',
    regression_POLYNOMIAL2: 'Polynomial regression X²',
    regression_POLYNOMIAL3: 'Polynomial regression X³',
    regression_POLYNOMIAL4: 'Polynomial regression X⁴',
    regression_EXPONENTIAL: 'Exponential regression',
    regression_LOGARITHMIC: 'Logarithmic regression',
    export: 'Export',
    data: 'Data',
    subperiods: 'Sub-periods',
    tabQueryLanguage: `"{{name}}" tab's settings`,
    createModel: 'New model',
    editModel: 'Edit model',
    customPeriods: 'Custom periods',
    addCommentToVar: 'Add a comment to the variable "{{var}}"',
  },
  subtitle: {
    variables: 'Variables',
    timeRange: 'Time range',
    selectedVariable: '{{name}}',
    home1: 'or click on the',
    home2: 'button for the other types.',
    groupBy: 'Frequency',
    variable: 'Variable',
    linearRegFormula: 'Formula',
    linearRegR2: 'r²',
    rules: 'Rules',
    addTyVariable: 'Add a first variable',
    addBarVariable: 'Add a first variable',
    addHistogramVariable: 'Select the variable',
    addYXyVariable: 'Add a first variable to the Y axis',
    addXXyVariable: 'Select the X axis variable',
    noChart: 'Edit this playground to add variables',
    exportFormat: 'File format',
    minValue: 'min : {{min}}',
    maxValue: 'max : {{max}}',
  },
  tab: {
    data: 'Data',
    tools: 'Tools',
    statistics: 'Statistics',
    threshold: 'Threshold',
    modelisation: 'Modelling',
    customPeriods: 'Periods',
  },
  button: {
    edit: 'Edit',
    saved: 'Saved',
    saveAs: 'Duplicate',
    delete: 'Delete',
    openInPlayground: 'Playground',
    previewBatch: 'Preview',
    createTab: 'Create',
    cancel: 'Cancel',
    download: 'Download',
    configuration: 'Configuration',
    addVariable: 'Add',
    addArea: 'Add',
    addStyledRule: 'Rule',
    addStyleConfiguration: 'List',
    createCalculatedVariable: 'Calculated variable',
    closeQueryLanguageDialog: 'Ok',
    createModel: 'New model',
    saveModel: 'Save model',
    exportModel: 'Export model',
    addPeriod: 'Add',
    share: 'Share',
    editCovariances: 'Edit list',
    saveStyleConfiguration: 'List',
    png: 'PNG',
  },
  text: {
    addTabOfType: 'Add a tab of type:',
    tabTypes_XY_CHART: 'XY line chart',
    tabTypes_TY_CHART: 'Timeline chart',
    tabTypes_BAR_CHART: 'Bar chart',
    tabTypes_PIE_CHART: 'Pie chart',
    tabTypes_HISTOGRAM: 'Histogram',
    tabTypes_LINEAR_REG: 'Linear regression',
    tabTypes_LIVE_CHART: 'Live chart',
    tabTypes_MULTI_LINEAR_REG: 'Multiple linear regression',
    tabTypes_TABLE: 'Table of values',
    [`tabTypes_${TabType.NONE}`]: 'None',
    noVariables: 'No variables',
    noVariable: 'No variable',
    xIndex: 'Index is used as X-axis',
    noStyledRules: 'No styled rules',
    mean: 'Mean',
    min: 'Min',
    max: 'Max',
    integral: 'Integral',
    stddev: 'Standard deviation',
    gap: 'Gap',
    thresholdNb: 'Number of overruns',
    thresholdTime: 'Total duration of overruns',
    thresholdIntegral: 'Integral',
    thresholdValue: 'Value',
    thresholdDelay: 'Delay',
    threshold: {
      [ThresholdDirection.ABOVE]: 'Above',
      [ThresholdDirection.UNDER]: 'Under',
    },
    from: 'From',
    to: 'To',
    date: 'Date',
    newGroup: 'New group',
    newPlayground: 'New playground',
    newModel: 'New model',
    duration: 'Duration',
    noModels: 'No models',
    multipleBatchTY: 'Multiple periods',
    modelHelper: 'Multiple linear regression are now accessible via ',
    commentDate: 'On {{date}}',
    restrictedEdition: 'You can edit but you cannot save your changes',
  },
  input: {
    label: {
      name: 'Name',
      period: 'Period',
      formula: 'Formula',
      groupBy: 'Sub-periods',
      from: 'From',
      to: 'To',
      customTimeRange: 'Custom for this tab',
      previewMin: 'min',
      previewMax: 'max',
      step: 'Step',
      placeholderVariable: 'Select a variable...',
      xXyVariable: 'X axis variable',
      yXyVariable: 'Y axis variables',
      areaRangeLeft: 'Left ',
      areaRangeRight: 'Right ',
      histogramVariable: 'Variable',
      batch: 'Batch',
      min: 'min',
      Min: 'Min',
      max: 'max',
      Max: 'Max',
      format: 'Format',
    },
    placeholder: {
      previewMin: 'auto',
      previewMax: 'auto',
      step: 'auto',
      selectBatch: 'Select...',
      excel: 'Excel',
    },
    value: {
      noVariable: 'No variable',
      queryLanguageDisabledTitle: 'One tab for the block',
      queryLanguageDisabledDescription: "Default. You will be able to select the block's variables.",
      queryLanguageEnabledTitle: "One tab per block's child",
      queryLanguageEnabledDescription:
        "During editing, you will be able to select the variables of the block's children. After saving, the tab will multiply for each block's children.",
      ofAllTypes: 'Every children',
      onlyOfType: 'Only of type...',
      standard: 'Variable',
      index: 'Index',
    },
    helperText: {
      groupBy: 'Select a frequency',
      batch: 'Select a batch',
    },
  },
  tooltip: {
    createPlayground: 'create a playground',
    savePlayground: 'save this playground',
    editPlayground: 'edit this playground',
    duplicatePlayground: 'duplicate this playground',
    sharePlayground: 'share this playground',
    deletePlayground: 'delete this playground',
    timeRangeDisabledFor_customTimeRange:
      'The global time range is disabled for this tab because of the custom time range activated in Data > X-axis.',
    timeRangeDisabledFor_customPeriod:
      'The global time range is disabled for this tab because of the custom time range activated in Periods > Custom Periods.',
    zoomOut: 'Zoom out',
    showTooltips: 'Show tooltips',
    hideTooltips: 'Hide tooltips',
    showYAxis: 'Show the ordinates',
    hideYAxis: 'Hide the ordinates',
    disconnectNulls: 'Keep data gaps',
    connectNulls: 'Fill data gaps',
    connectNullsTemporaryUnavailable: 'This feature is temporary unavailable',
    fullscreen: 'Fullscreen mode',
    exitFullscreen: 'Exit fullscreen mode',
    addBarVariable: 'Add a first variable',
    addTyVariable: 'Add a first variable',
    addHistogramVariable: 'Select the variable',
    addYXyVariable: 'Add a first variable to the Y axis',
    addXXyVariable: 'Select the X axis variable',
    closeTab: 'Close tab',
    dragDrop: 'Drag and drop',
    cantCreateTab: 'You cannot create more than 5 new tabs',
    displayGroup: 'Display group',
    hideGroup: 'Hide group',
    hideVariable: 'Hide variable',
    hideVariableTy: 'Hide variable on batch preview',
    displayVariable: 'Display variable',
    displayVariableTy: 'Display variable on batch preview',
    disableVariable: 'Disable variable',
    enableVariable: 'Enable variable',
    deleteVariable: 'Delete variable',
    duplicateVariable: 'Duplicate variable',
    undoPeriod: 'Previous selected period',
    redoPeriod: 'Next selected period',
    editVariable: 'Edit variable',
    deleteTab: 'Delete tab',
    queryLanguageEnabled: "One tab per block's child. Set up...",
    queryLanguageDisabled: 'One tab for the block. Set up...',
    deletePeriod: 'Delete period',
    editPeriod: 'Edit period',
    addVariableToChart: 'Add variable to chart',
    deleteInfluencingVariable: 'Remove variable from list',
    openInPlayground: 'Open in a playground',
    addStyledRule: 'Add a rule',
    saveStyleConfiguration: 'Save these rules in a list',
    addStyleConfiguration: 'Use a list of rules',
    fromBarChart: 'Transform bars into line',
    fromLineChart: 'Transform line into bars',
  },
  alert: {
    liveChartCustomTimeRange: "Live chart's time range is not modifiable and defaults to the last 5 minutes.",
    computationStats_CUSTOMPERIOD: 'Statistics are not available in custom periods mode.',
    modelisation: 'Modelling is not available for a calculation.',
    threshold: 'Thresholds are not available for a calculation.',
  },
  xgrid: {
    noData: 'No sub-periods',
    footerPagination: 'Sub-periods per page',
    footerTotalRun: 'Total sub-periods',
  },
};
