import { Box, Paper, Stack, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import { FC, useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Resizable, ResizableProps } from 'react-resizable';
import 'react-resizable/css/styles.css';

import ResizeHandler from 'components/UI/ResizeHandler/ResizeHandler';
import { WipFeatures } from 'config/featureFlags';
import { getScrollBarWidth } from 'functions/getScrollbarWidth';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { usePermission } from 'hooks/usePermission';
import { useVncStore } from 'zustand/stores/vnc';

import SmallToggleButton from '../../../../UI/SmallToggleButton/SmallToggleButton';
import { PropsContext } from '../../../context';
import FunctionsButton from '../Functions/FunctionsButton';

import Monaco from './Monaco';
import Slate from './Slate';

const scrollbarWidth = getScrollBarWidth();

interface Props {
  readOnly?: boolean;
}

const Textarea: FC<Props> = ({ readOnly = false }) => {
  const { t } = useTranslation('vnc');

  const canEditVncJson = usePermission('canEditVncJson');
  const enableFunction = useFeatureFlags(WipFeatures.ENABLE_FUNCTION);
  const textareaMode = useVncStore(state => state.textareaMode);
  const setTextareaMode = useVncStore(state => state.setTextareaMode);

  const [height, setHeight] = useState(100);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const { disableMaths } = useContext(PropsContext);

  const toggleJsonMode = useCallback<ToggleButtonGroupProps['onChange']>(
    (event, value) => {
      if (!value) return;
      setTextareaMode(value);
    },
    [setTextareaMode],
  );

  const handleResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleResizeStop = useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = useCallback<ResizableProps['onResize']>((_, { size }) => setHeight(size.height), []);

  return (
    <Box sx={{ position: 'relative', height: 1 }}>
      <Resizable
        handle={<ResizeHandler isResizing={isResizing} sx={{ position: 'absolute', zIndex: 1, mt: '-4px', borderRadius: `0 0 4px 4px` }} />}
        height={height}
        maxConstraints={[9999, window.innerHeight - 300]}
        minConstraints={[9999, 100]}
        resizeHandles={['s']}
        width={9999}
        onResize={resize}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
      >
        <Box sx={{ height, minHeight: '100%' }}>
          {textareaMode === 'json' ? <Monaco readOnly={readOnly} /> : <Slate readOnly={readOnly} />}
        </Box>
      </Resizable>
      <Stack
        alignItems="center"
        component={Paper}
        direction="row"
        spacing={0.5}
        sx={{
          position: 'absolute',
          right: scrollbarWidth + 2,
          bottom: 2,
          p: 0.5,
          backgroundColor: 'background.default',
        }}
      >
        {enableFunction && !disableMaths && textareaMode === 'slate' && !readOnly && <FunctionsButton />}
        {canEditVncJson && (
          <ToggleButtonGroup exclusive size="small" value={textareaMode} onChange={toggleJsonMode}>
            <SmallToggleButton sx={{ p: theme => `${theme.spacing(1)}!important` }} value="slate">
              {t('button.slate')}
            </SmallToggleButton>
            <SmallToggleButton sx={{ p: theme => `${theme.spacing(1)}!important` }} value="json">
              {t('button.json')}
            </SmallToggleButton>
          </ToggleButtonGroup>
        )}
      </Stack>
    </Box>
  );
};

export default Textarea;
