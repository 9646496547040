import { AddCommentOutlined } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateCommentOnVariableBody, IsDataVariable } from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import CommentModal from 'components/UI/Comments/CommentModal/CommentModal';
import CommentsProvider from 'components/UI/Comments/context/CommentsContext';
import { calculationToString } from 'functions/calculationToString';
import { useSelector } from 'store';
import { useCreateCommentMutation } from 'store/api/comments';
import { EntityType } from 'types/comment';

import CalculationMenuItem from '../CalculationMenuItem';

const AddCommentOption: FC = () => {
  const { t } = useTranslation('calculationMenu');

  const [createComment, { isLoading: isPosting }] = useCreateCommentMutation();

  const calculation = useSelector(state => state.calculationMenu.calculation);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const singleVariable = useMemo(() => getSingleVariableCalculation(calculation), [calculation]);
  const isEnabled = useMemo(() => singleVariable && IsDataVariable(singleVariable), [singleVariable]);

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCreateComment = useCallback(
    async (comment: CreateCommentOnVariableBody) => {
      if (!IsDataVariable(singleVariable)) {
        return;
      }
      await createComment({ comment, entity: EntityType.VARIABLE, entityUuid: singleVariable.variableUuid });
    },
    [singleVariable, createComment],
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <CalculationMenuItem
        disabled={!isEnabled || isPosting}
        icon={AddCommentOutlined}
        label={t('label.addComment')}
        tooltip={!isEnabled ? t('tooltip.disableAddComment') : undefined}
        tooltipProps={{ placement: 'right' }}
        onClick={handleOpenModal}
      />
      <CommentsProvider entity={EntityType.VARIABLE} entityUuid={IsDataVariable(singleVariable) ? singleVariable.variableUuid : undefined}>
        <CommentModal
          datePicker
          isMessageRequired
          entity={EntityType.VARIABLE}
          entityUuid={IsDataVariable(singleVariable) ? singleVariable.variableUuid : undefined}
          open={isModalOpen}
          title={t('label.addCommentToVar', { var: calculationToString(calculation) })}
          onClose={handleCloseModal}
          onCreate={handleCreateComment}
        />
      </CommentsProvider>
    </>
  );
};

export default AddCommentOption;
