import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogTitle, useTheme } from '@mui/material';
import { Dispatch, FC, FormEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateFolderBody, FolderInfo, UUID } from '@dametis/core';

import { createCreateFolderBody } from 'components/Lego/helpers/createCreateFolderBody';
import { getFolderColor } from 'components/Lego/helpers/getFolderColor';
import AnimatedHeightDialogContent from 'components/UI/AnimatedHeightDialogContent/AnimatedHeightDialogContent';
import { useDispatch } from 'store';
import { useCreateFolderMutation } from 'store/api/folders';
import { useCreateGlobalFolderMutation } from 'store/api/globalFolders';
import { addToast } from 'store/slices/toast';
import { ToastSeverity } from 'types';

import StepInformations from './StepInformations';

export interface CreateFolderModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit?: (newFolder: FolderInfo, parendId: UUID) => void;
  parentId?: UUID;
  isGlobal?: boolean;
}

const CreateFolderModal: FC<CreateFolderModalProps> = ({
  isOpen,
  setIsOpen,
  onSubmit = undefined,
  parentId = undefined,
  isGlobal = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('lego');
  const theme = useTheme();

  const [createFolder, { isLoading: isCreatingSiteFolder }] = useCreateFolderMutation();
  const [createGlobalFolder, { isLoading: isCreatingGlobalFolder }] = useCreateGlobalFolderMutation();

  const [createFolderBody, setCreateFolderBody] = useState<CreateFolderBody>(createCreateFolderBody);

  const isFolderValid = useMemo(() => createFolderBody.name.trim().length > 0, [createFolderBody]);
  const isCreating = useMemo(() => isCreatingSiteFolder || isCreatingGlobalFolder, [isCreatingSiteFolder, isCreatingGlobalFolder]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async event => {
      event.preventDefault();
      try {
        const data = await (isGlobal ? createGlobalFolder(createFolderBody).unwrap() : createFolder(createFolderBody).unwrap());
        if (onSubmit) {
          onSubmit(data, createFolderBody.parentUuid);
        }
        setIsOpen(false);
        dispatch(addToast({ message: t('toast.createBlockFolderSuccess'), severity: ToastSeverity.SUCCESS }));
      } catch (error) {
        console.error(error);
      }
    },
    [createFolder, isGlobal, createGlobalFolder, createFolderBody, dispatch, onSubmit, setIsOpen, t],
  );

  const handleClose = useCallback(() => {
    if (!isCreating) {
      setIsOpen(false);
    }
  }, [setIsOpen, isCreating]);

  useEffect(() => {
    if (isOpen) {
      setCreateFolderBody(createCreateFolderBody({ parentUuid: parentId, ui: { color: getFolderColor(null, theme) } }));
    }
  }, [isOpen, theme, parentId]);

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={isOpen} onClose={handleClose}>
        <DialogTitle>{t('title.createFolderModal')}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <AnimatedHeightDialogContent>
            <StepInformations<CreateFolderBody> folderBody={createFolderBody} setFolderBody={setCreateFolderBody} />
          </AnimatedHeightDialogContent>
          <DialogActions>
            <Button color="primary" disabled={isCreating} variant="text" onClick={handleClose}>
              {t('button.close')}
            </Button>
            <LoadingButton color="secondary" disabled={!isFolderValid} loading={isCreating} type="submit" variant="contained">
              {t('button.create')}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CreateFolderModal;
