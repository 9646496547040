import { CalendarTodayOutlined, WarningAmberOutlined } from '@mui/icons-material';
import { Button, Tooltip, Stack, Badge } from '@mui/material';
import { FC, MouseEventHandler, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Period } from '@dametis/core';

import { isAfter, isBefore, localizedFormat } from 'localization/localizedDateFns';
import { useSelector } from 'store';
import { CommentFilter } from 'types/comment';

import { CommentsContext } from '../Comments/context/CommentsContext';

import DateTimeRangePopper from './DateTimeRangePopper';

interface Props {
  from: Date;
  to: Date;
  onChange: ([from, to]: Array<Date>) => void;
  inputFormat?: string;
  disabled?: boolean;
  editing?: boolean;
}

const DateTimeRangeSetter: FC<Props> = ({ from, to, editing = false, onChange, inputFormat = null, disabled = false }) => {
  const { t } = useTranslation('comment');
  const openBtnEl = useRef<HTMLButtonElement>(null);
  const [openBtn, setOpenBtn] = useState<boolean>(false);
  const [period, setPeriod] = useState<Period>(new Period({ from, to }));
  const storePeriod = useSelector(state => state.period.present.period);
  const { periodFilter, entity } = useContext(CommentsContext);

  const shouldDisable = useMemo(() => !editing && storePeriod.AlmostEquals(new Period({ from, to })), [editing, from, storePeriod, to]);

  const shouldDisplayWarning = useMemo(
    () =>
      periodFilter === CommentFilter.CURRENT_PERIOD &&
      (isAfter(new Date(period.from), new Date(storePeriod.to)) || isBefore(new Date(period.to), new Date(storePeriod.from))),
    [period.from, period.to, periodFilter, storePeriod.from, storePeriod.to],
  );

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (editing) setOpenBtn(!openBtn);
    else onChange([period.from, period.to]);
  };

  useEffect(() => {
    setPeriod(new Period({ from, to }));
  }, [from, to]);

  return (
    <Stack>
      <Tooltip
        placement="bottom-start"
        title={editing ? '' : t(shouldDisable ? 'tooltip.samePeriod' : 'tooltip.goToPeriod', { entity: t(`label.entity.${entity}`) })}
      >
        <div>
          <Button
            ref={openBtnEl}
            disabled={disabled || shouldDisable}
            startIcon={
              <Badge
                badgeContent={
                  <Tooltip title={t('text.periodFilterWarning')}>
                    <WarningAmberOutlined
                      color="warning"
                      sx={theme => ({
                        fontSize: '18px !important',
                        backgroundColor: theme.palette.background.defaultTransparent,
                      })}
                    />
                  </Tooltip>
                }
                invisible={!editing || !shouldDisplayWarning}
              >
                <CalendarTodayOutlined sx={{ fontSize: '18px !important' }} />
              </Badge>
            }
            sx={{ lineHeight: 1.3, textTransform: 'lowercase', textAlign: 'right', fontSize: '12px' }}
            onClick={handleClick}
          >
            <Stack flexDirection="row" flexWrap="wrap">
              <Stack flexWrap="nowrap" justifyContent="space-between" spacing={2}>
                {localizedFormat(new Date(period.from), 'eee PP')} {localizedFormat(new Date(period.from), 'pp')}
              </Stack>
              <Stack sx={{ pr: 0.25, pl: 0.25 }}>-</Stack>
              <Stack flexWrap="nowrap" justifyContent="space-between" spacing={2}>
                {localizedFormat(new Date(period.to), 'eee PP')} {localizedFormat(new Date(period.to), 'pp')}
              </Stack>
            </Stack>
          </Button>
        </div>
      </Tooltip>
      <DateTimeRangePopper
        anchorRef={openBtnEl.current}
        inputFormat={inputFormat}
        open={openBtn}
        period={period}
        placement={{
          vertical: 'top',
          horizontal: 'right',
        }}
        setOpen={setOpenBtn}
        setPeriod={setPeriod}
        onChange={onChange}
      />
    </Stack>
  );
};

export default DateTimeRangeSetter;
