import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UUID } from '@dametis/core';

export interface MacrosState {
  progresses: Record<UUID, { name: string; logs: string; progress: string }>;
}

const initialState: MacrosState = {
  progresses: {},
};

export const macrosSlice = createSlice({
  name: 'macros',
  initialState,
  reducers: {
    setProgresses: (state, action: PayloadAction<Record<UUID, { name: string; logs: string; progress: string }>>) => {
      state.progresses = action.payload;
    },
    clearMacrosStore: () => initialState,
  },
});

export const { setProgresses, clearMacrosStore } = macrosSlice.actions;

export default macrosSlice.reducer;
