import { CancelRounded } from '@mui/icons-material';
import { Box, Chip, ChipProps, Link, SvgIcon, SvgIconProps, Tooltip } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import {
  NanoEntityInfo,
  ShortAlarmInfo,
  ShortPlaygroundInfo,
  ShortProjectInfo,
  ShortReportInfo,
  Shortcut,
  ShortcutCategory,
  SynopticExtendedInfo,
} from '@dametis/core';

import { useAlarms } from 'store/api/alarms';
import { usePlaygrounds } from 'store/api/playgrounds';
import { useProjects } from 'store/api/projects';
import { useReports } from 'store/api/reports';
import { useSynoptics } from 'store/api/synoptics';

import getShortcutTo from '../../../functions/getShortcutTo';

import { getPeriodLabel } from './functions/getPeriodLabel';
import { shortcutConfig } from './shortcutConfig';

const alarmsEmptyArray: ShortAlarmInfo[] = [];
const playgroundsEmptyArray: ShortPlaygroundInfo[] = [];
const projectsEmptyArray: ShortProjectInfo[] = [];
const reportsEmptyArray: ShortReportInfo[] = [];
const synopticsEmptyArray: SynopticExtendedInfo[] = [];

export interface ShortcutChipProps extends Omit<ChipProps, 'onDelete' | 'contextMenu' | 'disabled'> {
  shortcut: Shortcut | null;
  onDelete?: (shortcut: Shortcut, isPeriod: boolean) => ChipProps['onDelete'];
  disabled?: boolean;
  deactivated?: boolean;
  contextMenu?: boolean;
}

const ShortcutChip: FC<ShortcutChipProps> = ({
  shortcut,
  onDelete = undefined,
  disabled = false,
  contextMenu = false,
  deactivated = false,
  sx,
  ...props
}) => {
  const { t, i18n } = useTranslation('shortcut');

  const { data: synoptics = synopticsEmptyArray } = useSynoptics();

  const { data: alarms = alarmsEmptyArray } = useAlarms();
  const { data: playgrounds = playgroundsEmptyArray } = usePlaygrounds();
  const { data: projects = projectsEmptyArray } = useProjects();
  const { data: reports = reportsEmptyArray } = useReports();

  const shortcutComponent = useMemo(() => (shortcut && shortcut.category === ShortcutCategory.HREF ? Link : NavLink), [shortcut]);

  const list: NanoEntityInfo[] = useMemo(() => {
    switch (shortcut?.category) {
      case ShortcutCategory.ALARM:
        return alarms;
      case ShortcutCategory.PLAYGROUND:
        return playgrounds;
      case ShortcutCategory.PROJECT:
        return projects;
      case ShortcutCategory.REPORT:
        return reports;
      case ShortcutCategory.SYNOPTIC:
        return synoptics;
      default:
        return [];
    }
  }, [shortcut, alarms, playgrounds, projects, reports, synoptics]);

  const redirection = useMemo(() => {
    if (!shortcut || deactivated) {
      return null;
    }
    if (shortcut.url && shortcut.category === ShortcutCategory.HREF) {
      return shortcut.url?.length > 0 ? shortcut.url : null;
    }
    return shortcut.url?.length > 0 ? shortcut?.url : getShortcutTo(shortcut);
  }, [deactivated, shortcut]);

  const isValid = useMemo(
    () => !shortcut || list.find(e => e.uuid === shortcut?.uuid) !== undefined || shortcut.category === ShortcutCategory.HREF,
    [list, shortcut],
  );

  const isPeriod = shortcut ? shortcut?.category === ShortcutCategory.PLAYGROUND || shortcut?.category === ShortcutCategory.REPORT : false;

  const TooltipLabel = useMemo(() => {
    if (isValid) {
      if (shortcut?.category === ShortcutCategory.HREF) {
        return shortcut?.label;
      }
      const name = list.find(e => e.uuid === shortcut?.uuid)?.name;
      if (shortcut?.url) {
        const { periodLabel } = getPeriodLabel(shortcut, t, i18n.language);
        return (
          <>
            {name}
            <br />
            <br />
            {periodLabel}
          </>
        );
      }
      return name;
    }
    return t('text.brokenLink');
  }, [isValid, t, shortcut, list, i18n.language]);

  const chipLabel = useMemo(() => {
    if (isValid) {
      if (shortcut?.category === ShortcutCategory.HREF) {
        return shortcut?.label;
      }
      return list.find(e => e.uuid === shortcut?.uuid)?.name;
    }
    return t('text.brokenLink');
  }, [list, shortcut, isValid, t]);

  return (
    <Tooltip placement="top" title={shortcut ? <span style={{ whiteSpace: 'pre-line' }}>{TooltipLabel}</span> : ''}>
      <Box maxWidth="100%">
        <Chip
          clickable={Boolean(!deactivated)}
          component={shortcut && !deactivated ? shortcutComponent : undefined}
          deleteIcon={<CancelRounded />}
          disabled={!shortcut || (!isValid && !deactivated) || disabled}
          href={!contextMenu || !deactivated ? redirection : null}
          icon={
            <SvgIcon
              component={((shortcut && shortcutConfig[shortcut.category]?.icon) as React.ComponentType<SvgIconProps>) || undefined}
              fontSize="small"
            />
          }
          label={shortcut ? chipLabel : t('text.noShortcut')}
          // LRA: normally not needed now that they always open in a new tab
          // onClick={() => {
          //   // when going from report to report with shortcut, store is not clear, which prevents from disabling period in url
          //   if (shortcut?.category === ShortcutCategory.REPORT && shortcut?.uuid !== currentReport?.uuid) {
          //     dispatch(clearReport());
          //   }
          // }}
          sx={[!contextMenu ? { cursor: 'pointer' } : { cursor: 'context-menu' }, ...(Array.isArray(sx) ? sx : [sx])]}
          target="_blank"
          to={!contextMenu || !deactivated ? redirection : null}
          variant="link"
          onDelete={shortcut && onDelete && onDelete(shortcut, isPeriod)}
          {...props}
        />
      </Box>
    </Tooltip>
  );
};

export default ShortcutChip;
