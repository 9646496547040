import { RuleOperator } from '@dametis/core';

export default {
  tooltip: {
    deleteRule: 'Suprimir la regla',
    concat: 'Concatenar el texto de reemplazo cuando se cumplen varias reglas',
    concatShort: 'Concatenar el texto',
    resetStyledRuleStyle: 'Reiniciar el estilo',
    duplicateStyledRule: 'Duplicar la regla',
    deleteStyledRule: 'Suprimir la regla',
    fontColor: 'Color del texto',
    borderColor: 'Color del borde',
    fillColor: 'Color de fondo',
  },
  title: {
    styleConfigurationModal: 'Listas de reglas',
  },
  text: {
    emptySelect: 'Regla no definida.',
    value: 'Valor',
    valueWithUnit: 'Valor ({{unit}})',
    operator: 'Operador',
    altText: 'Texto de reemplazo',
    when: 'Cuando...',
    do: 'Aplicar...',
    noRule: 'Ninguna regla',
    rulesLength_zero: 'Ninguna regla',
    rulesLength_one: '1 regla',
    rulesLength_other: '{{count}} reglas',
    otherRulesLength_one: '+{{count}} regla',
    otherRulesLength_other: '+{{count}} reglas',
    withContent: 'contenido activado',
    withoutContent: 'contenido desactivado',
    withStyle: 'estilo activado',
    withoutStyle: 'estilo desactivado',
    noStyleConfiguration: 'Ninguna lista de reglas guardada en este sitio',
    orderDescription: 'El orden de prioridad de las reglas de estilo es de arriba abajo. Puedes organizarlas arrastrando y soltando.',
    shortOrderDescription: 'El orden de prioridad de las reglas de estilo es de arriba abajo.',
    noOperatorSymbol: '∅',
  },
  rule: {
    [RuleOperator.EQUAL_TO]: {
      label: 'igual a',
      label_withValue: 'igual a {{value}}',
    },
    [RuleOperator.NOT_EQUAL_TO]: {
      label: 'no igual a',
      label_withValue: 'no igual a {{value}}',
    },
    [RuleOperator.GREATER_THAN]: {
      label: 'superior a',
      label_withValue: 'superior a {{value}}',
    },
    [RuleOperator.GREATER_THAN_OR_EQUAL_TO]: {
      label: 'superior o igual a',
      label_withValue: 'superior o igual a {{value}}',
    },
    [RuleOperator.LESS_THAN]: {
      label: 'inferior a',
      label_withValue: 'inferior a {{value}}',
    },
    [RuleOperator.LESS_THAN_OR_EQUAL_TO]: {
      label: 'inferior o igual a',
      label_withValue: 'inferior o igual a {{value}}',
    },
    [RuleOperator.BIT_IS_SET]: {
      label: 'bit establecido en la posición',
      label_withValue: 'bit en la posición {{value}} está establecido',
    },
    [RuleOperator.BIT_IS_UNSET]: {
      label: 'bit no establecido en la posición',
      label_withValue: 'bit en la posición {{value}} no está establecido',
    },
  },
  button: {
    close: 'Cerrar',
    previous: 'Anterior',
    next: 'Siguiente',
    submit: 'Validar',
    cancel: 'Cancelar',
    downloadFileTemplate: 'Modelo {{type}}',
  },
  stepper: {
    creation: 'Creación',
    selection: 'Selección',
    settings: 'Ajustes',
  },
  label: {
    withStyle: 'Utilizar el estilo (cursiva, negrita, subrayado, colores)',
    withContent: 'Utilizar el contenido (texto, icono)',
  },
};
