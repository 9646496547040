import { alpha, GlobalStyles } from '@mui/material';

// Weird, but has to be exported like that: https://mui.com/customization/how-to-customize/#5-global-css-override
export const globalStyles = (
  <GlobalStyles
    styles={theme => ({
      '::selection': {
        backgroundColor: alpha(theme.palette.secondary.light, 0.5),
      },
      '.block-picker > div:nth-of-type(2)': {
        height: '55px !important',
      },
      '.block-picker > div:nth-of-type(2) > div': {
        display: 'none',
      },
      '.block-picker > div:last-of-type': {
        paddingBottom: '0 !important',
      },
      '.block-picker > div:last-of-type > div:last-of-type': {
        display: 'none',
      },
      blockquote: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
        borderLeft: `4px solid ${theme.palette.divider}`,
        margin: 0,
        padding: `${theme.spacing(0.25)} ${theme.spacing(1)}`,
      },
      pre: {
        margin: 0,
      },
      code: {
        fontFamily: '"Ubuntu Mono", monospace',
        fontSize: '1.07em',
      },
      a: {
        textDecoration: 'none',
        color: 'inherit',
      },
    })}
  />
);
