import { Done } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import Fuse from 'fuse.js';
import { format } from 'numfmt';
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Ecma376Format, Format, FormatCategory, FormatType, PhysicalQuantity } from '@dametis/core';
import { UnitConverter } from '@dametis/unit';

import { dateFormats, numberFormats } from 'config/formats';
import { areSameFormats } from 'functions/areSameFormats';

import FormatList from './FormatList';
import { defaultFormat, useFormatPickerContext } from './FormatPickerContext';
import { isExistingFormatCode } from './functions/isExistingFormatCode';

export interface FormatListsProps {
  inputValue: string;
  setInputValue: Dispatch<SetStateAction<string>>;
  isEmptyValue: boolean;
  onClose: (value: Format) => () => void;
}

const FormatLists: FC<FormatListsProps> = ({ inputValue, setInputValue, isEmptyValue, onClose }) => {
  const { t } = useTranslation('format');

  const { pickerValue, setPickerValue, unit, onChange } = useFormatPickerContext();

  const parsedUnit = useMemo(() => UnitConverter.Parse(unit ?? ''), [unit]);

  const parsedInputValue = useMemo(() => inputValue?.trim() ?? '', [inputValue]);

  const filteringFormats = useCallback(
    (formatList: Format[]) => {
      const searched = parsedInputValue.length !== 0;
      let newDateFormats = [];

      if (searched) {
        const fuse = new Fuse(formatList, {
          keys: [
            'code',
            {
              name: 'category',
              getFn: obj => t(`subheader.${obj.category}`),
            },
          ],
        });
        newDateFormats = fuse.search(parsedInputValue).map(fR => fR.item);
      } else {
        newDateFormats = formatList;
      }

      return newDateFormats;
    },
    [parsedInputValue, t],
  );

  const filteredNumberFormats: Format[] = useMemo(() => filteringFormats(numberFormats), [filteringFormats]);
  const filteredDateFormats: Format[] = useMemo(() => filteringFormats(dateFormats), [filteringFormats]);

  const displayFreeMode = useMemo(
    () => parsedInputValue.length > 0 && !isExistingFormatCode([...filteredNumberFormats, ...filteredDateFormats], parsedInputValue),
    [filteredNumberFormats, filteredDateFormats, parsedInputValue],
  );

  const customFormatValue: Ecma376Format = useMemo(
    () => ({ ...pickerValue, code: parsedInputValue, category: FormatCategory.CUSTOM, type: FormatType.ECMA376 }),
    [pickerValue, parsedInputValue],
  );

  const isCustomFormatValid = useMemo(() => {
    try {
      format(parsedInputValue, 1234.56);
      return true;
    } catch (err) {
      return false;
    }
  }, [parsedInputValue]);

  const handleSelectFormat = useCallback(
    (newValue: Format) => {
      setPickerValue(newValue);
      onChange(newValue);
      onClose(newValue)();
    },
    [setPickerValue, onChange, onClose],
  );

  const handleSelectCustomFormat = useCallback(() => {
    setPickerValue(customFormatValue);
    onChange(customFormatValue);
    setInputValue('');
    onClose(customFormatValue)();
  }, [setPickerValue, setInputValue, onChange, customFormatValue, onClose]);

  const handleClear = useCallback(() => {
    setPickerValue(defaultFormat);
    onChange(null);
    onClose(defaultFormat)();
  }, [setPickerValue, onChange, onClose]);

  // const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(event => {
  //   const bindingKeys = ['ArrowDown', 'ArrowUp', 'Enter'];
  //   if (bindingKeys.includes(event.key)) {
  //     event.preventDefault();
  //     if (event.key === 'ArrowDown') {
  //     }
  //     if (event.key === 'ArrowUp') {
  //     }
  //     if (event.key === 'Enter') {
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   setListsKeyDownHandler(() => handleKeyDown);
  // }, [setListsKeyDownHandler, handleKeyDown]);

  return (
    <>
      <List subheader={<li />} sx={{ height: 300, position: 'relative', overflow: 'auto', '& ul': { padding: 0 } }}>
        <li>
          <ul>
            {displayFreeMode && (
              <ListItem disablePadding>
                <ListItemButton disabled={!isCustomFormatValid} onClick={handleSelectCustomFormat}>
                  <ListItemText
                    primary={
                      isCustomFormatValid
                        ? t('text.newOption', { value: parsedInputValue })
                        : t('text.newOptionIncorrect', { value: parsedInputValue })
                    }
                    secondary={
                      areSameFormats(pickerValue, customFormatValue) && (
                        <Done fontSize="small" sx={{ color: theme => theme.palette.black }} />
                      )
                    }
                    secondaryTypographyProps={areSameFormats(pickerValue, customFormatValue) && { sx: { lineHeight: 0 } }}
                    sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between' }}
                  />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem disablePadding>
              <ListItemButton onClick={handleClear}>
                <ListItemText primary={t('text.noFormatting')} />
                {isEmptyValue && <Done fontSize="small" sx={{ ml: 1 }} />}
              </ListItemButton>
            </ListItem>
          </ul>
        </li>
        {/* )} */}
        {filteredDateFormats.length > 0 && parsedUnit.physicalQuantity === PhysicalQuantity.TIME && (
          <FormatList
            formatList={filteredDateFormats}
            subheader={t(`subheader.${FormatCategory.DATE}`)}
            onFormatClick={handleSelectFormat}
          />
        )}
        {filteredNumberFormats.length > 0 && (
          <FormatList
            formatList={filteredNumberFormats}
            subheader={t(`subheader.${FormatCategory.NUMBER}`)}
            onFormatClick={handleSelectFormat}
          />
        )}
        {filteredDateFormats.length > 0 && parsedUnit.physicalQuantity !== PhysicalQuantity.TIME && (
          <FormatList
            formatList={filteredDateFormats}
            subheader={t(`subheader.${FormatCategory.DATE}`)}
            onFormatClick={handleSelectFormat}
          />
        )}
      </List>
    </>
  );
};

export default FormatLists;
