import { Stack, Typography } from '@mui/material';
import { FC, Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { BlockTypeInfo, FolderInfo, ShortcutCategory, ShortStandardBlockInfo, UUID } from '@dametis/core';

import { useSelector } from 'store';
import { useBlockTypes } from 'store/api/blockTypes';
import { useStandardBlocks } from 'store/api/standardBlocks';

import BlockTypeItem from './BlockTypeItem';
import CatalogSubheader from './CatalogSubheader';
import FolderItem from './FolderItem/FolderItem';
import StandardBlockItem from './StandardBlockItem';

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

export enum ListItemType {
  FOLDER = 'folder',
  BLOCK_TYPE = 'blockType',
  STANDARD_BLOCK = 'standardBlock',
}

export interface ListItemFolder {
  type: ListItemType.FOLDER;
  content: FolderInfo;
}

export interface ListItemBlockType {
  type: ListItemType.BLOCK_TYPE;
  content: BlockTypeInfo;
}

export interface ListItemStandardBlock {
  type: ListItemType.STANDARD_BLOCK;
  content: ShortStandardBlockInfo;
}

export type ListItem = ListItemFolder | ListItemBlockType | ListItemStandardBlock;

export interface FolderContentProps {
  currentFolder?: FolderInfo;
}

const FolderContent: FC<FolderContentProps> = ({ currentFolder = undefined }) => {
  const { t } = useTranslation('lego');

  const { data: blockTypes = blockTypesEmptyArray } = useBlockTypes();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();

  const { entityId } = useParams<{ entityId?: UUID }>();

  const groupByType = useSelector(state => state.lego.navigationTools.groupByType);

  const folderBlockTypeIds = useMemo(
    () =>
      (currentFolder?.shortcuts ?? []).filter(shortcut => shortcut.category === ShortcutCategory.BLOCK_TYPE).map(shortcut => shortcut.uuid),
    [currentFolder?.shortcuts],
  );

  const folderStandardBlockIds = useMemo(
    () =>
      (currentFolder?.shortcuts ?? [])
        .filter(shortcut => shortcut.category === ShortcutCategory.STANDARD_BLOCK)
        .map(shortcut => shortcut.uuid),
    [currentFolder?.shortcuts],
  );

  const availableFolders: FolderInfo[] = useMemo(
    () => [...(currentFolder?.folders ?? [])].sort((folderA, folderB) => folderA.name.localeCompare(folderB.name)),
    [currentFolder?.folders],
  );

  const availableBlockTypes = useMemo(
    () =>
      blockTypes
        .filter(blockType => folderBlockTypeIds.includes(blockType.uuid))
        .sort((blockTypeA, blockTypeB) => blockTypeA.name.localeCompare(blockTypeB.name)),
    [blockTypes, folderBlockTypeIds],
  );

  const availableStandardBlocks = useMemo(
    () =>
      standardBlocks
        .filter(standardBlock => folderStandardBlockIds.includes(standardBlock.uuid))
        .sort((standardBlockA, standardBlockB) => standardBlockA.name.localeCompare(standardBlockB.name)),
    [standardBlocks, folderStandardBlockIds],
  );

  const availableItems: ListItem[] = useMemo(
    () =>
      [
        ...availableFolders.map<ListItemFolder>(folder => ({ type: ListItemType.FOLDER, content: folder })),
        ...availableBlockTypes.map<ListItemBlockType>(blockType => ({ type: ListItemType.BLOCK_TYPE, content: blockType })),
        ...availableStandardBlocks.map<ListItemStandardBlock>(standardBlock => ({
          type: ListItemType.STANDARD_BLOCK,
          content: standardBlock,
        })),
      ].sort((itemA, itemB) => itemA.content.name.localeCompare(itemB.content.name)),
    [availableFolders, availableBlockTypes, availableStandardBlocks],
  );

  return (
    <Stack my={0.5}>
      {groupByType && (
        <>
          {availableFolders.length > 0 && (
            <>
              <CatalogSubheader>{t('subtitle.folders')}</CatalogSubheader>
              {availableFolders.map(folder => (
                <FolderItem key={folder.uuid} folder={folder} />
              ))}
            </>
          )}
          {availableBlockTypes.length > 0 && (
            <>
              <CatalogSubheader>{t('subtitle.blockTypes')}</CatalogSubheader>
              {availableBlockTypes.map(blockType => (
                <BlockTypeItem key={blockType.uuid} blockType={blockType} selected={blockType.uuid === entityId} />
              ))}
            </>
          )}
          {availableStandardBlocks.length > 0 && (
            <>
              <CatalogSubheader>{t('subtitle.standardBlocks')}</CatalogSubheader>
              {availableStandardBlocks.map(standardBlock => (
                <StandardBlockItem key={standardBlock.uuid} selected={standardBlock.uuid === entityId} standardBlock={standardBlock} />
              ))}
            </>
          )}
        </>
      )}
      {!groupByType && (
        <>
          {availableItems.map(item => (
            <Fragment key={item.content.uuid}>
              {item.type === ListItemType.FOLDER && <FolderItem folder={item.content} />}
              {item.type === ListItemType.BLOCK_TYPE && (
                <BlockTypeItem blockType={item.content} selected={item.content.uuid === entityId} />
              )}
              {item.type === ListItemType.STANDARD_BLOCK && (
                <StandardBlockItem selected={item.content.uuid === entityId} standardBlock={item.content} />
              )}
            </Fragment>
          ))}
        </>
      )}
      {availableFolders.length === 0 && availableBlockTypes.length === 0 && availableStandardBlocks.length === 0 && (
        <Typography align="center" p={2} variant="subtitle2">
          {t('text.emptyFolder')}
        </Typography>
      )}
    </Stack>
  );
};

export default FolderContent;
