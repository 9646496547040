export enum ShareType {
  REPORT = 0,
  PLAYGROUND = 1,
  SYNOPTIC = 2,
  PROJECT = 3,
  ALARM = 4,
}

export enum NotifyItemType {
  REPORT = 0,
  PLAYGROUND = 1,
  SYNOPTIC = 2,
  PROJECT = 3,
  ALARM = 4,
  PROJECT_TASK = 5,
}
