import { makeStyles } from '@mui/styles';

const useOperationMenuStyles = makeStyles(theme => ({
  openBtn: {
    marginRight: '1rem',
  },
  openBtnWithCircularProgress: {
    columnGap: theme.spacing(1),
  },
  openBtn__label: {
    gridGap: `0 ${theme.spacing(1)}`,
  },
  openBtn__icon: {
    justifySelf: 'center',
    columnGap: theme.spacing(0.5),
  },
  'openBtn__icon--default': {
    color: `${theme.palette.icon.main}!important`,
  },
  'openBtn__icon--success': {
    color: `${theme.palette.success.light}!important`,
  },
  'openBtn__icon--warning': {
    color: `${theme.palette.warning.main}!important`,
  },
  'openBtn__icon--error': {
    color: `${theme.palette.error.main}!important`,
  },
  openBtn__progress__label: {
    fontSize: 10,
  },
  list__item: {
    display: 'flex',
    alignItems: 'center',
    width: 400,
    height: 50,
  },
  list__item__icon: {
    flexShrink: 0,
    color: theme.palette.icon.main,
    fontSize: theme.spacing(3),
    height: 25,
  },
  list__item__iconBadge: {
    width: '15px!important',
    height: '15px!important',
    minWidth: 'unset!important',
    color: theme.palette.white,
  },
  'list__item__iconBadge--default': {
    backgroundColor: `${theme.palette.icon.main}!important`,
  },
  'list__item__iconBadge--success': {
    backgroundColor: `${theme.palette.success.light}!important`,
  },
  'list__item__iconBadge--warning': {
    backgroundColor: `${theme.palette.warning.main}!important`,
  },
  'list__item__iconBadge--error': {
    backgroundColor: `${theme.palette.error.main}!important`,
  },
  'list__item__iconBadge--cancelled': {
    backgroundColor: `${theme.palette.info.main}!important`,
  },
  list__item__iconText: {
    fontSize: 13,
  },
  list__item__content: {
    flexGrow: 1,
    overflow: 'hidden',
    marginLeft: theme.spacing(2),
  },
  list__item__content__title: {
    lineHeight: theme.spacing(2),
  },
  list__item__content__progress: {
    marginTop: theme.spacing(1),
    opacity: 0.25,
  },
  list__item__content__subtitle: {
    fontSize: 12,
  },
  list__item__button: {
    fontSize: 16,
  },
}));

export default useOperationMenuStyles;
