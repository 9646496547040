import { format as numfmtFormat, dateFromSerial } from 'numfmt';

import { DateSystem, Format, IsDametisFormat, IsEcma376Format } from '@dametis/core';

import { getLocale } from 'localization';

export const EXCEL_UNIX_DIFFERENCE_IN_DAYS = 25569;

const minutesToDays = (minutes: number): number => minutes / 1440;

const toNativeDate = (dateArray: number[]) => {
  const [y, m, d, hh, mm, ss] = dateArray;
  const dt = new Date(0);
  dt.setUTCFullYear(y, m - 1, d);
  dt.setUTCHours(hh, mm, ss);
  return dt;
};

export const formatValueFromFormat = (value: number, format: Format): string => {
  if (IsEcma376Format(format)) {
    // Si l'opérateur TIME du VNC est utilisé, le timestamp reçu sera sur le système Unix (1970), il faut donc ajouter la différence avec le système Excel (1900) en jour
    let parsedValue = value + (format.dateSystem === DateSystem.TIMESTAMP_UNIX ? EXCEL_UNIX_DIFFERENCE_IN_DAYS : 0);
    if (format.withTimezoneOffset) {
      // Si le décalage horaire doit être pris en compte, il faut convertir notre valeur (en jour) en date, récupérer le décalage de timezone (en minute) et le soustraire à notre résultat
      const valueDate: Date = toNativeDate(dateFromSerial(parsedValue));
      parsedValue -= !Number.isNaN(valueDate.getTime()) ? minutesToDays(valueDate.getTimezoneOffset()) : 0;
    }
    return numfmtFormat(format.code, parsedValue, { locale: getLocale()?.code ?? 'en' });
  }
  if (IsDametisFormat(format)) {
    return format.formatter(value);
  }
  return `${value}`;
};
