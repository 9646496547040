import { Box, BoxProps } from '@mui/material';
import { forwardRef, useCallback, useMemo, MouseEventHandler, ReactNode } from 'react';

import { CalculationVariable } from '@dametis/core';

import { useDispatch } from 'store';
import { openCalculationMenu } from 'store/actions/calculationMenu';

// const Container = forwardRef(<C extends ElementType>(props: BoxProps<C, { component?: C }>, ref) => <Box ref={ref} {...props} />);
const itemsDefaultProp: ReactNode[] = [];

export interface CalculationMenuContainerProps extends Omit<BoxProps, 'onContextMenu'> {
  calculation: CalculationVariable | null;
  items?: ReactNode[];
  chartDisabled?: boolean;
}

const CalculationMenuContainer = forwardRef<HTMLElement, CalculationMenuContainerProps>(
  ({ calculation: userCalculation, items: userItems = itemsDefaultProp, chartDisabled = false, sx, children, ...props }, ref) => {
    const dispatch = useDispatch();

    const sxProp = useMemo(() => ({ cursor: userCalculation ? 'context-menu' : undefined, ...sx }), [sx, userCalculation]);

    const handleClick: MouseEventHandler<HTMLElement> = useCallback(
      event => {
        event.preventDefault();
        // if (menuPosition === null) {
        const position = { mouseX: event.clientX, mouseY: event.clientY };
        dispatch(openCalculationMenu({ position, calculation: userCalculation, items: userItems, chartDisabled }));
        // } else {
        // dispatch(closeCalculationMenu());
        // }
      },
      [userCalculation, dispatch, userItems, chartDisabled],
    );

    return (
      <Box ref={ref} sx={sxProp} onContextMenu={userCalculation ? handleClick : undefined} {...props}>
        {children}
      </Box>
    );
  },
);

CalculationMenuContainer.defaultProps = {
  items: [],
  chartDisabled: false,
};

CalculationMenuContainer.displayName = 'CalculationMenuContainer';

export default CalculationMenuContainer;
