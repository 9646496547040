import { memo } from 'react';
import { areEqual, ListChildComponentProps } from 'react-window';

import { VariableResult } from '../../../types';

import ListItemVariable from './ListItemVariable';

const ListItem = memo<ListChildComponentProps<VariableResult[]>>(props => <ListItemVariable {...props} />, areEqual);

ListItem.displayName = 'ListItem';

export default ListItem;
