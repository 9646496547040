import { Clear } from '@mui/icons-material';
import {
  Box,
  drawerClasses,
  FormControl,
  FormControlProps as MuiFormControlProps,
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps as MuiOutlinedInputProps,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, forwardRef, MouseEventHandler, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IsEmptyCalculation, Operator, VarCalc, VariableKind } from '@dametis/core';

import { GroupByInputProps as GroupByInputPropsType } from 'components/UI/GroupByInput/GroupByInput';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { FakeVariableKind } from 'types/variables';
import { VncFilters, AvailableVncFilters } from 'zustand/states/vnc';

import CalculationSlate from '../UI/CalculationSlate/CalculationSlate';

import VncDialog from './VncDialog';
import HelperText from './components/VncInput/HelperText';
import Label from './components/VncInput/Label';
import { sortedOperators, vncDefaultProps } from './props';
import { ListTab, VncProps } from './types';

const OutlinedInputPropsDefaultProp: Omit<MuiOutlinedInputProps, 'onChange' | 'variant' | 'label' | 'error'> = {};
const excludeVariableOperatorOptionsDefaultProp: Operator[] = [];
const excludeGlobalOperatorOptionsDefaultProp: Operator[] = [];
const disableFiltersDefaultProp: (keyof VncFilters)[] = [];
const selectionDefaultProp: VarCalc[] = [];
const availableFiltersDefaultProp: Partial<AvailableVncFilters> = {
  kinds: [
    VariableKind.CALCULATED,
    VariableKind.REAL,
    VariableKind.MANUAL,
    VariableKind.CONSTANT,
    VariableKind.NONE,
    FakeVariableKind.ALARM,
    FakeVariableKind.BATCH,
    FakeVariableKind.VAPOR_MIX,
  ],
};
const defaultFiltersDefaultProp: Partial<VncFilters> = {};
const GroupByInputPropsDefaultProp: Omit<GroupByInputPropsType, 'groupBy' | 'onGroupByChange'> = {};

type Props = VncProps & { open?: boolean } & Omit<MuiFormControlProps, 'onChange' | 'error'> & {
    editing?: boolean;
    enableNickname?: boolean;
    label?: MuiOutlinedInputProps['label'];
    helperText?: ReactNode;
    error?: MuiOutlinedInputProps['error'];
    OutlinedInputProps?: Omit<MuiOutlinedInputProps, 'onChange' | 'variant' | 'label' | 'error'>;
  };

const VncInput: FC<Props> = ({
  multiple = false,
  selection = selectionDefaultProp,
  onSelectionChange = undefined,
  sourceUuid = undefined,
  open,
  listTab = ListTab.VARIABLES,
  defaultVariableOperator = undefined,
  variableOperatorOptions = sortedOperators,
  excludeVariableOperatorOptions = excludeVariableOperatorOptionsDefaultProp,
  defaultGlobalOperator = undefined,
  globalOperatorOptions = sortedOperators,
  excludeGlobalOperatorOptions = excludeGlobalOperatorOptionsDefaultProp,
  disableMaths = false,
  butKeepVariableMenu = false,
  availableFilters = availableFiltersDefaultProp,
  defaultFilters = defaultFiltersDefaultProp,
  disableFilters = disableFiltersDefaultProp,
  calculatedVariableMode = false,
  standardBlockMetricMode = false,
  unPostedBlockType = null,
  editingBlockTypeMetricUuid = null,
  disableLego = false,
  disableModels = false,
  covarianceVariable = null,
  unitPicker = true,
  output = 'multipleValues',
  GroupByInputProps = GroupByInputPropsDefaultProp,
  disableCreateAlias = false,
  // VncDialog props
  value,
  onChange,
  sourceCategory,
  // VncInput props
  editing = true,
  enableNickname = false,
  label = undefined,
  helperText = undefined,
  error,
  OutlinedInputProps = OutlinedInputPropsDefaultProp,
  ...FormControlProps
}) => {
  const { t } = useTranslation('vnc');

  const [dialogOpen, setDialogOpen] = useState(false);
  const [popperOpen, setPopperOpen] = useState(false);

  const inputRef = useRef<HTMLDivElement>();

  const emptyCalculation = useMemo(() => createCalculationVariable(), []);
  const displayedUnit = useMemo(() => (unitPicker && value?.unit?.trim().length > 0 ? value.unit.trim() : ''), [unitPicker, value?.unit]);
  const isEmptyCalculation = useMemo(() => IsEmptyCalculation(value), [value]);

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const clearInput = useCallback<MouseEventHandler<HTMLButtonElement>>(
    e => {
      e.stopPropagation();
      onChange(emptyCalculation);
    },
    [onChange, emptyCalculation],
  );

  const closePopper = useCallback(() => {
    setPopperOpen(false);
  }, []);

  const boundingClientRect = inputRef.current?.getBoundingClientRect();
  const horizontal = (boundingClientRect?.left ?? 0) <= window.innerWidth - (boundingClientRect?.right ?? 0) ? 'left' : 'right';

  useEffect(() => {
    setDialogOpen(open);
  }, [open]);

  return (
    <>
      <FormControl
        {...FormControlProps}
        sx={[
          { maxWidth: 1, '&:hover .popperButton': { visibility: 'visible' } },
          ...(Array.isArray(FormControlProps.sx) ? FormControlProps.sx : [FormControlProps.sx]),
        ]}
      >
        <Label
          horizontal={horizontal}
          isEmptyCalculation={isEmptyCalculation}
          label={label}
          popperOpen={popperOpen}
          setPopperOpen={setPopperOpen}
        />
        <div ref={inputRef}>
          {editing && (
            <OutlinedInput
              endAdornment={
                Boolean(displayedUnit.length) || !isEmptyCalculation ? (
                  <InputAdornment position="end">
                    <Stack alignItems="center">
                      {Boolean(displayedUnit.length) && <Box component="span">{displayedUnit}</Box>}
                      {!isEmptyCalculation && (
                        <Tooltip title={t('tooltip.clear')}>
                          <IconButton onClick={clearInput}>
                            <Clear />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Stack>
                  </InputAdornment>
                ) : undefined
              }
              error={error}
              inputComponent={
                isEmptyCalculation
                  ? undefined
                  : // eslint-disable-next-line react/no-unstable-nested-components,react/display-name
                    forwardRef((props, ref) => (
                      <Box ref={ref} maxHeight={100} overflow="auto" p={editing ? 1 : undefined} width={1}>
                        <Box sx={{ width: 'max-content' }}>
                          <CalculationSlate calculation={value} />
                        </Box>
                      </Box>
                    ))
              }
              placeholder={
                OutlinedInputProps.placeholder ?? t('input.placeholder.input', { context: disableMaths ? 'disableMaths' : undefined })
              }
              onClick={openDialog}
              {...OutlinedInputProps}
              sx={[
                {
                  position: 'relative',
                  width: 1,
                  zIndex: 1,
                  [`.${drawerClasses.paper} &`]: { zIndex: theme => theme.zIndex.drawer + 1 },
                },
                popperOpen && { opacity: 0 },
                ...(Array.isArray(OutlinedInputProps.sx) ? OutlinedInputProps.sx : [OutlinedInputProps.sx]),
              ]}
            />
          )}
          {!editing && !isEmptyCalculation && (
            <Box maxHeight={100} minWidth={150} overflow="auto" width={1}>
              <Box width="max-content">
                <CalculationSlate calculation={value} />
              </Box>
            </Box>
          )}
          {!editing && isEmptyCalculation && <Typography>{t('global:text.noValue')}</Typography>}
        </div>
        {editing && <HelperText enableNickname={enableNickname} helperText={helperText} value={value} onChange={onChange} />}
      </FormControl>
      <Popover
        disableRestoreFocus
        anchorEl={inputRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal,
        }}
        open={popperOpen}
        slotProps={{ paper: { sx: [{ borderRadius: 1 }, !editing && { m: -1, p: 1 }] } }}
        transformOrigin={{
          vertical: 'top',
          horizontal,
        }}
        TransitionProps={{ timeout: 0 }}
        onClose={closePopper}
      >
        {editing ? (
          <OutlinedInput
            error={error}
            inputComponent={
              isEmptyCalculation
                ? undefined
                : // eslint-disable-next-line react/display-name,react/no-unstable-nested-components
                  forwardRef((props, ref) => (
                    <Box ref={ref} minWidth={inputRef.current?.clientWidth ?? 150} overflow="auto" p={1} width={1}>
                      <Box width="max-content">
                        <CalculationSlate calculation={value} />
                      </Box>
                    </Box>
                  ))
            }
            placeholder={
              OutlinedInputProps.placeholder ?? t('input.placeholder.input', { context: disableMaths ? 'disableMaths' : undefined })
            }
            onClick={openDialog}
            {...OutlinedInputProps}
            sx={[
              {
                position: 'relative',
                width: 1,
              },
            ]}
          />
        ) : (
          <Box minWidth={150} overflow="auto" width={1}>
            <Box width="max-content">
              <CalculationSlate calculation={value} />
            </Box>
          </Box>
        )}
      </Popover>
      <VncDialog
        availableFilters={availableFilters}
        butKeepVariableMenu={butKeepVariableMenu}
        calculatedVariableMode={calculatedVariableMode}
        covarianceVariable={covarianceVariable}
        defaultFilters={defaultFilters}
        defaultGlobalOperator={defaultGlobalOperator}
        defaultVariableOperator={defaultVariableOperator}
        disableCreateAlias={disableCreateAlias}
        disableFilters={disableFilters}
        disableLego={disableLego}
        disableMaths={disableMaths}
        disableModels={disableModels}
        editingBlockTypeMetricUuid={editingBlockTypeMetricUuid}
        excludeGlobalOperatorOptions={excludeGlobalOperatorOptions}
        excludeVariableOperatorOptions={excludeVariableOperatorOptions}
        globalOperatorOptions={globalOperatorOptions}
        GroupByInputProps={GroupByInputProps}
        listTab={listTab}
        multiple={multiple}
        open={dialogOpen}
        output={output}
        selection={selection}
        setOpen={setDialogOpen}
        sourceCategory={sourceCategory}
        sourceUuid={sourceUuid}
        standardBlockMetricMode={standardBlockMetricMode}
        unitPicker={unitPicker}
        unPostedBlockType={unPostedBlockType}
        value={value}
        variableOperatorOptions={variableOperatorOptions}
        onChange={onChange}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
};

VncInput.defaultProps = {
  ...vncDefaultProps,
  open: false,
  enableNickname: false,
  editing: true,
  label: undefined,
  helperText: undefined,
  OutlinedInputProps: {},
};

export default VncInput;
