import { BuildOutlined, BusinessCenterOutlined, ErrorOutlineOutlined, FactoryOutlined } from '@mui/icons-material';
import { SvgIcon, SvgIconProps, Tooltip, TooltipProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectType } from '@dametis/core';

const tooltipPropsDefaultProp: Omit<TooltipProps, 'children' | 'title'> = {};

export interface ProjectTypeIconProps extends SvgIconProps {
  type: ProjectType;
  disabled?: boolean;
  tooltip?: boolean;
  tooltipProps?: Omit<TooltipProps, 'children' | 'title'>;
}

const ProjectTypeIcon: FC<ProjectTypeIconProps> = ({
  type,
  disabled = false,
  tooltip = false,
  tooltipProps = tooltipPropsDefaultProp,
  ...props
}) => {
  const { t } = useTranslation('projects');

  const icon = useMemo(() => {
    switch (type) {
      case ProjectType.ANOMALY:
        return ErrorOutlineOutlined;
      case ProjectType.GLOBALPROJECT:
        return FactoryOutlined;
      case ProjectType.OPTIMISATION:
        return BuildOutlined;
      case ProjectType.PROJECT:
        return BusinessCenterOutlined;
      default:
        return null;
    }
  }, [type]);

  return (
    <Tooltip title={tooltip ? t('type.variant', { context: type }) : ''} {...tooltipProps}>
      {icon && <SvgIcon color={disabled ? 'disabled' : 'primary'} component={icon} {...props} />}
    </Tooltip>
  );
};

export default ProjectTypeIcon;
