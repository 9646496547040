export default {
  title: {
    shared: 'Sharings',
    unassigned: 'Unassigned',
    custom: 'Custom',
    teams: 'Teams',
    users: 'Users',
  },
  text: {
    users: 'Users',
    list: 'Teams',
    custom: 'Custom',
    owner: 'Owner',
    you: 'You',
    notfound: 'Did not match any result',
    addPeople: 'Add people or team',
    public: 'Public',
    unassigned: 'Unassigned',
  },
  button: {
    add: 'Add',
    ok: 'OK',
  },
  select: {
    writer: 'Write',
    reader: 'Read',
  },
  invitations: {
    title: 'Share via invitation link',
  },
  alert: {
    public: 'Everyone with this link can create an account.',
    reportPrivate: 'The report must first be made public in order to be shared with users who do not yet have an account.',
    synopticPrivate: 'The synoptic must first be made public in order to be shared with users who do not yet have an account.',
    playgroundPrivate: 'The playground must first be made public in order to be shared with users who do not yet have an account.',
  },
  toast: {
    shareReportSuccess: 'The report was successfully shared.',
    privatizeReportSuccess: 'The report was successfully made private.',
    sharePlaygroundSuccess: 'The playground was successfully shared.',
    privatizePlaygroundSuccess: 'The playground was successfully made private.',
    shareSynopticSuccess: 'The synoptic was successfully shared.',
    privatizeSynopticSuccess: 'The synoptic was successfully made private.',
    shareAlarmSuccess: 'The alarm was successfully shared.',
    privatizeAlarmSuccess: 'The alarm was successfully made private.',
    shareProjectSuccess: 'The project was successfully shared.',
    privatizeProjectSuccess: 'The project was successfully made private.',
    addUserSuccess: 'The user was successfully added to the sharing.',
    addTeamSuccess: 'The team was successfully added to the sharing.',
    deleteUserSuccess: 'The user was successfully removed from the sharing.',
    deleteTeamSuccess: 'The team was successfully removed from the sharing.',
    changeUserRoleSuccess: "The user's modification rights have been successfully modified.",
    changeTeamRoleSuccess: "The team's modification rights have been successfully modified.",
    shareError: 'An error occured while editing the sharings.',
  },
};
