import deepmerge from 'deepmerge';
import { TooltipOptions } from 'highcharts';

export const getTooltipOptions = (options: Partial<TooltipOptions>): Partial<TooltipOptions> =>
  deepmerge(
    {
      hideDelay: 20,
    },
    options,
  );
