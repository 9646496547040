import { List, ListItem, ListItemAvatar, ListItemText, Paper, Popper } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { mathFunctions } from 'config/functions';
import { useVncStore } from 'zustand/stores/vnc';

import EnergyIcon from '../../../../UI/EnergyIcon/EnergyIcon';
import { FunctionName } from '../styled';

import FunctionPopperListItem from './FunctionPopperListItem';

const FunctionPopper: FC = () => {
  const { t } = useTranslation('vnc');

  const hasSearch = useVncStore(state => Boolean(state.search.length));
  const functionName = useVncStore(state => state.focusedFunction?.functionName);
  const anchorEl = useVncStore(state => state.focusedFunction?.anchorEl);

  const mathFunction = mathFunctions[functionName];

  if (!mathFunction) return null;
  return (
    <Popper anchorEl={anchorEl} open={Boolean(anchorEl)} placement="bottom-start" sx={{ zIndex: 'modal' }}>
      <Paper elevation={8} sx={{ p: 0.75, width: 500 }}>
        <List dense>
          {!hasSearch && (
            <ListItem
              secondaryAction={
                <>
                  <div>{t(`functions.type.${mathFunction.output.type}`)}</div>
                  <div>{mathFunction.output.unit}</div>
                </>
              }
              sx={{ pr: 12 }}
            >
              <ListItemAvatar>
                <EnergyIcon energy={mathFunction.category} />
              </ListItemAvatar>
              <ListItemText
                primary={<FunctionName variant="h5">{t(`functions.name.${mathFunction.name}`)}()</FunctionName>}
                secondary={t(`functions.description.${mathFunction.name}`)}
                secondaryTypographyProps={{ variant: 'subtitle2' }}
              />
            </ListItem>
          )}
          {mathFunction.inputs.map((input, index) => (
            <FunctionPopperListItem key={input.name} func={mathFunction} index={index} input={input} />
          ))}
        </List>
      </Paper>
    </Popper>
  );
};

export default FunctionPopper;
