export default {
  button: {
    reset: 'Réinitialiser',
  },
  option: {
    all: 'Tout sélectionner',
  },
  text: {
    noTagSelected: 'Aucun tag sélectionné',
  },
};
