import { CreateFolderBody, FolderInfo, UUID, UpdateFolderBody } from '@dametis/core';

import { sdk } from '../../sdk';
import { RootState, useSelector } from '../index';

import { api } from './index';

export const foldersApi = api.injectEndpoints({
  endpoints: build => ({
    readRootFolder: build.query<FolderInfo, { siteId?: string } | void>({
      providesTags: ['Folders'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        if (!siteId) {
          console.error('No site ID');
          return { data: null };
        }
        try {
          const { data } = await sdk.folder.Tree(siteId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    createFolder: build.mutation<FolderInfo, CreateFolderBody>({
      invalidatesTags: ['Folders'],
      queryFn: async (block, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        if (!siteId) {
          throw new Error('No site ID');
        }
        try {
          const { data } = await sdk.folder.Create(siteId, block);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateFolder: build.mutation<FolderInfo, { uuid: UUID; body: UpdateFolderBody }>({
      invalidatesTags: ['Folders'],
      queryFn: async ({ uuid, body }, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        if (!siteId) {
          throw new Error('No site ID');
        }
        try {
          const { data } = await sdk.folder.Update(siteId, uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteFolder: build.mutation<void, UUID>({
      invalidatesTags: ['Folders'],
      queryFn: async (blockId, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        if (!siteId) {
          throw new Error('No site ID');
        }
        try {
          const { data } = await sdk.folder.Delete(siteId, blockId);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const { useCreateFolderMutation, useReadRootFolderQuery, useDeleteFolderMutation, useUpdateFolderMutation } = foldersApi;

export const useFolders: typeof useReadRootFolderQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadRootFolderQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};
