import { Energies, Operator, PhysicalQuantity, ProtocolKind } from '@dametis/core';

import { EntityTitleCategory } from 'hooks/useEntityTitle';

const energy: Record<Energies, string> = {
  [Energies.WATER]: 'Agua',
  [Energies.ELECTRICITY]: 'Electricidad',
  [Energies.FUEL]: 'Fuel',
  [Energies.GAS]: 'Gas',
  [Energies.PRODUCT]: 'Producto',
  [Energies.OTHER]: 'Otro',
  [Energies.AIR]: 'Aire',
  [Energies.COOLANT_FLUID]: 'Fluido de refrigeración',
  [Energies.COLD]: 'Frío',
  [Energies.STEAM]: 'Vapor',
  [Energies.COMPRESSED_AIR]: 'Aire comprimido',
};

export default {
  text: {
    pageTitleEntity: '{{name}} - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.ALARM}`]: '{{name}} - Alarma - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.PLAYGROUND}`]: '{{name}} - Playground - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.PROJECT}`]: '{{name}} - Proyecto - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.REPORT}`]: '{{name}} - Informe - Dametis',
    [`pageTitleEntity_${EntityTitleCategory.SYNOPTIC}`]: '{{name}} - Sinóptico - Dametis',
    loading: 'Carga',
    noValue: 'n/a',
    loadingValue: '...',
    noElement: 'Ningún elemento',
    unknownVariable: 'Variable desconocida',
    unknownBlock: 'Bloque desconocido',
    unknownModel: 'Modelo desconocido',
    unknownBatch: 'Batch desconocido',
    subtitle: {
      createdAt: 'Creado {{date}}',
      updatedAt: 'Última modificación {{date}}',
    },
    health: {
      title: 'Estado de salud',
      lastChecked: 'Última comprobación hace {{duration}}.',
      systemsHealthy: 'Todos los sistemas están operativos.',
      systemsUnhealthy: 'Algunos sistemas no están operativos.',
      details: 'Detalles',
    },
  },
  number: {
    toLocale: '{{value, localizado}}',
  },
  unit: {
    input: {
      label: 'Unidad',
      placeholder: 'Ninguna',
    },
    time_millisecond_one: 'milisegundo',
    time_millisecond_other: 'milisegundos',
    time_second_one: 'segundo',
    time_second_other: 'segundos',
    time_minute_one: 'minuto',
    time_minute_other: 'minutos',
    time_hour_one: 'hora',
    time_hour_other: 'horas',
    time_day_one: 'día',
    time_day_other: 'días',
    time_week_one: 'semana',
    time_week_other: 'semanas',
    time_month_one: 'mes',
    time_month_other: 'mes',
    time_year_one: 'año',
    time_year_other: 'años',
    time_millisecond_short_one: 'ms',
    time_millisecond_short_other: 'ms',
    time_second_short_one: 's',
    time_second_short_other: 's',
    time_minute_short_one: 'mín.',
    time_minute_short_other: 'mín.',
    time_hour_short_one: 'h',
    time_hour_short_other: 'h',
    time_day_short_one: 'd',
    time_day_short_other: 'd',
    time_week_short_one: 'sem',
    time_week_short_other: 'sem',
    time_month_short_one: 'mes',
    time_month_short_other: 'mes',
    time_year_short_one: 'a',
    time_year_short_other: 'a',
  },
  date: {
    month: {
      january: 'enero',
      february: 'febrero',
      march: 'marzo',
      april: 'abril',
      may: 'mayo',
      june: 'junio',
      july: 'julio',
      august: 'agosto',
      september: 'septiembre',
      october: 'octubre',
      november: 'noviembre',
      december: 'diciembre',
      january_short: 'ene.',
      february_short: 'feb.',
      march_short: 'marzo',
      april_short: 'abr.',
      may_short: 'mayo',
      june_short: 'junio',
      july_short: 'jul.',
      august_short: 'agosto',
      september_short: 'sept.',
      october_short: 'oct.',
      november_short: 'nov.',
      december_short: 'dic.',
    },
    at: ' a ',
  },
  physicalQuantity: {
    pQ_none: 'ninguna',
    pQ_other: 'otro',
    pQ_force: 'fuerza',
    pQ_pressure: 'presión',
    pQ_energy: 'energía',
    pQ_specificEnergy: 'energía específica',
    pQ_power: 'potencia',
    pQ_temperature: 'temperatura',
    pQ_speed: 'velocidad',
    pQ_acceleration: 'aceleración',
    pQ_density: 'densidad',
    pQ_massDensity: 'masa volumétrica',
    pQ_time: 'tiempo',
    pQ_mass: 'masa',
    pQ_volume: 'volumen',
    pQ_flow: 'caudal',
    pQ_dewPoint: 'punto de rocío',
    pQ_humidity: 'humedad',
    pQ_thermalConductivity: 'conductividad térmica',
    pQ_voltage: 'tensión',
    pQ_current: 'intensidad',
    pQ_frequency: 'frecuencia',
    pQ_length: 'longitud',
    pQ_surface: 'área',
    pQ_currency: 'moneda',
    pQ_fileSize: 'tamaño de archivo',
    pQ_viscosity: 'viscosidad',
  } as Record<`pQ_${PhysicalQuantity}`, string>,
  operator: {
    op_MIN: 'mín.',
    op_MAX: 'máx.',
    op_FIRST: 'primer valor',
    op_LAST: 'último valor',
    op_INDEX: 'índice',
    op_HISTORY: 'valor',
    op_MEAN: 'promedio',
    op_DELTA: 'delta',
    op_SELECT: 'valor',
    op_INTEGRAL: 'integral',
    op_TRAPEZOIDAL_INTEGRAL: 'integral trapezoidal',
    op_LEFT_RECTANGLE_INTEGRAL: 'integral rectángulo izquierda',
    op_DERIVATIVE: 'derivada',
    op_STDDEV: 'desviación estándar',
    op_GAP: 'desviación',
    op_VARIANCE: 'varianza',
    op_MOVING_AVERAGE: 'promedio móvil',
    op_SUM: 'suma',
    op_TIME_WEIGHTED_MEAN: 'promedio ponderado temporal',
    op_COUNT: 'contador',
    op_DISTINCT: 'distinto',
    op_TIME: 'tiempo',
    op_GROUP_BY_DURATION: 'duración de periodo',
    op_LINEAR_CADENCE: 'cadencia lineal',
    opShort_MIN: 'mín.',
    opShort_MAX: 'máx.',
    opShort_FIRST: 'prim.',
    opShort_LAST: 'últ.',
    opShort_INDEX: 'índice',
    opShort_HISTORY: 'val.',
    opShort_MEAN: 'prom.',
    opShort_DELTA: 'delta',
    opShort_SELECT: 'val.',
    opShort_INTEGRAL: 'int.',
    opShort_LEFT_RECTANGLE_INTEGRAL: 'rint.',
    opShort_TRAPEZOIDAL_INTEGRAL: 'tint.',
    opShort_DERIVATIVE: 'deriv.',
    opShort_STDDEV: 'éstd.',
    opShort_GAP: 'desviación',
    opShort_VARIANCE: 'var.',
    opShort_MOVING_AVERAGE: 'moy.',
    opShort_SUM: 'suma',
    opShort_TIME_WEIGHTED_MEAN: 'mpt.',
    opShort_COUNT: 'cont.',
    opShort_DISTINCT: 'dist.',
    opShort_TIME: 'tiempo',
    opShort_GROUP_BY_DURATION: 'dur.',
    opShort_LINEAR_CADENCE: 'lineal',
  } as Record<`op_${Operator}` | `opShort_${Operator}`, string>,
  button: {
    add: 'Añadir',
    edit: 'Modificar',
    delete: 'Vaciar',
    regenerate: 'Regenerar',
    save: 'Guardar',
    cancel: 'Cancelar',
    disable: 'Desactivar',
    enable: 'Activar',
    duplicate: 'Duplicar',
  },
  tooltip: {
    moreOptions: 'Más opciones',
    changeCountry: 'Cambiar de país',
    edit: 'Modificar',
    delete: 'Vaciar',
    add: 'Añadir',
    hide: 'Ocultar',
    show: 'Mostrar',
    return: 'Regresar',
    noRight: 'No tienes permisos para ',
    editMin: 'modificar ',
    deleteMin: 'vaciar ',
    addMin: 'añadir ',
    duplicateMin: 'duplicar ',
    disableMin: 'desactivar ',
    enableMin: 'activar ',
    regenerateMin: 'Regenerar ',
    back: 'Regresar',
    close: 'Cerrar',
    rename: 'renombrar',
    cancel: 'cancelar',
    submit: 'validar',
  },
  protocol: {
    modbusTcp: 'Modbus TCP',
    s7: 'Siemens S7',
    opcUa: 'OPC UA',
    opcDa: 'OPC DA',
    sql: 'SQL',
    http: 'HTTP',
    file: 'Archivo',
    bacnet: 'BACnet',
    custom: 'Personalizado',
  } as Record<ProtocolKind, string>,
  covariance: 'Índice de influencia',
  energy,
};
