import { styled, Typography, TypographyProps } from '@mui/material';
import { FC } from 'react';

export const FreeCounter = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    bgcolor: 'grey.400',
    borderRadius: 1,
    p: '0.1em 0.3em',
    textShadow: 'none',
  }),
);

export interface CounterProps extends TypographyProps {
  count: number | null;
  limit?: number;
}

const Counter: FC<CounterProps> = ({ count, limit = Infinity, ...props }) => (
  <FreeCounter fontSize="0.8em" variant="overline" {...props}>
    {/* eslint-disable-next-line no-nested-ternary */}
    {count !== null ? (count > limit ? `${limit}+` : count) : '...'}
  </FreeCounter>
);

export default Counter;
