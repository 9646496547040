import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SourceEntity } from '@dametis/core';

import ShortcutChip, { ShortcutChipProps } from '../../../../UI/ShortcutPanel/ShortcutChip';

interface Props extends Omit<ShortcutChipProps, 'shortcut'> {
  source: SourceEntity;
}

const AliasChip: FC<Props> = ({ source, ...props }) => {
  const { t } = useTranslation('shortcut');

  if (!source.uuid) {
    return t(`name.${source.category}_a`, { count: 1 });
  }
  return <ShortcutChip shortcut={source} {...props} />;
};

export default AliasChip;
