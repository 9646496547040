import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Socket } from 'socket.io-client';

import { ACL, DiscoverAuthenticationInfo, GroupInfo, Health, ShortGroupInfo, SiteInfo, UserInfo, UUID } from '@dametis/core';

import { AuthStatus, User } from 'types/auth';
import { GridView } from 'types/grid';

export interface AuthState {
  user: User | null;
  groups: GroupInfo[];
  selectedSite: SiteInfo | null;
  selectedGroup: GroupInfo | null;
  status: AuthStatus;
  WIPeMaaS: boolean;
  socket: Socket;
  gridView: GridView;
  version: number;
  authMethods: DiscoverAuthenticationInfo[];
  siteHealth: {
    health: Health | null;
    loading: boolean;
  };
}

const initialState: AuthState = {
  user: null,
  groups: [],
  selectedSite: null,
  selectedGroup: null,
  status: AuthStatus.UNKNOWN,
  WIPeMaaS: false,
  socket: undefined,
  gridView: GridView.TILE,
  version: -1,
  authMethods: [],
  siteHealth: {
    health: null,
    loading: true,
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<UserInfo>) => {
      state.user = { ...action.payload, acl: ACL.Build(action.payload.acl) };
    },
    setGroups: (state, action: PayloadAction<GroupInfo[]>) => {
      state.groups = action.payload;
    },
    setFavoriteGroups: (state, action: PayloadAction<string[]>) => {
      state.user.favorites.group = action.payload;
    },
    setSelectedSite: (state, action: PayloadAction<SiteInfo>) => {
      state.selectedSite = action.payload;
    },
    setSelectedSiteName: (state, action: PayloadAction<{ uuid: UUID; name: string }>) => {
      state.selectedSite.name = action.payload.name;
      let siteIndex: number;
      const groupIndex = state.groups.findIndex(group => {
        const foundSiteIndex = group.sites.findIndex(site => site.uuid === action.payload.uuid);
        if (foundSiteIndex !== -1) {
          siteIndex = foundSiteIndex;
          return true;
        }
        return false;
      });
      if (![groupIndex, siteIndex].includes(-1)) {
        state.groups[groupIndex].sites[siteIndex].name = action.payload.name;
      }
    },
    setSelectedSiteDefaultHomeReport: (state, action: PayloadAction<UUID>) => {
      state.selectedSite.defaultHomeReport = action.payload;
    },
    setSelectedGroup: (state, action: PayloadAction<GroupInfo>) => {
      state.selectedGroup = action.payload;
    },
    setAuthStatus: (state, action: PayloadAction<AuthStatus>) => {
      state.status = action.payload;
    },
    setSocketio: (state, action: PayloadAction<any>) => ({ ...state, socket: action.payload }),
    setGridView: (state, action: PayloadAction<GridView>) => {
      state.gridView = action.payload;
    },
    setUserGroupAndSite: (state, action: PayloadAction<{ selectedGroup: ShortGroupInfo; selectedSite: SiteInfo }>) => {
      state.WIPeMaaS = state.user.acl.HasAnyRole(action.payload.selectedGroup, action.payload.selectedSite, ['EMaaS', 'SUPER_ADMIN']);
      Object.assign(state, action.payload);
    },
    setVersion: (state, action: PayloadAction<number>) => {
      state.version = action.payload;
    },
    setAuthMethods: (state, action: PayloadAction<DiscoverAuthenticationInfo[]>) => {
      state.authMethods = action.payload;
    },
    setSiteHealth: (state, action: PayloadAction<Health>) => {
      state.siteHealth = {
        ...state.siteHealth,
        health: action.payload,
      };
    },
    setSiteHealthLoading: (state, action: PayloadAction<boolean>) => {
      state.siteHealth.loading = action.payload;
    },
    clearAuthStore: () => initialState,
  },
});

export const {
  setCurrentUser,
  setGroups,
  setFavoriteGroups,
  setSelectedSite,
  setSelectedSiteName,
  setSelectedSiteDefaultHomeReport,
  setSelectedGroup,
  setAuthStatus,
  setSocketio,
  setGridView,
  setUserGroupAndSite,
  setSiteHealth,
  setSiteHealthLoading,
  clearAuthStore,
  setVersion,
  setAuthMethods,
} = authSlice.actions;

export default authSlice.reducer;
