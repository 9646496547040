import {
  AccountTreeOutlined,
  DashboardOutlined,
  LinkOutlined,
  NotificationsNoneOutlined,
  ReceiptOutlined,
  SettingsOutlined,
  ShuffleOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';

import { ShortcutCategory } from '@dametis/core';

import { BlocksOutlined } from 'assets/icons/BlocksOutlined';
import { DeployedCodeOutlined } from 'assets/icons/DeployedCodeOutlined';
import { PlaygroundOutlined } from 'assets/icons/PlaygroundOutlined';
import accountTree from 'assets/images/synoptic/accountTree.svg';
import dashboard from 'assets/images/synoptic/dashboard.svg';
import multilineChart from 'assets/images/synoptic/multilineChart.svg';
import notificationsNone from 'assets/images/synoptic/notificationsNone.svg';
import workOutline from 'assets/images/synoptic/workOutline.svg';
import { ShortcutConfig } from 'types/shortcut';

export const shortcutConfig: Record<ShortcutCategory, ShortcutConfig> = {
  [ShortcutCategory.SYNOPTIC]: {
    icon: AccountTreeOutlined,
    category: ShortcutCategory.SYNOPTIC,
    svg: accountTree,
    corporate: false,
    enabled: true,
  },
  [ShortcutCategory.ALARM]: {
    icon: NotificationsNoneOutlined,
    category: ShortcutCategory.ALARM,
    svg: notificationsNone,
    corporate: false,
    enabled: true,
  },
  [ShortcutCategory.REPORT]: {
    icon: DashboardOutlined,
    category: ShortcutCategory.REPORT,
    svg: dashboard,
    corporate: true,
    enabled: true,
  },
  [ShortcutCategory.PLAYGROUND]: {
    icon: PlaygroundOutlined,
    category: ShortcutCategory.PLAYGROUND,
    svg: multilineChart,
    corporate: true,
    enabled: true,
  },
  [ShortcutCategory.PROJECT]: {
    icon: WorkOutlineOutlined,
    category: ShortcutCategory.PROJECT,
    svg: workOutline,
    corporate: false,
    enabled: true,
  },
  [ShortcutCategory.TASK]: {
    icon: WorkOutlineOutlined,
    category: ShortcutCategory.TASK,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.COMMENT]: {
    icon: WorkOutlineOutlined,
    category: ShortcutCategory.COMMENT,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.SITE]: {
    icon: WorkOutlineOutlined,
    category: ShortcutCategory.SITE,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.ASSET]: {
    icon: WorkOutlineOutlined,
    category: ShortcutCategory.ASSET,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.VARIABLE]: {
    icon: WorkOutlineOutlined,
    category: ShortcutCategory.VARIABLE,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.BLOCK]: {
    icon: BlocksOutlined,
    category: ShortcutCategory.BLOCK,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.BLOCK_TYPE]: {
    icon: DeployedCodeOutlined,
    category: ShortcutCategory.BLOCK_TYPE,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.STANDARD_BLOCK]: {
    icon: DeployedCodeOutlined,
    category: ShortcutCategory.STANDARD_BLOCK,
    svg: workOutline,
    corporate: false,
    enabled: false,
  },
  [ShortcutCategory.HREF]: {
    icon: LinkOutlined,
    category: ShortcutCategory.HREF,
    enabled: false,
  },
  [ShortcutCategory.MACRO]: {
    icon: SettingsOutlined,
    category: ShortcutCategory.MACRO,
    enabled: false,
  },
  [ShortcutCategory.CART]: {
    icon: WorkOutlineOutlined,
    category: ShortcutCategory.CART,
    enabled: false,
  },
  [ShortcutCategory.CONTRACT]: {
    icon: ReceiptOutlined,
    category: ShortcutCategory.CONTRACT,
    enabled: false,
  },
  [ShortcutCategory.BILL]: {
    icon: ReceiptOutlined,
    category: ShortcutCategory.BILL,
    enabled: false,
  },
  [ShortcutCategory.BATCH]: {
    icon: SettingsOutlined,
    category: ShortcutCategory.BATCH,
    enabled: false,
  },
  [ShortcutCategory.MODEL]: {
    icon: ShuffleOutlined,
    category: ShortcutCategory.MODEL,
    enabled: false,
  },
};

export const shortcutConfigArr: ShortcutConfig[] = Object.values(shortcutConfig).filter(({ enabled }) => enabled);
