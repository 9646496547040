import { Components, Theme } from '@mui/material';

export default {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      backgroundColor: ownerState.elevation > 0 ? theme.palette.white : undefined,
      backgroundBlendMode: 'overlay',
      // '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
      //   backdropFilter: 'blur(20px) saturate(180%)',
      // },
    }),
    rounded: ({ theme }) => ({
      borderRadius: theme.others.paperBorderRadius,
    }),
  },
  defaultProps: {
    elevation: 0,
  },
} as Components<Theme>['MuiPaper'];
