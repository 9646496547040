import { Chip, ChipProps, styled, svgIconClasses, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { getColorFromValue, setColorLightness } from '../../../functions/color';
import { getFormattedValue } from '../UnitPicker/functions/getFormattedValue';

import { icons } from './icons';

interface Props extends ChipProps {
  covariance: number | null;
}

const CovarianceChip = styled(({ covariance, ...props }: Props) => {
  const { t } = useTranslation('global');
  if (!Number.isFinite(covariance)) return null;
  const Icon = icons[Math.min(Math.floor(covariance * (icons.length / 100)), icons.length - 1)];
  if (!Icon) return null;
  return (
    <Chip
      icon={
        <Tooltip title={t('covariance')}>
          <Icon />
        </Tooltip>
      }
      label={getFormattedValue({ value: covariance, baseUnit: '%' })}
      {...props}
    />
  );
})(({ covariance, disabled }) => {
  const color = setColorLightness(getColorFromValue({ value: covariance }), 35);
  return {
    backgroundColor: 'transparent',
    color,
    [`& .${svgIconClasses.root}`]: {
      color,
    },
    ...(disabled && { filter: 'grayscale(1)' }),
  };
});

export default CovarianceChip;
