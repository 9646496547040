/* eslint-disable no-return-assign */

import { BatchInfo } from '@dametis/core';

type Tree = Node[];

interface Node {
  uuid: string;
  name: string;
  children: Tree;
}

const flattenTree = (batches: BatchInfo[]): Record<string, any> => {
  const result = {};
  batches.forEach(batch => {
    result[batch.uuid] = {
      uuid: batch.uuid,
      name: batch.name,
    };
    if (batch?.children.length > 0) {
      const children = flattenTree(batch.children);
      Object.values(children).forEach(
        c =>
          (result[c.uuid] = {
            uuid: c.uuid,
            name: c.name,
          }),
      );
    }
  });
  return result;
};

export default flattenTree;
