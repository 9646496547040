import i18next from 'i18next';

import { sdk } from 'sdk';

import { ToastSeverity } from '../../types/toast';
import { TypedThunk } from '../index';
import { setInfos } from '../slices/currentBox';
import { addToast } from '../slices/toast';

import { getBoxesStatus } from './configuration';
import { displaySdkErrorToast } from './toasts';

export const getCurrentBox =
  (boxId: string): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { list: boxes } = getState().boxes;
    const box = boxes.find(x => x.uuid === boxId);
    if (!box) {
      return;
    }
    try {
      dispatch(setInfos(box));
      await dispatch(getBoxesStatus());
    } catch (err) {
      console.error(err);
      dispatch(addToast({ severity: ToastSeverity.WARNING, message: i18next.t('toast:errorBoxVersion') }));
    }
  };

export const syncBox =
  (boxId?: string): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const uuid = boxId ?? getState().currentBox.infos.uuid;

    try {
      dispatch(addToast({ severity: ToastSeverity.INFO, message: i18next.t('toast:waitingSyncConfigBox') }));
      await sdk.box.SyncConfig(uuid);

      await dispatch(getCurrentBox(uuid));
      dispatch(addToast({ severity: ToastSeverity.SUCCESS, message: i18next.t('toast:successSyncConfigBox') }));
    } catch (err) {
      dispatch(displaySdkErrorToast(err));
    }
  };
