export default {
  // home: {
  //   collaborators: 'Colaboradores',
  //   activeAlarms: 'Alarmas activas',
  //   triggeredOn: 'Desencadenada el',
  //   seeMore_open: 'Ver {{number}} otras alarmas',
  //   seeMore_close: 'Ocultar {{number}} alarmas',
  // },
  title: {
    reports: 'Informes',
    yourMetrics: 'Mis indicadores',
  },
  text: {
    noReports: 'Ningún informe',
    welcomeIdentity: 'Hola {{name}}',
    welcomeGroup: 'Estás en Corporate de',
    defaultSiteReport: 'Informe por defecto del sitio',
    defaultGroupReport: 'Informe por defecto del grupo',
  },
  label: {
    displayReport: 'Mostrar un informe',
  },
  button: {
    setDefault: 'Establecer como predeterminado',
    unselect: 'Deseleccionar',
    close: 'Cerrar',
    save: 'Guardar',
  },
  toast: {
    errorFetchingReports: 'No se pueden recuperar los informes.',
    errorUploadingPreview: 'Error durante la descarga de la imagen.',
    errorReadingFilePreview: 'No se puede leer el archivo.',
    errorFileTypePreview: 'Tipo de archivo incorrecto.',
    errorDeletingPreview: 'Error durante la eliminación de la imagen.',
    errorSelectingReport: 'Error durante la guardado del informe.',
  },
  tooltip: {
    editReport: 'Cambiar informe',
    unselectReport: 'Deseleccionar informe',
    editPhoto: `Changer d'image`,
    deletePhoto: `Supprimer l'image`,
    removeDefault: 'Vaciar informe por defecto',
    setDefaultGroup: 'Establecer como informe por defecto de {{group}}',
    setDefaultSite: 'Establecer como informe por defecto de {{site}}',
    unsetDefaultGroup: 'Vaciar informe por defecto de {{group}}',
    unsetDefaultSite: 'Vaciar informe por defecto de {{site}}',
  },
};
