import { FixedVariable, SourceColumn } from '@dametis/core';

import { SourceValue } from '../UI/SourceValuePicker/SourceValuePicker';

import { areSameSourceColumns } from './areSameSourceColumns';
import { isSourceColumnFixedVariable, isSourceColumnSkipped } from './isSourceColumnInList';

export const getColumnSourceValue = (
  column: SourceColumn,
  dateTimeSource: SourceColumn,
  referenceSource: SourceColumn,
  valueSource: SourceColumn,
  skippedColumns: SourceColumn[],
  fixedVariables: FixedVariable[],
): SourceValue => {
  if (areSameSourceColumns(dateTimeSource, column)) {
    return SourceValue.DATE_TIME;
  }
  if (areSameSourceColumns(referenceSource, column)) {
    return SourceValue.REFERENCE;
  }
  if (areSameSourceColumns(valueSource, column)) {
    return SourceValue.VALUE;
  }
  if (isSourceColumnSkipped(skippedColumns, column)) {
    return SourceValue.SKIPPED_COLUMN;
  }
  if (isSourceColumnFixedVariable(fixedVariables, column)) {
    return SourceValue.FIXED_VARIABLE;
  }
  return SourceValue.DEFAULT;
};
