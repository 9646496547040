import { styled, ToggleButton } from '@mui/material';

export const ToolbarWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(0.5),
  background: theme.palette.white,
  alignItems: 'center',
  borderRadius: theme.shape.borderRadius,
  flexWrap: 'wrap',
}));

export const ToolbarButton = styled(ToggleButton)(({ theme }) => ({
  border: '0',
  display: 'flex',
  background: 'none',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  cursor: 'pointer',
  verticalAlign: 'middle',
  height: '28px',
  width: '28px',
  '& svg': {
    fontSize: '20px',
  },
  '&.toolbarItem.disabled': {
    cursor: 'not-allowed',
  },
  '&.spaced': {
    marginRight: '2px',
  },
  '& i.format': {
    backgroundSize: 'contain',
    marginTop: '2px',
    verticalAlign: '-0.25em',
    opacity: '0.6',
    '&:disabled': {
      opacity: '0.2',
    },
  },
  '&.active': {
    backgroundColor: theme.palette.grey[300],
    '& i.format': {
      opacity: '1',
    },
  },
}));
