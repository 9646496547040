import { Box, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CalculationStats } from '@dametis/core';

import { getFormattedValue } from 'components/UI/UnitPicker/functions/getFormattedValue';

export interface ChartStatisticsProps {
  unit: string;
  stats: CalculationStats | null;
  isFetching: boolean;
}

const ChartStatistics: FC<ChartStatisticsProps> = ({ unit, stats, isFetching }) => {
  const { t } = useTranslation('calculationMenu');

  return (
    <Box px={1}>
      {!isFetching && stats && (
        <Box component="table" sx={{ borderSpacing: theme => `0 ${theme.spacing(1)}` }} width={1}>
          <Box component="tbody">
            {Object.entries(stats).map(([key, point]) => (
              <Box key={key} component="tr">
                <Box component="td">
                  <Typography variant="subtitle2">{t(`text.${key}`)}</Typography>
                </Box>
                <Box align="right" component="td">
                  {getFormattedValue({ value: point?.value, userUnit: key !== 'integral' ? unit : '' })}
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
      {!isFetching && stats === null && <Typography variant="subtitle2">{t('text.errorStatistics')}</Typography>}
      {isFetching && (
        <Box component="table" sx={{ borderSpacing: theme => `0 ${theme.spacing(1)}` }} width={1}>
          <Box component="tbody">
            {[...Array(5)].map((_nb, index) => (
              /* eslint-disable-next-line react/no-array-index-key */
              <Box key={index} component="tr">
                <Box component="td">
                  <Typography variant="subtitle2">
                    <Skeleton />
                  </Typography>
                </Box>
                <Box align="right" component="td">
                  <Typography variant="body1">
                    <Skeleton />
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ChartStatistics;
