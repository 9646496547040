import { Locale, OrderBy, UserAccountStatus } from '@dametis/core';

import { BoxStatus } from 'components/Admin/Boxes/Boxes/Boxes';

export default {
  title: {
    activities: 'Activités',
    users: 'Utilisateurs',
    roles: 'Rôles',
  },

  tooltip: {
    copy: 'Copier',
  },

  activities: {
    order: {
      [OrderBy.ASC]: 'Croissant',
      [OrderBy.DESC]: 'Décroissant',
    },
    allSelected: 'Tout sélectionner',
    ignorePeriod: 'Ignorer la période',
    label: {
      objectTypes_zero: "Types d'objet",
      objectTypes_one: "Types d'objet ({{count}})",
      objectTypes_other: "Types d'objet ({{count}})",
      types_zero: 'Verbes',
      types_one: 'Verbes ({{count}})',
      types_other: 'Verbes ({{count}})',
      users_zero: 'Utilisateur',
      users_one: 'Utilisateurs ({{count}})',
      users_other: 'Utilisateurs ({{count}})',
    },
    columns: {
      published: 'Date',
      type: 'Verbe',
      actor: 'Acteur',
      objectType: "Type d'objet",
      objectName: 'Nom',
    },
    searchParamsChanged: 'Les paramètres de recherche ont changé.',
    detailTitlePanel: 'Détails',
    closeDetailPanel: 'Fermer',
    restoreEntity: 'Restaurer',
    successfullyRestore: "L'entité a été restauré",
    impossibleRestoreOnDeletedEntites: 'Restauration impossible pour les entités supprimées',
  },

  users: {
    inviteUser: 'Utilisateur',
    emailFirstNameLastName: 'Email, prénom, nom',
    canAccessPermission: 'A la permission',
    newUser: 'Nouvel utilisateur',
    columns: {
      name: 'Nom',
      email: 'Email',
      password: 'Mot de passe',
      firstName: 'Prénom',
      lastName: 'Nom',
      status: 'Statut',
      preferences: {
        locale: 'Langue',
        useSiteTimeZone: 'Utiliser le fuseau horaire du site',
      },
      global: 'Global',
      group: 'Group',
      site: 'Site',
    },
    status: {
      status: 'statut',
      [UserAccountStatus.ACTIVE]: 'Actif',
      [UserAccountStatus.INVITING]: 'Invitation',
      [UserAccountStatus.LOCKED]: 'Bloqué',
    },
    locale: {
      [Locale.FR]: 'Français',
      [Locale.EN]: 'Anglais',
      [Locale.ES]: 'Espagnol',
    },
    tabs: {
      information: 'Information',
      permissions: 'Permissions',
      entities: 'Entités',
    },
    edit: 'Modifier',
    save: 'Sauvegarder',
    supprimer: 'Supprimer',
    cancel: 'Annuler',
    impersonate: "Prendre l'identité",
    sendInvitationEmailAgain: "Renvoyer un email d'invitation",
    global: 'Global',
    groups: 'Groupes',
    sites: 'Sites',
    roles: 'Roles',
    permissions: 'Permissions',
    addGroup: 'GROUPE',
    addSite: 'SITE',
    addRole: 'ROLE',
    add: 'Ajouter',
    noGroup: 'Aucun groupe',
    noSite: 'Aucun site',
    noRole: 'Aucun rôle',
  },

  roles: {
    roles: 'Rôles',
    filterByPermission: 'Filtrer par permission',
    permissions: 'Permissions',
    text: {
      noOption: 'Aucune sélection',
    },
  },

  misc: {
    clearCache: 'Vider le cache API (LRU + Mongo)',
    syncConsul: 'Synchroniser les groupes Consul',
    deploy: "Deployer l'API",
  },

  boxes: {
    newBox: 'Nouvelle box',
    refresh: 'Rafraîchir',
    addBox: 'Box',
    save: 'Sauvegarder',
    edit: 'Modifier',
    cancel: 'Annuler',
    create: 'Créer',
    title: {
      boxes: 'Boxes',
    },
    nameReferenceMachineId: 'Nom, réference, machine id',
    boxStatus: {
      [BoxStatus.ONLINE]: 'CONNECTÉE',
      [BoxStatus.OFFLINE]: 'DÉCONNECTÉE',
      [BoxStatus.UNKNOWN]: 'INCONNU',
    },

    columns: {
      name: 'Nom',
      uuid: 'UUID',
      reference: 'Reference',
      status: 'Statut',
      machineId: 'MachineID',
      nbDevice: "Nombre d'équipements",
      site: 'Site',
      lastSeen: 'Dernière donnée',
    },
  },

  groups: {
    newGroup: 'Nouveau groupe',
    refresh: 'Rafraîchir',
    addGroup: 'Groupe',
    save: 'Sauvegarder',
    edit: 'Modifier',
    armageddon: 'Armageddon',
    cancel: 'Annuler',
    create: 'Créer',
    title: {
      groups: 'Groupes',
    },
    prompt: {
      armageddon:
        'Le mode Armageddon optimise les tables SQL et effectue un recalcul complet de toutes les variables ; utilisez cette fonctionnalité seulement si vous êtes bien sûr de ce que vous faites',
      scoresArmageddon:
        "L'armageddon des scores recalcule les corrélations sur l'entièreté de l'historique du groupe. Le recalcul peut prendre plusieurs heures. Utilisez cette fonctionnalité seulement si vous êtes bien sûr de ce que vous faites",
    },
    nameNormalizedNameDescription: 'Nom, nom normalisé, uuid...',
    columns: {
      uuid: 'UUID',
      name: 'Nom',
      normalizedName: 'Nom normalisé',
      description: 'Description',
      nbSites: 'Nombre de sites',
      region: 'Région',
      calculus: 'Calculus',
    },
    scores: {
      lastScore: 'Dernier score',
      resetScores: 'Reset scores',
      working: 'En cours',
    },
  },

  sites: {
    save: 'Sauvegarder',
    edit: 'Modifier',
    cancel: 'Annuler',
    create: 'Créer',
    newSite: 'Nouveau site',
    refresh: 'Rafraîchir',
    addSite: 'Site',
    title: {
      sites: 'Sites',
    },
    nameUuid: 'Nom, Measurement, uuid...',
    columns: {
      name: 'Nom',
      uuid: 'UUID',
      group: 'Groupe',
      createdAt: 'Date de création',
      updatedAt: 'Dernière modification',
      address: 'Adresse',
      measurement: 'Measurement',
      dataDelay: 'Data delay',
      defaultRole: 'Rôle par défaut',
      allowedRoles: 'Rôles autorisés',
      timeZone: 'Timezone',
    },
  },

  authentications: {
    save: 'Sauvegarder',
    edit: 'Modifier',
    cancel: 'Annuler',
    create: 'Créer',
    newAuthentication: 'Nouvel authentication',
    delete: 'Supprimer',
    addAuthentication: 'Authentication',
    title: {
      authentications: 'Authentications',
    },
    allSitesAllowed: 'Tous',
    nameUuid: 'Nom, uuid...',
    selectType: 'Selectionnez un type',
    addFallback: 'Ajoutez une alternative',

    toast: {
      successAuthenticationUpdate: 'Authentication mise a jour',
      errorAuthenticationUpdate: "Erreur lors de la mise a jour de l'Authentication",
      successDelete: 'Authentication supprimee',
      errorDelete: "Erreur lors de la suppression de l'authentication",
    },

    twoFA: {
      enforced: '2FA Obligatoire',
      graceDuration: 'Periode de grace',
      enabled: 'Activee',
      disabled: 'Desactivee',
    },

    columns: {
      name: 'Nom',
      uuid: 'UUID',
      createdAt: 'Date de création',
      updatedAt: 'Dernière modification',
      type: 'type',
      emailDomains: "Domaines d'emails (separes de virgule ',')",
      sites: 'Sites autorisés',
      fallbacks: 'Alternatives',
      params: {
        type: 'Type',
        issuer: 'Issuer',
        callback: 'Callback',

        // OAuth2

        clientId: 'Client ID',
        clientSecret: 'Client secret',
        accessType: 'Access Type',
        scope: 'Scope',
        prompt: 'Prompt',
        state: 'State',
        hd: 'hd',

        // Password

        strength: 'Force',

        // SAML

        entryPoint: 'Entry point',
        cert: 'Cert',
        signatureAlgorithm: 'Signature algorithm',
        digestAlgorithm: 'Digest algorithm',
        responseSigned: 'Response signed',
        assertionsSigned: 'Assertions signed',
        identifierFormat: 'Identifier format',
        authnContext: 'AuthnContext',
        disableRequestedAuthnContext: 'Disable Requested AuthnContext',
      },
    },
  },
};
