import { Alert, alertClasses } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  user: {
    email: string;
    firstName: string;
    lastName: string;
  };
}
const ImpersonateBanner: FC<Props> = ({ user }) => {
  const { t } = useTranslation('header');
  return (
    <Alert severity="error" sx={{ maxHeight: 48, [`& .${alertClasses.message}`]: { p: 0 } }}>
      {t('impersonate.msg', { firstName: user.firstName, lastName: user.lastName })}
    </Alert>
  );
};

export default ImpersonateBanner;
