import { decomposeColor, recomposeColor } from '@mui/material';

import { TinyUserInfo, UserListInfo } from '@dametis/core';

import { theme } from 'theme';

import { map } from './numberMap';

interface RGB {
  r: number;
  g: number;
  b: number;
}
interface HSL {
  h: number;
  s: number;
  l: number;
}

const RGBToHSL = (rgb: RGB): HSL => {
  const r = rgb.r / 255;
  const g = rgb.g / 255;
  const b = rgb.b / 255;
  const cMin = Math.min(r, g, b);
  const cMax = Math.max(r, g, b);
  const delta = cMax - cMin;
  let h = 0;
  let s = 0;
  let l = 0;
  if (delta === 0) {
    h = 0;
  } else if (cMax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cMax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }
  h = Math.round(h * 60);
  if (h < 0) {
    h += 360;
  }
  l = (cMax + cMin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = Math.round(+(s * 100));
  l = Math.round(+(l * 100));
  return { h, s, l };
};

export const setColorLightness = (color: string, l = 50): string => {
  if (!color) return '';
  const {
    type,
    values: [x, y, z],
  } = decomposeColor(color);
  const { h, s } = type === 'rgb' ? RGBToHSL({ r: x, g: y, b: z }) : { h: x, s: y };
  return recomposeColor({
    type: 'hsl',
    values: [h, s, l],
  });
};

export const setColorSaturation = (color: string, s = 50): string => {
  if (!color) return '';
  const {
    type,
    values: [x, y, z],
  } = decomposeColor(color);
  const { h, l } = type === 'rgb' ? RGBToHSL({ r: x, g: y, b: z }) : { h: x, l: z };
  return recomposeColor({
    type: 'hsl',
    values: [h, s, l],
  });
};

const DEFAULT_COLORS = [
  '#ff1744',
  '#fd1c15',
  '#fb4f13',
  '#f98111',
  '#f7b30f',
  '#f5e50d',
  '#cef30b',
  '#97f009',
  '#61ee07',
  '#04ea12',
  '#04ea12',
];
export const INVERTED_DEFAULT_COLORS = [...DEFAULT_COLORS].reverse();

export const getColorFromValue = ({
  value,
  min = 0,
  max = 100,
  colors = DEFAULT_COLORS,
}: {
  value: number;
  min?: number;
  max?: number;
  colors?: string[];
}) => colors[Math.min(Math.floor(map(value, [min, max], [0, colors.length])), colors.length - 1)];

export const getColorFromPValue = (pValue: number): string => {
  if (pValue < 0.05) return DEFAULT_COLORS.at(-1);
  if (pValue < 0.1) return DEFAULT_COLORS[4];
  return DEFAULT_COLORS[0];
};

export const getColorFromR2 = (r2: number): string => {
  if (r2 > 0.85) return DEFAULT_COLORS.at(-1);
  if (r2 > 0.5) return DEFAULT_COLORS[4];
  return DEFAULT_COLORS[0];
};

export const getColorFromMape = (mape: number): string => {
  return getColorFromValue({ value: mape, colors: INVERTED_DEFAULT_COLORS });
};

export const chartColors = [
  theme.palette.picker.lime,
  theme.palette.picker.blue,
  theme.palette.picker.orange,
  theme.palette.picker.pink,
  theme.palette.picker.cyan,
  theme.palette.picker.red,
  theme.palette.picker.green,
  theme.palette.picker.yellow,
  theme.palette.picker.indigo,
  theme.palette.picker.teal,
];

export const getNextColor = (variables: { color?: string }[]): string => {
  const usedColors: Record<string, number> = chartColors.reduce(
    (acc, color) => ({ ...acc, [color]: variables.filter(variable => variable.color === color).length }),
    {},
  );
  let useLessColor: string;
  chartColors.forEach(color => {
    useLessColor = !useLessColor || usedColors[color] < usedColors[useLessColor] ? color : useLessColor;
  });
  return useLessColor;
};

export const getUserColor = (user: TinyUserInfo): string => (user?.uuid ? `#${user.uuid.slice(0, 6)}` : theme.palette.secondary.main);
export const getUserListColor = (list: UserListInfo): string => (list?.uuid ? `#${list.uuid.slice(0, 6)}` : theme.palette.secondary.main);
