export default {
  button: {
    cancel: 'Cancel',
    create: 'Create',
    yesDelete: 'Yes, delete',
    updateSteamMixTemplate: 'Update template',
    createSteamMix: 'Create vapor mix',
    downloadDataEntryTemplate: 'Download data entry file model',
    downloadFile: 'Download file',
    runCalculation: 'Run calculation',
    generateReport: 'Generate report',
    regenerateReport: 'Regenerate report',
    openReport: 'Open report',
    downloadResults: 'Download results',
  },
  input: {
    name: 'Name',
    year: 'Year',
    pastWeeks: 'Past weeks',
  },
  select: {
    dataEntryTemplate: 'Select a pre-filled template',
    empty: 'Empty',
  },
  text: {
    year: 'Year {{year}}',
    noFilling: "The data entry file won't be pre-populated.",
    filling: 'The data entry file will be pre-populated with the actual data from {{startAt}} to {{endAt}}.',
    noSteamMixes: 'No vapor mixes',
    steamMixRunning:
      'Calculation in progress, please refresh your page in a few minutes. For an annual model, this will take approximately 2 minutes. For a multi-year model, this will take up to 15 minutes.',
    steamMixError: 'An error occured while running the calculation.',
    confirmSteamMixDeletion: 'Are you sure you want to delete {{name}}',
    createdAt: 'Created {{date}}',
    createdAtBy: 'Created {{date}} by {{name}}',
  },
  title: {
    steamMixes: 'Vapor mixes',
    addSteamMix: 'Add vapor mix',
    downloadDataEntry: 'Download data entry file',
    importDataEntry: 'Import data entry file',
    steamMix: 'Vapor mix',
    deleteSteamMix: 'Delete vapor mix',
  },
  tooltip: {
    createSteamMix: 'Create vapor mix',
    deleteSteamMix: 'Delete vapor mix',
    updateSteamMixTemplate: 'Update template',
  },
};
