import { DataOperationContextName, DataOperationStatus, EditDataAction, ModifierType } from '@dametis/core';

export default {
  title: {
    dataHistory: 'Historique des opérations',
    history: 'Historique',
    details: 'Détails',
  },
  column: {
    owner: 'Auteur',
    action: 'Action',
    context: 'Contexte',
    createdAt: 'Initié le',
    updatedAt: 'Dernière mis à jour le',
    operationId: 'ID de l’opération',
    variableUuids: 'Variables concernées',
    conditions: 'Conditions',
    modifiers: 'Modificateurs',
    details: '',
    revert: '',
    retry: '',
    status: 'Status',
  },
  input: {
    label: {
      message: 'Payload',
      [ModifierType.LINEAR_INTERPOLATION]: 'Interpolation linéaire',
      [ModifierType.MULTIPLIER]: 'Multiplicateur',
      [ModifierType.OFFSET]: 'Décalage',
    },
  },
  button: {
    close: 'Fermer',
    copy: 'Copier',
    details: 'Détails',
    revert: 'Restaurer',
    retry: 'Réessayer',
    refresh: 'Rafraîchir',
  },
  text: {
    [EditDataAction.IMPORT]: 'Import',
    [EditDataAction.EDIT]: 'Edition',
    [EditDataAction.DELETE]: 'Suppression',
    [DataOperationContextName.DEFAULT]: 'Défaut',
    [DataOperationContextName.MANUAL_ENTRY]: 'Entrée manuelle',
    [DataOperationContextName.CONSTANT]: 'Constante',
    [DataOperationContextName.ADVANCED_ENTRY]: 'Correction données',
    [DataOperationContextName.ADMIN]: 'Admin',
    [DataOperationContextName.RESTORE]: 'Restauration',
    [DataOperationContextName.FILE_IMPORT]: 'Import de données',
    noOperation: 'Aucune opération',
    patch: {
      init: 'toutes les données',
      edit: 'Édition de ',
      delete: 'Suppression de ',
    },
  },
  toast: {
    revertOperationSuccess: 'Opération restaurée avec succès',
    revertOperationError: "Erreur lors de la restauration de l'opération",
    retryOperationSuccess: 'Opération réessayée avec succès',
    retryOperationError: 'Erreur lors de l’opération',
    copySuccess: 'Copié dans le presse-papiers',
  },
  tooltip: {
    variableConflict: 'Une opération est déjà en cours sur cette variable. ',
    viewHistory: 'Voir l’historique',
    [DataOperationStatus.DONE]: 'Succès',
    [DataOperationStatus.FAILED]: 'Échec',
    [DataOperationStatus.PENDING]: 'En attente',
  },
};
