import { ClearAll } from '@mui/icons-material';
import { Box, CircularProgress, Divider, List, ListSubheader, Stack, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { FC, Fragment, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DataOperationInfo,
  DataOperationStatus,
  Dict,
  OperationInfo,
  OperationStates,
  OperationStatus,
  OperationType,
  PromiseLimit,
} from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import { setOperationStorage } from 'functions/operations';
import { usePermission } from 'hooks/usePermission';
import { sdk } from 'sdk';
import { useDispatch, useSelector } from 'store';
import { deleteOperation, updateStates } from 'store/actions/operation';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { setOperations } from 'store/slices/operations';

import PopperPanel from '../../../UI/PopperPanel/PopperPanel';
import BigButton from '../BigButton/BigButton';
import BigButtonDescription from '../BigButton/BigButtonDescription';
import BigButtonIcon from '../BigButton/BigButtonIcon';
import BigButtonTitle from '../BigButton/BigButtonTitle';

import OperationItem from './OperationItem';
import useOperationMenuStyles from './OperationMenu.styles';
import OperationStatusIcon from './OperationStatusIcon';

interface Props {
  className?: string;
}

const OperationMenu: FC<Props> = ({ className = '' }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('header');
  const classes = useOperationMenuStyles();

  const operations = useSelector(state => state.operations.list);
  const variablesStore = useSelector(state => state.variables.byId);
  const groupId = useSelector(state => state.auth.selectedGroup.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);
  const canAccessDataHistory = usePermission('canAccessDataHistory');

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState<OperationStatus>(OperationStatus.SUCCESS);
  const [buttonProgress, setButtonProgress] = useState<number | null>(null);
  const [buttonLabel, setButtonLabel] = useState<string>('...');
  const [tooltipTexts, setTooltipTexts] = useState<string[]>([]);
  const [cachedDataOperations, setCachedDataOperations] = useState<Dict<DataOperationInfo>>({});

  const menuAnchorEl = useRef(null);
  const interval = useRef<ReturnType<typeof setTimeout>>();

  const toggleMenu = useCallback(() => {
    setMenuOpen(s => !s);
  }, []);

  const fetchData = useCallback(async () => {
    const loadingOperations = operations.filter(operation => operation.status === OperationStatus.LOADING);
    const loadingDataOperations = loadingOperations.filter(
      operation =>
        operation.type === OperationType.IMPORT_DATA ||
        operation.type === OperationType.EDIT_DATA ||
        operation.type === OperationType.DELETE_DATA,
    );

    if (loadingDataOperations.length > 0) {
      try {
        const dataOperations = await PromiseLimit.do(
          loadingDataOperations,
          async operation => (await sdk.dataOperation.Read(operation.entityUuid)).data,
        );
        setCachedDataOperations(
          dataOperations.reduce((acc, dataOperation) => {
            acc[dataOperation.uuid] = dataOperation;
            return acc;
          }, {}),
        );

        const newStates: OperationStates = {};
        loadingDataOperations.forEach((operation, index) => {
          switch (dataOperations[index].status) {
            case DataOperationStatus.DONE:
              newStates[operation.uuid] = { status: OperationStatus.SUCCESS };
              break;
            case DataOperationStatus.FAILED:
              newStates[operation.uuid] = { status: OperationStatus.ERROR };
              break;
            case DataOperationStatus.PENDING:
              newStates[operation.uuid] = { status: OperationStatus.LOADING };
              break;
            default:
              newStates[operation.uuid] = { status: OperationStatus.ERROR };
              break;
          }
        });

        dispatch(updateStates(newStates));
      } catch (error) {
        console.error(error);
        dispatch(displaySdkErrorToast(error));
      }
    }

    const loadingTadaOperations = loadingOperations.filter(
      operation =>
        operation.type === OperationType.CREATE_BATCH ||
        operation.type === OperationType.CREATE_CALC_VAR ||
        operation.type === OperationType.EDIT_BATCH ||
        operation.type === OperationType.EDIT_CALC_VAR,
    );
    if (loadingTadaOperations.length > 0) {
      try {
        const { data } = await sdk.tada.Progress(
          groupId,
          loadingTadaOperations.map(operation => ({ uuid: operation.entityUuid })),
        );
        const newStates: OperationStates = {};
        loadingTadaOperations.forEach((operation, i) => {
          switch (data[i]) {
            case 100:
              newStates[operation.uuid] = { status: OperationStatus.SUCCESS, progress: data[i] };
              break;
            case -1:
              newStates[operation.uuid] = { status: OperationStatus.WARNING, progress: data[i] };
              break;
            case -99:
              newStates[operation.uuid] = { status: OperationStatus.ERROR, progress: data[i] };
              break;
            default:
              newStates[operation.uuid] = { status: OperationStatus.LOADING, progress: data[i] };
          }
        });

        dispatch(updateStates(newStates));
      } catch (err) {
        console.error(err);
        dispatch(displaySdkErrorToast(err));
      }
    }
  }, [dispatch, groupId, operations]);

  const getGoTo = useCallback(
    (type: OperationType, uuid: OperationInfo['entityUuid']) => {
      switch (type) {
        case OperationType.CREATE_CALC_VAR:
        case OperationType.EDIT_CALC_VAR:
          return `/configuration/variables?selectedVariables=${uuid}`;

        case OperationType.CREATE_BATCH:
        case OperationType.EDIT_BATCH:
          return `/configuration/batches/${uuid}`;

        case OperationType.EDIT_DATA:
        case OperationType.IMPORT_DATA:
        case OperationType.DELETE_DATA:
          if (canAccessDataHistory) {
            return `/configuration/dataHistory`;
          }
          return null;

        default:
          return null;
      }
    },
    [canAccessDataHistory],
  );

  const getName = useCallback(
    (type: OperationType, uuid: OperationInfo['entityUuid']) => {
      switch (type) {
        case OperationType.CREATE_CALC_VAR:
          return t(`operationMenu.operations.${type}.name`, {
            ...(variablesStore[uuid] && { context: 'withName', name: variablesStore[uuid].name }),
          });
        case OperationType.EDIT_CALC_VAR:
          return t(`operationMenu.operations.${type}.name`, {
            ...(variablesStore[uuid] && { context: 'withName', name: variablesStore[uuid].name }),
          });
        case OperationType.CREATE_BATCH:
          return t(`operationMenu.operations.${type}.name`);
        case OperationType.EDIT_BATCH:
          return t(`operationMenu.operations.${type}.name`);
        case OperationType.IMPORT_DATA:
          return t(`operationMenu.operations.${type}.name`, {
            ...(cachedDataOperations[uuid] && { context: 'withNumber', number: cachedDataOperations[uuid].variableUuids.length }),
          });
        case OperationType.EDIT_DATA:
          return t(`operationMenu.operations.${type}.name`, {
            ...(cachedDataOperations[uuid] && { context: 'withNumber', number: cachedDataOperations[uuid].variableUuids.length }),
          });
        case OperationType.DELETE_DATA:
          return t(`operationMenu.operations.${type}.name`, {
            ...(cachedDataOperations[uuid] && { context: 'withNumber', number: cachedDataOperations[uuid].variableUuids.length }),
          });
        default:
          return null;
      }
    },
    [t, variablesStore, cachedDataOperations],
  );

  const deleteItem = useCallback(
    (uuid: OperationInfo['uuid']) => () => {
      dispatch(deleteOperation(uuid));
    },
    [dispatch],
  );

  const handleClearOperations: MouseEventHandler = useCallback(() => {
    dispatch(setOperations([]));
    setOperationStorage(siteId, []);
    setMenuOpen(false);
  }, [dispatch, siteId]);

  useEffect(() => {
    const loadingOps = operations.filter(operation => operation.status === OperationStatus.LOADING);
    const errorOps = operations.filter(operation => operation.status === OperationStatus.ERROR);
    const warningOps = operations.filter(operation => operation.status === OperationStatus.WARNING);
    const successOps = operations.filter(operation => operation.status === OperationStatus.SUCCESS);
    if (loadingOps.length > 0) {
      setButtonState(OperationStatus.LOADING);
      let globalProgress =
        loadingOps.length > 0 ? Math.round(loadingOps.reduce((acc, op) => acc + op.progress, 0) / loadingOps.length) : null;
      if (Number.isNaN(globalProgress)) globalProgress = null;
      setButtonProgress(globalProgress);
      setButtonLabel(globalProgress !== null ? `${globalProgress} %` : '...');
    } else if (errorOps.length > 0) {
      setButtonState(OperationStatus.ERROR);
      setButtonLabel(t('operationMenu.buttonLabel.error', { count: errorOps.length }));
    } else if (warningOps.length > 0) {
      setButtonState(OperationStatus.WARNING);
      setButtonLabel(t('operationMenu.buttonLabel.warning', { count: warningOps.length }));
    } else {
      setButtonState(OperationStatus.SUCCESS);
      setButtonLabel(t('operationMenu.buttonLabel.over', { count: operations.length }));
    }
    setTooltipTexts([
      ...(loadingOps.length > 0 ? [t(`operationMenu.tooltip.${OperationStatus.LOADING}.text`, { count: loadingOps.length })] : []),
      ...(successOps.length > 0 ? [t(`operationMenu.tooltip.${OperationStatus.SUCCESS}.text`, { count: successOps.length })] : []),
      ...(warningOps.length > 0 ? [t(`operationMenu.tooltip.${OperationStatus.WARNING}.text`, { count: warningOps.length })] : []),
      ...(errorOps.length > 0 ? [t(`operationMenu.tooltip.${OperationStatus.ERROR}.text`, { count: errorOps.length })] : []),
    ]);
  }, [operations, t]);

  const timeout = useMemo(() => {
    const minNotChanged = Math.min(...operations.filter(op => op.status === OperationStatus.LOADING).map(op => op.notChanged));
    return Math.exp(minNotChanged / 2) * 400;
  }, [operations]);

  useEffect(() => {
    if (operations.length > 0) {
      interval.current = setTimeout(async () => {
        await fetchData();
      }, timeout);
    }
    return () => clearInterval(interval.current);
  }, [operations.length, timeout, fetchData]);

  return (
    <>
      <Tooltip
        placement="bottom"
        title={
          tooltipTexts.length
            ? tooltipTexts.map((text, i) => (
                <Fragment key={text}>
                  {i !== 0 && <br />}
                  {text}
                </Fragment>
              ))
            : ''
        }
      >
        <BigButton
          ref={menuAnchorEl}
          className={clsx(className, classes.openBtnWithCircularProgress)}
          labelClassName={classes.openBtn__label}
          onClick={toggleMenu}
        >
          <BigButtonIcon className={clsx(classes.openBtn__icon, classes[`openBtn__icon--${buttonState}`])}>
            <CircularProgress
              color="secondary"
              size={27}
              thickness={2.7}
              value={buttonState !== OperationStatus.LOADING ? 100 : buttonProgress}
              variant={buttonProgress || buttonState !== OperationStatus.LOADING ? 'determinate' : 'indeterminate'}
            />
            <OperationStatusIcon fontSize="small" status={buttonState} />
          </BigButtonIcon>
          <BigButtonTitle>{t('operationMenu.title')}</BigButtonTitle>
          <BigButtonDescription>{buttonLabel}</BigButtonDescription>
        </BigButton>
      </Tooltip>
      <PopperPanel
        anchorEl={menuAnchorEl.current}
        open={menuOpen}
        placement={{ vertical: 'bottom', horizontal: 'right' }}
        onClickAway={toggleMenu}
      >
        <List sx={{ maxHeight: 300, position: 'relative', overflow: 'auto', '& ul': { padding: 0 } }}>
          <li>
            <ul>
              <ListSubheader disableGutters sx={{ zIndex: 2, borderBottom: theme => `1px solid ${theme.palette.divider}` }}>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                  <Box>{t('operationMenu.title')}</Box>
                  <ActionButton disabled={operations.length === 0} startIcon={<ClearAll />} onClick={handleClearOperations}>
                    {t('operationMenu.clear')}
                  </ActionButton>
                </Stack>
              </ListSubheader>
              {operations.map((operation, i) => (
                <Fragment key={operation.uuid}>
                  {i !== 0 && <Divider />}
                  <OperationItem
                    deleteItem={deleteItem(operation.uuid)}
                    description={
                      operation.status !== OperationStatus.LOADING
                        ? t(`operationMenu.operations.${operation.type}.description`, { context: operation.status })
                        : undefined
                    }
                    goTo={getGoTo(operation.type, operation.entityUuid)}
                    name={getName(operation.type, operation.entityUuid)}
                    progress={operation.progress}
                    status={operation.status}
                    type={operation.type}
                  />
                </Fragment>
              ))}
            </ul>
          </li>
        </List>
      </PopperPanel>
    </>
  );
};

export default OperationMenu;
