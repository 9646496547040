import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BoxInfo,
  DamcoSubPeriod,
  DeviceInfo,
  RawBoxDamcoResult,
  RawBoxDeviceDamcoResult,
  RawSiteBoxesDamcoResult,
  UUID,
} from '@dametis/core';

import { RelativeToNowPeriod } from 'components/UI/RelativeToNowPeriodPicker/RelativeToNowPeriodPicker';

export interface DamcoBoxInfo extends RawSiteBoxesDamcoResult {
  box: BoxInfo;
}

export interface DamcoSiteDeviceInfo extends RawBoxDeviceDamcoResult {
  device: DeviceInfo;
}

export interface DamcoSiteInfo extends Omit<RawBoxDamcoResult, 'devices'> {
  devices: DamcoSiteDeviceInfo[];
}

export enum DamcoSubPeriodType {
  LAG = 'lag',
  HOLE = 'hole',
}

export interface LongDamcoSubPeriod extends DamcoSubPeriod {
  uuid: UUID;
  type: DamcoSubPeriodType;
  isCurrentLag?: boolean;
}

export interface MonitoringState {
  period: RelativeToNowPeriod;
  selectedBox: string;
  boxes: DamcoBoxInfo[] | null;
  devices: DamcoSiteInfo | null;
}

const initialState: MonitoringState = {
  period: RelativeToNowPeriod.THREE_DAYS,
  selectedBox: null,
  boxes: null,
  devices: null,
};

export const monitoringSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    setPeriod: (state, action: PayloadAction<RelativeToNowPeriod>) => {
      state.period = action.payload;
    },
    // Damco boxes => pour le zoomSite a renommer
    setBoxes: (state, action: PayloadAction<DamcoBoxInfo[]>) => {
      state.boxes = action.payload;
    },
    setDevices: (state, action: PayloadAction<DamcoSiteInfo>) => {
      state.devices = action.payload;
    },
  },
});

export const { setBoxes, setDevices, setPeriod } = monitoringSlice.actions;

export default monitoringSlice.reducer;
