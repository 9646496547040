export default {
  title: 'Group settings',
  groupMenu: {
    informations: {
      name: 'Information',
      description: '',
    },
    users: {
      name: 'Users',
      description: '',
    },
  },
  informations: {
    form: {
      siteName: 'Site name',
      groupName: 'Group name',
      defaultReport: 'Home default report',
      selectReport: 'Select a report',
      noReport: 'No default report',
      address: 'Address',
      language: 'Site language',
      timeZone: 'Time zone',
      noTimeZoneFound: 'No time zone found',
    },
  },
  toast: {
    successSaveChanges: 'The changes have been saved.',
    errorSaveChanges: 'Unable to save changes',
    infoUserInvitation: 'An invitation email has been sent.',
    errorUserInvitation: 'Unable to send the email invitation',
    userAlreadyExists: 'This email address is already associated with an account.',
    successNewPeriod: 'The new period is recorded.',
    successRemovePeriod: 'The period has been suppressed.',
  },
  tooltip: {
    editGroupInfo: 'Edit group informations',
  },
  modal: {
    title: 'Edit user',
    addTitle: 'Add a user',
    personalInformation: 'Personal information',
    permissions: 'Permissions',
    firstName: 'First name',
    lastName: 'Last name',
    phone: 'Phone',
    email: 'Email',
    corporateLabel: 'Access to the group',
    corporate: 'Corporate',
    userSiteList: 'User sites',
    warningEmptySites: 'The user must have at least one site',
    select: {
      groupRole: 'Group role',
      siteRole: 'Site role ',
      roleSITE_ADMINPrimary: 'Administrator',
      roleSITE_ADMINSecondary: 'Administration, read and write',
      roleCLIENTPrimary: 'Standard user',
      roleCLIENTSecondary: 'Read and write',
      roleReadOnlyPrimary: 'Limited user',
      roleReadOnlySecondary: 'Read only',
    },
    button: {
      next: 'Suivant',
      previous: 'Précédent',
    },
  },
  stepper: {
    informations: 'Informations',
    permissions: 'Permissions',
  },
};
