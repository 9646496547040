import { CreateTagBody, MergeTagsParams, TagInfo, TagsOfSiteUsagesInfo, UUID, UpdateTagBody } from '@dametis/core';

import { sdk } from 'sdk';
import store, { RootState, useSelector } from 'store';

import { api } from './index';

const tagsApi = api.injectEndpoints({
  endpoints: build => ({
    readTags: build.query<TagInfo[], { siteId?: UUID } | void>({
      providesTags: ['Tags'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const { siteId } = arg || { siteId: state.auth.selectedSite?.uuid };
        try {
          if (!siteId) {
            return { data: [] };
          }
          const { data } = await sdk.tag.List(siteId);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    readTagsDependencies: build.query<TagsOfSiteUsagesInfo, { siteId?: string } | void>({
      providesTags: ['TagsDependencies'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const siteId = (arg && arg.siteId) || state.auth.selectedSite?.uuid;
        try {
          if (!siteId) {
            return { data: undefined };
          }
          const { data } = await sdk.tag.ListUsagesOfSite(siteId);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    createTag: build.mutation<TagInfo, CreateTagBody>({
      invalidatesTags: ['Tags', 'TagsDependencies'],
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        try {
          if (!siteId) {
            throw new Error('No site ID');
          }
          const { data } = await sdk.tag.Create(siteId, body);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    updateTag: build.mutation<TagInfo, { body: UpdateTagBody; id: UUID }>({
      invalidatesTags: ['Tags', 'TagsDependencies'],
      queryFn: async ({ body, id }) => {
        try {
          const { data } = await sdk.tag.Update(id, body);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    mergeTags: build.mutation<TagInfo, MergeTagsParams>({
      invalidatesTags: ['Tags', 'TagsDependencies'],
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        try {
          if (!siteId) {
            throw new Error('No site ID');
          }
          const { data } = await sdk.tag.Merge(siteId, body);
          await sdk.tag.DeleteBulk(
            siteId,
            body.sourceTagIds.filter(tagId => tagId !== body.targetTag),
          );
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    deleteTags: build.mutation<UUID[], UUID[]>({
      invalidatesTags: ['Playgrounds', 'Reports', 'Synoptics', 'Tags', 'TagsDependencies'],
      queryFn: async (body, { getState }) => {
        const state = getState() as RootState;
        const siteId = state.auth.selectedSite?.uuid;
        try {
          if (!siteId) {
            throw new Error('No site ID');
          }
          await sdk.tag.DeleteBulk(siteId, body);
          return { data: body };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    restoreTag: build.mutation<UUID, { activityId: UUID; tagId: UUID }>({
      invalidatesTags: ['Tags', 'TagsDependencies'],
      queryFn: async ({ activityId, tagId }) => {
        try {
          await sdk.tag.Restore(tagId, activityId);
          return { data: tagId };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
  }),
});

export const { useCreateTagMutation, useUpdateTagMutation, useMergeTagsMutation, useDeleteTagsMutation, useRestoreTagMutation } = tagsApi;

export const selectTags: typeof tagsApi.endpoints.readTags.select = arg =>
  tagsApi.endpoints.readTags.select(arg ?? { siteId: store.getState().auth.selectedSite?.uuid });

export const useTags: typeof tagsApi.useReadTagsQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return tagsApi.useReadTagsQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};

export const useTagsDependencies: typeof tagsApi.useReadTagsDependenciesQuery = (arg, options) => {
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return tagsApi.useReadTagsDependenciesQuery(arg ?? { siteId }, { skip: !siteId, ...options });
};
