import i18next from 'i18next';
import { AnyAction } from 'redux';

import { BatchVariable, CalculationVariable, VarCalc } from '@dametis/core';
import { mathJs, mathJsTypes } from '@dametis/mathjs';

import store from 'store';
import { fetchBatches } from 'store/actions/batch';

export const batchToApiQuery = (query: { type: string; content: any }[]): { exp: string; vars: Record<string, any> } => {
  const { operator } = store.getState().batch.filters;
  const parseQuery = q => {
    let exp = '';
    const vars = {};
    q.forEach(({ type, content }, i) => {
      if (type === 'batch') {
        exp += `batch_${i}`;
        vars[`batch_${i}`] = { batchUuid: content.uuid };
      } else {
        exp += ` ${content} `;
      }
    });
    return {
      exp,
      vars,
      operator,
      flags: {
        changeOnly: true,
      },
    };
  };
  return parseQuery(query);
};

export const apiQueryToBatch = (query: CalculationVariable): any[] => {
  const { flattenBatches } = store.getState().batch;
  if (!flattenBatches) store.dispatch(fetchBatches() as unknown as AnyAction);
  const parseExpression = (exp: mathJsTypes.MathNode, vars: Record<string, BatchVariable>, arr) => {
    if (mathJs.isOperatorNode(exp)) {
      parseExpression(exp.args[0], vars, arr);
      arr.push({ type: 'operator', content: exp.op });
      parseExpression(exp.args[1], vars, arr);
    } else if (mathJs.isSymbolNode(exp)) {
      const batch = flattenBatches?.find(b => b.uuid === vars[exp.name]?.batchUuid);
      if (batch) {
        arr.push({ type: 'batch', content: batch });
      }
    }
  };
  const newQuery = [];
  parseExpression(mathJs.parse(query.exp), query.vars as Record<string, BatchVariable>, newQuery);
  return newQuery;
};

export const apiQueryToString = (query: CalculationVariable): string => {
  const { flattenBatches } = store.getState().batch;
  if (!flattenBatches) store.dispatch(fetchBatches() as unknown as AnyAction);
  const parseExpression = (exp: mathJsTypes.MathNode, vars: Record<string, VarCalc>, arr) => {
    if (mathJs.isOperatorNode(exp)) {
      parseExpression(exp.args[0], vars, arr);
      arr.push(exp.op);
      parseExpression(exp.args[1], vars, arr);
    } else if (mathJs.isSymbolNode(exp)) {
      const batch = flattenBatches?.find(b => b.uuid === (vars[exp.name] as BatchVariable).batchUuid);
      if (batch) {
        arr.push(batch.name);
      }
    }
  };
  const newQuery = [];
  parseExpression(mathJs.parse(query.exp), query.vars, newQuery);
  return newQuery.join(' ') || i18next.t('global:value.unknownBatch');
};
