import { Avatar, AvatarProps, capitalize, Tooltip, useTheme } from '@mui/material';
import clsx from 'clsx';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PhysicalQuantity } from '@dametis/core';

import { getPhysicalQuantities } from 'config/physicalQuantities';

import { usePQStyles } from './PhysicalQuantity.styles';

interface Props extends AvatarProps {
  physicalQuantity: PhysicalQuantity;
  disableTooltip?: boolean;
  size?: 'small' | 'medium' | 'default';
  outlined?: boolean;
}

const PhysicalQuantityAvatar = forwardRef<HTMLDivElement, Props>(
  ({ physicalQuantity, disableTooltip = false, size = 'small', outlined = false, className, sx, ...props }, ref) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const pQ = useMemo(() => {
      const pQs = getPhysicalQuantities(t, theme);
      return pQs[physicalQuantity] ?? pQs[PhysicalQuantity.OTHER];
    }, [physicalQuantity, t, theme]);

    const classes = usePQStyles();
    return (
      <Tooltip title={disableTooltip ? '' : capitalize(pQ.name)}>
        <Avatar
          ref={ref}
          className={clsx(
            classes.avatar,
            size === 'small' && classes['avatar--small'],
            size === 'medium' && classes['avatar--medium'],
            className,
          )}
          sx={{
            outlineWidth: 1,
            outlineStyle: 'solid',
            outlineColor: !outlined ? 'transparent' : pQ.color,
            backgroundColor: outlined ? '#FFFFFF' : pQ.color,
            color: `${!outlined ? '#FFFFFF' : pQ.color}!important`,
            ...sx,
          }}
          variant="rounded"
          {...props}
        >
          {pQ.symbol ?? ''}
        </Avatar>
      </Tooltip>
    );
  },
);

PhysicalQuantityAvatar.defaultProps = {
  disableTooltip: false,
  size: 'small',
  outlined: false,
};

PhysicalQuantityAvatar.displayName = 'PhysicalQuantityAvatar';

export default PhysicalQuantityAvatar;
