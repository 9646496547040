import { CalculationVariable, VarCalc } from '@dametis/core';

export const createCalculationVariable = <T extends VarCalc = VarCalc>({
  exp = '',
  vars = {},
  ...rest
}: Partial<CalculationVariable<T>> = {}): CalculationVariable<T> => ({
  exp,
  vars,
  ...rest,
});
