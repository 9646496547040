import {
  Checkbox,
  CheckboxProps,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Theme,
  Tooltip,
  Typography,
  Unstable_Grid2 as Grid2,
  useMediaQuery,
} from '@mui/material';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { areEqual, ListChildComponentProps } from 'react-window';

import { AreSameVarCalc } from '@dametis/core';

import { getPhysicalQuantities } from 'config';
import { setColorLightness } from 'functions/color';
import { getVariableKind } from 'functions/getVariableKind';
import { useSelector } from 'store';
import { palette } from 'theme/palette';
import { useVncStore } from 'zustand/stores/vnc';

import CovarianceChip from '../../../../UI/CovarianceChip/CovarianceChip';
import PhysicalQuantityAvatar from '../../../../UI/PhysicalQuantity/PhysicalQuantityAvatar';
import PhysicalQuantityChip from '../../../../UI/PhysicalQuantity/PhysicalQuantityChip';
import VariableKindIcon from '../../../../UI/VariableKindIcon/VariableKindIcon';
import { PropsContext } from '../../../context';
import { useInsertVariable } from '../../../hooks';
import { VariableResult } from '../../../types';
import { TypographyNoBreak } from '../TypographyNoBreak';

const ListItemVariable = memo<ListChildComponentProps<VariableResult[]>>(({ style, index, data }) => {
  const { t } = useTranslation('vnc');

  const { multiple, defaultVariableOperator, disableMaths, covarianceVariable, calculatedVariableMode, defaultGlobalOperator } =
    useContext(PropsContext);

  const vncHighlight = useSelector(state => state.auth.user.preferences.vncHighlight);
  const isJCE = useSelector(state => state.auth.user.email.includes('jeremy.cella'));

  const variable = useMemo(() => data[index], [data, index]);
  const color = useMemo(() => getPhysicalQuantities()[variable.physicalQuantity]?.color, [variable.physicalQuantity]);

  const insertVariable = useInsertVariable();
  const selection = useVncStore(state => state.selection);
  const someSelected = useVncStore(state => Boolean(state.selection.length));
  const addToSelection = useVncStore(state => state.addToSelection);
  const removeByUuidFromSelection = useVncStore(state => state.removeByUuidFromSelection);

  const selected = useMemo(
    () => selection.some(varCalc => AreSameVarCalc(varCalc, { variableUuid: variable.uuid })),
    [selection, variable],
  );

  const largeMQ = useMediaQuery<Theme>(theme => theme.breakpoints.up('lg'));
  const large = useMemo(() => (largeMQ ? !someSelected && !covarianceVariable : false), [largeMQ, someSelected, covarianceVariable]);

  const firstTags = useMemo(() => variable.tags.slice(0, large ? 2 : 1), [variable.tags, large]);
  const lastTags = useMemo(() => variable.tags.slice(large ? 2 : 1), [variable.tags, large]);

  const variableName = useMemo(() => {
    if (!vncHighlight || variable.highlight === undefined || variable.highlight.name === undefined) {
      return <>{variable.name}</>;
    }

    return variable.highlight.name[0].split('<|>').map((text, i) => <>{i % 2 === 0 ? text : <mark>{text}</mark>}</>);
  }, [vncHighlight, variable.name, variable.highlight]);

  const variableReference = useMemo(() => {
    if (!vncHighlight || variable.highlight === undefined || variable.highlight.reference === undefined) {
      return <>{variable.reference}</>;
    }

    return variable.highlight.reference[0].split('<|>').map((text, i) => <>{i % 2 === 0 ? text : <mark>{text}</mark>}</>);
  }, [vncHighlight, variable.reference, variable.highlight]);

  const variableDescription = useMemo(() => {
    if (!vncHighlight || variable.highlight === undefined || variable.highlight.description === undefined) {
      return <>{variable.description}</>;
    }

    return variable.highlight.description[0].split('<|>').map((text, i) => <>{i % 2 === 0 ? text : <mark>{text}</mark>}</>);
  }, [vncHighlight, variable.description, variable.highlight]);

  const addVariable = useCallback(() => {
    insertVariable({ variableUuid: variable.uuid }, disableMaths, defaultVariableOperator, calculatedVariableMode);
  }, [insertVariable, variable.uuid, defaultVariableOperator, disableMaths, calculatedVariableMode]);

  const toggleVariableFromCheckbox = useCallback<CheckboxProps['onChange']>(
    (_, checked) => {
      (checked ? addToSelection : removeByUuidFromSelection)({
        variableUuid: variable.uuid,
        operator: defaultVariableOperator ?? defaultGlobalOperator,
      });
    },
    [addToSelection, removeByUuidFromSelection, variable.uuid, defaultVariableOperator, defaultGlobalOperator],
  );

  const toggleVariableFromButton = useCallback(() => {
    (selected ? removeByUuidFromSelection : addToSelection)({
      variableUuid: variable.uuid,
      operator: defaultVariableOperator ?? defaultGlobalOperator,
    });
  }, [selected, removeByUuidFromSelection, addToSelection, variable.uuid, defaultVariableOperator, defaultGlobalOperator]);

  return (
    <ListItem
      divider={index < data.length - 1}
      secondaryAction={
        multiple && (
          <Tooltip placement="right" title={t(selected ? 'tooltip.removeFromSelection' : 'tooltip.addToSelection')}>
            <Checkbox
              checked={selected}
              edge="end"
              sx={[color && { color: `${color} !important` }]}
              onChange={toggleVariableFromCheckbox}
            />
          </Tooltip>
        )
      }
      sx={[{ pl: 0.5, py: 0.5, ...style }, someSelected && { pr: 0.5 }]}
    >
      <ListItemButton
        selected={selected}
        sx={{ height: 1, pr: theme => `${theme.spacing(someSelected ? 5.5 : 2)} !important` }}
        tabIndex={2 + index}
        onClick={someSelected ? toggleVariableFromButton : addVariable}
      >
        <ListItemAvatar>
          <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
            <PhysicalQuantityAvatar physicalQuantity={variable.physicalQuantity} size="default" />
            <TypographyNoBreak variant="caption">{t('list.text.variable')}</TypographyNoBreak>
          </Stack>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Grid2 container alignItems="center" spacing={0.5}>
              {/* eslint-disable-next-line no-nested-ternary */}
              <Grid2 xs={large ? (isJCE ? 5 : 6) : true}>
                <Typography noWrap variant="h6">
                  {variableName}
                </Typography>
                <Typography noWrap fontFamily="'Ubuntu Mono', monospace" fontSize={12} variant="subtitle2">
                  {variableReference}
                </Typography>
              </Grid2>
              {isJCE && (
                <Grid2 xs={1}>
                  <Chip
                    label={`${variable.sScore ? variable.sScore : 'n/a'}`}
                    size="small"
                    sx={{
                      fontSize: 12,
                      cursor: 'inherit',
                      borderRadius: 4,
                      backgroundColor: setColorLightness(palette.picker.orange, 95),
                      color: setColorLightness(palette.picker.orange, 45),
                    }}
                  />
                </Grid2>
              )}
              <Grid2 xs={large ? 2 : 3}>
                <PhysicalQuantityChip
                  disableAvatar
                  physicalQuantity={variable.physicalQuantity}
                  sx={{ fontSize: 12 }}
                  unit={variable.unit}
                />
              </Grid2>
              <Grid2 xs={3}>
                <Stack direction="row" spacing={0.5} sx={{ overflowX: 'auto', '&::-webkit-scrollbar': { display: 'none' } }}>
                  {firstTags.map(tag => (
                    <Chip key={tag.uuid} label={tag.name} size="small" sx={{ fontSize: 12 }} />
                  ))}
                  {Boolean(lastTags.length) && (
                    <Tooltip placement="right" title={lastTags.map(tag => tag.name).join(', ')}>
                      <Chip label={`+${lastTags.length}`} size="small" sx={{ fontSize: 12 }} />
                    </Tooltip>
                  )}
                </Stack>
              </Grid2>
              {Boolean(covarianceVariable) && Number.isFinite(variable.score) && (
                <Grid2 xs={2}>
                  <CovarianceChip covariance={variable.score} disabled={variable.dismissed} size="small" />
                </Grid2>
              )}
              <Grid2 xs={large ? 1 : undefined}>
                <Stack alignItems="center" direction="row" spacing={0.5}>
                  <VariableKindIcon fontSize="small" kind={getVariableKind(variable)} />
                  {large && (
                    <Typography fontSize={12} lineHeight="12px">
                      {t(`data:variableKind.${getVariableKind(variable)}`)}
                    </Typography>
                  )}
                </Stack>
              </Grid2>
            </Grid2>
          }
          primaryTypographyProps={{ component: 'div' }}
          secondary={
            <Grid2 container spacing={0.5}>
              <Grid2 sx={{ minWidth: '40%', maxWidth: '100%' }}>
                <Typography
                  noWrap
                  component="blockquote"
                  sx={{ backgroundColor: variable.description.length ? undefined : 'transparent' }}
                  variant="body2"
                >
                  {variableDescription}&nbsp;
                </Typography>
              </Grid2>
            </Grid2>
          }
          secondaryTypographyProps={{ component: 'div' }}
        />
      </ListItemButton>
    </ListItem>
  );
}, areEqual);

ListItemVariable.displayName = 'ListItemVariable';

export default ListItemVariable;
