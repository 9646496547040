import { ModbusTcpDataType, QueryStrategy, TransformerType, VariableKind } from '@dametis/core';

import { RecomputeFromMethod } from 'components/Configuration/Variables/CreateVariableModal/VariableSettingsForm/CalculatedVariableSettingsForm/RecomputeFromPicker';
import { EntityKey } from 'components/Configuration/Variables/VariablesRightPanel/DependenciesPanel/DependenciesPanel';
import { ModbusTcpFunction } from 'types';
import { BulkEditTagsFunction, PanelTab, VariablesGridColumn, VariablesGridColumnScope } from 'types/variables';

const columns: Record<VariablesGridColumn, string> = {
  [VariablesGridColumn.CHECKBOX]: '',
  [VariablesGridColumn.KIND]: 'Tipo',
  [VariablesGridColumn.NAME]: 'Nombre',
  [VariablesGridColumn.SENSOR_NAME]: 'Nombre del sensor',
  [VariablesGridColumn.REFERENCE]: 'Referencia',
  [VariablesGridColumn.DESCRIPTION]: 'Descripción',
  [VariablesGridColumn.PHYSICAL_QUANTITY]: 'Magnitud física',
  [VariablesGridColumn.LAST_POINT_VALUE]: 'Último valor',
  [VariablesGridColumn.UNIT]: 'Unidad',
  [VariablesGridColumn.LAST_POINT_DATE]: 'Fecha del último valor',
  [VariablesGridColumn.LAST_PROGRESS_VALUE]: 'Progresión',
  [VariablesGridColumn.PROTOCOL]: 'Protocolo',
  [VariablesGridColumn.TAGS]: 'Tags',
  [VariablesGridColumn.DEVICE]: 'Equipo',
  [VariablesGridColumn.OFFSET]: 'Desplazamiento',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER_TYPE]: 'Transformador',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER]: 'Valor del transformador',
  [VariablesGridColumn.MODBUS_TCP_UID]: 'ID de unidad',
  [VariablesGridColumn.MODBUS_TCP_FUNCTION]: 'Función',
  [VariablesGridColumn.MODBUS_TCP_ADDRESS]: 'Dirección',
  [VariablesGridColumn.MODBUS_TCP_INVERTWORDS]: 'Palabras invertidas',
  [VariablesGridColumn.MODBUS_TCP_INVERTBYTES]: 'Bytes invertidos',
  [VariablesGridColumn.MODBUS_TCP_DATATYPE]: 'Tipo',
  [VariablesGridColumn.MODBUS_TCP_BIT]: 'Bit',
  [VariablesGridColumn.OPC_UA_NODE_ID]: 'ID de nudo',
  [VariablesGridColumn.S7_ADDRESS]: 'Dirección',
  [VariablesGridColumn.SQL_MODE]: 'Modo',
  [VariablesGridColumn.SQL_REQUEST]: 'Solicitud',
  [VariablesGridColumn.SQL_VARIABLES]: 'Variables',
  [VariablesGridColumn.BACNET_ID]: 'ID',
  [VariablesGridColumn.MONITORING_EXPECTED_FREQUENCY]: 'Frecuencia esperada',
  [VariablesGridColumn.MONITORING_ABNORMALITY_DELAY]: 'Tiempo en valor anormal',
  [VariablesGridColumn.MONITORING_MIN]: 'Valor mínimo',
  [VariablesGridColumn.MONITORING_MAX]: 'Valor máximo',
};

const columnExamples: Partial<Record<VariablesGridColumn, string>> = {
  [VariablesGridColumn.NAME]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SENSOR_NAME]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.REFERENCE]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.DESCRIPTION]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.UNIT]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.TAGS]: 'Tag 1, Tag 2, ...',
  [VariablesGridColumn.OFFSET]: '$t(variables:fieldTypes.number)',
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER_TYPE]: Object.values(TransformerType).join(' | '),
  [VariablesGridColumn.VARIABLE_CONFIG_TRANSFORMER]:
    '$t(variables:fieldTypes.number) | in1: $t(variables:fieldTypes.number), in2: $t(variables:fieldTypes.number), out1: $t(variables:fieldTypes.number), out2: $t(variables:fieldTypes.number)',
  [VariablesGridColumn.MODBUS_TCP_UID]: '$t(variables:fieldTypes.number) (min: 0 / max: 254)',
  [VariablesGridColumn.MODBUS_TCP_FUNCTION]: '1 | 2 | 3 | 4',
  [VariablesGridColumn.MODBUS_TCP_ADDRESS]: '$t(variables:fieldTypes.number) (min: 0 / max: 65535)',
  [VariablesGridColumn.MODBUS_TCP_INVERTWORDS]: '$t(variables:fieldTypes.boolean)',
  [VariablesGridColumn.MODBUS_TCP_INVERTBYTES]: '$t(variables:fieldTypes.boolean)',
  [VariablesGridColumn.MODBUS_TCP_DATATYPE]: Object.values(ModbusTcpDataType).join(' | '),
  [VariablesGridColumn.MODBUS_TCP_BIT]: '$t(variables:fieldTypes.number) (min: 1 / max: 15)',
  [VariablesGridColumn.OPC_UA_NODE_ID]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.S7_ADDRESS]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SQL_MODE]: Object.values(QueryStrategy).join(' | '),
  [VariablesGridColumn.SQL_REQUEST]: '$t(variables:fieldTypes.string)',
  [VariablesGridColumn.SQL_VARIABLES]:
    '$t(variables:fieldTypes.string): $t(variables:fieldTypes.string), $t(variables:fieldTypes.string): $t(variables:fieldTypes.string), ...',
  [VariablesGridColumn.BACNET_ID]: '$t(variables:fieldTypes.number)',
};

const columnScopes: Record<VariablesGridColumnScope, string> = {
  [VariablesGridColumnScope.COMMON]: 'General',
  [VariablesGridColumnScope.ACQUISITION]: 'Adquisición',
  [VariablesGridColumnScope.DATA_TRANSFORMER]: 'Dato',
  [VariablesGridColumnScope.MCO]: 'Monitoring',
  [VariablesGridColumnScope.MODBUS_TCP]: 'Modbus TCP',
  [VariablesGridColumnScope.OPC_UA]: 'OPC UA',
  [VariablesGridColumnScope.S7]: 'Siemens S7',
  [VariablesGridColumnScope.SQL]: 'SQL',
  [VariablesGridColumnScope.BACNET]: 'BACnet',
  [VariablesGridColumnScope.MONITORING]: 'Monitoring',
};

const variableUsesEntity: Record<EntityKey, string> = {
  alarms: 'Alarmas',
  batches: 'Batchs',
  blockTypes: 'Tipos de bloque',
  calculatedVariables: 'Variables',
  models: 'Modelos',
  playgrounds: 'Playgrounds',
  reports: 'Informes',
  synoptics: 'Sinópticos',
  variableLists: 'Listas de variables',
  blocks: 'Bloques',
  aliases: 'Alias',
};

const queryStrategies: Record<QueryStrategy, string> = {
  [QueryStrategy.DEFAULT]: 'Defecto',
  [QueryStrategy.TEMPLATE]: 'Plantilla',
  [QueryStrategy.BULK]: 'Masivo',
};

export default {
  title: {
    variables: 'Variables',
    createVariableModal: 'Crear una nueva variable',
    duplicateVariableModal: 'Duplicar "{{name}}"',
    // rightPanel: '{{count}} variables seleccionadas',
    rightPanel_one: 'Detalles de la variable',
    rightPanel_other: 'Detalles de las variables',
    rightPanelSubtitle: '{{count}} variables seleccionadas',
    acquisitionSettings: 'Adquisición',
    dataTransformerSettings: 'Transformación de datos',
    mcoSettings: 'Monitoring',
    importRealVariablesModal: 'Importar variables adquiridas',
    importCalculatedVariablesModal: 'Importar variables calculadas',
    promptBeforeClosing: '¿Estás seguro?',
  },
  button: {
    variable: 'Variable',
    create: 'Crear',
    close: 'Cerrar',
    next: 'Siguiente',
    previous: 'Anterior',
    columns: 'Columnas',
    edit: 'Modificar',
    duplicate: 'Duplicar',
    delete: 'Suprimir',
    cancel: 'Cancelar',
    save: 'Guardar',
    addComment: 'Añadir un comentario',
    export: 'Exportar',
    exportAsCsv: 'Exportar a CSV',
    exportAsExcel: 'Exportar a Excel',
    refresh: 'Actualizar',
    new: 'Nuevo',
    import: 'Importar',
    downloadTemplateCsv: 'ejemplo.csv',
    recompute: 'Recalcular',
    yesClose: 'Cerrar sin guardar',
    noClose: 'Cancelar',
    debug: 'Debug',
  },
  label: {
    uuid: 'UUID',
    name: 'Nombre',
    enabled: 'Activo',
    favorite: 'Favorito',
    unit: 'Unidad',
    sensorName: 'Nombre del sensor',
    reference: 'Referencia',
    description: 'Descripción',
    kind: 'Tipo de variable',
    kindShort: 'Tipo',
    tags: 'Tags',
    device: 'Equipo',
    address: 'Dirección',
    uid: 'ID de unidad',
    id: 'ID',
    function: 'Función',
    dataType: 'Tipo',
    bit: 'Bit',
    transformer: 'Valor',
    transformerType: 'Transformador',
    invertWords: 'Palabras invertidas',
    invertBytes: 'Bytes invertidos',
    in1: 'In1',
    out1: 'Out1',
    in2: 'In2',
    out2: 'Out2',
    nodeId: 'ID de nudo',
    params: 'Expresión',
    accumulate: 'Acumulador',
    delay: 'Demora',
    value: 'Valor',
    physicalQuantity: 'Magnitud física',
    protocol: 'Protocolo',
    request: 'Solicitud',
    variables: 'Variables',
    lastPointValue: 'Último valor',
    lastPointDate: 'Fecha del último valor',
    offset: 'Desplazamiento',
    prefix: 'Prefijo',
    suffix: 'Sufijo',
    tagsFunction: {
      [BulkEditTagsFunction.REPLACE_TAGS]: 'Reemplazar',
      [BulkEditTagsFunction.MERGE_TAGS]: 'Agregar a los existentes',
    },
    dependencies: 'Dependencias',
    withoutTag: 'Sin tag',
    field: 'Columna nº{{index}}',
    settings: 'Parámetros',
    columns: 'Columnas',
    preview: 'Previsualización',
    notSelected: 'No seleccionado',
    gridUseHeader: 'Este archivo contiene un encabezado',
    valuesStart: 'Inicio de los valores',
    valuesEnd: 'Fin de los valores',
    mode: 'Modo',
    recomputeFromMethod: 'Modificar datos',
    progress: 'Progresión',
  },
  kind: {
    [VariableKind.CALCULATED]: 'Calculada',
    [VariableKind.CONSTANT]: 'Constante',
    [VariableKind.MANUAL]: 'Manual',
    [VariableKind.NONE]: 'Desconocida',
    [VariableKind.REAL]: 'Adquirida',
  },
  alert: {
    kind: {
      [VariableKind.CALCULATED]: 'Una variable calculada es una variable basada en otras variables, y cuya expresión determina su valor',
      [VariableKind.CONSTANT]: 'Una variable constante es una variable cuyo valor no cambia',
      [VariableKind.MANUAL]: 'Una variable manual es una variable cuyos valores se definirán mediante una importación manual',
      [VariableKind.NONE]: '',
      [VariableKind.REAL]: 'Una variable adquirida es una variable cuyos datos se reciben de un equipo',
    },
  },
  stepper: {
    common: 'General',
    settings: 'Ajustes',
    importing: 'Importación',
    creating: 'Creación',
    file: 'Archivo',
    data: 'Datos',
  },
  modbusTcp: {
    [`function_${ModbusTcpFunction.READCOILS_1}`]: '1 - Leer bobinas',
    [`function_${ModbusTcpFunction.READDISCRETEINPUTS_2}`]: '2 - Leer entradas discretas',
    [`function_${ModbusTcpFunction.READHOLDINGREGISTERS_3}`]: '3 - Leer registros de retención',
    [`function_${ModbusTcpFunction.READINPUTREGISTERS_4}`]: '4 - Leer registros de entrada',
  },
  transformerType: {
    [TransformerType.MULTIPLIER]: 'Multiplicador',
    [TransformerType.LINEAR_INTERPOLATION]: 'Interpolación lineal',
  },
  text: {
    allDevices: 'Todos los equipos',
    searchingVariables: 'Buscando variables...',
    displayAll: 'Mostrar todo',
    noSelection: 'Sin selección',
    exportFileName: 'dametis_{{group}}_{{site}}_{{date}}',
    noDependency: 'Sin dependencias',
    fetchedAt: 'Últimos valores recuperados el {{date}}',
    realVariable: 'Variable adquirida',
    true: 'Sí',
    false: 'No',
    createVariable: 'Crear una variable',
    importVariables: 'Importar variables',
    importRealVariables: 'Variables adquiridas',
    importCalculatedVariables: 'Variables calculadas',
    selectFile: 'Suelta un archivo o haz clic para elegir uno',
    dropFile: 'Suelta el archivo aquí',
    fileNotAllowed: 'Este tipo de archivo no está permitido',
    successCreateCalculatedVariable: 'Variables calculadas importadas con éxito',
    errorCreateCalculatedVariable: 'Se produjo un error durante la importación',
    errors_one: '1 error',
    errors_other: '{{count}} errores',
    lineNumber: 'línea nº',
    promptBeforeClosing: 'Estás saliendo del modo de edición.',
    notExistingVariable_one: 'Esta variable no existe.',
    notExistingVariable_other: 'Estas variables no existen.',
    deleteVariableWarning_one:
      'La eliminación de esta variable puede afectar el funcionamiento de las herramientas (Informes, Sinópticos, Playgrounds, etc.) que la utilizan. Verifique su uso antes de vaciarla.',
    deleteVariableWarning_other:
      'La eliminación de estas variables puede afectar el funcionamiento de las herramientas (Informes, Sinópticos, Playgrounds, etc.) que las utilizan. Verifique sus usos antes de vaciarlas.',
    selectAll: 'Seleccionar todo',
  },
  fieldTypes: {
    string: 'Cadena de caracteres',
    number: 'Número',
    boolean: 'Booleano',
  },
  columns,
  columnScopes,
  columnExamples,
  tooltip: {
    importVariables: 'importar variables',
    createVariable: 'crear una variable',
    editVariable: 'modificar una variable',
    duplicateVariable: 'duplicar una variable',
    deleteVariable: 'vaciar una variable',
    addComment: 'Añadir un comentario',
    copyToClipboard: 'copiar al portapapeles',
    recompute: 'recalcular',
    cannotDuplicateRealVariable: 'no se puede duplicar una variable adquirida',
  },
  toast: {
    copyToClipboard: 'UUID de la variable añadido al portapapeles',
    fileReadingAborted: 'Lectura del archivo cancelada',
    fileReadingFailed: 'Lectura del archivo imposible',
    csvInvalid: 'Formato CSV inválido',
    successRecompute: 'Solicitud recibida, recalculando...',
  },
  panelTab: {
    [PanelTab.INFORMATIONS]: 'Información',
    [PanelTab.COMMENTS]: 'Comentarios',
    [PanelTab.ACTIVITIES]: 'Actividades',
    [PanelTab.DEPENDENCIES]: 'Dependencias',
  },
  variableUsesEntity,
  queryStrategies,
  dataGrid: {
    noRowsLabel: 'Sin variable',
    footerTotalRows: 'Total variables :',
  },
  recomputeFromMethod: {
    [RecomputeFromMethod.START]: 'Desde siempre',
    [RecomputeFromMethod.NOW]: 'A partir de ahora',
    // [RecomputeFromMethod.DATE]: '',
  },
  recomputeFromLabel: {
    [RecomputeFromMethod.START]: 'Todos los datos se recalcularán con la nueva expresión.',
    [RecomputeFromMethod.NOW]:
      'Los datos ya calculados hasta ahora se conservarán (expresión antigua).\nLa nueva expresión tendrá efecto para todos los nuevos datos.',
    // date: "Los datos ya calculados hasta la fecha seleccionada se conservarán (expresión antigua).\nLa nueva expresión tendrá efecto para todos los datos a partir de esta fecha.",
  },
  notDefined: 'Indefinido',
  mco: {
    title: {
      settings: 'Monitoring',
    },
    label: {
      expectedFrequencyEnabled: 'Monitorear la frecuencia esperada',
      expectedFrequencyAuto: 'Frecuencia del equipo ({{inheritedValue}})',
      expectedFrequencyCustom: 'Esta frecuencia',
      abnormalityDelayEnabled: 'Monitorear valor fuera del umbral',
      minimumThreshold: 'Umbral mínimo',
      maximumThreshold: 'Umbral máximo',
      abnormalityDelayCustom: 'Tiempo excesivo',
    },
    link: {
      notifications: 'Manejar notificaciones',
    },
  },
};
