import {
  CommentInfo,
  CreateCommentOnAlarmBody,
  CreateCommentOnProjectBody,
  CreateCommentOnReportBody,
  CreateCommentOnTaskBody,
  CreateCommentOnVariableBody,
  UUID,
} from '@dametis/core';

import store from 'store';
import { EntityType } from 'types/comment';

export type EntityFromComment = { uuid: UUID; type: EntityType };

export const getEmptyComment = (
  entity: EntityType,
  entityUuid: UUID,
  options: Partial<CommentInfo> = {},
):
  | CreateCommentOnReportBody
  | CreateCommentOnProjectBody
  | CreateCommentOnTaskBody
  | CreateCommentOnVariableBody
  | CreateCommentOnAlarmBody => {
  const { period } = store.getState().period.present;
  if (entity === EntityType.REPORT) {
    return {
      reportId: entityUuid,
      message: null,
      shortcut: null,
      date: options.date ?? new Date(),
      period: { from: period.from.toISOString(), to: period.to.toISOString() },
    } as CreateCommentOnReportBody;
  }
  if (entity === EntityType.PROJECT) {
    return {
      projectId: entityUuid,
      date: options.date ?? new Date(),
      message: null,
      shortcut: null,
    } as CreateCommentOnProjectBody;
  }
  if (entity === EntityType.TASK) {
    return {
      date: options.date ?? new Date(),
      taskId: entityUuid,
      message: null,
      shortcut: null,
    } as CreateCommentOnTaskBody;
  }
  if (entity === EntityType.VARIABLE) {
    return {
      variableId: entityUuid,
      message: null,
      date: options.date ?? new Date(),
      shortcut: null,
    } as CreateCommentOnVariableBody;
  }
  if (entity === EntityType.ALARM) {
    return {
      alarmId: entityUuid,
      message: null,
      date: options.date ?? new Date(),
      shortcut: null,
    } as CreateCommentOnAlarmBody;
  }
  return null;
};
