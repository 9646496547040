import { ListItemIcon, ListItemText, MenuItem, MenuItemProps, SvgIcon } from '@mui/material';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { AUTO_GROUPBY, Operator, TabType, UUID } from '@dametis/core';

import { getTabsConfig } from 'components/Playground/config/tabs.config';
import { DateToUrlParam } from 'functions/dateInUrl';
import { getPlaygroundBody } from 'functions/getPlaygroundBody';
// import { addItem } from 'functions/playgroundRedirection';
import { append } from 'functions/localStorage';
import { PLAYGROUND_STORAGE_KEY } from 'functions/playgroundRedirection';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useSelector } from 'store';

export type CalculationBarOptionProps = Omit<MenuItemProps, 'children'>;

const CalculationBarOption: FC<CalculationBarOptionProps> = ({ ...props }) => {
  const { t } = useTranslation('calculationMenu');

  const calculation = useSelector(state => state.calculationMenu.calculation);
  const period = useSelector(state => state.calculationMenu.period);

  const playgroundBodyUuid: UUID = useMemo(() => uuidv4(), []);

  const localStorageKey = useMemo(() => append(PLAYGROUND_STORAGE_KEY, playgroundBodyUuid), [playgroundBodyUuid]);

  const [, addItem] = useLocalStorage(localStorageKey, undefined);

  const tabsConfig = useMemo(() => getTabsConfig(t), [t]);

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams();
    searchParams.append('playgroundBodyUuid', playgroundBodyUuid);
    if (period !== null) {
      searchParams.append('from', DateToUrlParam(period.from));
      searchParams.append('to', DateToUrlParam(period.to));
    }
    return searchParams.toString();
  }, [period, playgroundBodyUuid]);

  const addPlaygroundBodyToLocalStorage = useCallback(async () => {
    const playground = await getPlaygroundBody(
      [
        {
          ...calculation,
          ...(calculation.operator !== undefined &&
            calculation.operator !== Operator.HISTORY &&
            calculation.groupBy === undefined && { groupBy: AUTO_GROUPBY }),
          ...((calculation.operator === undefined || calculation.operator === Operator.HISTORY) && {
            operator: Operator.MEAN,
            groupBy: AUTO_GROUPBY,
          }),
        },
      ],
      true,
    );
    addItem({ playground, date: new Date().toISOString() });
  }, [calculation, addItem]);

  useEffect(() => {
    void addPlaygroundBodyToLocalStorage();
  }, [addPlaygroundBodyToLocalStorage]);

  return (
    <MenuItem component={NavLink} to={`/playgrounds/new?${queryParams}`} {...props}>
      <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
        <SvgIcon component={tabsConfig[TabType.BAR_CHART].icon} fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={tabsConfig[TabType.BAR_CHART].name} />
    </MenuItem>
  );
};

export default CalculationBarOption;
