import { createContext, Dispatch, FC, KeyboardEventHandler, PropsWithChildren, SetStateAction, useContext, useMemo, useState } from 'react';

import { DateSystem, Ecma376Format, Format, FormatCategory, FormatType } from '@dametis/core';

import { FormatPickerMenu, FormatResult } from 'types/format';

import { UnitResult } from '../UnitPicker/types';

export const defaultFormat: Ecma376Format = {
  category: FormatCategory.CUSTOM,
  type: FormatType.ECMA376,
  hideUnit: false,
  dateSystem: DateSystem.EXCEL_SERIAL_TIME,
  withTimezoneOffset: false,
  code: '',
};

export interface FormatPickerProviderProps {
  value: FormatResult;
  onChange: (newValue: FormatResult) => void;
  unit?: UnitResult;
}

export interface FormatPickerContextState extends FormatPickerProviderProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  pickerValue: Format;
  setPickerValue: Dispatch<SetStateAction<Format>>;
  selectedMenu: FormatPickerMenu;
  setSelectedMenu: Dispatch<SetStateAction<FormatPickerMenu>>;
  listsKeyDownHandler: KeyboardEventHandler;
  setListsKeyDownHandler: Dispatch<SetStateAction<KeyboardEventHandler>>;
  historyKeyDownHandler: KeyboardEventHandler;
  setHistoryKeyDownHandler: Dispatch<SetStateAction<KeyboardEventHandler>>;
}

export const contextInitialState: FormatPickerContextState = {
  value: null,
  onChange: undefined,
  unit: undefined,
  open: false,
  setOpen: undefined,
  pickerValue: defaultFormat,
  setPickerValue: undefined,
  selectedMenu: FormatPickerMenu.LISTS,
  setSelectedMenu: undefined,
  listsKeyDownHandler: undefined,
  setListsKeyDownHandler: undefined,
  historyKeyDownHandler: undefined,
  setHistoryKeyDownHandler: undefined,
};

export const FormatPickerContext = createContext<FormatPickerContextState>(contextInitialState);

const FormatPickerProvider: FC<PropsWithChildren<FormatPickerProviderProps>> = ({
  value,
  onChange,
  unit = undefined,
  children = undefined,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [pickerValue, setPickerValue] = useState<Format>(defaultFormat);
  const [selectedMenu, setSelectedMenu] = useState<FormatPickerMenu>(FormatPickerMenu.LISTS);
  const [listsKeyDownHandler, setListsKeyDownHandler] = useState<KeyboardEventHandler>(undefined);
  const [historyKeyDownHandler, setHistoryKeyDownHandler] = useState<KeyboardEventHandler>(undefined);

  const contextValues = useMemo(
    () => ({
      open,
      setOpen,
      pickerValue,
      setPickerValue,
      selectedMenu,
      setSelectedMenu,
      listsKeyDownHandler,
      setListsKeyDownHandler,
      historyKeyDownHandler,
      setHistoryKeyDownHandler,
      value,
      onChange,
      unit,
    }),
    [open, pickerValue, selectedMenu, listsKeyDownHandler, historyKeyDownHandler, value, onChange, unit],
  );

  return <FormatPickerContext.Provider value={contextValues}>{children}</FormatPickerContext.Provider>;
};

export const useFormatPickerContext = () => {
  const context = useContext(FormatPickerContext);

  if (!context) {
    throw Error('useFormatPickerContext must be used inside an FormatPickerProvider');
  }

  return context;
};

export default FormatPickerProvider;
