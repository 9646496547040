import { CalculationChartMenu } from 'components/UI/CalculationMenu/CalculationChart/CalculationChart';
import { FromPeriod, ToPeriod } from 'store/slices/calculationMenu';

export default {
  label: {
    from: 'From',
    fromPeriod: 'From...',
    to: 'to',
    to_other: 'to',
    toPeriod: 'To...',
    format: 'Format',
    playground: 'Create a Playground',
    addToCart: 'Add to cart',
    alreadyInCart: 'Already in cart',
    configuration: 'View in Configuration',
    dataVariableConfiguration: 'View in Configuration',
    blockConfiguration: 'View in Block Diagram',
    addComment: 'Add a comment',
    addCommentToVar: 'Add a comment to the variable "{{var}}"',
    copyVariableName: 'Copy variable name',
    copyCalculation: 'Copy calculation',
    calculation: 'Calculation',
    variables: 'Variables ({{count}})',
    addCalculation: 'Add calculation',
    addVariables: 'Add variables ({{count}})',
  },
  tooltip: {
    menu: {
      [CalculationChartMenu.GRAPH]: 'Graph',
      [CalculationChartMenu.STATISTICS]: 'Statistics',
      [CalculationChartMenu.SETTINGS]: 'Settings',
    },
    statisticsDisabled: 'Statistics are not available for a calculation',
    disableConfiguration: 'Configuration is only available for acquired variables and block-diagram',
    disableAddComment: 'Adding a comment is only available for acquired variables',
  },
  fromPeriod: {
    [FromPeriod.LAST_HOUR]: '1 hour',
    [FromPeriod.LAST_DAY]: '1 day',
    [FromPeriod.LAST_WEEK]: '1 week',
  },
  toPeriod: {
    [ToPeriod.NOW]: 'now',
    [ToPeriod.LAST_POINT]: 'last point',
  },
  text: {
    errorStatistics: 'Unable to calculate statistics',
    noFormatting: 'No formatting',
    mean: 'Average',
    min: 'Min',
    max: 'Max',
    integral: 'Integral',
    stddev: 'Standard deviation',
    gap: 'Gap',
    noData: 'No data to display.',
    lastPointDate: 'Last point date :',
    noLastPointDate: 'No value',
  },
  button: {
    goToLastPointDate: 'Go to last point',
  },
  toast: {
    copyVariableName: "Variable's name copied to clipboard",
    commentPostedWithSuccess: 'Comment created with success',
    commentPostedError: 'Unable to create the comment',
  },
};
