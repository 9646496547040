import type { DashStyleValue, SeriesLineOptions } from 'highcharts';
import LineSeries from 'highcharts/es-modules/Series/Line/LineSeries';
import Highcharts from 'highcharts/es-modules/masters/highcharts.src';
import ReactDOM from 'react-dom/client';
import { v4 as uuidv4 } from 'uuid';

import { binarySearch, CommentInfo, GroupBy1, interpolationSearch, Period, search, TadaApiResponse, UUID } from '@dametis/core';

import ChartComment from 'components/UI/ChartAnnotation/ChartComment';
import Wrapper from 'components/Wrapper/Wrapper';
import { theme } from 'theme';
import { FormatResult } from 'types/format';

import { DaAxis } from './DaAxis';
import { BOOST_POINTS_THRESHOLD, DaChart } from './DaChart';
import { ISeriesType } from './types';

interface Props {
  color?: string;
  name?: string;
  unit?: string;
  selectedUnit?: string;
  style?: {
    type?: ISeriesType;
    width?: number;
    dashStyle?: string;
  };
  hidden?: boolean;
  yAxisHidden?: boolean;
  pretty?: boolean;
  xAxis?: DaAxis;
  yAxis?: DaAxis;
  uuid?: UUID;
  format?: FormatResult;
  zIndex?: number;
  disableBoost?: boolean;
}

export default class DaLineSeries extends LineSeries {
  period: Period | undefined;

  groupBy: GroupBy1 | undefined;

  chart: DaChart;

  xAxis: DaAxis;

  yAxis: DaAxis;

  uuid: UUID;

  color: string;

  format: FormatResult;

  public constructor(
    chart: DaChart,
    {
      color,
      name,
      unit,
      selectedUnit,
      style = {},
      pretty = false,
      yAxis,
      xAxis,
      uuid,
      hidden = false,
      yAxisHidden = false,
      format = null,
      zIndex,
      disableBoost,
      ...props
    }: Props = {},
  ) {
    const options: SeriesLineOptions = {
      ...props,
      name,
      id: uuidv4(),
      visible: !hidden,
      lineWidth: pretty ? 4 : style.width || 1,
      color,
      tooltip: {
        valueSuffix: unit,
        valuePrefix: selectedUnit,
      },
      boostThreshold: style?.dashStyle || disableBoost ? undefined : BOOST_POINTS_THRESHOLD,
      type: ((t, p) => {
        if (p) return 'areaspline';
        if (t === 'dots') return 'line';
        if (t === 'bar') return 'column';
        return t;
      })(style.type, pretty) as any,
      dashStyle: (style.dashStyle as DashStyleValue) ?? 'Solid',
      marker: {
        radius: style.width + 1 || 2,
        symbol: 'circle',
      },
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          lineWidth: pretty ? 4 : style.width || 1,
          lineWidthPlus: 0,
        },
      },
      zIndex,
    };
    if (yAxis === undefined) {
      // eslint-disable-next-line no-param-reassign
      yAxis = new DaAxis(chart, { color, unit, pretty, hidden: yAxisHidden });
    }
    if (xAxis) options.xAxis = xAxis.userOptions.id;
    options.yAxis = yAxis?.options?.id;
    super();
    super.init(chart, options);
    this.color = color;
    this.uuid = uuid;
    this.format = format;
  }

  setColor(color: string, changeAxis = true): void {
    this.update({ type: 'line', color });

    if (changeAxis) {
      this.yAxis.setColor(color);
    }
  }

  update(options: SeriesLineOptions): void {
    const { uuid } = this;
    super.update(options, false);
    this.uuid = uuid;
  }

  setUnit(unit = '', apiUnit = this.options.tooltip.valueSuffix, updateYaxis: boolean = true): void {
    if (updateYaxis) {
      this.yAxis.setUnit(unit, apiUnit);
    }
    this.update({
      type: 'line',
      tooltip: {
        valuePrefix: unit ?? apiUnit,
        valueSuffix: apiUnit ?? '',
      },
    });
  }

  setVisibility(visibility: boolean, redraw = false): void {
    this.setVisible(visibility, redraw);
  }

  setName(name): void {
    this.update({ type: 'line', name });
  }

  setFormat(format = null): void {
    this.format = format;
  }

  addData(data: number[][], period?: Period, groupBy?: GroupBy1, comments?: CommentInfo[]): void {
    const { visible } = this;
    if (!visible) this.setVisibility(true);
    this.period = period;
    this.groupBy = groupBy;
    super.setData(data, false, false, false);
    if (comments) {
      this.chart.removeSeriesComments(this.uuid);
      comments.forEach(comment => {
        const xValue = new Date(comment.date).getTime();
        const nearestValue = data.reduce((a, b) => (Math.abs(b.at(0) - xValue) < Math.abs(a.at(0) - xValue) ? b : a));
        this.addComment(comment, { x: nearestValue.at(0), y: nearestValue.at(1) });
      });
    }
    if (!visible) this.setVisibility(false);
  }

  addComment(comment: CommentInfo, position: { x: number; y: number }): void {
    if (!position.x || !position.y) return;
    const { navigation } = this.chart.options;
    const newLabelHTML = `<div id="${comment.uuid}"/>`;
    this.chart.addAnnotation(
      Highcharts.merge(
        {
          id: comment.uuid,
          variableUuid: this.uuid,
          langKey: 'comment',
          labels: [
            {
              point: {
                xAxis: 0,
                yAxis: this.chart.yAxis.findIndex(a => a.userOptions.id === this.yAxis.userOptions.id),
                ...position,
              },
              height: 20,
              useHTML: true,
              text: newLabelHTML,
              shape: 'connector',
            },
          ],
        },
        navigation.annotationsOptions,
      ),
    );
    const component = document.getElementById(comment.uuid);
    const root = ReactDOM.createRoot(component);
    root.render(
      <Wrapper>
        <ChartComment comment={comment} handleDelete={() => this.chart.deleteComment(comment.uuid, root)} />
      </Wrapper>,
    );
  }

  addPoint(point: number[]): void {
    super.addPoint(point, true, true, true);
  }

  removeData(): void {
    super.setData([]);
  }

  static convertDataFromApiToHighcharts(data: TadaApiResponse['results']): number[][] {
    const [firstValue, lastValue] = [data.at(0)?.value ?? null, data.at(-1)?.value ?? null];
    // opti pour éviter de check tout le tableau dans le cas ou la var est "bien cadencée" (a des valeurs partout ou presque)
    if (firstValue === null && lastValue == null) {
      // si le premier et dernier point sont nulls, on est obligé de check tout pour être sûr, code sync & coûteux
      if (data.every(point => point.value === null)) {
        return []; // renvoyer tableau vide si tout est à null pour qu'HC affiche 'No data to display'
      }
    }
    return data.map(({ value, time }) => [Date.parse(time), value]);
  }

  static safeInterpolationSearch(arrayX: number[], arrayY: number[], x: number, y: number): number | null {
    try {
      const index = interpolationSearch(arrayX, arrayY, x, y, 0, arrayX.length - 1);
      // x is after found element ("fillPrevious") but not too far (< 60secs)
      return x - arrayX[index] >= 0 && x - arrayX[index] < 60000 ? index : null;
    } catch (err) {
      console.error(`safeInterpolationSearch ${err.message}`);
    }
    return null;
  }

  static safeBinarySearch(arrayX: number[], arrayY: number[], x: number, y: number): number | null {
    try {
      return binarySearch(arrayX, arrayY, x, y, 0, arrayX.length - 1);
    } catch (err) {
      console.error(`safeBinarySearch ${err.message}`);
    }
    return null;
  }

  static safeSearch(arrayX: number[], arrayY: number[], x: number, y: number, maxDist = 0): number | null {
    try {
      return search(arrayX, x, maxDist);
    } catch (err) {
      console.error(`safeSearch ${err.message}`);
    }
    return null;
  }

  setThreshold({
    value = 0,
    operator = '=',
    thresholdColor = theme.palette.warning.light,
    trueColor = theme.palette.secondary.light,
    falseColor = theme.palette.error.light,
  }): void {
    if (!Number.isFinite(value)) return;
    this.yAxis.update(
      {
        plotLines: [],
      },
      true, // TODO: pourquoi on est obligé de redraw ici ?
    );
    // if (this.graph !== undefined) this.graph.destroy();
    this.yAxis.addPlotLine({
      value,
      color: thresholdColor,
      width: 1,
      zIndex: 1,
    });
    if (['<', '>', '<=', '>='].includes(operator)) {
      this.update({
        type: 'line',
        threshold: value,
        color: ['<', '<='].includes(operator) ? trueColor : falseColor,
        negativeColor: ['<', '<='].includes(operator) ? falseColor : trueColor,
      });
    }
  }
}
