import { Editor, Transforms } from 'slate';

import { SymbolElement, Symbols } from '@dametis/core';

import { createSymbolElement } from 'components/VNC/slate/symbol';
import { allowedSymbolsInAlpha } from 'components/VNC/types';

enum BatchSymbols {
  LEFT_PARENTHESIS = '(',
  RIGHT_PARENTHESIS = ')',
}

const symbolsRegex = new RegExp(
  Object.values(BatchSymbols)
    .map(s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
    .join('|'),
);

export const insertSymbolInSlate = (editor: Editor) => {
  const { selection } = editor;
  if (!selection) return;
  const { anchor } = selection;
  const block = Editor.leaf(editor, anchor.path);
  const blockText = Editor.string(editor, block?.[1] ?? [])?.trim();
  if (blockText.at(0) === '"' || blockText.at(0) === "'") return;
  const found = blockText.match(symbolsRegex);
  const symbol = found?.[0] as Symbols;
  if (!symbol) return;
  const previousSymbol = Editor.previous<SymbolElement>(editor, { at: Editor.start(editor, anchor.path) });
  const multiSymbol =
    anchor.offset === 1 &&
    previousSymbol?.[0]?.type === 'symbol' &&
    Object.values(Symbols).includes(`${previousSymbol[0].symbol}${symbol}` as Symbols)
      ? (`${previousSymbol[0].symbol}${symbol}` as Symbols)
      : undefined;
  const [beforeText, afterText] = blockText.split(symbol);
  if (beforeText.length && !beforeText.match(/^((\d*\.\d+|\d+)([Ee][+-]?\d+)?|Infinity)$/) && !allowedSymbolsInAlpha.includes(symbol))
    return;
  Transforms.select(editor, anchor.path);
  Transforms.delete(editor);
  Transforms.insertText(editor, beforeText);
  const symbolElement = createSymbolElement(multiSymbol ?? symbol);
  Transforms.insertNodes(editor, symbolElement);
  Transforms.move(editor);
  Transforms.insertText(editor, afterText);
  if (multiSymbol) {
    Transforms.removeNodes(editor, { at: previousSymbol[1] });
  }
};
