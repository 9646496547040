import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { FC, useCallback, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FormatPopover from 'components/UI/FormatPicker/FormatPopover';
import { formatNameFromFormat } from 'components/UI/FormatPicker/functions/formatNameFromFormat';
import { useDispatch, useSelector } from 'store';
import { FromPeriod, ToPeriod, setFormat, setSelectedFrom, setSelectedTo } from 'store/slices/calculationMenu';
import { FormatResult } from 'types/format';

import FromMenu from './FromMenu';
import ToMenu from './ToMenu';

const ChartSettings: FC = () => {
  const { t } = useTranslation('calculationMenu');
  const dispatch = useDispatch();

  const selectedFrom = useSelector(state => state.calculationMenu.selectedFrom);
  const selectedTo = useSelector(state => state.calculationMenu.selectedTo);
  const format = useSelector(state => state.calculationMenu.format);

  const [fromMenuAnchorEl, setFromMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [toMenuAnchorEl, setToMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [formatPickerAnchorEl, setFormatPickerAnchorEl] = useState<HTMLElement | null>(null);

  const isFromMenuOpen = useMemo(() => Boolean(fromMenuAnchorEl), [fromMenuAnchorEl]);
  const isToMenuOpen = useMemo(() => Boolean(toMenuAnchorEl), [toMenuAnchorEl]);
  const isFormatPickerOpen = useMemo(() => Boolean(formatPickerAnchorEl), [formatPickerAnchorEl]);
  const displayedFormatValue = useMemo(() => (!format ? t('text.noFormatting') : formatNameFromFormat(format, t)), [format, t]);

  const handleOpenFromMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setFromMenuAnchorEl(event.currentTarget);
  }, []);

  const handleOpenToMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setToMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseFromMenu = useCallback(() => {
    setFromMenuAnchorEl(null);
  }, []);

  const handleCloseToMenu = useCallback(() => {
    setToMenuAnchorEl(null);
  }, []);

  const handleChangeFrom = useCallback(
    (newSelectedFrom: FromPeriod) => {
      dispatch(setSelectedFrom(newSelectedFrom));
      setFromMenuAnchorEl(null);
    },
    [dispatch],
  );

  const handleChangeTo = useCallback(
    (newSelectedTo: ToPeriod) => {
      dispatch(setSelectedTo(newSelectedTo));
      setToMenuAnchorEl(null);
    },
    [dispatch],
  );

  const handleOpenFormatPicker = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setFormatPickerAnchorEl(event.currentTarget);
  }, []);

  const handleCloseFormatPicker = useCallback(() => {
    setFormatPickerAnchorEl(null);
  }, []);

  const handleChangeFormat = useCallback(
    (newFormat: FormatResult) => {
      dispatch(setFormat(newFormat));
    },
    [dispatch],
  );

  return (
    <>
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={handleOpenFromMenu}>
            <ListItemText primary={t('label.fromPeriod')} />
            <Typography color="text.secondary" variant="body2">
              {t(`fromPeriod.${selectedFrom}`)}
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleOpenToMenu}>
            <ListItemText primary={t('label.toPeriod')} />
            <Typography color="text.secondary" variant="body2">
              {t(`toPeriod.${selectedTo}`)}
            </Typography>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleOpenFormatPicker}>
            <ListItemText primary={t('label.format')} />
            <Typography color="text.secondary" variant="body2">
              {displayedFormatValue}
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
      <FromMenu
        anchorEl={fromMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isFromMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        value={selectedFrom}
        onChange={handleChangeFrom}
        onClose={handleCloseFromMenu}
      />
      <ToMenu
        anchorEl={toMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={isToMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        value={selectedTo}
        onChange={handleChangeTo}
        onClose={handleCloseToMenu}
      />
      <FormatPopover
        anchorEl={formatPickerAnchorEl}
        open={isFormatPickerOpen}
        value={format}
        onChange={handleChangeFormat}
        onClose={handleCloseFormatPicker}
      />
    </>
  );
};

export default ChartSettings;
