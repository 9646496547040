import { useTheme } from '@mui/material';
import { DefaultTheme } from '@mui/styles';
import { TFunction } from 'i18next';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BatchInfo,
  BlockInfo,
  DataVariable,
  GetVarCalcKind,
  IsAlarmVariable,
  IsAliasVariable,
  IsBatchVariable,
  IsBlockTypeMetricVariable,
  IsBlockTypeParameterVariable,
  IsBlockVariable,
  IsCalculationVariable,
  IsDataVariable,
  IsModelVariable,
  IsPointsVariable,
  ModelInfo,
  ModelKeyField,
  ShortAlarmInfo,
  ShortAliasInfo,
  ShortBlockInfo,
  ShortStandardBlockInfo,
  VarCalc,
  VarCalcKinds,
  VariableInfo,
} from '@dametis/core';

import { getPhysicalQuantities } from 'config';
import { useSelector } from 'store';
import { useAlarms } from 'store/api/alarms';
import { useAliases } from 'store/api/aliases';
import { useBlocks } from 'store/api/blocks';
import { useModels } from 'store/api/models';
import { useStandardBlocks } from 'store/api/standardBlocks';

import { calculationToString } from '../functions/calculationToString';
import { isModelExplanatoryVariable } from '../functions/isModelExplanatoryVariable';
import { numberToSubscript } from '../functions/numberToSubscript';
import { exhaustiveCheck } from '../types';

const alarmsEmptyArray: ShortAlarmInfo[] = [];
const aliasesEmptyArray: ShortAliasInfo[] = [];
const batchesEmptyArray: BatchInfo[] = [];
const modelsEmptyArray: ModelInfo[] = [];
const blocksEmptyArray: ShortBlockInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

export const useVariableUnit = (varCalc: DataVariable) => {
  const variables = useSelector(state => state.variables.byId);

  return useMemo(() => variables?.[varCalc.variableUuid]?.unit ?? '', [variables, varCalc]);
};

export const useGetVariableName = (): ((varCalc: VarCalc) => string) => {
  const { t } = useTranslation();

  const variables = useSelector(state => state.variables.byId);
  const batches = useSelector(state => state.batch.flattenBatches) || batchesEmptyArray;

  const { data: alarms = alarmsEmptyArray } = useAlarms();
  const { data: aliases = aliasesEmptyArray } = useAliases();
  const { data: blocks = blocksEmptyArray } = useBlocks();
  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();
  const { data: models = modelsEmptyArray } = useModels();

  return useCallback(
    (varCalc: VarCalc): string => {
      return getVariableName(
        varCalc,
        {
          variables,
          blocks,
          standardBlocks,
          models,
          alarms,
          batches,
          aliases,
        },
        t,
      );
    },
    [alarms, aliases, batches, standardBlocks, blocks, models, t, variables],
  );
};

export const useVariableName = (varCalc: VarCalc): string => {
  const getVarName = useGetVariableName();

  return useMemo(() => getVarName(varCalc), [getVarName, varCalc]);
};

/**
 * @deprecated use useVariableName instead
 */
export const getVariableName = (
  varCalc: VarCalc,
  {
    variables,
    blocks,
    standardBlocks,
    models,
    alarms,
    batches,
    aliases,
  }: {
    variables: Record<string, VariableInfo>;
    blocks: BlockInfo[];
    standardBlocks: ShortStandardBlockInfo[];
    models: ModelInfo[];
    alarms: ShortAlarmInfo[];
    batches: BatchInfo[];
    aliases: ShortAliasInfo[];
  },
  t: TFunction,
): string => {
  if (IsDataVariable(varCalc)) {
    const variable = variables[varCalc.variableUuid];
    if (!variable) return t('global:text.unknownVariable');
    return `${variable.name}${variable.unit.length ? ` (${variable.unit})` : ''}`;
  }
  if (IsBlockVariable(varCalc)) {
    const { blockId } = varCalc;
    const block = blocks.find(storeBlock => storeBlock.uuid === blockId);
    if (!block) return t('global:text.unknownBlock');
    const standardBlock = standardBlocks.find(storeStandardBlock => storeStandardBlock.uuid === block.standardBlockId);
    if (!standardBlock) return t('global:text.unknownBlock');
    return t('lego:text.blockVariableWithPath', { standardBlock: standardBlock.name, block: block.name, variable: varCalc.blockKey });
  }
  if (IsBlockTypeParameterVariable(varCalc) || IsBlockTypeMetricVariable(varCalc)) {
    return varCalc.blockKey;
  }
  if (IsModelVariable(varCalc)) {
    const { modelUuid } = varCalc;
    const model = models.find(storeModel => storeModel.uuid === modelUuid);
    if (!model) return t('global:text.unknownModel');
    if (isModelExplanatoryVariable(varCalc)) {
      const variable = model.xVars.findIndex(xVar => xVar.uuid === varCalc.modelXVarUuid);
      if (variable < 0) return t('global:text.unknownVariable');
      return t(`models:text.modelVariableWithPath.${ModelKeyField.X_VAR}`, {
        variable: numberToSubscript(variable + 1),
        model: model.name,
      });
    }
    return t(`models:text.modelVariableWithPath.${varCalc.modelKey}`, { model: model.name });
  }
  if (IsAlarmVariable(varCalc)) {
    const alarm = alarms.find(storeAlarm => storeAlarm.uuid === varCalc.alarmUuid);
    if (!alarm) return t('global:text.unknownVariable');
    return alarm.name;
  }
  if (IsBatchVariable(varCalc)) {
    const batch = batches.find(storeBatch => storeBatch.uuid === varCalc.batchUuid);
    if (!batch) return t('global:text.unknownBatch');
    return batch.name;
  }
  if (IsAliasVariable(varCalc)) {
    const alias = aliases.find(storeAlias => storeAlias.uuid === varCalc.aliasUuid);
    if (!alias) return t('global:text.unknownVariable');
    return alias.name;
  }
  if (IsCalculationVariable(varCalc) || IsPointsVariable(varCalc)) {
    return calculationToString(varCalc);
  }
  exhaustiveCheck(varCalc);
  return t('global:text.unknownVariable');
};

export const useVariableColor = (varCalc: VarCalc, defaultColor?: string): string => {
  const theme = useTheme();

  const variables = useSelector(state => state.variables.byId);

  const { data: blocks = blocksEmptyArray } = useBlocks();
  const { data: models = modelsEmptyArray } = useModels();

  return useMemo(
    () => getVariableColor(varCalc, { variables, blocks, models }, theme, defaultColor),
    [blocks, defaultColor, models, theme, varCalc, variables],
  );
};

/**
  @deprecated use useVariableColor instead
 */
export const getVariableColor = (
  varCalc: VarCalc,
  { variables, blocks }: { variables: Record<string, VariableInfo>; blocks: BlockInfo[]; models: ModelInfo[] },
  theme: DefaultTheme,
  defaultColor?: string,
): string => {
  const defCol = defaultColor ?? theme.palette.primary.main;
  const physicalQuantities = getPhysicalQuantities(undefined, theme);
  if (IsDataVariable(varCalc)) {
    const variable = variables[varCalc.variableUuid];
    if (!variable) return defCol;
    return physicalQuantities[variable?.physicalQuantity]?.color;
  }
  if (IsBlockVariable(varCalc)) {
    const { blockId } = varCalc;
    const { blockKey } = varCalc;
    const block = blocks.find(storeBlock => storeBlock.uuid === blockId);
    if (!block) return defCol;
    const elements = [...block.parameters, ...block.metrics];
    const physicalQuantity = elements.find(elem => elem.blockKey === blockKey)?.physicalQuantity;
    return physicalQuantities[physicalQuantity]?.color;
  }
  if (IsModelVariable(varCalc)) {
    return defCol;
  }
  return defCol;
};

export const useVariableKind = (varCalc: VarCalc): VarCalcKinds => {
  return useMemo(() => GetVarCalcKind(varCalc), [varCalc]);
};
