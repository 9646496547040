import deepmerge from 'deepmerge';
import { Options } from 'highcharts';
import { TFunction } from 'i18next';

import { chartColors } from '../../functions/color';

export const getDaChartOptions = (options: Options, t: TFunction): Options => {
  const defaultOptions: Options = {
    time: {
      useUTC: false,
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: '',
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    tooltip: {
      backgroundColor: 'transparent',
      borderRadius: 0,
      padding: 0,
      useHTML: true,
      shared: false,
      enabled: false,
      shadow: false,
      followPointer: true,
      positioner(boxWidth, boxHeight, point) {
        const { chart } = this;
        const { plotLeft, plotTop, plotWidth, plotHeight } = chart;
        const distanceX = point.plotX > plotWidth / 2 ? 20 : -20 - boxWidth;
        const distanceY = 20;
        const marginX = 5;
        const marginY = 5;
        const { plotX: pointX, plotY: pointY } = point;
        let x = pointX + plotLeft + (chart.inverted ? distanceX : -boxWidth - distanceX);
        let y = pointY + plotTop + distanceY;
        if (x < marginX) {
          x = marginX;
        }
        if (x + boxWidth > plotLeft + plotWidth - marginX) {
          x = plotLeft + plotWidth - boxWidth - marginX;
        }
        if (y < marginY) {
          y = marginY;
        }
        if (y + boxHeight > plotTop + plotHeight - marginY) {
          y = plotTop + plotHeight - boxHeight - marginY;
        }
        return { x, y };
      },
    },
    colors: chartColors,
    chart: {
      backgroundColor: 'transparent',
      zooming: {
        type: 'x',
      },
    },
    lang: { noData: t('dachart:label.noData'), loading: t('dachart:label.loading') },
    credits: {
      enabled: false,
    },
  };
  return deepmerge(defaultOptions, options);
};
