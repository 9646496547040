const dict = {
  '0': '₀',
  '1': '₁',
  '2': '₂',
  '3': '₃',
  '4': '₄',
  '5': '₅',
  '6': '₆',
  '7': '₇',
  '8': '₈',
  '9': '₉',
} as const;

export const numberToSubscript = (number: number): string => {
  return number.toString().replace(/[0123456789]/g, match => dict[match]);
};
