import { inputBaseClasses, InputBaseProps, styled } from '@mui/material';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SearchInputBase from './SearchInputBase';

const StyledInput = styled(SearchInputBase, { shouldForwardProp: propName => propName !== 'maxWidth' })<{ maxWidth?: string }>(
  ({ theme, maxWidth }) => ({
    maxWidth: maxWidth ?? '10rem',
    transition: theme.transitions.create(['background-color', 'max-width']),
    [`&.${inputBaseClasses.focused}`]: {
      maxWidth: '30rem',
    },
  }),
);

export interface SearchInputProps extends Omit<InputBaseProps, 'onChange' | 'startAdornment' | 'fullWidth' | 'margin'> {
  onChange: (search: string) => void;
  maxWidth?: string;
  extendable?: boolean;
  value: string;
  withDebounce?: boolean;
}

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ value: outerValue, onChange, placeholder, maxWidth = '10rem', extendable = true, withDebounce = false, ...props }, ref) => {
    const { t } = useTranslation('form');

    const debounceTimeout = useRef<NodeJS.Timeout>(null);

    const [value, setValue] = useState<string>(outerValue);

    const handleChange: InputBaseProps['onChange'] = useCallback(
      event => {
        const newValue = event.target.value;
        if (withDebounce) {
          setValue(newValue);
          clearTimeout(debounceTimeout.current);
          debounceTimeout.current = setTimeout(() => {
            onChange(newValue);
          }, 200);
        } else {
          onChange(newValue);
        }
      },
      [onChange, withDebounce],
    );

    useEffect(() => {
      return () => {
        clearTimeout(debounceTimeout.current);
      };
    }, []);

    return (
      <StyledInput
        ref={ref}
        fullWidth
        maxWidth={extendable ? maxWidth : '100%'}
        placeholder={placeholder ?? t('form:placeholder.searchFor')}
        type="search"
        value={withDebounce ? value : outerValue}
        onChange={handleChange}
        {...props}
      />
    );
  },
);

SearchInput.displayName = 'SearchInput';

SearchInput.defaultProps = {
  maxWidth: '10rem',
  extendable: true,
  withDebounce: false,
};

export default SearchInput;
