import { FC } from 'react';

import UnitPickerProvider from './UnitPickerContext';
import UnitPopoverBase, { UnitPopoverBaseProps } from './UnitPopoverBase';
import { CommonProps, UnitPickerMenu } from './types';

export type UnitPopoverProps = CommonProps & Partial<UnitPopoverBaseProps>;

const UnitPopover: FC<UnitPopoverProps> = ({
  value = null,
  onChange = undefined,
  freeMode = true,
  baseUnit = undefined,
  noUnitEnabled = false,
  defaultMenu = UnitPickerMenu.LIST,
  anchorEl = null,
  open = undefined,
  onClose = undefined,
  ...props
}) => {
  return (
    <UnitPickerProvider
      baseUnit={baseUnit}
      defaultMenu={defaultMenu}
      freeMode={freeMode}
      noUnitEnabled={noUnitEnabled}
      value={value}
      onChange={onChange}
      {...props}
    >
      <UnitPopoverBase anchorEl={anchorEl} open={open} onClose={onClose} />
    </UnitPickerProvider>
  );
};

export default UnitPopover;
