export * from './alarm';
export * from './data';
export * from './device';
export * from './playground';
export * from './user';
export * from './physicalQuantities';
export * from './variable';
export * from './phoneNumber';
export * from './toast';

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[] ? RecursivePartial<U>[] : T[P] extends object ? RecursivePartial<T[P]> : T[P];
};

export type OptionalKeys<T> = {
  [K in keyof T]-?: unknown extends Pick<T, K> ? K : never;
}[keyof T];

export type OnlyOptionalKeys<T> = Required<Pick<T, OptionalKeys<T>>>;

type ObjectEntry<BaseType> = [keyof BaseType, BaseType[keyof BaseType]];
export type ObjectEntries<BaseType> = Array<ObjectEntry<BaseType>>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const exhaustiveCheck = (_: never): void => {
  //
};
