import type { PointOptionsType, SeriesScatterOptions } from 'highcharts';
import ScatterSeries from 'highcharts/es-modules/Series/Scatter/ScatterSeries';

import { EntityStyleConfiguration, StyledRule } from '@dametis/core';

import { DaAxis } from './DaAxis';
import { DaChart, BOOST_POINTS_THRESHOLD } from './DaChart';

interface Props {
  name?: string;
  color?: string;
  unit?: string;
  yAxis?: DaAxis;
  hidden?: boolean;
  custom?: SeriesScatterOptions['custom'];
  min?: number | null;
  max?: number | null;
}

export default class DaScatterSeries extends ScatterSeries {
  chart: DaChart;

  options: SeriesScatterOptions;

  yAxis: DaAxis;

  color: string;

  public constructor(chart: DaChart, { name = '', color, unit = '', yAxis, hidden = false, custom = {} }: Props = {}) {
    const options: SeriesScatterOptions = {
      type: 'scatter',
      name,
      color,
      visible: !hidden,
      tooltip: {
        valueSuffix: unit ?? '',
      },
      data: [],
      states: {
        inactive: {
          opacity: 1,
        },
        hover: {
          lineWidthPlus: 0,
        },
      },
      custom,
      // Ne fonctionne pas en boost mode
      zIndex: 2,
      // Threshold is arbitrary but needed to avoid boosting too soon
      // When boosting we have to lose time label in tooltip to comply with boost mode constraints
      // => (2D array of numbers instead of object array)
      boostThreshold: BOOST_POINTS_THRESHOLD,
    };
    if (yAxis === undefined) {
      // eslint-disable-next-line no-param-reassign
      yAxis = new DaAxis(chart, { color, unit });
    }
    options.yAxis = yAxis?.options?.id;
    super();
    super.init(chart, options);
  }

  addData(data: PointOptionsType[]): void {
    super.setData(data, false);
  }

  update(options: SeriesScatterOptions): void {
    super.update(options, false);
    if (this.graph !== undefined) this.graph.destroy();
  }

  setColor(color: string): void {
    this.update({ color } as SeriesScatterOptions);
    this.yAxis.setColor(color);
    this.color = color;
  }

  setUnit(unit = '', updateYaxis: boolean = true): void {
    if (updateYaxis) {
      this.yAxis.setUnit(unit ?? '');
    }
    this.update({
      tooltip: {
        valueSuffix: unit ?? '',
      },
    } as SeriesScatterOptions);
  }

  setVisibility(visibility = true): void {
    this.setVisible(visibility, false);
  }

  setName(name = ''): void {
    this.update({ name } as SeriesScatterOptions);
  }

  setStyledRules(styledRules: StyledRule[] | EntityStyleConfiguration): void {
    //  first update is useful when passing from StyledRule[] to EntityStyleConfiguration because this.update tries to merge with previous value
    this.update({ custom: { styledRules: null } as SeriesScatterOptions['custom'] } as SeriesScatterOptions);
    const custom: SeriesScatterOptions['custom'] = { ...this.options.custom, styledRules };
    this.update({ custom } as SeriesScatterOptions);
  }
}
