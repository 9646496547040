import { ActivityVerb, StandardProjectStatus, ProjectTemplateCategoryType, ProjectType, StandardTaskStatus } from '@dametis/core';

export default {
  title: {
    projects: 'My projects',
    createForm: 'New project',
    editForm: 'Edit project',
    task: 'Task',
    editTask: 'Edit task',
    newProject: 'New project',
    newSaving: 'New saving',
    newForecastedSaving: 'New forecasted saving',
    editSaving: 'Edit saving',
    editForecastedSaving: 'Edit forecasted saving',
    newConsumption: 'Set reference consumption',
    editConsumption: 'Edit reference consumption',
    editInformation: 'Edit informations',
    newExpense: 'New expense',
    newSubsidy: 'New subsidy',
    duplicateProject: 'Duplicate project',
    redirection: 'Redirection',
    relativeSavings: 'Savings relative to the reference period',
    createdAtBy: 'Created on {{date}} by {{name}}',
    deletedUser: 'Deleted user',
    editValues: 'Edit values',
    editRating: 'Edit rating criteria',
    caroussel: 'Image : {{imageName}}',
  },
  button: {
    newProject: 'Project',
    addCost: 'Add an expense',
    create: 'Create',
    save: 'Save',
    share: 'Share',
    edit: 'Edit...',
    cancel: 'Cancel',
    continue: 'Continue',
    delete: 'Delete',
    no: 'No',
    yes: 'Yes',
    addSubpart: 'Add subpart',
    addTask: 'Add task',
    removeTask: 'Remove task',
    deleteTask: 'Delete task',
    editTask: 'Edit task',
    add: 'Add',
    upload: 'Upload',
    newAsset: 'Add an asset',
    newTask: 'Add a task',
    newSaving: 'Add a saving',
    newForecastedSaving: 'Add a forecasted saving',
    newConsumption: 'Add a consumption',
    changeStatus: 'Change status',
    newExpense: 'Add',
    newSubsidy: 'Add',
    editShortcuts: 'Edit shortcuts',
    editCustom: 'Edit',
    duplicate: 'Duplicate',
    order: {
      newest: 'Newest first',
      oldest: 'Oldest first',
    },
    newImage: 'Add image',
    close: 'Close',
    editImage: 'Edit',
    deleteImage: 'Delete',
  },
  tooltip: {
    createProject: 'create a project',
    duplicateProject: 'duplicate a project',
    shareProject: 'share this project',
    editProject: 'edit this project',
    deleteProject: 'delete this project',
    addDate: 'Add date',
    deleteDate: 'Delete date',
    editDates: 'Edit reference period',
    savingByYear: 'Savings by year',
    consumptionByPeriod: 'Consumption by period',
    delete: 'Delete',
    send: 'Send',
    edit: 'Edit',
    taskAfterDeadline: 'The deadline for the task was exceeded',
    previousImage: 'Previous',
    nextImage: 'Next',
    noAccessUser: 'User does not have access to this project',
  },
  toggle: {
    byYear: 'By year',
    byPeriod: 'By period',
  },
  input: {
    label: {
      name: 'Name',
      description: 'Description',
      currency: 'Currency',
      costLabel: 'Cost',
      costValue: 'Amount',
      budget: 'Budget allowance',
      orderBy: 'Order By',
      search: 'Search...',
      subpart: 'Subpart',
      task: 'Task',
      in: 'In',
      dependency: 'Dependencies',
      noDependency: 'No dependencies',
      from: 'From',
      auto: 'Auto',
      duration: 'Duration',
      milestone: 'Milestone',
      co2: 'CO2',
      finance: 'Savings',
      electricity: 'Electricity',
      water: 'Water',
      gaz: 'Gaz',
      assignee: 'Assigned user',
      deadline: 'Deadline',
      type: 'Type',
      priority: 'Priority',
      assets: 'Assets',
      carbon: 'Carbon',
      financial: 'Financial',
      energyType: 'Energy type',
      energy: 'Quantity',
      // trackingMethod: 'Tracking method',
      // trackingFrequency: 'Tracking frequency',
      referencePeriod: 'Reference period',
      enableReferencePeriod: 'Enable reference period',
      label: 'Expense name',
      value: 'Value',
      note: 'Note',
      chief: 'Leader',
      tags: 'Tags',
      tasksTemplate: 'Tasks template',
      keepTasks: 'Duplicate existing tasks',
      startDate: 'From',
      endDate: 'To',
      currentStatus: 'Current status',
      statusDates: 'Status date',
      score: 'Global score',
      subsidyLabel: 'Subsidy name',
      template: 'Template',
      energiesUnit: 'Energies unit',
      impact: 'Impact',
    },
    placeholder: {
      emptyType: 'No type selected',
      emptyPriority: 'No priority selected',
      emptyStatus: 'No status selected',
      emptyTag: 'No tag selected',
      emptySite: 'Every sites',
      noSelect: 'No selection',
      renameFile: 'You have to select an asset',
    },
  },
  timeUnit: {
    hours: 'Hours',
    days: 'Days',
    weeks: 'Weeks',
    months: 'Months',
  },
  text: {
    name: 'Name',
    chief: 'Leader',
    type: 'Type',
    priority: 'Priority',
    state: 'State',
    currency: 'Currency',
    cost: 'Cost',
    cost_plural: 'Costs',
    ongoingProjects: 'Ongoing projects',
    finishedProjects: 'Finished projects',
    carbon: 'of carbon emissions',
    energy: 'of energy consumption',
    electricity: 'of electricity saving',
    gas: 'of gas saving',
    fuel: 'of fuel saving',
    water: 'of water saving',
    product: 'of product saving',
    finance: 'of savings',
    deleteProject: 'Delete the project {{name}} ?',
    ganttChart: 'Gantt chart',
    site: 'Site',
    noExpense: 'No expense',
    noSubsidy: 'No subsidy',
    noAsset: 'No asset',
    noImages: 'No images',
    noCommentLinked: 'No comment linked to this project',
    noShortcut: 'No shortcuts defined',
    noPlayground: 'No playground linked yet',
    noSynoptic: 'No synoptic linked yet',
    noReport: 'No report linked yet',
    reports: 'Reports',
    synoptics: 'Synoptics',
    playgrounds: 'Playgrounds',
    unassigned: 'Unassigned',
    noUser: 'User unknown',
    collaborators: 'Collaborators',
    noTemplate: 'No template',
    commentNumber_zero: 'No comment',
    commentNumber_one: '{{count}} comment',
    commentNumber_other: '{{count}} comments',
    unknownSite: 'Unknown site',
    redirection: 'You will be redirected to the site',
    allSelected: 'All selected',
    byYear: '/year',
    byPeriod: '/period',
    years_one: 'year',
    years_other: 'years',
    paybackPeriod: {
      short: 'Real ROI',
      full: 'Real return on investment',
      value: 'with subsidies',
      valueWithoutSubsidies: 'without subsidies',
      noData: "Couldn't calculate ROI",
    },
    forecastedPaybackPeriod: {
      short: 'Forecasted ROI',
      full: 'Forecasted return on investment',
      value: 'with subsidies',
      valueWithoutSubsidies: 'without subsidies',
      noData: "Couldn't calculate forecasted ROI",
    },
    categorySelectItemDisabled: 'You need to select a Cost category and the Expenses category',
    display: 'Display',
    period: 'Period',
    total: 'Total',
    noConsumption: {
      title: 'No reference consumption',
      description: 'Add a reference consumption to see your relative savings.',
    },
    comment: '“{{comment}}”',
    noComment: 'No comment',
    alarm: 'alarm',
    playground: 'playground',
    report: 'report',
    variable: 'variable',
    noValue: '-',
    template: 'Template',
    withoutTemplate: 'No template',
    customStatus: 'Other statuses',
    completionRate: 'Completion',
    [ProjectTemplateCategoryType.STATUS]: 'Status',
    [ProjectTemplateCategoryType.RATING]: 'Rating',
    [ProjectTemplateCategoryType.REFPERIOD]: 'Reference period',
    [ProjectTemplateCategoryType.FORECASTED_SAVINGS]: 'Forecasted savings',
    [ProjectTemplateCategoryType.SAVINGS]: 'Real savings',
    [ProjectTemplateCategoryType.EXPENSES]: 'Expenses',
    [ProjectTemplateCategoryType.SUBSIDIES]: 'Subsidies',
    [ProjectTemplateCategoryType.PAYBACK]: 'ROI',
    [ProjectTemplateCategoryType.TASKS]: 'Tasks',
    [ProjectTemplateCategoryType.ASSETS]: 'Assets',
    [ProjectTemplateCategoryType.SHORTCUTS]: 'Shortcuts',
    [ProjectTemplateCategoryType.LINKS]: 'Links',
    [ProjectTemplateCategoryType.COMMENTS]: 'Mentions',
    [ProjectTemplateCategoryType.ACTIVITIES]: 'Activities',
    [ProjectTemplateCategoryType.CUSTOM]: 'Custom',
    [ProjectTemplateCategoryType.IMAGES]: 'Images',
    noTask: 'No task',
  },
  priority: {
    variant_improvement: 'Improvement',
    variant_necessary: 'Necessary',
    variant_critical: 'Critical',
  },
  type: {
    [`variant_${ProjectType.ANOMALY}`]: 'Anomaly',
    [`variant_${ProjectType.OPTIMISATION}`]: 'Optimisation',
    [`variant_${ProjectType.GLOBALPROJECT}`]: 'Global projet',
    [`variant_${ProjectType.PROJECT}`]: 'Project',
  },
  state: {
    variant_PENDING_VALIDATION_CAPEX: 'CAPEX validation process',
    variant_DEFINITION: 'Project definition',
    variant_PENDING_SIGNATURE: 'Pending signature',
    variant_VALIDATED: 'Project approved',
    variant_FINISH: 'Project closed',
  },
  status: {
    [`variant_${StandardProjectStatus.PENDING_VALIDATION}`]: 'Pending validation',
    [`variant_${StandardProjectStatus.VALIDATED}`]: 'Validated',
    [`variant_${StandardProjectStatus.IN_PROGRESS}`]: 'In progress',
    [`variant_${StandardProjectStatus.COMPLETED}`]: 'Completed',
    [`variant_${StandardProjectStatus.PERFORMANCE_VALIDATION}`]: 'Performance validation',
    [`variant_${StandardProjectStatus.CLOSED}`]: 'Closed',
  },
  tasks: {
    status: {
      [`variant_${StandardTaskStatus.TODO}`]: 'To do',
      [`variant_${StandardTaskStatus.ONGOING}`]: 'On going',
      [`variant_${StandardTaskStatus.TESTING}`]: 'Testing',
      [`variant_${StandardTaskStatus.DONE}`]: 'Done',
    },
  },
  activities: {
    type: {
      [`variant_${ActivityVerb.CREATE}`]: 'creation',
      [`variant_${ActivityVerb.UPDATE}`]: 'edition',
      [`variant_${ActivityVerb.DELETE}`]: 'deletion',
    },
    data: {
      variant_projects: 'Project',
      variant_sites: 'Site',
      variant_assets: 'Document',
      variant_synoptics: 'Synoptic',
      variant_alarms: 'Alarm',
      variant_reports: 'Report',
      variant_playgrounds: 'Playground',
      variant_tasks: 'Task',
    },
    label: `$t(projects:activities.data.variant, { "context": "{{data}}" }) $t(projects:activities.type.variant, { "context": "{{type}}" })`,
  },
  template: {
    name: {
      optimisationCip: 'CIP timing optimisation',
      optimisationEnvironment: 'Environmental optimisation',
    },
    tasks: {
      traceCleanProfile: '1. Trace clean profile',
      analyse: '2. Analyse',
      defineNewTime: '3. Define new times',
      preValidationQuality: '4. Pre-validation quality',
      editAutomatism: '5. Time adjustment: automatic action',
      postValidationQuality: '6. Post-validation quality',
      technicalValidation: 'Technical validation',
      financialValidation: 'Financial validation',
      environmentalValidation: 'Environmental validation',
    },
  },
  grid: {
    noConsumption: 'No consumption set',
    noSavings: 'No savings',
    noForecastedSavings: 'No forecasted savings',
    savings: 'Savings',
    carbon: 'Carbon',
    electricity: 'Electricity',
    gas: 'Gas',
    fuel: 'Fuel',
    water: 'Water',
    product: 'Product',
    financial: 'Économy',
  },
  unit: {
    PCS: 'hhv',
  },
  toast: {
    delete: {
      success: 'Project deleted successfully',
      error: 'An error occurred while deleting the project',
    },
  },
};
