import { Box, BoxProps, LinearProgress, LinearProgressProps, Typography } from '@mui/material';
import { FC } from 'react';

const containerPropsDefaultProp: BoxProps<'div'> = {};

export interface LinearProgressWithLabelProps extends LinearProgressProps {
  containerProps?: BoxProps<'div'>;
  minFilling?: number;
}

const LinearProgressWithLabel: FC<LinearProgressWithLabelProps> = ({
  value,
  minFilling = 0,
  containerProps = containerPropsDefaultProp,
  ...props
}) => (
  <Box alignItems="center" display="flex" {...containerProps}>
    <Box mr={1} width="100%">
      <LinearProgress value={Math.max(minFilling, value)} variant="determinate" {...props} />
    </Box>
    {(value || value === 0) && (
      <Box minWidth={35}>
        <Typography color="textSecondary" variant="body2">{`${Math.round(value)} %`}</Typography>
      </Box>
    )}
  </Box>
);

export default LinearProgressWithLabel;
