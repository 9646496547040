import { Components, drawerClasses, outlinedInputClasses, Theme } from '@mui/material';
import { red } from '@mui/material/colors';

export default {
  styleOverrides: {
    root: ({ theme }) => ({
      backgroundColor: theme.palette.white,
      color: theme.palette.black,
      'label + &': {
        marginTop: 24,
      },
      [`&.${outlinedInputClasses.error}`]: {
        color: red['700'],
      },
      [`.${drawerClasses.paper} &`]: {},
    }),
    input: {
      padding: 8,
    },
    notchedOutline: ({ theme }) => ({
      top: 0,
      border: `solid 2px ${theme.palette.grey[300]}`,
      '& legend': {
        display: 'none',
      },
      [`.${outlinedInputClasses.root}:hover &, .${outlinedInputClasses.root}.${outlinedInputClasses.multiline}:hover &, .${outlinedInputClasses.root}.${outlinedInputClasses.root}:hover &`]:
        {
          borderColor: theme.palette.grey[500],
        },
      [`.${outlinedInputClasses.root}.${outlinedInputClasses.focused} &, .${outlinedInputClasses.root}.${outlinedInputClasses.multiline}.${outlinedInputClasses.focused} &, .${outlinedInputClasses.root}.${outlinedInputClasses.root}.${outlinedInputClasses.focused} &`]:
        {
          borderColor: theme.palette.grey[900],
        },
      [`.${outlinedInputClasses.root}.${outlinedInputClasses.error} &`]: {
        borderColor: red['200'],
      },
      [`.${outlinedInputClasses.root}.${outlinedInputClasses.error}:hover &`]: {
        borderColor: red['300'],
      },
      [`.${outlinedInputClasses.root}.${outlinedInputClasses.error}.${outlinedInputClasses.focused} &`]: {
        borderColor: red['500'],
      },
    }),
    multiline: {
      padding: 8,
    },
    inputMultiline: {
      padding: 0,
    },
  },
} as Components<Theme>['MuiOutlinedInput'];
