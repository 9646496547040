import { AddOutlined, ClearAll, Close } from '@mui/icons-material';
import { Drawer, drawerClasses, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Resizable, ResizableProps } from 'react-resizable';

import { CalculationVariable, ShortcutCategory, UUID } from '@dametis/core';

import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import GoToPlaygroundButton from 'components/UI/GoToPlaygroundButton/GoToPlaygroundButton';
import ResizeHandler from 'components/UI/ResizeHandler/ResizeHandler';
import {
  RightPanel,
  RightPanelActions,
  RightPanelBody,
  RightPanelHeader,
  RightPanelHeaderButton,
  RightPanelHeaderText,
  RightPanelSection,
  RightPanelSectionContent,
  RightPanelSectionTitle,
} from 'components/UI/RightPanel';
import VncDialog from 'components/VNC/VncDialog';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { useDispatch, useSelector } from 'store';
import { setIsOpen } from 'store/slices/cart';
import { RIGHT_PANEL_MAX_WIDTH, RIGHT_PANEL_MIN_WIDTH, RIGHT_PANEL_WIDTH } from 'theme/others';

import CartItemPreview from './CartItemPreview';
import useCartStorage from './useCartStorage';

const Cart: FC = () => {
  const { t } = useTranslation('synoptics');
  const dispatch = useDispatch();

  const isOpen = useSelector(state => state.cart.isOpen);
  const { items, setItems, addItem, removeItem, clearCart } = useCartStorage();

  const calculations = useMemo(() => items.map(item => item.variable), [items]);

  const [isVncOpen, setIsVncOpen] = useState<boolean>(false);
  const [calculation, setCalculation] = useState<CalculationVariable>(createCalculationVariable);
  const [width, setWidth] = useState<number>(RIGHT_PANEL_WIDTH);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const handleCloseDrawer = useCallback(() => {
    dispatch(setIsOpen(false));
  }, [dispatch]);

  const handleDeleteItem = useCallback(
    (itemUuid: UUID): MouseEventHandler =>
      () => {
        removeItem(itemUuid);
      },
    [removeItem],
  );

  const handleOpenVnc: MouseEventHandler = useCallback(() => {
    setCalculation(createCalculationVariable());
    setIsVncOpen(true);
  }, []);

  const handleAddVariable = useCallback(
    (newCalculation: CalculationVariable) => {
      addItem(newCalculation);
    },
    [addItem],
  );

  const handleClearCart: MouseEventHandler = useCallback(() => {
    setItems([]);
  }, [setItems]);

  const handleOpenInPlayground: MouseEventHandler<HTMLAnchorElement> = useCallback(
    event => {
      if (event.type === 'click') {
        clearCart();
        dispatch(setIsOpen(false));
      }
    },
    [dispatch, clearCart],
  );

  const resize = useCallback<ResizableProps['onResize']>((_, { size }) => setWidth(size.width), []);

  const handleResizeStart = useCallback(() => {
    setIsResizing(true);
  }, []);

  const handleResizeStop = useCallback(() => {
    setIsResizing(false);
  }, []);

  const handleClickResizer: MouseEventHandler<HTMLDivElement> = useCallback(event => {
    if (event.detail === 2) {
      setWidth(RIGHT_PANEL_WIDTH);
    }
  }, []);

  return (
    <>
      <Resizable
        handle={<ResizeHandler isResizing={isResizing} orientation="vertical" sx={{ position: 'absolute' }} onClick={handleClickResizer} />}
        height={9999}
        maxConstraints={[RIGHT_PANEL_MAX_WIDTH, 9999]}
        minConstraints={[RIGHT_PANEL_MIN_WIDTH, 9999]}
        resizeHandles={['w']}
        width={width}
        onResize={resize}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeStop}
      >
        <Drawer anchor="right" open={isOpen} sx={{ width, [`& .${drawerClasses.paper}`]: { width } }} onClose={handleCloseDrawer}>
          <RightPanel>
            <RightPanelHeader>
              <RightPanelHeaderButton icon={Close} onClick={handleCloseDrawer} />
              <RightPanelHeaderText title={t('cart.title')} />
            </RightPanelHeader>
            <RightPanelActions>
              <ActionButton startIcon={<AddOutlined />} onClick={handleOpenVnc}>
                {t('cart.button.variable')}
              </ActionButton>
              <ActionButton disabled={items.length === 0} startIcon={<ClearAll />} onClick={handleClearCart}>
                {t('cart.button.clear')}
              </ActionButton>
            </RightPanelActions>
            <RightPanelBody sx={{ flexGrow: '0!important' }}>
              {/* <CartHelper /> */}
              <RightPanelSection>
                <RightPanelSectionTitle>{t('cart.variables')}</RightPanelSectionTitle>
                <RightPanelSectionContent>
                  <Stack spacing={1}>
                    {items.map(item => (
                      <CartItemPreview key={item.uuid} item={item} onDelete={handleDeleteItem(item.uuid)} />
                    ))}
                  </Stack>
                  {items.length === 0 && (
                    <Typography align="center" variant="subtitle2">
                      {t('cart.noVariables')}
                    </Typography>
                  )}
                </RightPanelSectionContent>
              </RightPanelSection>
            </RightPanelBody>
            <Stack direction="row" justifyContent="flex-end" p={2} spacing={1}>
              <GoToPlaygroundButton calculations={calculations} disabled={items.length === 0} onClick={handleOpenInPlayground} />
            </Stack>
          </RightPanel>
        </Drawer>
      </Resizable>
      <VncDialog
        open={isVncOpen}
        setOpen={setIsVncOpen}
        sourceCategory={ShortcutCategory.CART}
        value={calculation}
        onChange={handleAddVariable}
      />
    </>
  );
};

export default Cart;
