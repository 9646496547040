import { CreateReportBody, ReportInfo, ShortReportInfo, UUID, UpdateReportBody } from '@dametis/core';

import { removeLastEntity } from 'components/Layouts/Drawer/hooks';

import { sdk } from '../../sdk';
import { LastEntityType } from '../../types/drawer';
import { RootState, useSelector } from '../index';

import { api } from './index';

const reportsApi = api.injectEndpoints({
  endpoints: build => ({
    readReports: build.query<ShortReportInfo[], { groupId?: UUID; siteId?: UUID } | void>({
      providesTags: ['Reports'],
      queryFn: async (arg, { getState }) => {
        const state = getState() as RootState;
        const { groupId, siteId } = arg || { groupId: state.auth.selectedGroup?.uuid, siteId: state.auth.selectedSite?.uuid };
        try {
          const { data } = await (siteId
            ? sdk.report.List(siteId)
            : sdk.corporate.ListReports(groupId, { params: { isCorporate: 'true' } }));
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    readReport: build.query<ReportInfo, UUID>({
      providesTags: ['Reports'],
      queryFn: async uuid => {
        try {
          const { data } = await sdk.report.Read(uuid);
          return { data };
        } catch (error) {
          console.error(error);
          return { error };
        }
      },
    }),
    createReport: build.mutation<ShortReportInfo, CreateReportBody>({
      invalidatesTags: ['Reports', 'TagsDependencies'],
      queryFn: async (body, { getState }) => {
        const {
          auth: { selectedGroup, selectedSite },
        } = getState() as RootState;
        try {
          const { data } = await (selectedSite?.uuid
            ? sdk.report.Create(selectedSite.uuid, body)
            : sdk.corporate.CreateReport(selectedGroup.uuid, body));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    deleteReport: build.mutation<void, UUID>({
      invalidatesTags: ['Reports', 'TagsDependencies'],
      queryFn: async (uuid, { dispatch }) => {
        try {
          const { data } = await sdk.report.Delete(uuid);
          dispatch(removeLastEntity(LastEntityType.REPORTS, uuid));
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    duplicateReport: build.mutation<ShortReportInfo | null, { uuid: UUID; name: string }>({
      invalidatesTags: ['Reports', 'TagsDependencies'],
      queryFn: async ({ uuid, name }) => {
        try {
          const { data } = await sdk.report.Duplicate(uuid, { name });
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
    updateReport: build.mutation<ShortReportInfo | null, { uuid: UUID; body: UpdateReportBody }>({
      invalidatesTags: ['Reports', 'TagsDependencies'],
      queryFn: async ({ uuid, body }) => {
        try {
          const { data } = await sdk.report.Update(uuid, body);
          return { data };
        } catch (error) {
          return { error };
        }
      },
    }),
  }),
});

export const {
  useReadReportQuery,
  useLazyReadReportQuery,
  useReadReportsQuery,
  useLazyReadReportsQuery,
  useCreateReportMutation,
  useUpdateReportMutation,
  useDeleteReportMutation,
  useDuplicateReportMutation,
} = reportsApi;

export const useReports: typeof useReadReportsQuery = (arg, options) => {
  const groupId = useSelector(state => state.auth.selectedGroup?.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  return useReadReportsQuery(arg ?? { groupId, siteId }, { skip: !siteId && !groupId, ...options });
};
