import { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { RenderElementProps, useReadOnly, useSelected } from 'slate-react';

import { FunctionElement } from '@dametis/core';

import { FunctionSpan } from '../styled';

import InlineChromiumBugfix from './InlineChromiumBugfix';

interface Props extends RenderElementProps {
  element: FunctionElement;
}

const SlateFunction: FC<Props> = ({ element, attributes, children = undefined }) => {
  const { t, i18n } = useTranslation('vnc');

  const readOnly = useReadOnly();
  const selected = useSelected() && !readOnly;

  const spanRef = useRef<HTMLSpanElement>();

  return (
    <span {...attributes}>
      <FunctionSpan ref={spanRef} selected={selected}>
        {i18n.exists(`vnc:functions.name.${element.fn}`) ? t(`functions.name.${element.fn}`) : element.fn}(
      </FunctionSpan>
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </span>
  );
};

export default SlateFunction;
