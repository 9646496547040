import { OutlinedInput, OutlinedInputProps, outlinedInputClasses, styled } from '@mui/material';
import { FC, useState, useCallback, ChangeEventHandler, KeyboardEventHandler, useRef, FocusEventHandler } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const editableInputClasses = {
  parent: `editable-input-parent-${uuidv4()}`,
};

interface StyledOutlinedInputProps {
  tagsDisabled?: boolean;
}

const StyledOutlinedInput = styled(OutlinedInput, {
  shouldForwardProp: prop => prop !== 'tagsDisabled',
})<StyledOutlinedInputProps>(({ theme, tagsDisabled }) => ({
  color: 'inherit',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  backgroundColor: 'transparent',
  lineHeight: 0,
  borderRadius: tagsDisabled ? '6px' : '6px 6px 0px 0px',
  [`&.Mui-disabled`]: {
    color: 'inherit',
  },

  [`& .${outlinedInputClasses.input}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 'auto',
    padding: 6,
  },

  [`& .${outlinedInputClasses.input}.Mui-disabled`]: {
    WebkitTextFillColor: 'currentColor',
  },

  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: 'transparent',
  },

  [`&.Mui-disabled .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: 'transparent',
  },

  [`&.Mui-disabled:hover .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: 'transparent',
  },

  [`.${editableInputClasses.parent}:hover &:not(.Mui-disabled, .Mui-focused, :hover) .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.grey[300],
  },

  [`&[data-shown]:not(.Mui-disabled, .Mui-focused, :hover) .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.grey[300],
  },

  [`&:hover:not(.Mui-disabled, .Mui-focused) .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: theme.palette.grey[500],
  },
}));

export interface EditableInputProps extends Omit<OutlinedInputProps, 'onChange'> {
  onChange: (value: string) => void | Promise<void>;
  editMode?: boolean;
  required?: boolean;
  shown?: boolean;
  tagsDisabled?: boolean;
  value: string;
}

const EditableInput: FC<EditableInputProps> = ({
  onChange,
  editMode = false,
  required = false,
  shown = false,
  tagsDisabled = false,
  value,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>();

  const [savedValue, setSavedValue] = useState<string>(value);

  const handleInputFocus = useCallback<FocusEventHandler<HTMLInputElement>>(() => {
    if (value || !required) {
      setSavedValue(value);
    }
  }, [required, value]);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async event => {
      await onChange(event.target.value);
    },
    [onChange],
  );

  const handleInputKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    async event => {
      if (inputRef.current) {
        if (event.key === 'Escape') {
          await onChange(savedValue);
          inputRef.current.blur();
        } else if (event.key === 'Enter' && (value || !required)) {
          inputRef.current.blur();
          setSavedValue(value);
        }
      }
    },
    [onChange, required, savedValue, value],
  );

  if (!editMode) {
    return value;
  }

  return (
    <StyledOutlinedInput
      data-shown={shown ? '' : null}
      error={required && !value}
      inputRef={inputRef}
      tagsDisabled={tagsDisabled}
      value={value}
      onChange={handleInputChange}
      onFocus={handleInputFocus}
      onKeyDown={handleInputKeyDown}
      {...props}
    />
  );
};

export default EditableInput;
