import { Descendant } from 'slate';

import {
  CalculationVariable,
  CreateBlockTypeBody,
  DataVariable,
  Operator,
  ShortcutCategory,
  Symbols,
  TinyVariableSearchInfo,
  UUID,
  UpdateBlockTypeBody,
  VarCalc,
  VariableInfo,
} from '@dametis/core';

import { AvailableVncFilters, VncFilters } from 'zustand/states/vnc';

import { GroupByInputProps } from '../../UI/GroupByInput/GroupByInput';

export const symbolsRegex = new RegExp(
  Object.values(Symbols)
    .map(s => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
    .join('|'),
);
export const allowedSymbolsInAlpha = [Symbols.LEFT_PARENTHESIS, Symbols.RIGHT_PARENTHESIS, Symbols.ASSIGN, Symbols.SEMICOLON];

// PROPS

export type VncProps = {
  value: CalculationVariable;
  onChange: (calcVar: CalculationVariable) => unknown | Promise<unknown>;
  sourceCategory: ShortcutCategory;
  sourceUuid?: UUID;
  multiple?: boolean;
  selection?: VarCalc[];
  onSelectionChange?: (selection: VarCalc[]) => unknown | Promise<unknown>;
  listTab?: ListTab;
  defaultVariableOperator?: Operator;
  variableOperatorOptions?: Operator[];
  excludeVariableOperatorOptions?: Operator[];
  defaultGlobalOperator?: Operator;
  globalOperatorOptions?: Operator[];
  excludeGlobalOperatorOptions?: Operator[];
  disableMaths?: boolean;
  butKeepVariableMenu?: boolean;
  availableFilters?: Partial<AvailableVncFilters>;
  defaultFilters?: Partial<VncFilters>;
  disableFilters?: (keyof VncFilters)[];
  calculatedVariableMode?: boolean;
  standardBlockMetricMode?: boolean;
  unPostedBlockType?: CreateBlockTypeBody | UpdateBlockTypeBody | null;
  editingBlockTypeMetricUuid?: UUID | null;
  disableLego?: boolean;
  disableModels?: boolean;
  covarianceVariable?: DataVariable | null;
  unitPicker?: boolean;
  output?: 'singleValue' | 'multipleValues';
  GroupByInputProps?: Omit<GroupByInputProps, 'groupBy' | 'onGroupByChange'>;
  disableCreateAlias?: boolean;
};

// LIST

export const enum ListTab {
  HISTORY = 'history',
  VARIABLES = 'variables',
  BLOCKS = 'blocks',
  BLOCK_CHILDREN = 'blockChildren',
  MODELS = 'models',
}

export interface HistoryItem {
  id: string;
  slate: Descendant[];
  group: UUID;
  site: UUID;
  createdAt: Date;
}

export const enum OpenReplayEvent {
  // NAV
  OPEN_GROUP_SITE_MENU = 'openGroupSiteMenu',
  OPEN_DRAWER_MENU = 'openDrawerMenu',

  // REPORT
  REPORT_LOAD_TIME = 'reportLoadTime',

  // VNC
  VNC_OPEN = 'vncOpen',
  VNC_FILTER_PHYSICAL_QUANTITY = 'vncFilterPhysicalQuantity',
  VNC_FILTER_OTHER = 'vncFilterOther',
  VNC_SLATE_OPERATOR_MENU_CLICK = 'vncSlateOperatorMenuClick',
  VNC_SLATE_OPERATOR_LEFT_CLICK = 'vncSlateOperatorLeftClick',
  VNC_SLATE_VARIABLE_RIGHT_CLICK = 'vncSlateVariableRightClick',
  VNC_SLATE_OPERATOR_CHANGING = 'vncSlateOperatorChanging',
  VNC_HISTORY_INSERTING_ELEMENT = 'vncHistoryInsertingElement',
  VNC_SUBMIT_WITHOUT_MULTISELECT = 'vncSubmitWithoutMultiselect',
  VNC_SUBMIT_WITH_MULTISELECT = 'vncSubmitWithMultiselect',
}

export type VariableResult = VariableInfo & Pick<TinyVariableSearchInfo, 'score' | 'dismissed' | 'sScore' | 'highlight'>;
