import type { EnhancedStore } from '@redux-devtools/instrument';
import { ThunkAction, configureStore, Middleware, isRejectedWithValue, isAction, UnknownAction, MiddlewareAPI } from '@reduxjs/toolkit';
import { createRoot } from 'react-dom/client';
import { useSelector as useReduxSelector, useDispatch as useReduxDispatch } from 'react-redux';
import undoable, { excludeAction } from 'redux-undo';

import DevTools from './DevTools';
import { displaySdkErrorToast } from './actions/toasts';
import { api } from './api';
import { CLEAR_PERIOD, REDO_PERIOD, UNDO_PERIOD, UPDATE_DATA_PERIOD, UPDATE_MAX_GROUPBY } from './constants';
import advancedEntryReducer from './slices/advancedEntry';
import alarmsReducer from './slices/alarms';
import authReducer from './slices/auth';
import batchReducer from './slices/batch';
import billingReducer from './slices/billing';
import boxesReducer from './slices/boxes';
import calculationMenuReducer from './slices/calculationMenu';
import cartReducer from './slices/cart';
import configurationReducer from './slices/configuration';
import currentBoxReducer from './slices/currentBox';
import dataHistoryReducer from './slices/dataHistory';
import invitationReducer from './slices/invitations';
import legoReducer from './slices/lego';
import macroReducer from './slices/macro';
import macrosReducer from './slices/macros';
import manualEntryReducer from './slices/manualEntry';
import monitoringReducer from './slices/monitoring';
import operationsReducer from './slices/operations';
import periodReducer, { PeriodState } from './slices/period';
import playgroundReducer from './slices/playground';
import popupReducer from './slices/popup';
import projectReducer from './slices/project';
import projectTemplateReducer from './slices/projectTemplate';
import reportsReducer from './slices/reports';
import sharingReducer from './slices/sharing';
import synopticReducer from './slices/synoptic';
// import tagsReducer from './slices/tags';
import toastReducer from './slices/toast';
import variablesReducer from './slices/variables';

const rtkQueryErrorMiddleware: Middleware =
  ({ dispatch }: MiddlewareAPI<TypedDispatch>) =>
  next =>
  action => {
    if (isAction(action) && isRejectedWithValue(action)) {
      dispatch(displaySdkErrorToast(action.payload));
    }
    return next(action);
  };

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    advancedEntry: advancedEntryReducer,
    alarms: alarmsReducer,
    auth: authReducer,
    batch: batchReducer,
    billing: billingReducer,
    boxes: boxesReducer,
    cart: cartReducer,
    calculationMenu: calculationMenuReducer,
    configuration: configurationReducer,
    currentBox: currentBoxReducer,
    invitations: invitationReducer,
    lego: legoReducer,
    macro: macroReducer,
    macros: macrosReducer,
    manualEntry: manualEntryReducer,
    monitoring: monitoringReducer,
    operations: operationsReducer,
    // oldMonitoring: oldMonitoringReducer,
    period: undoable<PeriodState, UnknownAction>(periodReducer, {
      limit: 15,
      undoType: UNDO_PERIOD,
      redoType: REDO_PERIOD,
      // clearHistoryType: CLEAR_PERIOD,
      ignoreInitialState: true,
      initTypes: [CLEAR_PERIOD],
      filter: excludeAction([UPDATE_DATA_PERIOD, UPDATE_MAX_GROUPBY]),
      syncFilter: true,
    }),
    playground: playgroundReducer,
    popup: popupReducer,
    project: projectReducer,
    projectTemplate: projectTemplateReducer,
    reports: reportsReducer,
    sharing: sharingReducer,
    synoptic: synopticReducer,
    // tags: tagsReducer,
    toast: toastReducer,
    variables: variablesReducer,
    dataHistory: dataHistoryReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(api.middleware, rtkQueryErrorMiddleware),
});

export default store;

if (process.env.NODE_ENV === 'development') {
  document.addEventListener('keydown', evt => {
    if (evt.key !== '`') return;
    const popup = window.open(null, 'Redux DevTools', 'menubar=no,location=no,resizable=yes,scrollbars=no,status=no');
    popup.location.reload();
    setTimeout(() => {
      popup.document.write('<div id="react-devtools-root"></div>');
      const devTools = createRoot(popup.document.getElementById('react-devtools-root'));
      devTools.render(Boolean(DevTools) && <DevTools store={store as EnhancedStore<RootState, UnknownAction, unknown>} />);
    }, 10);
  });
}

export type RootState = ReturnType<typeof store.getState>;

export type TypedDispatch = typeof store.dispatch;
export type TypedThunk<R> = ThunkAction<R, RootState, unknown, UnknownAction>;

export const useSelector = useReduxSelector.withTypes<RootState>();
export const useDispatch = useReduxDispatch.withTypes<TypedDispatch>();
