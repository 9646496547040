import { AddOutlined, DeleteOutlined } from '@mui/icons-material';
import { FormLabel, IconButton, Paper, Stack, Typography } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CalculationVariable,
  FixedVariable,
  IsDataVariable,
  ReferencedBy,
  SourceColumn,
  SourceColumnRealByHeader,
  SourceColumnRealByIndex,
} from '@dametis/core';
import { getSingleVariableCalculation } from '@dametis/mathjs';

import SourceColumnPicker from 'components/Configuration/FileImport/UI/SourceColumnPicker/SourceColumnPicker';
import ActionButton from 'components/UI/Buttons/ActionButton/ActionButton';
import Helper from 'components/UI/Helper/Helper';
import VncDialog from 'components/VNC/VncDialog';
import { createCalculationVariable } from 'functions/createCalculationVariable';
import { UserHelperName } from 'types/userHelpers';

import DateTimeSourceForm from './DateTimeSourceForm';
import FixedVariablePicker from './FixedVariablePicker';
import { useMappingSettingsContext } from './MappingSettingsContext';
import { vncProps } from './SourceColumnForm';

export interface SourcesViewSettingsProps {
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnNames: string[];
}

const SourcesViewSettings: FC<SourcesViewSettingsProps> = ({
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnNames,
}) => {
  const { t } = useTranslation('fileImport');

  const {
    fixedVariables,
    usedSources,
    referencedBy,
    dateTimeSource,
    referenceSource,
    valueSource,
    skippedColumns,
    setFixedVariables,
    setDateTimeSource,
    setReferenceSource,
    setValueSource,
    setSkippedColumns,
  } = useMappingSettingsContext();

  const [isVNCOpen, setIsVNCOpen] = useState<boolean>(false);
  const [vncValue, setVNCValue] = useState<CalculationVariable>(createCalculationVariable);

  const handleChangeDateTimeSource = useCallback(
    (newSource: SourceColumn) => {
      setDateTimeSource(newSource);
    },
    [setDateTimeSource],
  );

  const handleChangeReferenceSource = useCallback(
    (newSource: SourceColumn) => {
      setReferenceSource(newSource);
    },
    [setReferenceSource],
  );

  const handleChangeValueSource = useCallback(
    (newSource: SourceColumn) => {
      setValueSource(newSource);
    },
    [setValueSource],
  );

  const handleChangeSkippedColumn = useCallback(
    (sourceIndex: number) => (newSource: SourceColumn) => {
      setSkippedColumns(state => state.map((source, index) => (sourceIndex === index ? newSource : source)));
    },
    [setSkippedColumns],
  );

  const handleAddSkippedColumn = useCallback(() => {
    setSkippedColumns(state => [...state, null]);
  }, [setSkippedColumns]);

  const handleDeleteSkippedColumn = useCallback(
    (sourceIndex: number) => () => {
      setSkippedColumns(state => state.filter((_source, index) => sourceIndex !== index));
    },
    [setSkippedColumns],
  );

  const handleChangeFixedVariable = useCallback(
    (sourceIndex: number) => (newFixedVariable: FixedVariable) => {
      setFixedVariables(state => state.map((source, index) => (sourceIndex === index ? newFixedVariable : source)));
    },
    [setFixedVariables],
  );

  const handleOpenFixedVariableVNC = useCallback(() => {
    setVNCValue(createCalculationVariable());
    setIsVNCOpen(true);
  }, []);

  const handleAddFixedVariable = useCallback(
    (newFixedVariableCalculation: CalculationVariable) => {
      const singleVariable = getSingleVariableCalculation(newFixedVariableCalculation);
      if (singleVariable && IsDataVariable(singleVariable)) {
        const newFixedVariable: FixedVariable = { variableId: singleVariable.variableUuid, source: null };
        setFixedVariables(state => [...state, newFixedVariable]);
      }
    },
    [setFixedVariables],
  );

  const handleDeleteFixedVariable = useCallback(
    (sourceIndex: number) => () => {
      setFixedVariables(state => state.filter((_source, index) => sourceIndex !== index));
    },
    [setFixedVariables],
  );

  return (
    <Stack gap={2}>
      <Paper sx={{ p: 2 }}>
        <Typography mb={1} variant="h6">
          {t('title.dateTime')}
        </Typography>
        <Stack gap={1}>
          <SourceColumnPicker
            fullWidth
            availableRealByHeaderColumns={availableRealByHeaderColumns}
            availableRealByIndexColumns={availableRealByIndexColumns}
            availableVirtualColumnNames={availableVirtualColumnNames}
            label={t('label.dateTimeSource')}
            usedSources={usedSources}
            value={dateTimeSource}
            onChange={handleChangeDateTimeSource}
          />
          <DateTimeSourceForm />
        </Stack>
      </Paper>
      {referencedBy === ReferencedBy.ROWS && (
        <>
          <Paper sx={{ p: 2 }}>
            <Typography mb={1} variant="h6">
              {t('title.reference')}
            </Typography>
            <SourceColumnPicker
              fullWidth
              availableRealByHeaderColumns={availableRealByHeaderColumns}
              availableRealByIndexColumns={availableRealByIndexColumns}
              availableVirtualColumnNames={availableVirtualColumnNames}
              label={t('label.referenceSource')}
              usedSources={usedSources}
              value={referenceSource}
              onChange={handleChangeReferenceSource}
            />
          </Paper>
          <Paper sx={{ p: 2 }}>
            <Typography mb={1} variant="h6">
              {t('title.value')}
            </Typography>
            <SourceColumnPicker
              fullWidth
              availableRealByHeaderColumns={availableRealByHeaderColumns}
              availableRealByIndexColumns={availableRealByIndexColumns}
              availableVirtualColumnNames={availableVirtualColumnNames}
              label={t('label.valueSource')}
              usedSources={usedSources}
              value={valueSource}
              onChange={handleChangeValueSource}
            />
          </Paper>
        </>
      )}
      <Paper sx={{ p: 2 }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h6">{t('title.skippedColumns')}</Typography>
          <ActionButton startIcon={<AddOutlined />} onClick={handleAddSkippedColumn}>
            {t('button.add')}
          </ActionButton>
        </Stack>
        <Stack gap={1}>
          {skippedColumns.map((skippedColumn, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Stack key={index} gap={0.25}>
              <FormLabel>{t('label.skippedColumnWithIndex', { index: index + 1 })}</FormLabel>
              <Stack alignItems="center" direction="row" gap={0.5}>
                <SourceColumnPicker
                  fullWidth
                  availableRealByHeaderColumns={availableRealByHeaderColumns}
                  availableRealByIndexColumns={availableRealByIndexColumns}
                  availableVirtualColumnNames={availableVirtualColumnNames}
                  usedSources={usedSources}
                  value={skippedColumn}
                  onChange={handleChangeSkippedColumn(index)}
                />

                <IconButton size="small" onClick={handleDeleteSkippedColumn(index)}>
                  <DeleteOutlined fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          ))}
          {skippedColumns.length === 0 && (
            <Typography align="center" pt={2} px={2} variant="subtitle2">
              {t('text.noSkippedColumn')}
            </Typography>
          )}
        </Stack>
      </Paper>
      <Paper sx={{ p: 2 }}>
        <Stack alignItems="center" direction="row" justifyContent="space-between" mb={1}>
          <Typography variant="h6">{t('title.fixedVariables')}</Typography>
          <ActionButton startIcon={<AddOutlined />} onClick={handleOpenFixedVariableVNC}>
            {t('button.add')}
          </ActionButton>
        </Stack>
        <Helper name={UserHelperName.FILE_IMPORT_FIXED_VARIABLES}>{t('helper.fixedVariables')}</Helper>
        <Stack gap={1} mt={1}>
          <VncDialog open={isVNCOpen} setOpen={setIsVNCOpen} value={vncValue} onChange={handleAddFixedVariable} {...vncProps} />
          {fixedVariables.map((fixedVariable, index) => (
            <FixedVariablePicker
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              availableRealByHeaderColumns={availableRealByHeaderColumns}
              availableRealByIndexColumns={availableRealByIndexColumns}
              availableVirtualColumnNames={availableVirtualColumnNames}
              usedSources={usedSources}
              value={fixedVariable}
              onChange={handleChangeFixedVariable(index)}
              onDelete={handleDeleteFixedVariable(index)}
            />
          ))}
          {fixedVariables.length === 0 && (
            <Typography align="center" pt={2} px={2} variant="subtitle2">
              {t('text.noFixedVariable')}
            </Typography>
          )}
        </Stack>
      </Paper>
    </Stack>
  );
};

export default SourcesViewSettings;
