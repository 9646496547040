import { v4 as uuidv4 } from 'uuid';

import { TabType, UUID } from '@dametis/core';

import { DaChart } from 'classes/DaCharts/DaChart';
import { TypedThunk } from 'store';

import {
  IBarChart,
  IChart,
  IChartProps,
  IHistogram,
  ILiveChart,
  ITab,
  ITable,
  ITableProps,
  ITyChart,
  IVariable,
  IXyChart,
} from '../../types';
import { createChart, exportChart } from '../Chart';
import { createHistogram, exportHistogram, getHistogramData } from '../HISTOGRAM/Histogram';
import { createLiveChart, exportLiveChart } from '../LIVE_CHART/LiveChart';
import { createTableChart, exportTable, getTableData } from '../TABLE/Table';
import { createTyChart, exportTyChart, getTyChartData } from '../TY_CHART/TyChart';
import { createXyChart, exportXyChart, getXyChartData } from '../XY_CHART/XyChart';

export const createChartFromType = (type: TabType, props?: IChartProps | ITableProps): IChart | ITable => {
  switch (type) {
    case TabType.TY_CHART:
      return createTyChart({ ...(props as IChartProps), isBarChart: false });
    case TabType.BAR_CHART:
      return createTyChart({ ...(barChartToTyChartWithBar(props) as IChartProps), isBarChart: true });
    case TabType.HISTOGRAM:
      return createHistogram(props as IChartProps);
    case TabType.XY_CHART:
      return createXyChart(props as IChartProps);
    case TabType.LIVE_CHART:
      return createLiveChart(props as IChartProps);
    case TabType.TABLE:
      return createTableChart(props as ITableProps);
    default:
      return createChart(props);
  }
};

export const exportChartFromType = (type: TabType, chart: IChart | ITable): Promise<IChart | ITable> | IChart => {
  switch (type) {
    case TabType.TY_CHART:
      return exportTyChart(chart as ITyChart);
    case TabType.BAR_CHART:
      return exportTyChart(chart as ITyChart);
    case TabType.HISTOGRAM:
      return exportHistogram(chart as IHistogram);
    case TabType.XY_CHART:
      return exportXyChart(chart as IXyChart);
    case TabType.LIVE_CHART:
      return exportLiveChart(chart as ILiveChart);
    case TabType.TABLE:
      return exportTable(chart as ITable);
    default:
      return exportChart(chart);
  }
};

export const getChartDataFromType =
  (type: TabType, tab: ITab): TypedThunk<Promise<void>> =>
  dispatch => {
    switch (type) {
      case TabType.TY_CHART:
        return dispatch(getTyChartData(tab.uuid));
      case TabType.BAR_CHART:
        return dispatch(getTyChartData(tab.uuid));
      case TabType.HISTOGRAM:
        return dispatch(getHistogramData(tab.uuid));
      case TabType.XY_CHART:
        return dispatch(getXyChartData(tab as ITab<IXyChart>));
      case TabType.LIVE_CHART:
        return null;
      case TabType.TABLE:
        return dispatch(getTableData(tab.uuid));
      default:
        return null;
    }
  };

export const chartToPng = async (chart: DaChart): Promise<string> => {
  if (!chart) return '';
  const svg = chart.getSVG();

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const img = document.createElement('img');
  const data = encodeURIComponent(svg);

  img.setAttribute('src', `data:image/svg+xml;utf8,${data}`);
  await img.decode(); // similar to image.onload

  canvas.width = 400;
  canvas.height = img.height * (400 / img.width);
  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  return canvas.toDataURL();
};

export const getStoreTab =
  (uuid: UUID): TypedThunk<ITab> =>
  (dispatch, getState) => {
    const { tabs } = getState().playground;
    const tabIndex = tabs.findIndex(t => t.uuid === uuid);
    return tabs[tabIndex];
  };

const HasVariables = (tab: any): tab is { chart: { variables: IVariable[] } } => Array.isArray(tab?.chart?.variables);

export const getStoreVariable =
  <Var extends IVariable>(tabUuid: UUID, variableUuid: UUID): TypedThunk<Var> =>
  dispatch => {
    const tab = dispatch(getStoreTab(tabUuid));
    if (!HasVariables(tab)) return null;
    return (tab.chart.variables.find(v => v.uuid === variableUuid) as Var) ?? null;
  };

const IsOldBarChart = (chart: any): chart is IBarChart => chart && !(chart?.groups?.length >= 0);

export const barChartToTyChartWithBar = (barChart: IChartProps): IChartProps => {
  if (!IsOldBarChart(barChart)) return barChart;

  const { variables, ...chartBase } = barChart;

  const newChart: ITyChart = {
    ...chartBase,
    customTimeRange: undefined,
    groups: variables.map(variable => {
      const groupUuid = uuidv4();

      return {
        uuid: groupUuid,
        name: variable.unit,
        unit: variable.unit,
        min: null,
        max: null,
        yAxis: null,
        hidden: false,
        variables: [
          {
            uuid: variable.uuid,
            name: variable.name,
            expression: variable.expression,
            daSeries: null,
            unit: variable.unit,
            apiUnit: null,
            color: variable.color,
            hidden: false,
            groupUuid,
          },
        ],
      };
    }),
  };

  return newChart;
};
