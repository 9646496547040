import { Stack, Typography } from '@mui/material';
import { FC, Fragment, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { BlockTypeInfo, FolderDepthFirstSearch, ShortcutCategory, ShortStandardBlockInfo, UUID } from '@dametis/core';

import { useSelector } from 'store';
import { useBlockTypes } from 'store/api/blockTypes';
import { useReadGlobalRootFolderQuery } from 'store/api/globalFolders';
import { useStandardBlocks } from 'store/api/standardBlocks';

import BlockTypeItem from './BlockTypeItem';
import CatalogSubheader from './CatalogSubheader';
import { ListItem, ListItemBlockType, ListItemStandardBlock, ListItemType } from './FolderContent';
import StandardBlockItem from './StandardBlockItem';

const blockTypesEmptyArray: BlockTypeInfo[] = [];
const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];

const NoFolderContent: FC = () => {
  const { t } = useTranslation('lego');
  const navigate = useNavigate();

  const { entityId } = useParams<{ entityId?: UUID }>();

  const groupByType = useSelector(state => state.lego.navigationTools.groupByType);

  const {
    data: globalRootFolder = null,
    isLoading: isGlobalRootFolderLoading,
    isUninitialized: isGlobalRootFolderUninitialized,
  } = useReadGlobalRootFolderQuery();

  const {
    data: blockTypes = blockTypesEmptyArray,
    isLoading: isBlockTypesLoading,
    isUninitialized: isBlockTypesUninitialized,
  } = useBlockTypes();

  const {
    data: standardBlocks = standardBlocksEmptyArray,
    isLoading: isStandardBlocksLoading,
    isUninitialized: isStandardBlocksUninitialized,
  } = useStandardBlocks();

  const isGlobalRootFolderFetched = useMemo(
    () => !isGlobalRootFolderLoading && !isGlobalRootFolderUninitialized,
    [isGlobalRootFolderLoading, isGlobalRootFolderUninitialized],
  );

  const isBlockTypesFetched = useMemo(
    () => !isBlockTypesLoading && !isBlockTypesUninitialized,
    [isBlockTypesLoading, isBlockTypesUninitialized],
  );

  const isStandardBlocksFetched = useMemo(
    () => !isStandardBlocksLoading && !isStandardBlocksUninitialized,
    [isStandardBlocksLoading, isStandardBlocksUninitialized],
  );

  const entityInFolderIds = useMemo(
    () =>
      globalRootFolder
        ? FolderDepthFirstSearch(
            globalRootFolder,
            (acc: UUID[], folder) => {
              const entityShortcuts = folder.shortcuts.reduce<UUID[]>((result, shortcut) => {
                if (
                  shortcut.uuid &&
                  (shortcut.category === ShortcutCategory.BLOCK_TYPE || shortcut.category === ShortcutCategory.STANDARD_BLOCK)
                ) {
                  result.push(shortcut.uuid);
                }
                return result;
              }, []);
              return acc.concat(...entityShortcuts);
            },
            [],
          )
        : [],
    [globalRootFolder],
  );

  const availableBlockTypes = useMemo(
    () =>
      blockTypes
        .filter(blockType => !entityInFolderIds.includes(blockType.uuid))
        .sort((blockTypeA, blockTypeB) => blockTypeA.name.localeCompare(blockTypeB.name)),
    [blockTypes, entityInFolderIds],
  );

  const availableStandardBlocks = useMemo(
    () =>
      standardBlocks
        .filter(standardBlock => !entityInFolderIds.includes(standardBlock.uuid))
        .sort((standardBlockA, standardBlockB) => standardBlockA.name.localeCompare(standardBlockB.name)),
    [standardBlocks, entityInFolderIds],
  );

  const availableItems: ListItem[] = useMemo(
    () =>
      [
        ...availableBlockTypes.map<ListItemBlockType>(blockType => ({ type: ListItemType.BLOCK_TYPE, content: blockType })),
        ...availableStandardBlocks.map<ListItemStandardBlock>(standardBlock => ({
          type: ListItemType.STANDARD_BLOCK,
          content: standardBlock,
        })),
      ].sort((itemA, itemB) => itemA.content.name.localeCompare(itemB.content.name)),
    [availableBlockTypes, availableStandardBlocks],
  );

  useEffect(() => {
    if (entityId && isGlobalRootFolderFetched && isBlockTypesFetched && isStandardBlocksFetched) {
      /**
       * Si un entityId est spécifié, on vérifie qu'il fait partie des blockTypes existants n'ayant pas de dossier parent
       */
      const type = availableBlockTypes.find(filteredBlockType => filteredBlockType.uuid === entityId);
      const standard = availableStandardBlocks.find(filteredStandardBlock => filteredStandardBlock.uuid === entityId);
      if (!type && !standard) {
        navigate(`/blockTypes/noFolder`, { replace: true });
      }
    }
  }, [
    availableBlockTypes,
    availableStandardBlocks,
    entityId,
    isGlobalRootFolderFetched,
    navigate,
    isBlockTypesFetched,
    isStandardBlocksFetched,
  ]);

  return (
    <Stack my={0.5}>
      {groupByType && (
        <>
          {availableBlockTypes.length > 0 && (
            <>
              <CatalogSubheader>{t('subtitle.blockTypes')}</CatalogSubheader>
              {availableBlockTypes.map(blockType => (
                <BlockTypeItem key={blockType.uuid} blockType={blockType} selected={blockType.uuid === entityId} />
              ))}
            </>
          )}
          {availableStandardBlocks.length > 0 && (
            <>
              <CatalogSubheader>{t('subtitle.standardBlocks')}</CatalogSubheader>
              {availableStandardBlocks.map(standardBlock => (
                <StandardBlockItem key={standardBlock.uuid} selected={standardBlock.uuid === entityId} standardBlock={standardBlock} />
              ))}
            </>
          )}
        </>
      )}
      {!groupByType && (
        <>
          {availableItems.map(item => (
            <Fragment key={item.content.uuid}>
              {item.type === ListItemType.BLOCK_TYPE && (
                <BlockTypeItem blockType={item.content} selected={item.content.uuid === entityId} />
              )}
              {item.type === ListItemType.STANDARD_BLOCK && (
                <StandardBlockItem selected={item.content.uuid === entityId} standardBlock={item.content} />
              )}
            </Fragment>
          ))}
        </>
      )}
      {availableBlockTypes.length === 0 && availableStandardBlocks.length === 0 && (
        <Typography align="center" p={2} variant="subtitle2">
          {t('text.noElement')}
        </Typography>
      )}
    </Stack>
  );
};

export default NoFolderContent;
