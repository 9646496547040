import { Tooltip, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityInfo } from '@dametis/core';

import { isEqual, localizedFormat, localizedFormatDistanceToNowStrict } from 'localization/localizedDateFns';

interface Props {
  entity: Pick<Partial<EntityInfo>, 'createdAt' | 'updatedAt'>;
}

const EntityPanelSubtitle: FC<Props> = ({ entity }) => {
  const { t } = useTranslation('global');

  const createdAt = useMemo(() => new Date(entity.createdAt), [entity.createdAt]);
  const updatedAt = useMemo(() => new Date(entity.updatedAt ?? entity.createdAt), [entity.updatedAt, entity.createdAt]);

  return isEqual(createdAt, updatedAt) ? (
    <Tooltip title={localizedFormat(createdAt, 'Pp')}>
      <Typography variant="caption">
        {t('text.subtitle.createdAt', { date: localizedFormatDistanceToNowStrict(createdAt, { addSuffix: true }) })}
      </Typography>
    </Tooltip>
  ) : (
    <Tooltip title={localizedFormat(updatedAt, 'Pp')}>
      <Typography variant="caption">
        {t('text.subtitle.updatedAt', { date: localizedFormatDistanceToNowStrict(updatedAt, { addSuffix: true }) })}
      </Typography>
    </Tooltip>
  );
};

export default EntityPanelSubtitle;
