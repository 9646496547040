import { LinearProgress } from '@mui/material';
import { FC, Suspense, useEffect, useMemo } from 'react';
import { Route, Routes as RouterRoutes, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useSelector } from 'store';

import { matomoTrack } from '../../functions/matomo';
import { openReplay } from '../../functions/openReplay';
import routesList from '../../routes';
import { AuthStatus } from '../../types/auth';

import RouteElement from './RouteElement';

const Routes: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const user = useSelector(state => state.auth.user);
  const status = useSelector(state => state.auth.status);
  const selectedSite = useSelector(state => state.auth.selectedSite);
  const selectedGroup = useSelector(state => state.auth.selectedGroup);

  const routes = useMemo(
    () =>
      routesList.filter(
        route =>
          (route.corporate === null || route.corporate === !selectedSite) &&
          (!route.permission || !user?.acl || user.acl.HasPermission(route.permission, selectedGroup?.uuid, selectedSite)),
      ),
    [selectedGroup?.uuid, selectedSite, user?.acl],
  );

  useEffect(() => {
    matomoTrack();
  }, [location.pathname]);

  useEffect(() => {
    void openReplay(user, selectedGroup, selectedSite);
  }, [user, selectedGroup, selectedSite]);

  useEffect(() => {
    if (user !== null && searchParams.get('redirectUri') !== null) {
      navigate(searchParams.get('redirectUri'));
    }
  }, [user, navigate, searchParams]);

  if ([AuthStatus.LOADING, AuthStatus.UNKNOWN].includes(status)) return null;
  return (
    <Suspense fallback={<LinearProgress color="secondary" />}>
      <RouterRoutes>
        {routes.map(route => (
          <Route key={route.path} element={<RouteElement route={route} />} path={route.path} />
        ))}
      </RouterRoutes>
    </Suspense>
  );
};

export default Routes;
