import { OperationStatus, OperationType } from '@dametis/core';

export default {
  userMenu: {
    logOut: 'Cerrar sesión',
    myAccount: 'Mi cuenta',
    support: 'Ayuda y asistencia',
    stopImpersonation: 'Recuperar su identidad',
  },
  siteSelect: {
    group: 'Grupo',
    groups: 'Grupos',
    sites: 'Sitios',
    site: 'Sitio',
    corporate: 'Corporate',
  },
  statusMenu: {
    status: 'Iniciar sesión',
    msg_normal: 'Normal',
    msg_warning: 'Anomalía',
    msg_critical: 'Problema',
    msg_noBox: 'Falta de caja',
  },
  operationMenu: {
    title: 'Operaciones',
    clear: 'Vaciar',
    tooltip_none: 'No hay operaciones en curso',
    tooltip_one: '{{count}} operación en curso',
    tooltip_other: '{{count}} operaciones en curso',
    tooltip: {
      [OperationStatus.LOADING]: {
        text_one: '{{count}} operación en curso',
        text_other: '{{count}} operaciones en curso',
      },
      [OperationStatus.CANCELLED]: {
        text_one: '{{count}} operación cancelada',
        text_other: '{{count}} operaciones canceladas',
      },
      [OperationStatus.SUCCESS]: {
        text_one: '{{count}} operación exitosa',
        text_other: '{{count}} operaciones exitosas',
      },
      [OperationStatus.WARNING]: {
        text_one: '{{count}} advertencia',
        text_other: '{{count}} advertencias',
      },
      [OperationStatus.ERROR]: {
        text_one: '{{count}} error',
        text_other: '{{count}} errores',
      },
    },
    buttonLabel: {
      over_one: '{{count}} completado',
      over_other: '{{count}} completadas',
      warning_one: '{{count}} alerta',
      warning_other: '{{count}} alertas',
      error_one: '{{count}} error',
      error_other: '{{count}} errores',
    },
    operations: {
      [OperationType.CREATE_CALC_VAR]: {
        name: 'Creación de una variable calculada',
        name_withName: 'Creación de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Variable creada con éxito.',
        [`description_${OperationStatus.CANCELLED}`]: 'Variable cancelada durante el cálculo.',
        [`description_${OperationStatus.WARNING}`]: 'Variable creada pero datos faltantes.',
        [`description_${OperationStatus.ERROR}`]: 'Se ha producido un error.',
      },
      [OperationType.EDIT_CALC_VAR]: {
        name: 'Modificación de una variable calculada',
        name_withName: 'Modificación de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Variable modificada con éxito.',
        [`description_${OperationStatus.CANCELLED}`]: 'Variable cancelada durante el cálculo.',
        [`description_${OperationStatus.WARNING}`]: 'Variable modificada pero datos faltantes.',
        [`description_${OperationStatus.ERROR}`]: 'Se ha producido un error.',
      },
      [OperationType.CREATE_BATCH]: {
        name: 'Creación de un batch',
        name_withName: 'Creación de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Batch creado con éxito.',
        [`description_${OperationStatus.CANCELLED}`]: 'Batch cancelado durante el cálculo.',
        [`description_${OperationStatus.WARNING}`]: 'Batch creado pero datos faltantes.',
        [`description_${OperationStatus.ERROR}`]: 'Se ha producido un error.',
      },
      [OperationType.EDIT_BATCH]: {
        name: 'Modificación de un batch',
        name_withName: 'Modificación de {{name}}',
        [`description_${OperationStatus.SUCCESS}`]: 'Batch modificado con éxito.',
        [`description_${OperationStatus.CANCELLED}`]: 'Batch cancelado durante el cálculo.',
        [`description_${OperationStatus.WARNING}`]: 'Batch modificado pero datos faltantes.',
        [`description_${OperationStatus.ERROR}`]: 'Se ha producido un error.',
      },
      [OperationType.EDIT_DATA]: {
        name: 'Edición de datos',
        name_withNumber: 'Edición de datos de {{number}} variable(s)',
        [`description_${OperationStatus.SUCCESS}`]: 'Datos editados con éxito.',
        [`description_${OperationStatus.ERROR}`]: 'Se ha producido un error.',
      },
      [OperationType.IMPORT_DATA]: {
        name: 'Importación de datos',
        name_withNumber: 'Importación de datos de {{number}} variable(s)',
        [`description_${OperationStatus.SUCCESS}`]: 'Datos importados con éxito.',
        [`description_${OperationStatus.ERROR}`]: 'Se ha producido un error.',
      },
      [OperationType.DELETE_DATA]: {
        name: 'Eliminación de datos',
        name_withNumber: 'Eliminación de datos de {{number}} variable(s)',
        [`description_${OperationStatus.SUCCESS}`]: 'Datos eliminados con éxito.',
        [`description_${OperationStatus.ERROR}`]: 'Se ha producido un error.',
      },
    },
  },
  helpMenu: {
    tooltip: '¿Una pregunta, un problema o un error?',
    button: 'Soporte',
  },
  maintenance: {
    msg: 'La plataforma Dametis está realizando una actualización importante.\nAlgunas funcionalidades pueden estar temporalmente no disponibles o más lentas, pero volverán pronto.',
    ovh: 'Nuestro proveedor de servicios está experimentando un incidente importante.\nAlgunas funcionalidades pueden estar temporalmente no disponibles o más lentas, pero volverán pronto.',
  },
  impersonate: {
    msg: 'Ahora estás usando la identidad de {{ firstName }} {{ lastName }}',
  },
  tooltip: {
    settings: 'Configuración',
    favorite: 'Agregar a favoritos',
    unfavorite: 'Vaciar de favoritos',
  },
  helper: {
    menu: 'para abrir este menú',
  },
};
