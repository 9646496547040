import { FC, useCallback, useEffect, useMemo } from 'react';
import { createEditor, Descendant, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact } from 'slate-react';

import { Operator, VarCalc, VariableKind } from '@dametis/core';

import { GroupByInputProps as GroupByInputPropsType } from 'components/UI/GroupByInput/GroupByInput';
import { openReplayEvent } from 'functions/openReplay';
import { getDefaultGroupByForOperator } from 'functions/tada/getGroupBy';
import { useDispatch } from 'store';
import { FakeVariableKind } from 'types/variables';
import { AvailableVncFilters, VncFilters } from 'zustand/states/vnc';
import { createVncStore, useVncStore, VncStoreContext } from 'zustand/stores/vnc';

import Actions from './components/Actions/Actions';
import Content from './components/Content';
import Title from './components/Title/Title';
import { StyledDialog } from './components/styled';
import { IPropsContext, PropsContext } from './context';
import { sortedOperators } from './props';
import { resetNodes, withCustomElements } from './slate';
import { createParagraph } from './slate/paragraph';
import { keepProps, tadaToSlate } from './slate/tada';
import { ListTab, OpenReplayEvent, VncProps } from './types';

const initialValue: Descendant[] = [createParagraph()];
const excludeVariableOperatorOptionsDefaultProp: Operator[] = [];
const excludeGlobalOperatorOptionsDefaultProp: Operator[] = [];
const disableFiltersDefaultProp: (keyof VncFilters)[] = [];
const selectionDefaultProp: VarCalc[] = [];
const availableFiltersDefaultProp: Partial<AvailableVncFilters> = {
  kinds: [
    VariableKind.CALCULATED,
    VariableKind.REAL,
    VariableKind.MANUAL,
    VariableKind.CONSTANT,
    VariableKind.NONE,
    FakeVariableKind.ALARM,
    FakeVariableKind.BATCH,
    FakeVariableKind.VAPOR_MIX,
  ],
};
const defaultFiltersDefaultProp: Partial<VncFilters> = {};
const GroupByInputPropsDefaultProp: Omit<GroupByInputPropsType, 'groupBy' | 'onGroupByChange'> = {};

type Props = VncProps & { open: boolean; setOpen: (open: boolean) => void };

const VncDialogWithContext: FC<Props> = ({
  value,
  onChange,
  multiple = false,
  selection = selectionDefaultProp,
  onSelectionChange = undefined,
  sourceCategory,
  sourceUuid = undefined,
  open,
  setOpen,
  listTab = ListTab.VARIABLES,
  defaultVariableOperator = undefined,
  variableOperatorOptions = sortedOperators,
  excludeVariableOperatorOptions = excludeVariableOperatorOptionsDefaultProp,
  defaultGlobalOperator = undefined,
  globalOperatorOptions = sortedOperators,
  excludeGlobalOperatorOptions = excludeGlobalOperatorOptionsDefaultProp,
  disableMaths = false,
  butKeepVariableMenu = false,
  availableFilters = availableFiltersDefaultProp,
  defaultFilters = defaultFiltersDefaultProp,
  disableFilters = disableFiltersDefaultProp,
  calculatedVariableMode = false,
  standardBlockMetricMode = false,
  unPostedBlockType = null,
  editingBlockTypeMetricUuid = null,
  disableLego = false,
  disableModels = false,
  covarianceVariable = null,
  unitPicker = true,
  output = 'multipleValues',
  GroupByInputProps = GroupByInputPropsDefaultProp,
  disableCreateAlias = false,
}) => {
  const dispatch = useDispatch();
  const setCovarianceVariable = useVncStore(state => state.setCovarianceVariable);
  const setCalcVarProps = useVncStore(state => state.setCalcVarProps);
  const setSelection = useVncStore(state => state.setSelection);
  const clearStore = useVncStore(state => state.clearStore);
  const setUnPostedBlockType = useVncStore(state => state.setUnPostedBlockType);
  const editor = useMemo(() => withCustomElements(withHistory(withReact(createEditor())), disableMaths), [disableMaths]);
  const propsContext = useMemo<IPropsContext>(
    () => ({
      onChange,
      multiple,
      selection,
      onSelectionChange,
      sourceCategory,
      sourceUuid,
      listTab,
      defaultVariableOperator,
      variableOperatorOptions,
      excludeVariableOperatorOptions,
      defaultGlobalOperator,
      globalOperatorOptions,
      excludeGlobalOperatorOptions,
      disableMaths,
      butKeepVariableMenu,
      availableFilters,
      defaultFilters,
      disableFilters,
      calculatedVariableMode,
      standardBlockMetricMode,
      editingBlockTypeMetricUuid,
      disableLego,
      disableModels,
      covarianceVariable,
      unitPicker,
      output,
      GroupByInputProps,
      disableCreateAlias,
    }),
    [
      onChange,
      multiple,
      selection,
      onSelectionChange,
      sourceCategory,
      sourceUuid,
      listTab,
      defaultVariableOperator,
      variableOperatorOptions,
      excludeVariableOperatorOptions,
      defaultGlobalOperator,
      globalOperatorOptions,
      excludeGlobalOperatorOptions,
      disableMaths,
      butKeepVariableMenu,
      availableFilters,
      defaultFilters,
      disableFilters,
      calculatedVariableMode,
      standardBlockMetricMode,
      editingBlockTypeMetricUuid,
      disableLego,
      disableModels,
      covarianceVariable,
      unitPicker,
      output,
      GroupByInputProps,
      disableCreateAlias,
    ],
  );
  useEffect(() => {
    if (!open || !editor) return undefined;
    setCovarianceVariable(covarianceVariable);
    setCalcVarProps({
      ...(!value.operator && {
        operator: defaultGlobalOperator,
        groupBy: getDefaultGroupByForOperator(defaultGlobalOperator, { isCalculatedVariable: calculatedVariableMode }),
      }),
      ...keepProps(value),
    });
    setUnPostedBlockType(unPostedBlockType);
    setSelection(selection);
    editor.children = tadaToSlate(value);
    editor.onChange();
    Transforms.select(editor, Editor.end(editor, []));
    return () => {
      resetNodes(editor);
      clearStore();
    };
  }, [
    open,
    editor,
    value,
    dispatch,
    covarianceVariable,
    selection,
    defaultGlobalOperator,
    defaultVariableOperator,
    calculatedVariableMode,
    unPostedBlockType,
    setUnPostedBlockType,
    setCovarianceVariable,
    setCalcVarProps,
    setSelection,
    clearStore,
  ]);
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  useEffect(() => {
    if (open) {
      openReplayEvent(OpenReplayEvent.VNC_OPEN);
    }
  }, [open]);

  return (
    <Slate editor={editor} initialValue={initialValue}>
      {' '}
      <PropsContext.Provider value={propsContext}>
        {' '}
        <StyledDialog fullWidth maxWidth={false} open={open} onClose={close}>
          {' '}
          <Title /> <Content /> <Actions setOpen={setOpen} />{' '}
        </StyledDialog>{' '}
      </PropsContext.Provider>{' '}
    </Slate>
  );
};
const VncDialog: FC<Props> = ({
  multiple = false,
  selection = selectionDefaultProp,
  onSelectionChange = undefined,
  sourceUuid = undefined,
  listTab = ListTab.VARIABLES,
  defaultVariableOperator = undefined,
  variableOperatorOptions = sortedOperators,
  excludeVariableOperatorOptions = excludeVariableOperatorOptionsDefaultProp,
  defaultGlobalOperator = undefined,
  globalOperatorOptions = sortedOperators,
  excludeGlobalOperatorOptions = excludeGlobalOperatorOptionsDefaultProp,
  disableMaths = false,
  butKeepVariableMenu = false,
  availableFilters = availableFiltersDefaultProp,
  defaultFilters = defaultFiltersDefaultProp,
  disableFilters = disableFiltersDefaultProp,
  calculatedVariableMode = false,
  standardBlockMetricMode = false,
  unPostedBlockType = null,
  editingBlockTypeMetricUuid = null,
  disableLego = false,
  disableModels = false,
  covarianceVariable = null,
  unitPicker = true,
  output = 'multipleValues',
  GroupByInputProps = GroupByInputPropsDefaultProp,
  disableCreateAlias = false,
  ...props
}) => {
  const store = useMemo(createVncStore, []);
  return (
    <VncStoreContext.Provider value={store}>
      {' '}
      <VncDialogWithContext
        availableFilters={availableFilters}
        butKeepVariableMenu={butKeepVariableMenu}
        calculatedVariableMode={calculatedVariableMode}
        covarianceVariable={covarianceVariable}
        defaultFilters={defaultFilters}
        defaultGlobalOperator={defaultGlobalOperator}
        defaultVariableOperator={defaultVariableOperator}
        disableCreateAlias={disableCreateAlias}
        disableFilters={disableFilters}
        disableLego={disableLego}
        disableMaths={disableMaths}
        disableModels={disableModels}
        editingBlockTypeMetricUuid={editingBlockTypeMetricUuid}
        excludeGlobalOperatorOptions={excludeGlobalOperatorOptions}
        excludeVariableOperatorOptions={excludeVariableOperatorOptions}
        globalOperatorOptions={globalOperatorOptions}
        GroupByInputProps={GroupByInputProps}
        listTab={listTab}
        multiple={multiple}
        output={output}
        selection={selection}
        sourceUuid={sourceUuid}
        standardBlockMetricMode={standardBlockMetricMode}
        unitPicker={unitPicker}
        unPostedBlockType={unPostedBlockType}
        variableOperatorOptions={variableOperatorOptions}
        onSelectionChange={onSelectionChange}
        {...props}
      />{' '}
    </VncStoreContext.Provider>
  );
};

export default VncDialog;
