/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { Chip } from '@mui/material';
import { DecoratorNode, LexicalNode, NodeKey } from 'lexical';
import { ReactNode } from 'react';

import { TinyUserInfo } from '@dametis/core';

import UserAvatar from 'components/UI/UserAvatar/UserAvatar';

export type SerializedMentionNode = {
  mention: string;
  user: TinyUserInfo;
  type: 'mention';
  version: 1;
};

export class MentionNode extends DecoratorNode<ReactNode> {
  __mention: string;

  __user: TinyUserInfo;

  static getType = (): string => 'mention';

  static clone = (node: MentionNode): MentionNode => new MentionNode(node.__user, node.__key);

  static importJSON = (serializedNode: SerializedMentionNode): MentionNode => $createMentionNode(serializedNode.user);

  constructor(user: TinyUserInfo, key?: NodeKey) {
    super(key);
    this.__mention = `${user?.firstName} ${user?.lastName}`;
    this.__user = user;
  }

  exportJSON(): SerializedMentionNode {
    return {
      mention: this.__mention,
      user: this.__user,
      type: 'mention',
      version: 1,
    };
  }

  createDOM = (): HTMLElement => document.createElement('span');

  updateDOM = (): false => false;

  decorate(): ReactNode {
    return (
      <Chip
        icon={
          <UserAvatar
            sx={{
              color: '#FFF !important',
              fontSize: '10px !important',
              width: '18px !important',
              height: '18px !important',
            }}
            user={this.__user}
          />
        }
        label={this.__mention}
        size="small"
        sx={{ mb: '2px' }}
      />
    );
  }
}

export const $createMentionNode = (user: TinyUserInfo): MentionNode => {
  const mentionNode = new MentionNode(user);
  return mentionNode;
};

export const $isMentionNode = (node: LexicalNode | null | undefined): node is MentionNode => node instanceof MentionNode;
