import { red } from '@mui/material/colors';

const white = '#ffffff';
const grey = {
  50: '#f8f9fa',
  100: '#edf0f2',
  200: '#e2e7ea',
  300: '#d6dde2',
  400: '#cbd4d9',
  500: '#bfcad1',
  600: '#b4c1c9',
  700: '#a9b7c1',
  800: '#9daeb8',
  900: '#92a4b0',
  1000: '#869ba8',
  1100: '#7b91a0',
  1200: '#708898',
  1300: '#667e8d',
  1400: '#5e7482',
  1500: '#566977',
  1600: '#4d5f6b',
  1700: '#455560',
  1800: '#3d4b55',
  1900: '#354149',
  2000: '#2d373e',
  2100: '#242d32',
  2200: '#1c2327',
  2300: '#14181c',
  2400: '#0c0e10',
  2500: '#030405',
};
const black = grey[2200];
const daRed = {
  ...red,
  light: '#ff8f61',
  dark: '#e58057',
  main: '#ff8f61',
  contrastText: '#e58057',
};
const greens = {
  50: '#e2f0d7',
  100: '#cde6ba',
  200: '#b8db9d',
  300: '#a3d181',
  400: '#8ec664',
  500: '#79bb47',
  600: '#70af40',
  700: '#5d9236',
  800: '#4b752b',
  900: '#395921',
  A100: '#263c16',
  A200: '#141f0c',
  A400: '#020301',
};
const daGreen = {
  ...greens,
  dark: greens[700],
  main: greens[400],
  light: '#a4d183',
  contrastText: white,
};
const blues = {
  50: '#147ce3',
  100: '#1372d1',
  200: '#1168bf',
  300: '#0f5ead',
  400: '#0e549b',
  500: '#0c4b89',
  600: '#0b4177',
  700: '#093765',
  800: '#072d53',
  900: '#062341',
  A100: '#041a2f',
  A200: '#03101d',
  A400: '#01060b',
};
const daBlue = {
  ...blues,
  dark: blues[700],
  main: blues[600],
  light: '#476598',
  contrastText: white,
};

export { black, daBlue, daGreen, daRed, grey, white };
