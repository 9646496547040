import { DateRangeOutlined, Delete } from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  DateRangePicker,
  DateRangeValidationError,
  LocalizationProvider,
  DateRange,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import clsx from 'clsx';
import { endOfDay, isValid, set, startOfDay, subDays, subHours, subMonths, subYears } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getLocale } from 'localization';
import { localizedFormat } from 'localization/localizedDateFns';

import { addTimeRange, deleteTimeRange, getTimeRanges } from '../../../functions/timeRangeHistory';
import { TimeRange } from '../../../types/timeRange';

import useDateTimePickerStyles from './DateTimeRangePanel.styles';

interface Range {
  count: number;
  unit: string;
  from: Date;
  to: Date;
}

interface Props {
  from: Date;
  to: Date;
  format: string;
  displayFormat: string;
  onChange: ([from, to]: Array<Date>) => void;
  setOpenPanel: (open: boolean) => void;
  setBatchDate: (date: DateRange<any>) => void;
}

const CalendarTab: FC<Props> = ({ from, to, format, displayFormat, onChange, setOpenPanel, setBatchDate }) => {
  const { t } = useTranslation('dateTimePicker');

  const [relative, setRelative] = useState<string>('');
  const [pickerOpen, setPickerOpen] = useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<DateRange<any>>([null, null]);
  const [timeRangeHistory, setTimeRangeHistory] = useState<TimeRange[]>([]);

  const classes = useDateTimePickerStyles();

  const isFocused = useRef(false);

  const getRanges = useCallback((): Range[] => {
    const nowWoMs = set(new Date(), { milliseconds: 0 });
    const nowWoMin = set(nowWoMs, { seconds: 0, minutes: 0 });
    const nowWoHour = set(nowWoMin, { hours: 0 });
    const nowWoDay = set(nowWoHour, { date: 0 });
    return [
      { count: 5, unit: 'hour', from: subHours(nowWoMs, 5), to: nowWoMs },
      { count: 24, unit: 'hour', from: subHours(nowWoMs, 24), to: nowWoMs },
      { count: 2, unit: 'day', from: subDays(nowWoMin, 2), to: nowWoMin },
      { count: 7, unit: 'day', from: subDays(nowWoMin, 7), to: nowWoMin },
      { count: 1, unit: 'month', from: subMonths(nowWoHour, 1), to: nowWoHour },
      { count: 3, unit: 'month', from: subMonths(nowWoHour, 3), to: nowWoHour },
      { count: 6, unit: 'month', from: subMonths(nowWoHour, 6), to: nowWoHour },
      { count: 1, unit: 'year', from: subYears(nowWoDay, 1), to: nowWoDay },
      { count: 2, unit: 'year', from: subYears(nowWoDay, 2), to: nowWoDay },
      { count: 5, unit: 'year', from: subYears(nowWoDay, 5), to: nowWoDay },
    ];
  }, []);

  const ranges = useMemo(getRanges, [getRanges]);

  const changeTimeRange = useCallback(
    (newFrom: Date, newTo: Date) => {
      setTimeRangeHistory(addTimeRange({ from: newFrom, to: newTo }));
      onChange([newFrom, newTo]);
      setOpenPanel(false);
    },
    [setOpenPanel, onChange],
  );

  const handleRelative = useCallback(
    (index: number) => () => {
      const range = getRanges()[index];
      setRelative(`${range.count}${range.unit}`);
      setTimeRange([range.from, range.to]);
      onChange([range.from, range.to]);
      setOpenPanel(false);
    },
    [getRanges, setOpenPanel, onChange],
  );

  const handleTimeRange = useCallback(
    (newDates: DateRange<any>, context: PickerChangeHandlerContext<DateRangeValidationError>) => {
      setRelative('');
      if (!context.validationError[0] && !context.validationError[1]) {
        const extremes: [Date, Date] = [startOfDay(newDates[0]), endOfDay(newDates[1])];
        setBatchDate(extremes);
        setTimeRange(isFocused.current ? newDates : extremes);
      }
    },
    [setBatchDate],
  );

  const handleSubmit = useCallback(() => {
    changeTimeRange(timeRange[0], timeRange[1]);
  }, [timeRange, changeTimeRange]);

  const handleOpenPicker = useCallback(
    (state: boolean) => () => {
      setPickerOpen(state);
    },
    [],
  );

  const handleSelectTimeRange = useCallback(
    (uuid: string) => () => {
      const newTimeRange = timeRangeHistory.find(e => e.uuid === uuid);
      if (newTimeRange) {
        onChange([newTimeRange.from, newTimeRange.to]);
        setOpenPanel(false);
      }
    },
    [timeRangeHistory, onChange, setOpenPanel],
  );

  const handleDeleteTimeRange = useCallback(
    (uuid: string) => () => {
      setTimeRangeHistory(deleteTimeRange(uuid));
    },
    [],
  );

  useEffect(() => {
    setTimeRangeHistory(getTimeRanges());
  }, []);

  useEffect(() => {
    setTimeRange([from, to]);
  }, [from, to]);

  const setIsFocused = useCallback(
    (newIsFocused: boolean) => () => {
      isFocused.current = newIsFocused;
    },
    [],
  );

  return (
    <Grid container sx={{ width: '480px' }}>
      <Grid item className={clsx(classes.popper__element, classes.popper__element__top)} xs={6}>
        <Typography className={classes.popper__element__title} variant="h6">
          {t('title.absoluteTimeRange')}
        </Typography>
        <LocalizationProvider adapterLocale={getLocale()} dateAdapter={AdapterDateFns}>
          <DateRangePicker
            disableAutoMonthSwitching
            format={format}
            localeText={{
              start: t('input.from.label'),
              end: t('input.to.label'),
            }}
            minDate={new Date(+0)}
            open={pickerOpen}
            slotProps={{
              fieldRoot: {
                sx: { display: 'flex', flexDirection: 'column' },
              },
              fieldSeparator: { sx: { display: 'none' } },
              textField: {
                sx: { mt: '10px !important', ml: '0 !important' },
                onFocus: setIsFocused(true),
                onBlur: setIsFocused(false),
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton className={classes.dateRange__iconButton} size="large" onClick={handleOpenPicker(!pickerOpen)}>
                        <DateRangeOutlined />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              },
            }}
            value={timeRange}
            onChange={handleTimeRange}
            onClose={handleOpenPicker(false)}
          />
          <Button
            color="secondary"
            disabled={!(timeRange[0] && isValid(timeRange[0]) && timeRange[1] && isValid(timeRange[1]))}
            size="small"
            sx={{
              mt: '10px',
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            {t('button.submit')}
          </Button>
        </LocalizationProvider>
      </Grid>
      <Grid item className={clsx(classes.popper__element, classes.popper__element__top)} xs={6}>
        <Typography className={classes.popper__element__title} variant="h6">
          {t('title.relativeTimeRanges')}
        </Typography>
        <List>
          {ranges.map((r, i) => (
            <ListItem key={`${r.count}${r.unit}`} disablePadding>
              <ListItemButton selected={`${r.count}${r.unit}` === relative} onClick={handleRelative(i)}>
                {t('button.lastXUnitOfTime', { value: r.count, unit: r.unit })}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Grid>
      {timeRangeHistory.length > 0 && (
        <Grid item className={clsx(classes.popper__element, classes.popper__element__bottom)} xs={12}>
          <Typography className={classes.popper__element__title} variant="h6">
            {t('title.history')}
          </Typography>
          <List component="nav">
            {timeRangeHistory.slice(0, 5).map(item => (
              <ListItem key={item.uuid} button dense disableGutters onClick={handleSelectTimeRange(item.uuid)}>
                <ListItemText primary={`${localizedFormat(item.from, displayFormat)} - ${localizedFormat(item.to, displayFormat)}`} />
                <ListItemSecondaryAction onClick={handleDeleteTimeRange(item.uuid)}>
                  <Tooltip title={t('global:tooltip.delete')}>
                    <IconButton edge="end" size="small">
                      <Delete fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default CalendarTab;
