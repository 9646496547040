import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AUTO_GROUPBY,
  GroupBy1,
  GroupByUnits,
  IsCalculationVariable,
  IsTimeGroupBy,
  POINT_TO_POINT_GROUPBY,
  timeIntervalRegex,
} from '@dametis/core';

export const shortUnitToLongUnit: Record<GroupByUnits, string> = {
  ms: 'millisecond',
  s: 'second',
  m: 'minute',
  h: 'hour',
  d: 'day',
  w: 'week',
  mo: 'month',
  y: 'year',
};

export const useLocalizedGroupBy = (groupBy: GroupBy1, short = false, displayNone = false): string => {
  const { t } = useTranslation(['groupBy', 'global']);

  const count = useMemo(() => (IsTimeGroupBy(groupBy) ? parseInt(groupBy, 10) : undefined), [groupBy]);
  const unit = useMemo<string>(
    () => (IsTimeGroupBy(groupBy) ? shortUnitToLongUnit[groupBy.match(timeIntervalRegex)?.[2]] : undefined),
    [groupBy],
  );

  if (!groupBy) {
    return displayNone ? t('none') : '';
  }
  if (groupBy === AUTO_GROUPBY) {
    return t('auto');
  }
  if (groupBy === POINT_TO_POINT_GROUPBY) {
    return t('pointToPoint', short && { context: 'short' });
  }
  if (IsCalculationVariable(groupBy)) {
    return t('batch');
  }
  return `${count} ${t('global:unit.time', {
    count,
    context: short ? `${unit}_short` : unit,
  })}`;
};
