import { FixedVariable, SourceColumn } from '@dametis/core';

import { areSameSourceColumns } from './areSameSourceColumns';

export const isSourceColumnInList = (list: SourceColumn[], source: SourceColumn): boolean =>
  list.some(listSource => areSameSourceColumns(listSource, source));

export const isSourceColumnSkipped = (skippedColumns: SourceColumn[], source: SourceColumn): boolean =>
  skippedColumns.some(skippedColumn => areSameSourceColumns(skippedColumn, source));

export const isSourceColumnFixedVariable = (fixedVariables: FixedVariable[], source: SourceColumn): boolean =>
  fixedVariables.some(fixedVariable => areSameSourceColumns(fixedVariable.source, source));
