import { FormLabel, Popover, Stack } from '@mui/material';
import { Dispatch, FC, KeyboardEventHandler, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { PhysicalQuantity } from '@dametis/core';
import { MultiplesByName, PhysicalUnitByUnitNames, UnitConverter, UnitName } from '@dametis/unit';

import { WipFeatures } from 'config/featureFlags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

import PhysicalQuantityAvatar from '../PhysicalQuantity/PhysicalQuantityAvatar';

import UnitColumns from './UnitColumns';
import UnitInput from './UnitInput';
import UnitList from './UnitList';
import { useUnitPickerContext } from './UnitPickerContext';
import { getUnitName } from './functions/getUnitName';
import { ListElement, UnitPickerMenu } from './types';

export interface UnitPopoverBaseProps {
  anchorEl: HTMLElement;
  open: boolean | undefined;
  onClose: () => void | undefined;
}

const UnitPopoverBase: FC<UnitPopoverBaseProps> = ({ anchorEl, open: userOpen, onClose: userOnClose }) => {
  const { t } = useTranslation('unit');

  const enablePhysicalParameter = useFeatureFlags(WipFeatures.ENABLE_PHYSICAL_PARAMETER);

  const {
    open,
    setOpen,
    setFocusIndex,
    setInputValue,
    baseUnit,
    selectedMenu,
    setSelectedMenu,
    defaultMenu,
    pickerSubmit,
    focusIndex,
    listElements,
    columnsPhysicalQuantities,
    columnsUnits,
    columnsMultiples,
    columnsSelectedPhysicalQuantity,
    setColumnsSelectedPhysicalQuantity,
    columnsSelectedUnit,
    setColumnsSelectedUnit,
    columnsSelectedMultiple,
    setColumnsSelectedMultiple,
    onChange,
  } = useUnitPickerContext();

  const parsedBaseUnit = useMemo(() => UnitConverter.Parse(baseUnit !== null ? baseUnit : undefined), [baseUnit]);
  const allUnits = useMemo(() => Object.values(UnitName), []);

  /**
   * Si l'unité de base n'existe pas, toutes les unités existantes sont disponibles
   * Si l'unité de base existe mais ne fait pas parti des unités existantes, seule celle-ci est disponible
   * Si l'unité de base existe et fait parti des unités existances, les unités à la même Physical Quantity sont disponibles
   */
  const availableUnits = useMemo(() => {
    if (parsedBaseUnit.physicalQuantity === PhysicalQuantity.OTHER) {
      if (baseUnit?.trim().length > 0) {
        return [parsedBaseUnit.unitName];
      }
      return allUnits;
    }
    return Object.entries(PhysicalUnitByUnitNames)
      .filter(([, physicalQuantity]) => physicalQuantity === parsedBaseUnit.physicalQuantity)
      .map(([unit]) => unit);
  }, [allUnits, parsedBaseUnit, baseUnit]);

  /**
   * On trie les unités par ordre alphabétiques en forçant la baseUnit en première position
   */
  const sortedAvailableUnits = useMemo(
    () =>
      availableUnits.sort((unitA, unitB) => {
        if (unitA === parsedBaseUnit.unitName) {
          return -1;
        }
        if (unitB === parsedBaseUnit.unitName) {
          return 1;
        }
        return getUnitName(unitA, t).localeCompare(getUnitName(unitB, t));
      }),
    [parsedBaseUnit.unitName, availableUnits, t],
  );

  const handleClose = useCallback(() => {
    setOpen(false);
    if (userOnClose !== undefined) {
      userOnClose();
    }
  }, [setOpen, userOnClose]);

  const handlePopoverKeyDownList: KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      const bindingKeys = ['ArrowDown', 'ArrowUp', 'Enter'];
      if (bindingKeys.includes(event.key)) {
        event.preventDefault();
        if (event.key === 'ArrowDown') {
          setFocusIndex(state => (state < listElements.length - 1 ? state + 1 : 0));
        }
        if (event.key === 'ArrowUp') {
          setFocusIndex(state => (state > 0 ? state - 1 : listElements.length - 1));
        }
        if (event.key === 'Enter') {
          const listElement: ListElement = listElements[focusIndex];
          if (listElement) {
            pickerSubmit(listElement);
          }
        }
      }
    },
    [listElements, focusIndex, pickerSubmit, setFocusIndex],
  );

  const getColumns = useCallback((): {
    previous: string[];
    setPrevious: Dispatch<SetStateAction<string>>;
    current: string[];
    setCurrent: Dispatch<SetStateAction<string>>;
    next: string[];
    setNext: Dispatch<SetStateAction<string>>;
  } => {
    let previous = null;
    let setPrevious;
    let current = null;
    let setCurrent;
    let next = null;
    let setNext;
    if (columnsSelectedPhysicalQuantity !== null) {
      if (columnsSelectedUnit !== null) {
        if (columnsSelectedMultiple !== null) {
          previous = columnsUnits;
          setPrevious = setColumnsSelectedUnit;
          current = columnsMultiples;
          setCurrent = setColumnsSelectedMultiple;
          next = null;
          setNext = undefined;
        } else {
          previous = columnsPhysicalQuantities;
          setPrevious = setColumnsSelectedPhysicalQuantity;
          current = columnsUnits;
          setCurrent = setColumnsSelectedUnit;
          next = columnsMultiples;
          setNext = setColumnsSelectedMultiple;
        }
      } else {
        previous = null;
        setPrevious = undefined;
        current = columnsPhysicalQuantities;
        setCurrent = setColumnsSelectedPhysicalQuantity;
        next = columnsUnits;
        setNext = setColumnsSelectedUnit;
      }
    } else {
      // moving in pq
      previous = null;
      setPrevious = undefined;
      current = columnsPhysicalQuantities;
      setCurrent = setColumnsSelectedPhysicalQuantity;
      next = null;
      setNext = undefined;
    }
    return {
      previous,
      setPrevious,
      current,
      setCurrent,
      next,
      setNext,
    };
  }, [
    columnsPhysicalQuantities,
    columnsSelectedPhysicalQuantity,
    setColumnsSelectedPhysicalQuantity,
    columnsUnits,
    columnsSelectedUnit,
    setColumnsSelectedUnit,
    columnsMultiples,
    columnsSelectedMultiple,
    setColumnsSelectedMultiple,
  ]);

  const handleColumnSubmit = useCallback(() => {
    const value = `${MultiplesByName[columnsSelectedMultiple].symbol}${columnsSelectedUnit}`;
    onChange(value);
    setOpen(false);
  }, [onChange, setOpen, columnsSelectedMultiple, columnsSelectedUnit]);

  const handlePopoverKeyDownColumns: KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      const bindingKeys = ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter'];
      if (bindingKeys.includes(event.key)) {
        event.preventDefault();
        const { previous, current, setCurrent, next, setNext } = getColumns();
        if (event.key === 'ArrowDown') {
          setCurrent(state => current[current.indexOf(state) < current.length - 1 ? current.indexOf(state) + 1 : 0]);
        }
        if (event.key === 'ArrowUp') {
          setCurrent(state => {
            if (current.indexOf(state) < 0) {
              return current[current.length - 1];
            }
            return current[current.indexOf(state) > 0 ? current.indexOf(state) - 1 : current.length - 1];
          });
        }
        if (event.key === 'ArrowLeft') {
          if (previous) {
            setCurrent(null);
          }
        }
        if (event.key === 'ArrowRight') {
          if (next) {
            setNext(next[0]);
          }
        }
        if (event.key === 'Enter') {
          if (next) {
            setNext(next[0]);
          } else if (previous && !next) {
            handleColumnSubmit();
          }
        }
      }
    },
    [getColumns, handleColumnSubmit],
  );

  const handlePopoverKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (selectedMenu === UnitPickerMenu.LIST) {
        handlePopoverKeyDownList(event);
      }
      if (selectedMenu === UnitPickerMenu.COLUMNS) {
        handlePopoverKeyDownColumns(event);
      }
    },
    [handlePopoverKeyDownList, handlePopoverKeyDownColumns, selectedMenu],
  );

  const resetDefaultColumns = useCallback(() => {
    if (baseUnit) {
      setColumnsSelectedPhysicalQuantity(parsedBaseUnit.physicalQuantity);
      setColumnsSelectedUnit(parsedBaseUnit.unitName);
      setColumnsSelectedMultiple(parsedBaseUnit.multiple.name);
    } else {
      setColumnsSelectedPhysicalQuantity(null);
      setColumnsSelectedUnit(null);
      setColumnsSelectedMultiple(null);
    }
  }, [baseUnit, parsedBaseUnit, setColumnsSelectedPhysicalQuantity, setColumnsSelectedUnit, setColumnsSelectedMultiple]);

  useEffect(() => {
    if (open) {
      setFocusIndex(-1);
      resetDefaultColumns();
      setInputValue('');
      setSelectedMenu(defaultMenu);
    }
  }, [open, setFocusIndex, setInputValue, setSelectedMenu, defaultMenu, resetDefaultColumns]);

  useEffect(() => {
    if (selectedMenu === UnitPickerMenu.LIST) {
      resetDefaultColumns();
    }
    if (selectedMenu === UnitPickerMenu.COLUMNS) {
      setFocusIndex(-1);
    }
  }, [selectedMenu, setFocusIndex, resetDefaultColumns]);

  useEffect(() => {
    if (userOpen !== undefined) {
      setOpen(userOpen);
    }
  }, [userOpen, setOpen]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={handleClose}
      onKeyDown={handlePopoverKeyDown}
    >
      {baseUnit && (
        <Stack alignItems="center" direction="row" mt={1} mx={1} spacing={1}>
          <PhysicalQuantityAvatar physicalQuantity={parsedBaseUnit.physicalQuantity} />
          <FormLabel>{t('text.baseUnit', { unit: baseUnit })}</FormLabel>
        </Stack>
      )}
      <UnitInput />
      {selectedMenu === UnitPickerMenu.LIST && <UnitList units={sortedAvailableUnits} />}
      {selectedMenu === UnitPickerMenu.COLUMNS && enablePhysicalParameter && <UnitColumns />}
    </Popover>
  );
};

export default UnitPopoverBase;
