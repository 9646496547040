import { Box, DialogContent, DialogContentProps } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

export interface AnimatedHeightDialogContentProps extends DialogContentProps {
  isAnimated?: boolean;
}

const AnimatedHeightDialogContent: FC<AnimatedHeightDialogContentProps> = ({ isAnimated = true, children = undefined, sx, ...props }) => {
  const containerRef = useRef<HTMLElement>(null);

  const [dialogContentHeight, setDialogContentHeight] = useState<number>(0);

  const resizeObserver = useRef<ResizeObserver>(null);

  const handleResize = useCallback(() => {
    if (!containerRef.current) return;
    const { clientHeight } = containerRef.current;
    setDialogContentHeight(clientHeight);
  }, []);

  useEffect(() => {
    resizeObserver.current = new ResizeObserver(handleResize);
    if (containerRef.current) resizeObserver.current.observe(containerRef.current);
    return () => {
      resizeObserver.current.disconnect();
    };
  }, [handleResize]);

  return (
    <DialogContent
      {...props}
      sx={{
        // overflow: 'hidden',
        overflow: 'auto',
        py: 0,
        height: dialogContentHeight,
        ...(isAnimated && { transition: theme => theme.transitions.create(['height']) }),
        ...sx,
      }}
    >
      <Box ref={containerRef}>{children}</Box>
    </DialogContent>
  );
};

export default AnimatedHeightDialogContent;
