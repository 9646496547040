import { ArrowRight } from '@mui/icons-material';
import { Box, Chip, Grid, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShortBlockInfo, ShortStandardBlockInfo, TagInfo, UUID } from '@dametis/core';

import { getBlockPath } from 'components/Lego/helpers/getBlockPath';
import getEntitiesById from 'functions/getEntitiesById';
import { useDispatch } from 'store';
import { useBlocks } from 'store/api/blocks';
import { useFolders } from 'store/api/folders';
import { useStandardBlocks } from 'store/api/standardBlocks';
import { useTags } from 'store/api/tags';
import { useVncStore } from 'zustand/stores/vnc';

import BlockElementsList from '../LegoList/BlockElementsList';
import { ListItemButtonWithArrow } from '../LegoList/ListItemButtonWithArrow';

export const standardBlocksEmptyArray: ShortStandardBlockInfo[] = [];
export const blocksEmptyArray: ShortBlockInfo[] = [];
export const tagEmptyArray: TagInfo[] = [];

const FiltersView: FC = () => {
  const { t } = useTranslation('vnc');
  const theme = useTheme();
  const dispatch = useDispatch();

  const { data: rootFolder = null } = useFolders();

  const { data: standardBlocks = standardBlocksEmptyArray } = useStandardBlocks();
  const { data: blocks = blocksEmptyArray } = useBlocks();
  const { data: tags = tagEmptyArray } = useTags();

  const standardBlocksFilter = useVncStore(state => state.filters.standardBlocks);
  const tagsFilter = useVncStore(state => state.filters.tags);
  const selectedBlockUuid = useVncStore(state => state.lego.selectedBlockUuid);
  const setSelectedBlockUuid = useVncStore(state => state.setSelectedBlockUuid);
  const setFilterValue = useVncStore(state => state.setFilterValue);
  const getResults = useVncStore(state => state.getResults);

  const standardBlocksById = useMemo(() => getEntitiesById<ShortStandardBlockInfo>(standardBlocks), [standardBlocks]);

  const tagsById: Record<UUID, TagInfo> = useMemo(
    () =>
      tags.reduce((acc, tag) => {
        if (!acc[tag.uuid]) {
          acc[tag.uuid] = tag;
        }
        return acc;
      }, {}),
    [tags],
  );

  const enabledStandardBlocks: ShortStandardBlockInfo[] = useMemo(
    () =>
      Object.entries(standardBlocksFilter).reduce((acc, [key, enabled]) => {
        if (enabled) {
          acc.push(standardBlocksById[key]);
        }
        return acc;
      }, []),
    [standardBlocksById, standardBlocksFilter],
  );

  const enabledTags: TagInfo[] = useMemo(
    () =>
      Object.entries(tagsFilter).reduce((acc, [key, enabled]) => {
        if (enabled) {
          acc.push(tagsById[key]);
        }
        return acc;
      }, []),
    [tagsById, tagsFilter],
  );

  const isStandardBlocksFilterEnabled = useMemo(() => enabledStandardBlocks.length > 0, [enabledStandardBlocks]);
  const isTagsFilterEnabled = useMemo(() => enabledTags.length > 0, [enabledTags]);

  const filteredBlocks = useMemo(
    () =>
      blocks.filter(
        block =>
          (!isStandardBlocksFilterEnabled || standardBlocksFilter[block.standardBlockId]) &&
          (!isTagsFilterEnabled || block.tags?.some(tag => tagsFilter[tag.uuid])),
      ),
    [blocks, isStandardBlocksFilterEnabled, standardBlocksFilter, isTagsFilterEnabled, tagsFilter],
  );

  const handleDeleteStandardBlock = useCallback(
    (standardBlockId: UUID) => () => {
      setFilterValue('standardBlocks', standardBlockId, false);
    },
    [setFilterValue],
  );

  const handleDeleteTag = useCallback(
    (tagId: UUID) => async () => {
      setFilterValue('tags', tagId, false);
      await dispatch(getResults());
    },
    [dispatch, getResults, setFilterValue],
  );

  const handleSelectBlock = useCallback(
    (blockId: UUID) => () => {
      setSelectedBlockUuid(blockId);
    },
    [setSelectedBlockUuid],
  );

  useEffect(() => {
    setSelectedBlockUuid(null);
  }, [setSelectedBlockUuid]);

  return (
    <>
      <Stack height="100%">
        <Stack alignItems="center" direction="row" flexShrink={0} px={2} py={1} spacing={1}>
          <Typography flexShrink={0} fontSize={14} variant="subtitle2">
            {t('text.filteredWith')}
          </Typography>
          {enabledStandardBlocks.map(standardBlock => (
            <Chip
              key={standardBlock.uuid}
              // SLE: here
              // avatar={
              //   <Avatar sx={{ backgroundColor: standardBlock.content.color }}>
              //     <DeployedCodeOutlined sx={{ fontSize: 18, color: filter.standardBlock.ui.color }} />
              //   </Avatar>
              // }
              label={standardBlock.name}
              size="small"
              onDelete={handleDeleteStandardBlock(standardBlock.uuid)}
            />
          ))}
          {enabledTags.map(tag => (
            <Chip key={tag.uuid} label={`#${tag.name}`} size="small" onDelete={handleDeleteTag(tag.uuid)} />
          ))}
        </Stack>
        <Box sx={{ overflowX: 'auto', flexGrow: 1, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Grid container sx={{ minWidth: '100%', width: 'fit-content', height: '100%' }} wrap="nowrap">
            <Grid item xs sx={{ overflow: 'auto', height: '100%', pr: 0.5, minWidth: 300 }}>
              <List>
                {filteredBlocks.map(block => (
                  <ListItem key={block.uuid} disablePadding>
                    <ListItemButtonWithArrow selected={block.uuid === selectedBlockUuid} onClick={handleSelectBlock(block.uuid)}>
                      <ListItemText primaryTypographyProps={{ noWrap: true }} secondary={getBlockPath(rootFolder, block.uuid, t)}>
                        {block.name}
                      </ListItemText>
                      <ArrowRight />
                    </ListItemButtonWithArrow>
                  </ListItem>
                ))}
                {filteredBlocks.length === 0 && (
                  <ListItem>
                    <Typography variant="subtitle2">{t('text.noBlock')}</Typography>
                  </ListItem>
                )}
              </List>
            </Grid>
            {selectedBlockUuid && (
              <Grid item xs sx={{ overflow: 'auto', height: '100%', pr: 0.5, minWidth: 300 }}>
                <BlockElementsList />
              </Grid>
            )}
          </Grid>
        </Box>
      </Stack>
    </>
  );
};

export default FiltersView;
