import { Shortcut, ShortcutCategory } from '@dametis/core';

export default ({ category, uuid, url }: Shortcut, searchParams?: string): string => {
  if (url) {
    return url.length > 0 ? url : null;
  }
  switch (category) {
    case ShortcutCategory.SYNOPTIC:
      return `/synoptics/${uuid}`;
    case ShortcutCategory.ALARM:
      return `/alarms/${uuid}`;
    case ShortcutCategory.REPORT:
      return searchParams ? `/reports/${uuid}?${searchParams}` : `/reports/${uuid}`;
    case ShortcutCategory.PLAYGROUND:
      return searchParams ? `/playgrounds/${uuid}?${searchParams}` : `/playgrounds/${uuid}`;
    case ShortcutCategory.PROJECT:
      return `/projects/${uuid}`;
    case ShortcutCategory.HREF:
      return `/${url}`;
    default:
      return null;
  }
};
