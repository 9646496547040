import { Button } from '@mui/material';
import axios from 'axios';
import i18n from 'i18next';
import Cookies from 'js-cookie';
import { Link, NavLink } from 'react-router-dom';

import {
  ACL,
  AclInfo,
  EntityKind,
  GroupInfo,
  NotifyType,
  PermissionKey,
  ShortGroupInfo,
  SigninBody,
  SiteInfo,
  UpdateUserBody,
  UserNotification,
  groupsToSitesDict,
} from '@dametis/core';

import { cleanOldItems } from 'functions/playgroundRedirection';
import { sdk } from 'sdk';
import { TypedThunk } from 'store';
import { api, tagTypes } from 'store/api';
import { clearAlarmsStore } from 'store/slices/alarms';
import {
  clearAuthStore,
  setAuthMethods,
  setAuthStatus,
  setCurrentUser,
  setGridView,
  setGroups,
  setSocketio,
  setUserGroupAndSite,
  setVersion,
} from 'store/slices/auth';
import { addToast } from 'store/slices/toast';
import { AuthStatus, CONFIGURE_ENROLLMENT_LATER, TwoFAError } from 'types/auth';
import { GridView } from 'types/grid';
import { ToastSeverity } from 'types/toast';

import { getUserColor } from '../../functions/color';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../functions/localStorage';
import parseErrors from '../../functions/parseErrors';
import { initSocketIo } from '../../functions/socketIo';
import { UserInfos } from '../../types/userInfos';

import { fetchBatches } from './batch';
import { fetchMacros } from './configuration';
import { fetchEquipment } from './fetchEquipment';
import { fetchVariables } from './fetchVariables';
import { fetchHealth } from './health';
import { getOperations } from './operation';
import { displaySdkErrorToast } from './toasts';

export const updateSiteAndGroup =
  ({ site, group }: { site: SiteInfo | null; group: ShortGroupInfo & Pick<GroupInfo, 'sites'> }): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { selectedGroup, selectedSite, user } = getState().auth;
    // si selectedGroup n'est pas null, ce que l'on tente de changer de groupe / site, ce n'est pas juste un F5
    if (site !== null) {
      sessionStorage.setItem(`selectedSite_${user.uuid}`, JSON.stringify(site));
      if (selectedGroup !== null) {
        setLocalStorageItem('selectedSite', site, { userId: user.uuid });
      }
    } else {
      sessionStorage.removeItem(`selectedSite_${user.uuid}`);
      if (selectedGroup !== null) {
        removeLocalStorageItem('selectedSite', { userId: user.uuid });
      }
    }
    try {
      if (group.uuid !== selectedGroup?.uuid || site?.uuid !== selectedSite?.uuid) {
        dispatch(
          setUserGroupAndSite({
            selectedSite: site,
            selectedGroup: group,
          }),
        );
        dispatch(clearAlarmsStore());
        /**
         * Here invalidate RTK Query tags to update them, maybe not the best solution but useful for now we do not have migrated everything
         */
        dispatch(api.util.invalidateTags([...tagTypes]));
        await dispatch(getEverything(site));
        dispatch(setAuthStatus(AuthStatus.LOGGED));
      }
    } catch (err) {
      console.error(err);
    }
  };

const getVersion = (): TypedThunk<Promise<void>> => async dispatch => {
  let version: number;
  try {
    const req = await axios.get('/version.txt?sorry');
    version = req.status !== 200 ? -1 : Number.parseInt(req.data, 10);
  } catch {
    version = -1;
  }
  dispatch(setVersion(version));
};

export const getEverything =
  (site?: SiteInfo): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const {
      auth: {
        selectedGroup,
        selectedSite,
        WIPeMaaS,
        user: { acl },
      },
    } = getState();
    const hasPermission = (permission: PermissionKey): boolean => acl.HasPermission(permission, selectedGroup?.uuid, selectedSite);
    const promises: (Promise<unknown> | false)[] = [];
    if (site) {
      dispatch(getOperations());
    }
    if (WIPeMaaS && selectedSite) {
      void dispatch(fetchHealth(false));
    }
    promises.push(
      dispatch(getVersion()),
      dispatch(fetchAuthenticationMethods()),
      dispatch(fetchVariables()),
      dispatch(fetchEquipment()),
      hasPermission('canAccessBatch') && dispatch(fetchBatches()),
      hasPermission('canAccessMacro') && dispatch(fetchMacros()),
    );
    await Promise.allSettled(promises.filter(Boolean));
  };

export const fetchAuthenticationMethods = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const {
    auth: {
      user: { email },
    },
  } = getState();

  try {
    const { data: authMethods } = await sdk.login.Discover(email);
    dispatch(setAuthMethods(authMethods));
  } catch (err) {
    dispatch(displaySdkErrorToast(err));
  }
};

export const login =
  (formData: SigninBody): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      const response = await sdk.login.Signin(formData);
      if (response.headers.location?.includes('/login/twofa')) {
        throw new Error('/login/twofa', {
          cause: response.headers.location,
        });
      }
      await dispatch(getCurrentUser(true));
    } catch (err) {
      if (Object.values(TwoFAError).includes(err.message) || err.message === '/login/twofa') {
        throw err;
      }

      const error: { errorName: AuthStatus; status: number } = err.response?.data;
      // dispatch(displaySdkErrorToast(err));
      dispatch(setAuthStatus(error.errorName ?? AuthStatus.ERROR));
      throw err;
    }
  };

export const patchPassword =
  (currentPassword: string, newPassword: string): TypedThunk<Promise<void>> =>
  async dispatch => {
    try {
      await sdk.login.UpdatePassword({
        currentPassword,
        newPassword,
      });
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18n.t('toast:successSaveChanges'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const patchUser =
  (user: UpdateUserBody): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const userId = getState().auth.user.uuid;

      const { data } = await sdk.user.Update(userId, {
        sitesHomeReport: user.sitesHomeReport,
        groupsHomeReport: user.groupsHomeReport,
        phone: user.phone,
        firstName: user.firstName,
        lastName: user.lastName,
        preferences: user.preferences,
      });
      dispatch(setCurrentUser(data));
      dispatch(
        addToast({
          severity: ToastSeverity.SUCCESS,
          message: i18n.t('toast:successSaveChanges'),
        }),
      );
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    }
  };

export const getUserGroups = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  const { selectedSite, selectedGroup } = getState().auth;

  try {
    const { data: groups } = await sdk.group.List();
    // le selectedGroup peut être null lors du premier chargement de la page
    if (selectedGroup) {
      // si on refresh les usersGroups, il faut aussi propager les changements dans le selectedGroup et le selectedSite
      const newSelectedGroup = groups.find(group => group.uuid === selectedGroup.uuid);
      // attention, selectedSite peut être null en mode Corporate
      const newSelectedSite = selectedSite ? newSelectedGroup.sites.find(site => site.uuid === selectedSite.uuid) : selectedSite;
      dispatch(setUserGroupAndSite({ selectedGroup: newSelectedGroup, selectedSite: newSelectedSite }));
    }
    if (!groups.length) throw new Error('No groups');
    dispatch(setGroups(groups));
  } catch (err) {
    console.error(err);
    throw err;
    // dispatch(displaySdkErrorToast(err));
  }
};

export const getCurrentUser =
  (enrollCheck?: boolean): TypedThunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const { data } = await sdk.login.ReadMe();
      if (enrollCheck && data.twoFA.enforcedAt && !data.twoFA.byEmail.state && !data.twoFA.byPhone.state) {
        // if (enrollCheck || (data.twoFA.enforcedAt && !data.twoFA.byEmail.state && !data.twoFA.byPhone.state)) {
        dispatch(setAuthStatus(AuthStatus.LOGGED));
        if (isEmptyAcl(data.acl)) {
          throw new Error(TwoFAError.REQUIRED_OUTDATED);
        } else {
          const configureEnrollmentLater = Cookies.get(CONFIGURE_ENROLLMENT_LATER);
          if (configureEnrollmentLater === 'true') {
            dispatch(
              addToast({
                message: i18n.t('toast:warningMustConfigureTwoFA'),
                severity: ToastSeverity.WARNING,
                action: (
                  <Button
                    color="inherit"
                    component={NavLink}
                    size="small"
                    sx={{ color: theme => theme.palette.white }}
                    to="/account#security"
                  >
                    {i18n.t('toast:openToast') as string}
                  </Button>
                ),
              }),
            );
          } else {
            throw new Error(TwoFAError.REQUIRED);
          }
        }
      }
      dispatch(setAuthStatus(AuthStatus.LOADING));
      await dispatch(getUserGroups());
      if (data?.preferences?.locale) {
        await i18n.changeLanguage(data.preferences.locale, err => {
          if (err) {
            console.error('Error changing language', err);
          }
        });
      }
      const { groups } = getState().auth;
      dispatch(setSocketio(initSocketIo()));

      const { socket } = getState().auth;

      socket.on(`users:${data.uuid}`, (notification: UserNotification) => {
        if (notification.type === NotifyType.SHARINGS) {
          const { entity, kind, sharer } = notification.data;
          dispatch(
            addToast({
              severity: ToastSeverity.INFO,
              message: i18n.t('toast:sharedWithYou', {
                firstName: sharer.firstName,
                lastName: sharer.lastName,
                name: entity.name,
                kind,
              }),
              action: (
                <Button color="inherit" component={Link} to={`/${kind}s/${entity.uuid}`}>
                  {i18n.t('toast:openToast') as string}
                </Button>
              ),
            }),
          );
        } else if (notification.type === NotifyType.PROJECT_TASK_ASSIGNEE) {
          const { entity, kind, sharer } = notification.data;
          dispatch(
            addToast({
              severity: ToastSeverity.INFO,
              message: i18n.t('toast:assignedYouTask', {
                firstName: sharer.firstName,
                lastName: sharer.lastName,
                name: entity.name,
                kind,
              }),
              action: (
                <Button color="inherit" component={Link} to={`/${kind}s/${entity.uuid}`}>
                  {i18n.t('toast:openToast')}
                </Button>
              ),
            }),
          );
        } else if (notification.type === NotifyType.ALARM_ACTION) {
          const { entity, kind, sharer } = notification.data;
          dispatch(
            addToast({
              severity: ToastSeverity.INFO,
              message: i18n.t('toast:assignedYouAlarm', {
                firstName: sharer.firstName,
                lastName: sharer.lastName,
                name: entity.name,
                kind,
              }),
              action: (
                <Button color="inherit" component={Link} to={`/${kind}s/${entity.uuid}`}>
                  {i18n.t('toast:openToast')}
                </Button>
              ),
            }),
          );
        } else if (notification.type === NotifyType.ALARM_ACKNOWLEDGE_MENTION) {
          const { entity, sharer } = notification.data;

          dispatch(
            addToast({
              severity: ToastSeverity.INFO,
              message: i18n.t('toast:acknowledgedAlarmAndMentionnedYou', {
                firstName: sharer.firstName,
                lastName: sharer.lastName,
                name: entity.name,
                kind: 'alarm',
              }),
              action: (
                <Button color="inherit" component={Link} to={`/alarms/${entity.uuid}`}>
                  {i18n.t('toast:openToast')}
                </Button>
              ),
            }),
          );
        } else if (notification.type === NotifyType.MENTION) {
          const { entity, kind, sharer } = notification.data;

          const to =
            kind === EntityKind.VARIABLE ? `/configuration/variables?selectedVariables=${entity.uuid}` : `/${kind}s/${entity.uuid}`;

          dispatch(
            addToast({
              severity: ToastSeverity.INFO,
              message: i18n.t('toast:mentionnedYou', {
                firstName: sharer.firstName,
                lastName: sharer.lastName,
                name: entity.name,
                kind,
              }),
              action: (
                <Button color="inherit" component={Link} to={to}>
                  {i18n.t('toast:openToast')}
                </Button>
              ),
            }),
          );
        }
      });

      const defaultGridView = getLocalStorageItem<GridView>('defaultGridView', { userId: data.uuid });
      dispatch(setGridView(Object.values(GridView).includes(defaultGridView) ? defaultGridView : GridView.TILE));

      // TODO: gérer corpo selectedGroup
      const sessionSelectedSite = JSON.parse(sessionStorage.getItem(`selectedSite_${data.uuid}`));
      const localSelectedSite = getLocalStorageItem<SiteInfo>('selectedSite', { userId: data.uuid });

      const groupSites = groupsToSitesDict(groups)[sessionSelectedSite?.uuid] ?? groupsToSitesDict(groups)[localSelectedSite?.uuid];

      const group = groupSites?.group ?? groups.at(0);
      const site = groupSites?.site ?? group?.sites.at(0);

      if (!site) {
        dispatch(setAuthStatus(AuthStatus.USER_NO_SITES));
        return;
      }
      dispatch(setCurrentUser(data));

      const acl = ACL.Build(data.acl);
      const canAccessCorporate = acl.HasPermissionOnGroup('canAccessCorporate', group.uuid);
      if (canAccessCorporate && !localSelectedSite) {
        await dispatch(updateSiteAndGroup({ site: null, group }));
      } else {
        await dispatch(updateSiteAndGroup({ site, group }));
      }

      const { user } = getState().auth;
      const userInfos: UserInfos = { email: user.email, userColor: getUserColor(user), firstName: user.firstName, lastName: user.lastName };
      setLocalStorageItem('userInfos', userInfos);

      // Clean Playground Redirection Storage
      cleanOldItems();
    } catch (err) {
      if (Object.values(TwoFAError).includes(err.message)) {
        throw err;
      }
      // dispatch(displaySdkErrorToast(err));
      const error = parseErrors(err.response);
      dispatch(setAuthStatus(error.errorName ?? AuthStatus.ERROR));
      throw err;
    }
  };

export const isEmptyAcl = (aclInfo: AclInfo) =>
  aclInfo.sites.length === 0 &&
  aclInfo.groups.length === 0 &&
  aclInfo.global.roles.length === 0 &&
  !Object.keys(aclInfo.global.permissions).some(permission => aclInfo.global.permissions[permission]);

export const clearAuth = (): TypedThunk<void> => (dispatch, getState) => {
  const { socket } = getState().auth;
  if (socket) {
    socket.disconnect();
  }
  dispatch(clearAuthStore());
};

export const logout = (): TypedThunk<Promise<void>> => async (dispatch, getState) => {
  try {
    await sdk.login.Logout();
  } catch (err) {
    const error = parseErrors(err.response);
    console.error(error);
    throw err;
  }
  const { socket } = getState().auth;
  if (socket) {
    socket.disconnect();
  }
  dispatch(clearAuthStore());
  Cookies.set(CONFIGURE_ENROLLMENT_LATER, 'false', { expires: 2 });
};
