import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
  dialogClasses,
} from '@mui/material';
import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Slate, withReact } from 'slate-react';

import { CalculationVariable, Operator } from '@dametis/core';

import { createParagraph } from 'components/VNC/slate/paragraph';
import { slateToTada, tadaToSlate } from 'components/VNC/slate/tada';
import { useDispatch, useSelector } from 'store';
import { setFilterOperator } from 'store/slices/batch';
import { UserHelperName } from 'types/userHelpers';

import Helper from '../Helper/Helper';

import BatchFilterSlate from './BatchFilterSlate';
import BatchTree from './BatchTree';
import { withCustomElements } from './slate/withCustomElements';

const initialSlate = [createParagraph()];

interface BatchFilterModalProps {
  batch: CalculationVariable;
  setBatch: Dispatch<SetStateAction<CalculationVariable>> | any;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  noOperator?: boolean;
}

const BatchFilterModal: FC<BatchFilterModalProps> = ({ batch, setBatch, open, setOpen, noOperator = false }) => {
  const { t } = useTranslation('batch');
  const dispatch = useDispatch();

  const operator = useSelector(state => state.batch.filters.operator);

  const [isCalcValid, setIsCalcValid] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const editor = useMemo(() => withCustomElements(withHistory(withReact(createEditor())), false), []);
  const slate = useMemo(() => tadaToSlate(batch) ?? initialSlate, [batch]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setLoading(false);
    dispatch(setFilterOperator(batch?.operator));
  }, [batch?.operator, dispatch, setOpen]);

  const handleSubmit = useCallback(() => {
    const { children } = editor;
    setBatch(slateToTada(children, { calcVarProps: { operator, flags: { changeOnly: true } } }));
    handleClose();
  }, [editor, setBatch, operator, handleClose]);

  const handleOperatorChange = useCallback(
    e => {
      dispatch(setFilterOperator(e.target.value));
    },
    [dispatch],
  );

  return (
    <Slate editor={editor} initialValue={slate}>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        sx={{
          [`& .${dialogClasses.paper}`]: {
            height: '90%',
          },
        }}
        onClose={handleClose}
      >
        <DialogTitle>
          <Helper name={UserHelperName.BATCH_FILTER_HELPER} sx={{ mb: 2 }}>
            {t('helper.howToUse')}
          </Helper>
          <Box>
            <BatchFilterSlate batch={batch} isCalcValid={isCalcValid} setError={setError} setIsCalcValid={setIsCalcValid} />
            {!isCalcValid && (
              <Alert severity="error" sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                <Typography color={theme => theme.palette.error.main} variant="body2">
                  {error ?? 'Invalid expression'}
                </Typography>
              </Alert>
            )}
          </Box>
        </DialogTitle>
        <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', width: '90%', height: '30rem' }}>
          <Box height="100%" overflow="auto">
            <BatchTree />
          </Box>
          {!noOperator && (
            <>
              <Divider orientation="horizontal" />
              <Stack alignItems="center" direction="row" justifyContent="center" mt={theme => theme.spacing(1)} spacing={1}>
                <FormLabel>{t('input.label.operator')}</FormLabel>
                <TextField
                  select
                  SelectProps={{
                    MenuProps: { disablePortal: true },
                  }}
                  value={operator}
                  variant="outlined"
                  onChange={handleOperatorChange}
                >
                  {[Operator.SUM, Operator.MEAN, Operator.MIN, Operator.MAX].map(op => (
                    <MenuItem key={op} value={op}>
                      {t('global:operator.op', { context: op }).toUpperCase()}
                    </MenuItem>
                  ))}
                </TextField>
              </Stack>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            {t('button.cancel')}
          </Button>
          <Button
            color="secondary"
            disabled={!isCalcValid}
            startIcon={loading ? <CircularProgress color="inherit" size={18} /> : undefined}
            variant="contained"
            onClick={handleSubmit}
          >
            {t('button.add')}
          </Button>
        </DialogActions>
      </Dialog>
    </Slate>
  );
};

export default BatchFilterModal;
