import { ArrowDropDown } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import UnitPopover from 'components/UI/UnitPicker/UnitPopover';
import { getUnitName } from 'components/UI/UnitPicker/functions/getUnitName';
import { UnitResult } from 'components/UI/UnitPicker/types';

export interface PreviewUnitButtonProps {
  unit: string;
  onChange: (unit: string) => void;
}

const PreviewUnitButton: FC<PreviewUnitButtonProps> = ({ unit, onChange }) => {
  const { t } = useTranslation('vnc');

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const displayedValue = useMemo(() => unit?.trim() ?? '', [unit]);
  const displayArrow = useMemo(() => displayedValue.length === 0, [displayedValue]);
  const unitName = useMemo(() => (displayedValue.length > 0 ? getUnitName(displayedValue, t) : t('text.noUnit')), [displayedValue, t]);

  const handleChange = useCallback(
    (newValue: UnitResult) => {
      onChange(newValue);
      setAnchorEl(null);
    },
    [onChange],
  );

  const handleOpen = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <Tooltip placement="top" title={unitName}>
        <Button
          color="inherit"
          sx={{
            flexShrink: 0,
            textTransform: 'unset',
            fontSize: 'inherit',
            lineHeight: 'inherit',
            fontWeight: 500,
            px: displayArrow ? 0 : 0.5,
            minWidth: 'unset',
          }}
          onClick={handleOpen}
        >
          {displayedValue}
          {displayArrow && <ArrowDropDown fontSize="small" />}
        </Button>
      </Tooltip>
      <UnitPopover noUnitEnabled anchorEl={anchorEl} open={open} value={unit} onChange={handleChange} onClose={handleClose} />
    </>
  );
};

export default PreviewUnitButton;
