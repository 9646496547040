import { HelpOutlineOutlined } from '@mui/icons-material';
import { AppBar as MuiAppBar, Box, Button, Toolbar, Tooltip } from '@mui/material';
import { AnimatePresence, useMotionValueEvent, useScroll } from 'framer-motion';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WipFeatures } from 'config/featureFlags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { usePermission } from 'hooks/usePermission';
import { useSelector } from 'store';

import ImpersonateBanner from '../Banner/ImpersonateBanner';
import MaintenanceBanner from '../Banner/MaintenanceBanner';
import Cart from '../Cart/Cart';
import AdminButton from '../OldHeader/Admin';
import OperationMenu from '../OldHeader/OperationMenu/OperationMenu';
import StatusMenu from '../OldHeader/StatusMenu';
import UserMenu from '../OldHeader/UserMenu';

import { MotionDiv } from './AppBar.styled';
import CartButton from './CartButton/CartButton';
import GroupSiteMenu from './GroupSiteMenu/GroupSiteMenu';
import HealthPopper from './Health/HealthPopper';

const isUnderMaintenance = process.env.MAINTENANCE === 'ON';

const AppBar: FC = () => {
  const { t } = useTranslation('header');
  const { scrollY } = useScroll();

  const canReadBoxStatus = usePermission('canReadBoxStatus');

  const enableAdmin = useFeatureFlags(WipFeatures.ENABLE_ADMIN);
  const hasOperations = useSelector(state => Boolean(state.operations.list.length));
  const user = useSelector(state => state.auth.user);
  const isCorporate = useSelector(state => !state.auth.selectedSite);

  const enableHealthCheck = useFeatureFlags(WipFeatures.ENABLE_HEALTHCHECK);

  const [isScrolled, setIsScrolled] = useState(false);

  useMotionValueEvent(scrollY, 'change', latestValue => {
    setIsScrolled(latestValue > 0);
  });

  return (
    <MuiAppBar color="transparent" elevation={0} position="sticky">
      <Toolbar sx={{ gap: 1 }}>
        <AnimatePresence>
          {!isScrolled && (
            <MotionDiv>
              <GroupSiteMenu />
            </MotionDiv>
          )}
        </AnimatePresence>
        <Box sx={{ flex: 1 }}>
          <AnimatePresence>
            {isUnderMaintenance && !isScrolled && (
              <MotionDiv>
                <MaintenanceBanner />
              </MotionDiv>
            )}
            {user.realUser !== undefined && user.realUser !== null && !isScrolled && (
              <MotionDiv>
                <ImpersonateBanner user={user} />
              </MotionDiv>
            )}
          </AnimatePresence>
        </Box>
        <AnimatePresence>
          {!isScrolled && (
            <MotionDiv>
              {/* {isAdmin && <MonitoringMenu />} */}
              <CartButton />
              {/* <FileImportMenu /> */}
              <Cart />
              {enableHealthCheck && !isCorporate && <HealthPopper />}
              {canReadBoxStatus && <StatusMenu />}
              {hasOperations && <OperationMenu />}
              <Tooltip placement="bottom" title={t('helpMenu.tooltip')}>
                <Button
                  color="icon"
                  component="a"
                  href="https://dametis.atlassian.net/servicedesk/customer/portals"
                  rel="noopener noreferrer"
                  size="small"
                  startIcon={<HelpOutlineOutlined />}
                  target="_blank"
                >
                  {t('helpMenu.button')}
                </Button>
              </Tooltip>
              {enableAdmin && <AdminButton />}
            </MotionDiv>
          )}
        </AnimatePresence>
        <UserMenu />
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
