export default {
  title: {
    debuggus: 'Debuggus',
  },
  text: {
    dependencies: 'Variables de dépendance',
    dependants: 'Variables dépendantes',
    toInspect: 'Variable à inspecter',
    general: 'Général',
    properties: 'Propriétés',
    expression: 'Expression',
    graph: 'Graphique',
    unknown: 'Inconnu',
    noVariables: "Vous n'avez pas encore sélectionné de variable.",
    name: 'Nom:',
    uuid: 'UUID:',
    owner: 'Propriétaire:',
    createdAt: 'Créé le:',
    updatedAt: 'Mis à jour le:',
    progress: 'Progrès:',
    chunks: 'Chunks:',
    lastSeen: 'Vu:',
    lastValue: 'Dernière valeur:',
    raw: 'Brut:',
    syncMad: 'Différence médiane absolue (régularité)',
    version: 'Version',
    tree: 'Arbre',
  },
  toast: {
    readError: "Erreur lors de la lecture des données depuis l'API",
    copyToClipboard: "L'UUID de la variable a été copié dans le presse-papiers",
    copyToClipboardJSON: 'JSON a été copié dans le presse-papiers',
  },
  buttons: {
    vnc: 'VNC',
    json: 'JSON',
    close: 'Fermer',
    copy: 'Copier',
  },
  tooltip: {
    disabledJSON: 'Aucune donnée à afficher',
    buttonJSON: 'Afficher les données au format JSON',
    copy: "Copier l'UUID",
  },
  input: {
    label: {
      message: 'Message',
    },
  },
};
