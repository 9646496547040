import { TagTab } from 'types/tags';

export default {
  title: {
    editTags: 'Tags',
    tags: 'Tags',
    tagsDetails: 'Détails des tags',
    column: {
      name: 'Nom',
      dependencies: 'Dépendances',
    },
    entityType: {
      blocks: 'Blocs',
      playgrounds: 'Playgrounds',
      projects: 'Projets',
      reports: 'Rapports',
      synoptics: 'Synoptiques',
      variables: 'Variables',
    },
    tab: {
      [TagTab.INFORMATIONS]: 'Informations',
      [TagTab.DEPENDENCIES]: 'Dépendances',
    },
  },
  label: {
    dependencies: 'Dépendances',
  },
  input: {
    tags: 'Tags',
    tagName: 'Nom du tag',
    targetTagName: 'Nom du tag cible',
  },
  button: {
    tag: 'Tag',
    editTags: 'Éditer les tags',
    close: 'Fermer',
    cancel: 'Annuler',
    save: 'Enregistrer',
    validate: 'Valider',
    create: 'Créer',
    merge: 'Fusionner',
    confirmDelete: 'Oui, supprimer',
    createTag: 'Créer un tag',
    editTag: 'Modifier',
    mergeTags: 'Fusionner',
    deleteTag: 'Supprimer',
    deleteTag_one: 'Supprimer le tag',
    deleteTag_other: 'Supprimer les {{count}} tags',
  },
  tagAutocomplete: {
    newTag: 'Nouveau tag',
    unknownTag: 'Tag inconnu',
    emptyTag: 'Aucun tag sélectionné',
  },
  text: {
    noDependency: 'Aucune dépendance',
    noOptions: 'Aucune option',
    deleteTagsAdvice:
      'Supprimer $t(text.thisTag, {"count": {{tagCount}} }) le supprimera de $t(text.entity, {"count": {{dependenciesCount}} }). Vérifiez les dépendances avant la suppression.',
    mergeTagsAdvice:
      'Fusionner $t(text.thisTag, {"count": {{tagCount}} }) ajoutera $t(text.entity, {"count": {{dependenciesCount}} }) dans le tag cible et supprimera les tags source. Vérifiez les dépendances avant la fusion.',
    thisTag_one: 'le tag',
    thisTag_other: 'les {{count}} tags',
    entity_one: '{{count}} entité',
    entity_other: '{{count}} entités',
    updatedAt: 'Modifié {{date}}',
  },
  error: {
    maxTagAllowed: 'Nombre maximum de tags atteint',
  },
  toast: {
    success: 'Nouveaux tags sauvegardés.',
    error: 'Une erreur est survenue pendant la sauvegarde.',
    createTagSuccess: 'Le tag a bien été créé.',
    deleteTagSuccess_one: 'Le tag a bien été supprimé.',
    deleteTagSuccess_other: 'Les tags ont bien été supprimés.',
    mergeTagsSuccess: 'Les tags ont bien été fusionnés.',
    mergeTagsItselfFailure: 'Impossible de fusionner un tag uniquement avec lui-même',
    updateTagSuccess: 'Le tag a bien été modifié.',
  },
  modal: {
    cancelEdition: 'Êtes-vous sûr ? Vous êtes en train de fermer le mode édition.',
  },
};
