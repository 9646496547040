import { Editor, Transforms } from 'slate';

import { SymbolElement, Symbols } from '@dametis/core';

import { allowedSymbolsInAlpha, symbolsRegex } from '../types';

import { createEmptyText } from './text';

export const createSymbolElement = (symbol: SymbolElement['symbol']): SymbolElement => ({
  type: 'symbol',
  symbol,
  children: [createEmptyText()],
});

export const insertSymbol = (editor: Editor) => {
  const { selection } = editor;
  if (!selection) return;
  const { anchor } = selection;
  const block = Editor.leaf(editor, anchor.path);
  const blockText = Editor.string(editor, block?.[1] ?? [])?.trim();
  if (blockText.at(0) === '"' || blockText.at(0) === "'") return;
  const found = blockText.match(symbolsRegex);
  const symbol = found?.[0] as Symbols;
  if (!symbol) return;
  const previousSymbol = Editor.previous<SymbolElement>(editor, { at: Editor.start(editor, anchor.path) });
  const multiSymbol =
    anchor.offset === 1 &&
    previousSymbol?.[0]?.type === 'symbol' &&
    Object.values(Symbols).includes(`${previousSymbol[0].symbol}${symbol}` as Symbols)
      ? (`${previousSymbol[0].symbol}${symbol}` as Symbols)
      : undefined;
  const [beforeText, afterText] = blockText.split(symbol);
  if (beforeText.length && !beforeText.match(/^((\d*\.\d+|\d+)([Ee][+-]?\d+)?|Infinity)$/) && !allowedSymbolsInAlpha.includes(symbol))
    return;
  Transforms.select(editor, anchor.path);
  Transforms.delete(editor);
  Transforms.insertText(editor, beforeText);
  const symbolElement = createSymbolElement(multiSymbol ?? symbol);
  Transforms.insertNodes(editor, symbolElement);
  Transforms.move(editor);
  Transforms.insertText(editor, afterText);
  if (multiSymbol) {
    Transforms.removeNodes(editor, { at: previousSymbol[1] });
  }
};
