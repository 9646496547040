import { Theme } from '@mui/material';
import deepmerge from 'deepmerge';
import { YAxisOptions } from 'highcharts';

export const getYAxisOptions = (options: YAxisOptions, theme: Theme): YAxisOptions =>
  deepmerge(
    {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: theme.palette.grey[1200],
          fontSize: '11px',
        },
      },
    },
    options,
  );
