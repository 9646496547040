import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3';
import { subYears } from 'date-fns';
import { ChangeEventHandler, Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateProjectBody, ProjectPriority, ProjectTemplateInfo, ProjectType, TagInfo, UUID } from '@dametis/core';

import PriorityChip from 'components/Project/UI/PriorityChip';
import ProjectTypeIcon from 'components/Project/UI/ProjectTypeIcon';
import ChipSelect from 'components/UI/ChipSelect/ChipSelect';
import ChipSelectItem from 'components/UI/ChipSelect/ChipSelectItem';
import TagAutocomplete from 'components/UI/TagAutocomplete/TagAutocomplete';
import { WipFeatures } from 'config/featureFlags';
import { ProjectTasksTemplate, projectTasksTemplates } from 'config/projectTasksTemplates';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { getLocale } from 'localization';
import { useProjectTemplates } from 'store/api/projectTemplates';

const templatesEmptyArray: ProjectTemplateInfo[] = [];

export interface NewProjectFormProps {
  body: CreateProjectBody;
  setBody: Dispatch<SetStateAction<CreateProjectBody>>;
  tasksTemplate: ProjectTasksTemplate;
  setTemplate: Dispatch<SetStateAction<ProjectTasksTemplate>>;
}

const NewProjectForm: FC<NewProjectFormProps> = ({ body, setBody, tasksTemplate, setTemplate }) => {
  const { t } = useTranslation('projects');
  const enableReferencePeriod = useFeatureFlags(WipFeatures.ENABLE_REFERENCE_PERIOD);
  const { data: availableTemplates = templatesEmptyArray } = useProjectTemplates();

  const [tags, setTags] = useState<TagInfo[]>([]);

  const availableTasksTemplates = useMemo(
    () => projectTasksTemplates.filter(projectTasksTemplate => projectTasksTemplate.types.indexOf(body.type) !== -1),
    [body.type],
  );

  const handleName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      setBody(state => ({ ...state, name: event.target.value }));
    },
    [setBody],
  );

  const handleType = useCallback(
    (type: ProjectType) => {
      setBody(state => ({ ...state, type }));
    },
    [setBody],
  );

  const handlePriority = useCallback(
    (priority: ProjectPriority) => {
      setBody(state => ({ ...state, priority }));
    },
    [setBody],
  );

  const handleDeadline = useCallback(
    (newDeadline: Date) => {
      setBody(state => ({ ...state, deadline: newDeadline }));
    },
    [setBody],
  );

  const handleTemplate = useCallback(
    (event: SelectChangeEvent<UUID>) => {
      setBody(state => ({ ...state, projectTemplateId: event.target.value }));
    },
    [setBody],
  );

  const handleTaskTemplate = useCallback(
    (event: SelectChangeEvent<UUID>) => {
      setTemplate(projectTasksTemplates.find(projectTasksTemplate => projectTasksTemplate.uuid === event.target.value));
    },
    [setTemplate],
  );

  const handleTags = useCallback(
    (newTags: TagInfo[]) => {
      setTags(newTags);
      setBody(state => ({ ...state, tagIds: newTags.map(tag => tag.uuid) }));
    },
    [setBody],
  );

  const handleReferencePeriod = useCallback(
    (referencePeriodEnabled: boolean) => {
      setBody(state => ({
        ...state,
        referencePeriod: { from: subYears(new Date(), 1), to: new Date(), consumptions: [], enabled: referencePeriodEnabled },
      }));
    },
    [setBody],
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          fullWidth
          label={t('input.label.name')}
          margin="dense"
          name="name"
          value={body.name}
          variant="outlined"
          onChange={handleName}
        />
      </Grid>
      <Grid item xs={12}>
        <ChipSelect title={t('input.label.type')}>
          {Object.values(ProjectType).map(value => (
            <ChipSelectItem
              key={value}
              icon={<ProjectTypeIcon fontSize="small" type={value} />}
              label={t('type.variant', { context: value })}
              selected={value === body.type}
              onClick={() => handleType(value)}
            />
          ))}
        </ChipSelect>
      </Grid>
      {availableTasksTemplates.length > 0 && (
        <Grid item xs={6}>
          <FormControl fullWidth margin="dense">
            <InputLabel>{t('input.label.tasksTemplate')}</InputLabel>
            <Select fullWidth value={tasksTemplate?.uuid ?? ''} onChange={handleTaskTemplate}>
              <MenuItem value="">{t('text.noTemplate')}</MenuItem>
              {availableTasksTemplates.map(availableTaskTemplate => (
                <MenuItem key={availableTaskTemplate.uuid} value={availableTaskTemplate.uuid}>
                  {availableTaskTemplate.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <ChipSelect title={t('input.label.priority')}>
          {Object.values(ProjectPriority).map(value => (
            <PriorityChip
              key={value}
              selected={value === body.priority}
              size="medium"
              variant={value}
              onClick={() => handlePriority(value)}
            />
          ))}
        </ChipSelect>
      </Grid>
      {enableReferencePeriod && !body.projectTemplateId && (
        <Grid item xs={6}>
          <InputLabel sx={{ mt: 1 }}>{t('text.referencePeriod')}</InputLabel>
          <FormControlLabel
            control={<Checkbox checked={body?.referencePeriod?.enabled} onChange={e => handleReferencePeriod(e.target.checked)} />}
            label={t('input.label.enableReferencePeriod')}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <LocalizationProvider adapterLocale={getLocale()} dateAdapter={AdapterDateFns}>
          <DatePicker
            label={t('input.label.deadline')}
            minDate={new Date(+0)}
            slotProps={{ textField: { variant: 'outlined', fullWidth: true, margin: 'dense' } }}
            value={body.deadline}
            onChange={handleDeadline}
          />
        </LocalizationProvider>
      </Grid>
      {availableTemplates.length > 0 && (
        <Grid item xs={12}>
          <FormControl fullWidth margin="dense">
            <InputLabel>{t('input.label.template')}</InputLabel>
            <Select fullWidth value={body.projectTemplateId} onChange={handleTemplate}>
              <MenuItem value={undefined}>{t('text.noTemplate')}</MenuItem>
              {availableTemplates.map(availableTemplate => (
                <MenuItem key={availableTemplate.uuid} value={availableTemplate.uuid}>
                  {availableTemplate.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12}>
        <TagAutocomplete label={t('input.label.tags')} value={tags} onChange={handleTags} />
      </Grid>
    </Grid>
  );
};

export default NewProjectForm;
