import { CachedOutlined } from '@mui/icons-material';
import { IconButton, Menu, MenuItem, MenuProps, Skeleton, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { localizedFormat } from 'localization/localizedDateFns';
import { useDispatch, useSelector } from 'store';
import { ToPeriod, setLastPointDate } from 'store/slices/calculationMenu';

import { fetchCalculationLastPointDate } from '../getPeriod';

export interface ToMenuProps extends Omit<MenuProps, 'value' | 'onChange'> {
  value: ToPeriod;
  onChange: (newTo: ToPeriod) => void;
}

const ToMenu: FC<ToMenuProps> = ({ value, onChange, ...props }) => {
  const { t } = useTranslation('calculationMenu');
  const dispatch = useDispatch();

  const apiCalculation = useSelector(state => state.calculationMenu.apiCalculation);
  const selectedGroup = useSelector(state => state.auth.selectedGroup);
  const lastPointDate = useSelector(state => state.calculationMenu.lastPoint.date);
  const isFetchingLastPoint = useSelector(state => state.calculationMenu.lastPoint.isFetching);

  // const [lastPointDate, setLastPointDate] = useState<Date>(null);

  // const getPoint = useCallback(async () => {
  //   if (apiCalculation === null) return;
  //   const newLastPoint = await getLastPointDate(apiCalculation, group);
  //   setLastPointDate(newLastPoint);
  // }, [apiCalculation, group]);

  const handleSelectValue = useCallback(
    (to: ToPeriod) => () => {
      onChange(to);
    },
    [onChange],
  );

  const handleRefreshLastPoint: MouseEventHandler<HTMLButtonElement> = useCallback(
    async event => {
      event.stopPropagation();
      const newLastPointDate = await dispatch(fetchCalculationLastPointDate(selectedGroup?.uuid, apiCalculation));
      dispatch(setLastPointDate(newLastPointDate));
    },
    [selectedGroup?.uuid, apiCalculation, dispatch],
  );

  // useEffect(() => {
  //   void getPoint();
  // }, [getPoint]);

  return (
    <Menu {...props}>
      <MenuItem selected={ToPeriod.NOW === value} onClick={handleSelectValue(ToPeriod.NOW)}>
        {t(`toPeriod.${ToPeriod.NOW}`)}
      </MenuItem>
      <MenuItem
        disabled={isFetchingLastPoint || !lastPointDate}
        selected={ToPeriod.LAST_POINT === value}
        sx={{ pr: 1 }}
        onClick={handleSelectValue(ToPeriod.LAST_POINT)}
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Stack>
            {t(`toPeriod.${ToPeriod.LAST_POINT}`)}
            <Typography color="text.secondary" variant="body2">
              {isFetchingLastPoint ? (
                <Skeleton />
              ) : (
                <>{lastPointDate ? <>{localizedFormat(new Date(lastPointDate), 'Ppp')}</> : <>{t('text.noLastPointDate')}</>}</>
              )}
            </Typography>
          </Stack>
          <IconButton disabled={isFetchingLastPoint} size="small" onClick={handleRefreshLastPoint}>
            <CachedOutlined fontSize="small" />
          </IconButton>
        </Stack>
      </MenuItem>
    </Menu>
  );
};

export default ToMenu;
