import { ClickAwayListener, Divider, List, Popover, Stack, popoverClasses } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'store';
import { closeCalculationMenu } from 'store/actions/calculationMenu';
import { setLastPointDate, setMenuPosition, setPeriod } from 'store/slices/calculationMenu';

import CalculationChart, { CalculationChartMenu } from './CalculationChart/CalculationChart';
import CalculationNavBar from './CalculationChart/CalculationNavBar';
import AddCommentOption from './CalculationOptions/AddCommentOption';
import AddToCartOption from './CalculationOptions/AddToCartOption/AddToCartOption';
import ConfigurationOption from './CalculationOptions/ConfigurationOption';
import CopyVariableNameOption from './CalculationOptions/CopyVariableNameOption';
import PlaygroundOption from './CalculationOptions/PlaygroundOption/PlaygroundOption';
import { fetchCalculationLastPointDate, getPeriod } from './getPeriod';

const CalculationMenu: FC = () => {
  const dispatch = useDispatch();

  const menuPosition = useSelector(state => state.calculationMenu.menuPosition);
  const items = useSelector(state => state.calculationMenu.items);
  const apiCalculation = useSelector(state => state.calculationMenu.apiCalculation);
  const selectedGroup = useSelector(state => state.auth.selectedGroup);
  const selectedFrom = useSelector(state => state.calculationMenu.selectedFrom);
  const selectedTo = useSelector(state => state.calculationMenu.selectedTo);
  const lastPointDate = useSelector(state => state.calculationMenu.lastPoint.date);
  const corporate = useSelector(state => !state.auth.selectedSite);
  const chartDisabled = useSelector(state => state.calculationMenu.chartDisabled);

  const [selectedMenu, setSelectedMenu] = useState<CalculationChartMenu>(CalculationChartMenu.GRAPH);

  const isMenuOpen = useMemo(() => Boolean(menuPosition !== null), [menuPosition]);

  const handleCloseMenu = useCallback(() => {
    dispatch(setMenuPosition(null));
  }, [dispatch]);

  const handleClickAway = useCallback(() => {
    dispatch(closeCalculationMenu());
  }, [dispatch]);

  const refreshPeriod = useCallback(() => {
    const newPeriod = getPeriod(selectedFrom, selectedTo, lastPointDate);
    dispatch(setPeriod(newPeriod));
  }, [selectedFrom, selectedTo, dispatch, lastPointDate]);

  const getCalculationLastPointDate = useCallback(async () => {
    const newLastPointDate = await dispatch(fetchCalculationLastPointDate(selectedGroup?.uuid, apiCalculation));
    dispatch(setLastPointDate(newLastPointDate));
  }, [dispatch, selectedGroup?.uuid, apiCalculation]);

  useEffect(() => {
    if (isMenuOpen) {
      refreshPeriod();
    }
  }, [refreshPeriod, isMenuOpen]);

  useEffect(() => {
    void getCalculationLastPointDate();
  }, [getCalculationLastPointDate]);

  return (
    <Popover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorPosition={menuPosition !== null ? { top: menuPosition.mouseY, left: menuPosition.mouseX } : undefined}
      anchorReference="anchorPosition"
      open={isMenuOpen}
      sx={{
        pointerEvents: 'none',
        [`& .${popoverClasses.paper}`]: {
          pointerEvents: 'all',
        },
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handleCloseMenu}
    >
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleClickAway}>
        <Stack>
          <CalculationChart selectedMenu={selectedMenu} />
          {!chartDisabled && <CalculationNavBar selectedMenu={selectedMenu} setSelectedMenu={setSelectedMenu} />}
          <Divider sx={{ my: 1 }} />
          <List sx={{ mb: 1 }}>
            <PlaygroundOption />
            <AddToCartOption />
            <ConfigurationOption />
            {!corporate && <AddCommentOption />}
            <CopyVariableNameOption />
          </List>
          {items?.length > 0 && (
            <>
              <Divider sx={{ my: 1 }} />
              <List sx={{ mb: 1 }}>{items}</List>
            </>
          )}
        </Stack>
      </ClickAwayListener>
    </Popover>
  );
};

export default CalculationMenu;
