import { FormControlLabel, List, ListItem, ListItemButton, ListItemText, Switch, Typography } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useMemo, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { DateSystem } from '@dametis/core';

import DateSystemMenu from './DateSystemMenu';
import { useFormatPickerContext } from './FormatPickerContext';

export interface FormatSettingsProps {
  isEmptyValue: boolean;
}

const FormatSettings: FC<FormatSettingsProps> = ({ isEmptyValue }) => {
  const { t } = useTranslation('format');

  const { pickerValue, setPickerValue, onChange } = useFormatPickerContext();

  const [dateSystemMenuAnchorEl, setDateSystemMenuAnchorEl] = useState<HTMLElement | null>(null);

  const hideUnit = useMemo(() => pickerValue.hideUnit, [pickerValue]);
  const withTimezoneOffset = useMemo(() => pickerValue.withTimezoneOffset, [pickerValue]);
  const dateSystem = useMemo(() => pickerValue.dateSystem, [pickerValue]);
  const dateSystemMenuOpen = useMemo(() => Boolean(dateSystemMenuAnchorEl), [dateSystemMenuAnchorEl]);

  const handleHideUnit: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newValue = { ...pickerValue, hideUnit: event.target.checked };
      setPickerValue(newValue);
      onChange(newValue);
    },
    [setPickerValue, pickerValue, onChange],
  );

  const handleWithTimezoneOffset: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newValue = { ...pickerValue, withTimezoneOffset: event.target.checked };
      setPickerValue(newValue);
      onChange(newValue);
    },
    [setPickerValue, pickerValue, onChange],
  );

  const handleOpenDateSystemMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setDateSystemMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseDateSystemMenu = useCallback(() => {
    setDateSystemMenuAnchorEl(null);
  }, []);

  const handleChangeDateSystem = useCallback(
    (newDateSystem: DateSystem) => {
      const newValue = { ...pickerValue, dateSystem: newDateSystem };
      setPickerValue(newValue);
      onChange(newValue);
      setDateSystemMenuAnchorEl(null);
    },
    [setPickerValue, pickerValue, onChange],
  );

  return (
    <>
      <List>
        <ListItem>
          <FormControlLabel
            control={<Switch checked={hideUnit} color="secondary" sx={{ mr: -1.5 }} onChange={handleHideUnit} />}
            disabled={isEmptyValue}
            label={t('text.hideUnit')}
            labelPlacement="start"
            sx={{ display: 'flex', justifyContent: 'space-between', width: 1, ml: 0 }}
          />
        </ListItem>
        <ListItem>
          <FormControlLabel
            control={<Switch checked={withTimezoneOffset} color="secondary" sx={{ mr: -1.5 }} onChange={handleWithTimezoneOffset} />}
            disabled={isEmptyValue}
            label={t('text.timezoneOffset')}
            labelPlacement="start"
            sx={{ display: 'flex', justifyContent: 'space-between', width: 1, ml: 0 }}
          />
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton disabled={isEmptyValue} onClick={handleOpenDateSystemMenu}>
            <ListItemText primary={t('text.dateSystem')} sx={{ '&:first-letter': { textTransform: 'uppercase' } }} />
            <Typography color="text.secondary" variant="body2">
              {t(`dateSystem.${dateSystem}`)}
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
      <DateSystemMenu
        anchorEl={dateSystemMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={dateSystemMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        value={dateSystem}
        onChange={handleChangeDateSystem}
        onClose={handleCloseDateSystemMenu}
      />
    </>
  );
};

export default FormatSettings;
