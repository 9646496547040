import i18next from 'i18next';

const localizedNumber = (number, [minimumFractionDigits, maximumFractionDigits] = [], lng = i18next.language) => {
  if (!Number.isFinite(number)) {
    return number;
  }
  return number.toLocaleString(lng, { minimumFractionDigits, maximumFractionDigits });
};

export default localizedNumber;
