import { SearchOffOutlined } from '@mui/icons-material';
import { Grid, Stack, Typography } from '@mui/material';
import Fuse from 'fuse.js';
import { cloneDeep } from 'lodash';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo, OrderBy, ShortcutCategory } from '@dametis/core';

import EntityAccordion from 'components/UI/EntityAccordion/EntityAccordion';
import EntityAccordionDetails from 'components/UI/EntityAccordion/EntityAccordionDetails';
import { isBefore } from 'localization/localizedDateFns';

import FolderListToolbar from './FolderListToolbar/FolderListToolbar';
import FolderTile from './FolderTile';

// const CREATE_FOLDER_BUTTON_CLASS = 'createFolderButton';

export enum SortedBy {
  FOLDER_NAME = 'folderName',
  // OWNER = 'owner',
  NB_FOLDERS = 'nbFolders',
  NB_BLOCKS = 'nbBlocks',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
}

export interface FolderListProps {
  folders: FolderInfo[];
  search: string;
}

const FolderList: FC<FolderListProps> = ({ folders, search }) => {
  const { t } = useTranslation('lego');

  const [sortedBy, setSortedBy] = useState<SortedBy>(SortedBy.FOLDER_NAME);
  const [orderBy, setOrderBy] = useState<OrderBy>(OrderBy.ASC);
  // const [selectedUsersUuid, setSelectedUsersUuid] = useState<UUID[]>([]);
  const [isFolderListOpen, setIsFolderListOpen] = useState<boolean>(true);

  // const users = useMemo(
  //   () =>
  //     Object.values(
  //       folders.reduce<Record<UUID, ShortUserInfo>>((acc, folder) => {
  //         if (!acc[folder.owner.uuid]) {
  //           acc[folder.owner.uuid] = folder.owner;
  //         }
  //         return acc;
  //       }, {}),
  //     ),
  //   [folders],
  // );

  const displayedFolders = useMemo(() => {
    // const filteredBlocks = blocks.filter(
    //   block => selectedBlockTypesUuid.includes(block.blockTypeId) && selectedUsersUuid.includes(block.owner.uuid),
    // );
    const filteredFolders = cloneDeep(folders);
    if (search?.length > 0) {
      const fuse = new Fuse(filteredFolders, {
        keys: ['name'],
      });
      return fuse.search(search).map(fR => fR.item);
    }
    return filteredFolders.sort((folderA, folderB) => {
      let result = 0;
      if (sortedBy === SortedBy.FOLDER_NAME) {
        result = folderA.name.localeCompare(folderB.name);
      }
      // if (sortedBy === SortedBy.OWNER) {
      //   const nameA = `${folderA.owner.firstName ?? ''} ${folderA.owner.lastName ?? ''}`;
      //   const nameB = `${folderB.owner.firstName ?? ''} ${folderB.owner.lastName ?? ''}`;
      //   result = nameA.localeCompare(nameB);
      // }
      if (sortedBy === SortedBy.NB_BLOCKS) {
        result =
          folderB.shortcuts.filter(shortcut => shortcut.category === ShortcutCategory.BLOCK).length -
          folderA.shortcuts.filter(shortcut => shortcut.category === ShortcutCategory.BLOCK).length;
      }
      if (sortedBy === SortedBy.NB_FOLDERS) {
        result = folderB.folders.length - folderA.folders.length;
      }
      if (sortedBy === SortedBy.CREATED_AT) {
        result = isBefore(new Date(folderB.createdAt ?? 0), new Date(folderA.createdAt ?? 0)) ? 1 : -1;
      }
      if (sortedBy === SortedBy.UPDATED_AT) {
        result = isBefore(new Date(folderB.updatedAt ?? 0), new Date(folderA.updatedAt ?? 0)) ? 1 : -1;
      }
      return result * (orderBy === OrderBy.DESC ? -1 : 1);
    });
  }, [folders, search, sortedBy, orderBy]);

  const handleChangeFolderListOpen = useCallback(() => {
    setIsFolderListOpen(state => !state);
  }, []);

  // useEffect(() => {
  //   setSelectedUsersUuid(users.map(user => user.uuid));
  // }, [users]);

  return (
    <EntityAccordion expanded={isFolderListOpen} onChange={handleChangeFolderListOpen}>
      <FolderListToolbar
        isOpen={isFolderListOpen}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        // selectedUsersUuid={selectedUsersUuid}
        // setSelectedUsersUuid={setSelectedUsersUuid}
        setSortedBy={setSortedBy}
        sortedBy={sortedBy}
        // users={users}
      />
      <EntityAccordionDetails>
        {displayedFolders.length > 0 && (
          <Grid container spacing={2}>
            {displayedFolders.map(folder => (
              <Grid key={folder.uuid} item lg={3} md={4} sm={6} xs={12}>
                <FolderTile folder={folder} search={search} />
              </Grid>
            ))}
          </Grid>
        )}
        {folders.length === 0 && (
          <Stack alignItems="center" direction="row" justifyContent="center" p={4} spacing={3}>
            <Typography fontSize={16} variant="subtitle2">
              {t('text.noFolder')}
            </Typography>
          </Stack>
        )}
        {displayedFolders.length === 0 && folders.length !== 0 && (
          <Stack alignItems="center" direction="row" justifyContent="center" p={4} spacing={3}>
            <SearchOffOutlined sx={{ fontSize: 50, color: theme => theme.palette.grey[600] }} />
            <Typography fontSize={16} variant="subtitle2">
              {t('text.noCorrespondingFolder')}
            </Typography>
          </Stack>
        )}
      </EntityAccordionDetails>
    </EntityAccordion>
  );
};

export default FolderList;
