import { Editor, Transforms, Element } from 'slate';

import { insertSymbol } from './symbol';

export const withCustomElements = (editor: Editor, disableMaths: boolean): Editor => {
  const { isVoid, isInline, insertText, insertBreak, insertSoftBreak, deleteBackward } = editor;
  editor.isVoid = element => ['variable', 'symbol', 'function', 'batch', 'operator'].includes(element.type) || isVoid(element);
  editor.isInline = element => ['variable', 'symbol', 'block', 'function', 'batch', 'operator'].includes(element.type) || isInline(element);
  editor.insertText = (...args) => {
    insertText(...args);
    if (disableMaths) return;
    insertSymbol(editor);
  };
  editor.insertBreak = (...args) => {
    if (disableMaths) return;
    insertBreak(...args);
  };
  editor.insertSoftBreak = (...args) => {
    if (disableMaths) return;
    insertSoftBreak(...args);
  };
  editor.deleteBackward = (...args) => {
    deleteBackward(...args);
    const { selection } = editor;
    if (!selection) return;
    const [, afterPath] = Editor.node(editor, selection.anchor);
    const [above] = Editor.above(editor, { at: afterPath });
    if (Element.isElement(above) && above.type !== 'paragraph') {
      Transforms.move(editor, { unit: 'character' });
    }
  };
  return editor;
};

export const resetNodes = (editor: Editor) => {
  const children = [...editor.children];
  children.forEach(node => editor.apply({ type: 'remove_node', path: [0], node }));
};
