import { ListSubheader, MenuItem, TextField } from '@mui/material';
import { ChangeEventHandler, FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColumnType,
  IsSourceColumnRealByIndex,
  IsSourceColumnRealByHeader,
  IsSourceColumnVirtual,
  SourceColumn,
  SourceColumnRealByIndex,
  SourceColumnRealByHeader,
} from '@dametis/core';

import {
  createSourceColumnRealByIndex,
  createSourceColumnRealByHeader,
  createSourceColumnVirtual,
} from 'components/Configuration/FileImport/helpers/createSourceColumn';
import { AdvancedTextFieldProps } from 'components/UI/Inputs/AdvancedTextField/AdvancedTextField';

export const REAL_BY_INDEX_VALUE_PREFIX = 'real_by_index';
export const REAL_BY_HEADER_VALUE_PREFIX = 'real_by_header';
export const VIRTUAL_VALUE_PREFIX = 'virtual_';

const usedSourcesDefaultProp: SourceColumn[] = [];

export interface SourceColumnPickerProps extends Omit<AdvancedTextFieldProps, 'select' | 'value' | 'onChange' | 'children'> {
  value: SourceColumn | null;
  onChange: (newSourceColumn: SourceColumn) => void;
  availableRealByIndexColumns: SourceColumnRealByIndex[];
  availableRealByHeaderColumns: SourceColumnRealByHeader[];
  availableVirtualColumnNames: string[];
  usedSources?: SourceColumn[];
}

const SourceColumnPicker: FC<SourceColumnPickerProps> = ({
  value,
  onChange,
  availableRealByIndexColumns,
  availableRealByHeaderColumns,
  availableVirtualColumnNames,
  usedSources = usedSourcesDefaultProp,
  ...props
}) => {
  const { t } = useTranslation('fileImport');

  const textFieldValue = useMemo(() => {
    if (IsSourceColumnRealByIndex(value)) {
      return `${REAL_BY_INDEX_VALUE_PREFIX}${value.value}`;
    }
    if (IsSourceColumnRealByHeader(value)) {
      return `${REAL_BY_HEADER_VALUE_PREFIX}${value.value}`;
    }
    if (IsSourceColumnVirtual(value)) {
      return `${VIRTUAL_VALUE_PREFIX}${value.value}`;
    }
    return '';
  }, [value]);

  const parsedUsedSources = useMemo(
    () =>
      usedSources.reduce<string[]>((result, usedSource) => {
        if (IsSourceColumnRealByIndex(usedSource)) {
          const parsedValue = `${REAL_BY_INDEX_VALUE_PREFIX}${usedSource.value}`;
          if (parsedValue !== textFieldValue) {
            result.push(parsedValue);
          }
        }
        if (IsSourceColumnRealByHeader(usedSource)) {
          const parsedValue = `${REAL_BY_HEADER_VALUE_PREFIX}${usedSource.value}`;
          if (parsedValue !== textFieldValue) {
            result.push(parsedValue);
          }
        }
        if (IsSourceColumnVirtual(usedSource)) {
          const parsedValue = `${VIRTUAL_VALUE_PREFIX}${usedSource.value}`;
          if (parsedValue !== textFieldValue) {
            result.push(parsedValue);
          }
        }
        return result;
      }, []),
    [usedSources, textFieldValue],
  );

  const handleChangeContentValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.target.value.startsWith(REAL_BY_INDEX_VALUE_PREFIX)) {
        const parsedTextFieldValue = Number.parseFloat(event.target.value.slice(REAL_BY_INDEX_VALUE_PREFIX.length));
        onChange(createSourceColumnRealByIndex({ value: parsedTextFieldValue }));
      }
      if (event.target.value.startsWith(REAL_BY_HEADER_VALUE_PREFIX)) {
        const parsedTextFieldValue = event.target.value.slice(REAL_BY_HEADER_VALUE_PREFIX.length);
        onChange(createSourceColumnRealByHeader({ value: parsedTextFieldValue }));
      }
      if (event.target.value.startsWith(VIRTUAL_VALUE_PREFIX)) {
        const parsedTextFieldValue = event.target.value.slice(VIRTUAL_VALUE_PREFIX.length);
        onChange(createSourceColumnVirtual({ value: parsedTextFieldValue }));
      }
    },
    [onChange],
  );

  return (
    <TextField select value={textFieldValue} onChange={handleChangeContentValue} {...props}>
      {availableRealByIndexColumns.length > 0 && <ListSubheader>{t(`columnType.${ColumnType.REAL_BY_INDEX}`)}</ListSubheader>}
      {availableRealByIndexColumns.map(availableRealByIndexColumn => (
        <MenuItem
          key={availableRealByIndexColumn.value}
          disabled={parsedUsedSources.includes(`${REAL_BY_INDEX_VALUE_PREFIX}${availableRealByIndexColumn.value}`)}
          value={`${REAL_BY_INDEX_VALUE_PREFIX}${availableRealByIndexColumn.value}`}
        >
          {t('text.realByIndexColumn', { index: availableRealByIndexColumn.value + 1 })}
        </MenuItem>
      ))}
      {availableRealByHeaderColumns.length > 0 && <ListSubheader>{t(`columnType.${ColumnType.REAL_BY_HEADER}`)}</ListSubheader>}
      {availableRealByHeaderColumns.length > 0 &&
        availableRealByHeaderColumns.map(availableRealByHeaderColumn => (
          <MenuItem
            key={availableRealByHeaderColumn.value}
            disabled={parsedUsedSources.includes(`${REAL_BY_HEADER_VALUE_PREFIX}${availableRealByHeaderColumn.value}`)}
            value={`${REAL_BY_HEADER_VALUE_PREFIX}${availableRealByHeaderColumn.value}`}
          >
            {availableRealByHeaderColumn.value}
          </MenuItem>
        ))}
      {availableVirtualColumnNames.length > 0 && <ListSubheader>{t(`columnType.${ColumnType.VIRTUAL}`)}</ListSubheader>}
      {availableVirtualColumnNames.length > 0 &&
        availableVirtualColumnNames.map(availableVirtualColumn => (
          <MenuItem
            key={availableVirtualColumn}
            disabled={parsedUsedSources.includes(`${VIRTUAL_VALUE_PREFIX}${availableVirtualColumn}`)}
            value={`${VIRTUAL_VALUE_PREFIX}${availableVirtualColumn}`}
          >
            {availableVirtualColumn}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default SourceColumnPicker;
