import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { ChangeEventHandler, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateCommentBody, CreateProjectBody, ProjectPriority, ProjectType, Shortcut, ShortcutCategory, UUID } from '@dametis/core';

import NewProjectForm from 'components/Projects/NewProjectForm';
import ProjectList from 'components/UI/Comments/ProjectList';
import { ProjectTasksTemplate } from 'config/projectTasksTemplates';
import { isValid } from 'localization/localizedDateFns';
import { useDispatch } from 'store';
import { createProject } from 'store/actions/project';
import { displaySdkErrorToast } from 'store/actions/toasts';
import { LinkedProjectType, ProjectModalMode } from 'types/comment';

const dialogPropsDefaultProp: Omit<DialogProps, 'open' | 'onClose' | 'fullWidth' | 'maxWidth'> = {};

export interface ProjectPluginModalProps {
  open: boolean;
  defaultValue?: CreateCommentBody;
  mode?: `${ProjectModalMode}`;
  title?: ReactNode;
  subtitle?: ReactNode;
  onCreate?: (shortcut: Shortcut) => void;
  onUpdate?: (shortcut: Shortcut) => void;
  onClose?: () => void;
  dialogProps?: Omit<DialogProps, 'open' | 'onClose' | 'fullWidth' | 'maxWidth'>;
  footerText?: string;
  datePicker?: boolean;
}

const ProjectPluginModal: FC<ProjectPluginModalProps> = ({
  open,
  defaultValue = undefined,
  mode = ProjectModalMode.CREATE,
  title = undefined,
  subtitle = undefined,
  onCreate = undefined,
  onUpdate = undefined,
  onClose = undefined,
  dialogProps = dialogPropsDefaultProp,
  footerText = undefined,
}) => {
  const { t } = useTranslation('comment');
  const dispatch = useDispatch();

  const emptyProject: CreateProjectBody = useMemo(
    () => ({
      name: '',
      type: ProjectType.GLOBALPROJECT,
      priority: ProjectPriority.IMPROVEMENT,
      deadline: null,
      currency: '€',
      tags: [],
    }),
    [],
  );

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [linkedProjectType, setLinkedProjectType] = useState<LinkedProjectType>(LinkedProjectType.NEW);
  const [createProjectBody, setCreateProjectBody] = useState<CreateProjectBody>(emptyProject);
  const [selectedTemplate, setSelectedTemplate] = useState<ProjectTasksTemplate | undefined>(undefined);
  const [selectedProjectUuid, setSelectedProjectUuid] = useState<UUID | null>(null);

  const isNewProjectValid = useMemo(
    () => isValid(createProjectBody.deadline) && createProjectBody.name.trim().length > 0,
    [createProjectBody.name, createProjectBody.deadline],
  );
  const isExistingProjectValid = useMemo(() => !!selectedProjectUuid, [selectedProjectUuid]);

  const isProjectValid = useMemo(
    () =>
      (linkedProjectType === LinkedProjectType.NEW && isNewProjectValid) ||
      (linkedProjectType === LinkedProjectType.EXISTING && isExistingProjectValid),
    [linkedProjectType, isNewProjectValid, isExistingProjectValid],
  );

  const handleClose = useCallback(() => {
    if (!submitting) {
      setOpenModal(false);
      if (onClose) {
        onClose();
      }
    }
  }, [onClose, submitting]);

  const getProjectUuid = useCallback(async (): Promise<UUID | null> => {
    let projectUuid = null;
    if (linkedProjectType === LinkedProjectType.NEW) {
      const project = await dispatch(createProject(createProjectBody, selectedTemplate));
      if (project) {
        projectUuid = project.uuid;
      }
    } else {
      projectUuid = selectedProjectUuid;
    }
    return projectUuid;
  }, [linkedProjectType, dispatch, createProjectBody, selectedTemplate, selectedProjectUuid]);

  const onSubmit = useCallback(
    (shortcut: Shortcut) => {
      if (onCreate && mode === ProjectModalMode.CREATE) {
        onCreate(shortcut);
      }
      if (onUpdate && mode === ProjectModalMode.UPDATE) {
        onUpdate(shortcut);
      }
    },
    [mode, onCreate, onUpdate],
  );

  const handleSubmit = useCallback(async () => {
    try {
      setSubmitting(true);
      const projectUuid = await getProjectUuid();
      onSubmit(projectUuid !== null ? { category: ShortcutCategory.PROJECT, uuid: projectUuid } : undefined);
      setOpenModal(false);
      if (onClose) {
        onClose();
      }
    } catch (err) {
      console.error(err);
      dispatch(displaySdkErrorToast(err));
    } finally {
      setSubmitting(false);
    }
  }, [dispatch, onClose, onSubmit, getProjectUuid]);

  const handleChangeLinkedProjectType: ChangeEventHandler<HTMLInputElement> = useCallback(event => {
    setLinkedProjectType(event.target.value as LinkedProjectType);
  }, []);

  const handleChangeSelectedProject = useCallback((uuid: UUID | null) => {
    setSelectedProjectUuid(uuid);
  }, []);

  useEffect(() => {
    setOpenModal(open);
    if (open) {
      setCreateProjectBody(emptyProject);
      setSelectedTemplate(undefined);
      setSelectedProjectUuid(defaultValue?.shortcut?.uuid ?? null);
      setLinkedProjectType(defaultValue?.shortcut?.uuid ? LinkedProjectType.EXISTING : LinkedProjectType.NEW);
    }
  }, [open, emptyProject, defaultValue]);

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="sm" open={openModal} onClose={handleClose}>
      <DialogTitle>
        <Stack alignItems="flex-start" direction="row" justifyContent="space-between">
          <Box>
            <>{title ?? t('button.joinProject')}</>
            {subtitle && <Typography variant="caption">{subtitle}</Typography>}
          </Box>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <RadioGroup row value={linkedProjectType} onChange={handleChangeLinkedProjectType}>
          <FormControlLabel
            control={<Radio />}
            label={t(`label.linkedProjectType.${LinkedProjectType.NEW}`)}
            value={LinkedProjectType.NEW}
          />
          <FormControlLabel
            control={<Radio />}
            label={t(`label.linkedProjectType.${LinkedProjectType.EXISTING}`)}
            value={LinkedProjectType.EXISTING}
          />
        </RadioGroup>

        {linkedProjectType === LinkedProjectType.NEW && (
          <NewProjectForm
            body={createProjectBody}
            setBody={setCreateProjectBody}
            setTemplate={setSelectedTemplate}
            tasksTemplate={selectedTemplate}
          />
        )}
        {linkedProjectType === LinkedProjectType.EXISTING && (
          <ProjectList value={selectedProjectUuid} onChange={handleChangeSelectedProject} />
        )}
      </DialogContent>
      <DialogActions>
        {footerText && (
          <Typography ml={2} mr="auto">
            {footerText}
          </Typography>
        )}
        <LoadingButton
          color="secondary"
          disabled={
            // !message ||
            !isProjectValid
          }
          loading={submitting}
          variant="contained"
          onClick={handleSubmit}
        >
          {t('button.submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectPluginModal;
