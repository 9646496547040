import { StyleFormat, TextAlign } from '@dametis/core';

import { CommentFilter, CommentView, LinkedProjectType, CommentSortBy, EntityType } from 'types/comment';

export default {
  title: {
    comments: 'Comentarios',
    modal: 'Añadir un comentario',
  },
  stepper: {
    comment: 'Comentario',
    project: 'Proyecto',
  },
  placeholder: {
    leaveAComment: 'Escribe un comentario...',
  },
  text: {
    requiredComment: 'Se requiere dejar un comentario',
    sort: 'Ordenar',
    filter: 'Filtro',
    noComment: 'Sin comentarios',
    owner: 'Usuario',
    allSelected: 'Todo seleccionado',
    from: 'desde {{from}}',
    to: 'hasta {{to}}',
    noEntity: 'Ninguna entidad',
    title: {
      variable: 'variable',
      report: 'informe',
      alarm: 'alarma',
      project: 'proyecto',
      task: 'tarea',
    },
    fontSize: {
      small: 'Pequeño',
      medium: 'Mediano',
      large: 'Grande',
      auto: 'Auto',
    },
    periodFilterWarning:
      'Al guardar, tu comentario desaparecerá de la lista porque el período asociado ya no está incluido en el período actual. Para encontrarlo de nuevo, desactiva el filtro del período actual.',
    noUserFound: 'Ningún colaborador encontrado para "{{search}}"',
  },
  label: {
    withProject: 'Vincular este comentario a un proyecto',
    linkedProjectType: {
      [LinkedProjectType.NEW]: 'Nuevo proyecto',
      [LinkedProjectType.EXISTING]: 'Proyecto existente',
    },
    date: 'Fecha',
    displayOnlyOnCurrentPeriod: 'Mostrar comentarios del período actual',
    [StyleFormat.BOLD]: 'Negrita',
    [StyleFormat.ITALIC]: 'Cursiva',
    [StyleFormat.UNDERLINE]: 'Subrayado',
    [TextAlign.LEFT]: 'Alineado a la izquierda',
    [TextAlign.CENTER]: 'Alineado al centro',
    [TextAlign.RIGHT]: 'Alineado a la derecha',
    entity: {
      [EntityType.ALARM]: 'alarma',
      [EntityType.PLAYGROUND]: 'playground',
      [EntityType.REPORT]: 'informe',
      [EntityType.PROJECT]: 'proyecto',
      [EntityType.SYNOPTIC]: 'sinóptico',
      [EntityType.VARIABLE]: 'variable',
      [EntityType.TASK]: 'tarea',
    },
  },
  button: {
    close: 'Cerrar',
    next: 'Siguiente',
    submit: 'Validar',
    previous: 'Anterior',
    fullscreen: 'Pantalla completa',
    exitFullscreen: 'Salir del modo de pantalla completa',
    send: 'Enviar',
    joinProject: 'Vincular un proyecto',
    cancel: 'Cancelar',
    insertMention: 'Mencionar un colaborador',
  },
  sortBy: {
    [CommentSortBy.DATE]: 'Fecha asociada',
    [CommentSortBy.DATE_REVERSE]: 'Fecha asociada (descendente)',
    [CommentSortBy.CREATION_DATE]: 'Fecha del comentario',
    [CommentSortBy.CREATION_DATE_REVERSE]: 'Fecha del comentario (descendente)',
    [CommentSortBy.PERIOD_FROM]: 'Inicio del período',
    [CommentSortBy.PERIOD_FROM_REVERSE]: 'Inicio del período (descendente)',
    [CommentSortBy.PERIOD_TO]: 'Fin del período',
    [CommentSortBy.PERIOD_TO_REVERSE]: 'Fin del período (descendente)',
  },
  view: {
    [CommentView.LIST]: 'Vista de lista',
    [CommentView.BY_PROJECT]: 'Vista por proyecto',
  },
  filter: {
    [CommentFilter.ALL]: 'Mostrar todos los comentarios',
    [CommentFilter.CURRENT_PERIOD]: 'Mostrar comentarios del período actual',
  },
  tooltip: {
    notAllowedDeleteComment: 'No puedes vaciar este comentario',
    notAlloweEditComment: 'No puedes modificar este comentario',
    goToPeriod: 'Establecer este período en el {{entity}}',
    samePeriod: 'Este período ya está establecido en el {{entity}}',
  },
};
