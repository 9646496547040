import { Box } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createEditor, Descendant } from 'slate';
import { Editable, Slate, withReact } from 'slate-react';

import { CalculationVariable, IsCalculationVariable } from '@dametis/core';

import { createCalculationVariable } from '../../../functions/createCalculationVariable';
import { useLocalizedGroupBy } from '../../../localization/useLocalizedGroupBy';
import { RenderElement } from '../../VNC/components/Title/Textarea/RenderElement';
import { RenderLeaf } from '../../VNC/components/Title/Textarea/RenderLeaf';
import { BlockAggregator } from '../../VNC/components/Title/styled';
import { withCustomElements } from '../../VNC/slate';
import { createParagraph } from '../../VNC/slate/paragraph';
import { slateToTada, tadaToSlate } from '../../VNC/slate/tada';

const initialSlate = [createParagraph()];
const initialCalculation = createCalculationVariable();

interface Props {
  calculation: CalculationVariable | Descendant[];
  hideCalcVarProps?: boolean;
}

const CalculationSlate: FC<Props> = ({ calculation, hideCalcVarProps = false }) => {
  const { t } = useTranslation('global');

  const editor = useMemo(() => withCustomElements(withReact(createEditor()), false), []);
  const slate = useMemo(() => (IsCalculationVariable(calculation) ? tadaToSlate(calculation) : calculation) ?? initialSlate, [calculation]);
  const calcVar = useMemo(
    () => (IsCalculationVariable(calculation) ? calculation : slateToTada(calculation)) ?? initialCalculation,
    [calculation],
  );

  const groupBy = useLocalizedGroupBy(calcVar.groupBy, true);

  useEffect(() => {
    editor.children = slate;
    editor.onChange();
  }, [editor, slate]);

  return (
    <Box component="span" display="block">
      {!hideCalcVarProps && (Boolean(calcVar.operator) || Boolean(groupBy)) && (
        <BlockAggregator>
          {Boolean(calcVar.operator) && t('operator.opShort', { context: calcVar.operator })}
          {Boolean(groupBy) && ` ${groupBy}`}
        </BlockAggregator>
      )}
      <Slate editor={editor} initialValue={slate}>
        <Editable readOnly renderElement={RenderElement} renderLeaf={RenderLeaf} />
      </Slate>
    </Box>
  );
};

export default CalculationSlate;
