import { Checkbox, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Tooltip } from '@mui/material';
import { FC, MouseEventHandler, ReactNode, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AreSameVarCalc, ModelVariable } from '@dametis/core';

import { PropsContext } from 'components/VNC/context';
import { useVariableColor } from 'hooks/useVariableProp';
import { useVncStore } from 'zustand/stores/vnc';

import { TypographyNoBreak } from '../TypographyNoBreak';

interface Props {
  variable?: ModelVariable;
  primary?: string;
  secondary?: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  avatar?: ReactNode;
  caption?: string;
}

const ModelItem: FC<Props> = ({
  variable = undefined,
  primary = '',
  secondary = undefined,
  onClick = undefined,
  avatar = undefined,
  caption = '',
}) => {
  const { t } = useTranslation('vnc');

  const { multiple, defaultVariableOperator, defaultGlobalOperator } = useContext(PropsContext);

  const selection = useVncStore(state => state.selection);
  const someSelected = useVncStore(state => Boolean(state.selection.length));
  const addToSelection = useVncStore(state => state.addToSelection);
  const removeByUuidFromSelection = useVncStore(state => state.removeByUuidFromSelection);

  const selected = useMemo(() => selection.some(varCalc => AreSameVarCalc(varCalc, variable)), [selection, variable]);

  const color = useVariableColor(variable);

  const toggleElementInSelection = useCallback(() => {
    if (!variable) return;
    const newVariable: ModelVariable = {
      ...variable,
      operator: defaultVariableOperator ?? defaultGlobalOperator,
    };
    if (selected) {
      removeByUuidFromSelection(newVariable);
    } else {
      addToSelection(newVariable);
    }
  }, [variable, defaultVariableOperator, defaultGlobalOperator, selected, removeByUuidFromSelection, addToSelection]);

  const listItemOnClick = useMemo(() => {
    if (someSelected && variable) {
      return toggleElementInSelection;
    }
    if (!someSelected) {
      return onClick;
    }
    return undefined;
  }, [someSelected, variable, toggleElementInSelection, onClick]);

  if (!listItemOnClick) return null;
  return (
    <ListItem
      disablePadding
      secondaryAction={
        multiple && variable ? (
          <Tooltip placement="right" title={t(selected ? 'tooltip.removeFromSelection' : 'tooltip.addToSelection')}>
            <Checkbox checked={selected} edge="end" sx={[color && { color: `${color} !important` }]} onClick={toggleElementInSelection} />
          </Tooltip>
        ) : undefined
      }
    >
      <ListItemButton selected={selected} onClick={listItemOnClick}>
        {avatar && (
          <ListItemAvatar>
            <Stack alignItems="center" spacing={0.5} sx={{ width: 40 }}>
              {avatar}
              <TypographyNoBreak variant="caption">{caption}</TypographyNoBreak>
            </Stack>
          </ListItemAvatar>
        )}
        <ListItemText primary={primary} primaryTypographyProps={{ noWrap: true, variant: 'h6' }} secondary={secondary} />
      </ListItemButton>
    </ListItem>
  );
};

export default ModelItem;
