import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import { COMMAND_PRIORITY_LOW, KEY_ENTER_COMMAND, LexicalCommand, createCommand } from 'lexical';
import { useLayoutEffect } from 'react';

export const SAVE_COMMAND: LexicalCommand<KeyboardEvent> = createCommand('SAVE_COMMAND');

const KeyEventsPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useLayoutEffect(() => {
    mergeRegister(
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (event: KeyboardEvent | null) => {
          if (event !== null && (event.ctrlKey || event.metaKey)) {
            event.preventDefault();
            editor.dispatchCommand(SAVE_COMMAND, event);
            return true;
          }
          return false;
        },
        COMMAND_PRIORITY_LOW,
      ),
    );
  }, [editor]);

  return null;
};

export default KeyEventsPlugin;
