import { DataVariable, Period, ReadMacroProgressResult, UUID } from '@dametis/core';

export enum WidgetCategories {
  CHARTS = 'charts',
  TABLES = 'tables',
  BASICS = 'basics',
  PRETTY = 'pretty',
}

export enum WidgetScope {
  BASIC = 'basic',
  SUBPERIODS = 'subperiods',
  YEARONLY = 'yearOnly',
  MONTHONLY = 'monthOnly',
  WEEKONLY = 'weekOnly',
}

export type ChartVariable = Record<string, DataVariable>;

export interface MacrosResults {
  uuid: UUID;
  results: ReadMacroProgressResult['returnValue'];
  period: Period;
}
