import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Button, IconButton, Menu, MenuItem, Stack, SvgIcon, Tooltip } from '@mui/material';
import { Dispatch, FC, SetStateAction, useMemo, useState, MouseEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderBy } from '@dametis/core';

import { SortedBy } from '../BlockList';

export interface OrderAndSortMenuProps {
  orderBy: OrderBy;
  setOrderBy: Dispatch<SetStateAction<OrderBy>>;
  sortedBy: SortedBy;
  setSortedBy: Dispatch<SetStateAction<SortedBy>>;
  isOpen: boolean;
}

const OrderAndSortMenu: FC<OrderAndSortMenuProps> = ({ orderBy, setOrderBy, sortedBy, setSortedBy, isOpen }) => {
  const { t } = useTranslation('lego');

  const [sortedByMenuAnchorEl, setSortedByMenuAnchorEl] = useState<HTMLElement | null>(null);

  const sortedByMenuOpen = useMemo(() => Boolean(sortedByMenuAnchorEl), [sortedByMenuAnchorEl]);

  const handleOpenSortedByMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setSortedByMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseSortedByMenu = useCallback(() => {
    setSortedByMenuAnchorEl(null);
  }, []);

  const handleSelectSortedBy = useCallback(
    (newSortedBy: SortedBy) => () => {
      setSortedBy(newSortedBy);
      setSortedByMenuAnchorEl(null);
    },
    [setSortedBy],
  );

  const handleChangeOrderBy = useCallback(() => {
    setOrderBy(state => (state === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC));
  }, [setOrderBy]);

  return (
    <>
      <Stack alignItems="center" direction="row">
        <Button color="inherit" disabled={!isOpen} sx={{ textTransform: 'unset', flexShrink: 0 }} onClick={handleOpenSortedByMenu}>
          {t('button.sortedBy', { value: sortedBy })}
        </Button>
        <Tooltip title={t(`text.orderBy.${orderBy}`)}>
          <IconButton disabled={!isOpen} size="small" onClick={handleChangeOrderBy}>
            <SvgIcon component={orderBy === OrderBy.ASC ? ArrowUpward : ArrowDownward} fontSize="small" />
          </IconButton>
        </Tooltip>
      </Stack>
      <Menu
        anchorEl={sortedByMenuAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={sortedByMenuOpen}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseSortedByMenu}
      >
        {Object.values(SortedBy).map(sortedByValue => (
          <MenuItem key={sortedByValue} selected={sortedByValue === sortedBy} onClick={handleSelectSortedBy(sortedByValue)}>
            {t(`label.sortedBy.${sortedByValue}`)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OrderAndSortMenu;
