import { cloneDeep } from 'lodash';

import {
  AUTO_GROUPBY,
  CalculationVariable,
  FillOperators,
  GroupBy1,
  IsCalculationVariable,
  setOptionsToLeaves,
  Smoothing,
  TadaApiResponse,
  TraverseCalculation,
} from '@dametis/core';
import { Request, Response } from '@dametis/sdk';

import store from 'store';

import { sdk } from '../../sdk';

import { getGroupBy } from './getGroupBy';
import { applySmartFill } from './helpers';
import { applySmoothing, getSmoothingGroupBy } from './smoothing';

export const MAX_POINTS = 40000;
const TARGET_POINTS = 4000;

interface SingleValueOptions {
  singleValue: true;
}
interface MultipleValuesOptions {
  singleValue?: false;
  groupBy?: GroupBy1 | null;
  rawMode?: boolean;
  targetPoints?: number;
  smoothing?: Smoothing;
  disableMaxPoints?: boolean;
}
type ValueOptions = SingleValueOptions | MultipleValuesOptions;
interface OtherOptions {
  groupId?: string;
  fill?: FillOperators | 'AUTO';
}
type Options = ValueOptions & OtherOptions;

export const Tada = (
  datas: CalculationVariable[],
  req?: Request<never, CalculationVariable[]>,
  options: Options = {},
): Promise<Pick<Response<TadaApiResponse[]>, 'data'>> => {
  if (datas.length === 0) return Promise.resolve({ data: [] });
  const rawMode = options.singleValue === true ? false : options.rawMode;
  const targetPoints = options.singleValue === true ? null : options.targetPoints ?? TARGET_POINTS;
  const groupBy = options.singleValue === true ? null : options.groupBy;
  const smoothing = options.singleValue === true ? null : options.smoothing;
  const disableMaxPoints = options.singleValue === true ? false : options.disableMaxPoints;
  const {
    auth: { selectedGroup, selectedSite },
  } = store.getState();
  const groupId = options.groupId ?? selectedGroup.uuid;
  const site = options.groupId ? undefined : selectedSite || selectedGroup.sites.at(0);
  const timeZone = site?.timeZone;
  const newDatas = datas.map(data => {
    let cloned = cloneDeep(data);
    TraverseCalculation(cloned, node => {
      if (IsCalculationVariable(node)) {
        delete node.slate;
      }
    });
    if (smoothing) {
      cloned = applySmoothing(cloned, smoothing);
    }
    let autoGroupByReplacement = groupBy;
    if (smoothing) {
      autoGroupByReplacement = getSmoothingGroupBy(data.period);
    } else if (autoGroupByReplacement === undefined || autoGroupByReplacement === AUTO_GROUPBY) {
      if (data.period) {
        autoGroupByReplacement = getGroupBy(new Date(data.period.from), new Date(data.period.to), null, MAX_POINTS, MAX_POINTS);
      } else {
        autoGroupByReplacement = null;
      }
    }
    const newData: CalculationVariable = {
      ...cloned,
      timeZone,
      maxPoints: disableMaxPoints ? undefined : MAX_POINTS,
      targetPoints: rawMode ? targetPoints : undefined,
      autoGroupByReplacement,
    };
    if (options.fill !== undefined) {
      if (options.fill === 'AUTO') {
        applySmartFill(newData);
      } else {
        setOptionsToLeaves(newData, { fill: options.fill }, true);
      }
    }
    return newData;
  });
  return sdk.tada.Tada(groupId, newDatas, req);
};

let timeout: ReturnType<typeof setTimeout> = null;
let calcVars: {
  calcVar: CalculationVariable;
  callback: (res: TadaApiResponse) => void;
  errorCallback: (err: any) => void;
}[] = [];

export const MultiTada = (calcVar: CalculationVariable, options?: Options): Promise<TadaApiResponse> =>
  new Promise((resolve, reject) => {
    const startingState = store.getState();
    const startingGroupId = startingState.auth.selectedGroup?.uuid;
    const startingSiteId = startingState.auth.selectedSite?.uuid;
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    calcVars.push({
      calcVar,
      callback: data => {
        resolve(data);
      },
      errorCallback: err => {
        reject(err);
      },
    });
    timeout = setTimeout(async () => {
      const calcVarsCopy = [...calcVars];
      calcVars = [];
      const endingState = store.getState();
      const endingGroupId = endingState.auth.selectedGroup?.uuid;
      const endingSiteId = endingState.auth.selectedSite?.uuid;
      if (startingGroupId === endingGroupId && startingSiteId === endingSiteId) {
        try {
          const { data } = await Tada(
            calcVarsCopy.map(({ calcVar: calcVar2 }) => calcVar2),
            undefined,
            options,
          );
          data.forEach((res, i) => {
            calcVarsCopy[i].callback(res);
          });
        } catch (err) {
          calcVarsCopy.forEach(calc => {
            calc.errorCallback(err);
          });
        }
      } else {
        clearTimeout(timeout);
      }
    }, 100);
  });
