import { Drawer, drawerClasses, DrawerProps, styled } from '@mui/material';
import { useContext } from 'react';

import { RightPanelDrawerContext } from './RightPanelDrawerContainer';
import RightPanelDrawerResizer from './RightPanelDrawerResizer';

const RightPanelDrawer = styled(({ children, ...props }: Omit<DrawerProps, 'open'>) => {
  const { open, container, panelWidth, resizable } = useContext(RightPanelDrawerContext);
  return (
    <Drawer anchor="right" container={container} elevation={0} open={open} variant="persistent" {...props} sx={{ width: panelWidth }}>
      {children}
      {resizable && <RightPanelDrawerResizer />}
    </Drawer>
  );
})(({ theme }) => ({
  height: '100%',
  [`& .${drawerClasses.paper}`]: {
    position: 'sticky',
    top: theme.others.headerHeight,
    zIndex: theme.zIndex.appBar - 1,
    height: '100%',
    maxHeight: `calc(100vh - ${theme.others.headerHeight})`,
    border: 'none',
    borderRadius: '10px 0 0 10px',
  },
}));

export default RightPanelDrawer;
