import { DeleteOutlined, SettingsOutlined } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, MenuItem, Popover, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { ChangeEventHandler, FC, MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  BlockTypeMetadata,
  BlockTypeMetadataNumber,
  IsBlockTypeMetadataNumber,
  IsBlockTypeMetadataOption,
  MetadataType,
} from '@dametis/core';

import {
  createBlockTypeMetadataNumberContent,
  createBlockTypeMetadataOptionContent,
  createBlockTypeMetadataStringContent,
} from 'components/Lego/helpers/blockType/createBlockTypeMetadata';
import UnitPicker from 'components/UI/UnitPicker/UnitPicker';
import { UnitResult } from 'components/UI/UnitPicker/types';

import { MetadataListName } from './MetadataListsStep';
import MetadataOptionSettings from './MetadataOptionSettings';

export interface MetadataFormProps {
  value: BlockTypeMetadata;
  listName: MetadataListName;
  onChange: (newValue: BlockTypeMetadata) => void;
  onChangeListName: (newListName: MetadataListName) => void;
  onDelete: () => void;
}

const MetadataForm: FC<MetadataFormProps> = ({ value, listName, onChange, onChangeListName, onDelete }) => {
  const { t } = useTranslation('lego');

  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isSettingsMenuOpen = useMemo(() => Boolean(settingsMenuAnchorEl), [settingsMenuAnchorEl]);
  const availableTypes = useMemo(() => Object.values(MetadataType), []);
  const availableListNames = useMemo(() => Object.values(MetadataListName), []);

  const handleChangeName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newValue = { ...value, name: event.target.value };
      onChange(newValue);
    },
    [value, onChange],
  );

  const handleChangeType: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      const newType = event.target.value as MetadataType;
      let newContent = null;
      if (newType === MetadataType.STRING) {
        newContent = createBlockTypeMetadataStringContent();
      }
      if (newType === MetadataType.NUMBER) {
        newContent = createBlockTypeMetadataNumberContent();
      }
      if (newType === MetadataType.OPTION) {
        newContent = createBlockTypeMetadataOptionContent();
      }
      if (newContent) {
        const newValue: BlockTypeMetadata = { ...value, type: newType, content: newContent };
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const handleChangeListName: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => {
      onChangeListName(event.target.value as MetadataListName);
    },
    [onChangeListName],
  );

  const handleChangeUnit = useCallback(
    (unit: UnitResult) => {
      if (IsBlockTypeMetadataNumber(value)) {
        const newValue: BlockTypeMetadataNumber = { ...value, content: { ...value.content, unit } };
        onChange(newValue);
      }
    },
    [value, onChange],
  );

  const handleDelete = useCallback(() => {
    onDelete();
  }, [onDelete]);

  const handleOpenSettingsMenu: MouseEventHandler<HTMLButtonElement> = useCallback(event => {
    setSettingsMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseSettingsMenu = useCallback(() => {
    setSettingsMenuAnchorEl(null);
  }, []);

  return (
    <>
      <Grid container alignItems="center" flexWrap="nowrap" spacing={1}>
        <Grid item xs={3}>
          <TextField fullWidth select value={listName} onChange={handleChangeListName}>
            {availableListNames.map(availableListName => (
              <MenuItem key={availableListName} value={availableListName}>
                {t(`metadataListName.${availableListName}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField fullWidth placeholder={t('placeholder.name')} value={value.name} onChange={handleChangeName} />
        </Grid>
        <Grid item xs>
          <TextField fullWidth select value={value.type} onChange={handleChangeType}>
            {availableTypes.map(availableType => (
              <MenuItem key={availableType} value={availableType}>
                {t(`metadataType.${availableType}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {IsBlockTypeMetadataNumber(value) && (
          <Grid item xs>
            <UnitPicker freeMode noUnitEnabled value={value.content.unit} onChange={handleChangeUnit} />
          </Grid>
        )}
        {IsBlockTypeMetadataOption(value) && (
          <Grid item>
            <Typography noWrap variant="subtitle2">
              {t('text.metadataOptionsLength', { count: value.content.availableOptions.length })}
            </Typography>
          </Grid>
        )}
        <Grid item xs={false}>
          <Stack direction="row">
            {IsBlockTypeMetadataOption(value) && (
              <Tooltip title={t('tooltip.settings')}>
                <IconButton size="small" onClick={handleOpenSettingsMenu}>
                  <SettingsOutlined fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title={t('tooltip.delete')}>
              <IconButton size="small" onClick={handleDelete}>
                <DeleteOutlined fontSize="small" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
      <Popover
        anchorEl={settingsMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSettingsMenuOpen}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        onClose={handleCloseSettingsMenu}
      >
        <Stack alignItems="flex-end">
          <Box p={1} width={300}>
            {IsBlockTypeMetadataOption(value) && <MetadataOptionSettings value={value} onChange={onChange} />}
          </Box>
          <Button color="primary" variant="text" onClick={handleCloseSettingsMenu}>
            {t('button.close')}
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default MetadataForm;
