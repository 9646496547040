import { Avatar, AvatarProps } from '@mui/material';
import { forwardRef } from 'react';

import { TinyUserInfo } from '@dametis/core';

import { getUserColor } from '../../../functions/color';

import { UserAvatarBorder } from './UserAvatarBorder';

interface Props extends AvatarProps {
  user: TinyUserInfo;
  size?: number;
  checked?: boolean;
  onClick?: () => void;
}

const UserAvatar = forwardRef<HTMLDivElement, Props>(({ user, size = 25, checked = false, onClick = undefined, sx, ...props }, ref) => (
  <Avatar
    {...props}
    ref={ref}
    sx={{
      backgroundColor: getUserColor(user),
      width: size,
      height: size,
      fontSize: Math.round(size / 2),
      ...(!!onClick && { overflow: 'visible' }),
      ...sx,
    }}
    onClick={onClick}
  >
    {(user?.firstName ?? '').charAt(0).toUpperCase()}
    {(user?.lastName ?? '').charAt(0).toUpperCase()}
    <UserAvatarBorder checked={checked} clickable={!!onClick} />
  </Avatar>
));

UserAvatar.defaultProps = {
  size: 25,
  onClick: undefined,
  checked: false,
};

UserAvatar.displayName = 'UserAvatar';

export default UserAvatar;
