import i18next from 'i18next';

import {
  BlockVariable,
  IsAliasVariable,
  IsBatchVariable,
  IsBlockTypeMetricVariable,
  IsBlockTypeParameterVariable,
  IsBlockVariable,
  IsCalculationVariable,
  IsDataVariable,
  IsModelVariable,
  ModelKeyField,
  ModelVariable,
  Operator,
  PartialCalculationVariable,
} from '@dametis/core';
import { CalculationToString, mathJs } from '@dametis/mathjs';

import store from 'store';
import { selectAliases } from 'store/api/aliases';
import { selectBlocks } from 'store/api/blocks';
import { selectModelsResult } from 'store/api/models';
import { selectStandardBlocks } from 'store/api/standardBlocks';

import { isModelExplanatoryVariable } from './isModelExplanatoryVariable';
import { numberToSubscript } from './numberToSubscript';

export const blockVariableToString = (variable: BlockVariable, blockPath, enrichedString = false): string => {
  const { data: blocks = [] } = selectBlocks()(store.getState());
  const { data: standardBlocks = [] } = selectStandardBlocks()(store.getState());

  const selectedBlock = blocks.find(block => block.uuid === variable.blockId);
  const selectedStandardBlock = standardBlocks.find(standardBlock => standardBlock.uuid === selectedBlock?.standardBlockId);
  if (selectedStandardBlock && selectedBlock) {
    if (blockPath) {
      return enrichedString && variable.operator
        ? `${i18next.t('global:operator.opShort', { context: variable.operator }).toUpperCase()}(${i18next.t(
            'lego:text.blockVariableWithPath',
            {
              standardBlock: selectedStandardBlock.name,
              block: selectedBlock.name,
              variable: variable.blockKey,
            },
          )})`
        : i18next.t('lego:text.blockVariableWithPath', {
            standardBlock: selectedStandardBlock.name,
            block: selectedBlock.name,
            variable: variable.blockKey,
          });
    }
    return variable.blockKey;
  }
  return i18next.t('vnc:text.unknownBlock');
};

export const modelVariableToString = (variable: ModelVariable, enrichedString = false): string => {
  const { data: models = [] } = selectModelsResult()(store.getState());
  const model = models.find(model => model.uuid === variable.modelUuid);
  if (!model) {
    return i18next.t('global:text.unknownModel');
  }
  let translation = '';
  if (isModelExplanatoryVariable(variable)) {
    const variableIndex = model.xVars.findIndex(xVar => xVar.uuid === variable.modelXVarUuid);
    if (variableIndex < 0) return i18next.t('global:text.unknownVariable');
    translation = i18next.t(`models:text.modelVariableWithPath.${ModelKeyField.X_VAR}`, {
      variable: numberToSubscript(variableIndex + 1),
      model: model.name,
    });
  } else {
    translation = i18next.t(`models:text.modelVariableWithPath.${variable.modelKey}`, { model: model.name });
  }
  return enrichedString && variable.operator
    ? `${i18next.t('global:operator.opShort', { context: variable.operator }).toUpperCase()}(${translation})`
    : translation;
};

export const calculationToString = (
  calculationVariable: PartialCalculationVariable | null | undefined,
  blockPath = true,
  enrichedString = false,
): string => {
  if (calculationVariable === undefined || calculationVariable === null) {
    return i18next.t('global:text.unknownVariable');
  }
  const variables = store.getState().variables.byId;
  const { flattenBatches } = store.getState().batch;

  return CalculationToString(mathJs, calculationVariable, varCalc => {
    const options: string = [
      ![Operator.HISTORY, null, undefined].includes(varCalc.operator)
        ? `${i18next.t('global:operator.opShort', { context: varCalc.operator })?.toUpperCase()}`
        : null,
      varCalc.groupBy === 'POINT_TO_POINT' ? i18next.t('groupBy:pointToPoint_short') : varCalc.groupBy,
      varCalc.fill,
      varCalc.timestamp,
    ]
      .filter((option): option is Exclude<typeof option, null | undefined> => option !== null && option !== undefined)
      .map(option => option.toString())
      .join('_');

    if (IsCalculationVariable(varCalc)) {
      return enrichedString ? options : '';
    }

    if (IsDataVariable(varCalc)) {
      const variable = variables[varCalc.variableUuid];
      if (variable === undefined) {
        return i18next.t('global:text.unknownVariable');
      }
      return enrichedString && options.length ? `${options}(${variable.name})` : `${variable.name}`;
    }
    if (IsBatchVariable(varCalc)) {
      const batch = flattenBatches.find(batch => batch.uuid === varCalc.batchUuid);
      if (batch === undefined) {
        return i18next.t('global:text.unknownVariable');
      }
      return enrichedString && options.length ? `${options}(${batch.name})` : `${batch.name}`;
    }
    if (IsBlockVariable(varCalc)) {
      return blockVariableToString(varCalc, blockPath, enrichedString);
    }
    if (IsBlockTypeParameterVariable(varCalc) || IsBlockTypeMetricVariable(varCalc)) {
      return enrichedString && varCalc.operator
        ? `(${i18next.t('global:operator.opShort', { context: varCalc.operator }).toUpperCase()})${varCalc.blockKey}`
        : varCalc.blockKey;
    }
    if (IsModelVariable(varCalc)) {
      return modelVariableToString(varCalc, enrichedString);
    }
    if (IsAliasVariable(varCalc)) {
      const { data: aliases = [] } = selectAliases()(store.getState());
      const alias = aliases.find(alias => alias.uuid === varCalc.aliasUuid);
      if (alias === undefined) {
        return i18next.t('global:text.unknownVariable');
      }
      return enrichedString && options.length ? `${options}(${alias.name})` : `${alias.name}`;
    }
    return i18next.t('global:text.unknownVariable');
  });
};
