import { v4 as uuidv4 } from 'uuid';

import {
  StandardBlockMetadata,
  StandardBlockMetadataNumberContent,
  StandardBlockMetadataOptionContent,
  StandardBlockMetadataStringContent,
  MetadataScope,
  MetadataType,
  StandardBlockMetadataBody,
  MetadataContent,
} from '@dametis/core';

export const createStandardBlockMetadata = ({
  uuid = uuidv4(),
  name = '',
  type = MetadataType.STRING,
  content = createStandardBlockMetadataStringContent(),
  ui = {},
}: Partial<Omit<StandardBlockMetadata, 'scope'>> = {}): StandardBlockMetadata => ({
  uuid,
  name,
  type,
  scope: MetadataScope.STANDARD_BLOCK,
  content,
  ui,
});

export const createStandardBlockMetadataBody = ({
  name = '',
  type = MetadataType.STRING,
  content = createStandardBlockMetadataStringContent(),
  ui = {},
}: Partial<StandardBlockMetadataBody>): StandardBlockMetadataBody => ({
  name,
  type,
  scope: MetadataScope.STANDARD_BLOCK,
  content,
  ui,
});

export const createStandardBlockMetadataContent = <T extends MetadataType = MetadataType>(
  type: T,
  content: Partial<MetadataContent<MetadataScope.STANDARD_BLOCK>[T]>,
): StandardBlockMetadataStringContent | StandardBlockMetadataOptionContent | StandardBlockMetadataNumberContent => {
  if (type === MetadataType.STRING) {
    return createStandardBlockMetadataStringContent(content);
  }
  if (type === MetadataType.NUMBER) {
    return createStandardBlockMetadataNumberContent(content);
  }
  return createStandardBlockMetadataOptionContent(content);
};

export const createStandardBlockMetadataStringContent = ({
  value = '',
}: Partial<StandardBlockMetadataStringContent> = {}): StandardBlockMetadataStringContent => ({ value });

export const createStandardBlockMetadataOptionContent = ({
  availableOptions = [],
  selectedOptionId = null,
}: Partial<StandardBlockMetadataOptionContent> = {}): StandardBlockMetadataOptionContent => ({ availableOptions, selectedOptionId });

export const createStandardBlockMetadataNumberContent = ({
  unit = null,
  value = 0,
}: Partial<StandardBlockMetadataNumberContent> = {}): StandardBlockMetadataNumberContent => ({ unit, value });
