import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { subWeeks } from 'date-fns';

import { timeGroupBy, Period } from '@dametis/core';

import { getGroupBy, getRawGroupBy } from '../../functions/tada/getGroupBy';

import { initialBoxesState } from './boxes';

export interface PeriodState {
  period: Period;
  groupBy: timeGroupBy;
  lowGroupBy: timeGroupBy;
  rawGroupBy: number;
  userGroupBy: timeGroupBy | null;
}

const initialPeriod = new Period({ from: subWeeks(new Date(), 1), to: new Date() });

const initialState: PeriodState = {
  period: initialPeriod,
  lowGroupBy: getGroupBy(initialPeriod.from, initialPeriod.to, null, initialBoxesState.maxLoopTime, 50),
  rawGroupBy: getRawGroupBy(initialPeriod.from, initialPeriod.to, null, initialBoxesState.maxLoopTime, 5000),
  groupBy: getGroupBy(initialPeriod.from, initialPeriod.to, null, initialBoxesState.maxLoopTime, 5000),
  userGroupBy: null,
};

export const periodSlice = createSlice({
  name: 'period',
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<Partial<PeriodState>>) => {
      if (state.period !== null && state.period.AlmostEquals(action.payload.period)) {
        if (process.env.NODE_ENV === 'development') {
          // console.warn('UPDATE_PERIOD called with same period (second precision)');
        }
        return state;
      }
      return {
        ...state,
        ...action.payload,
      };
    },
    setUserGroupBy: (state, action: PayloadAction<timeGroupBy | null>) => {
      state.userGroupBy = action.payload;
    },
    clearPeriodStore: () => initialState,
  },
});

export const { setState, setUserGroupBy, clearPeriodStore } = periodSlice.actions;

export default periodSlice.reducer;
