export default {
  title: {
    noReportSet: 'Sélectionner un rapport à afficher :',
    metrics: 'Mes indicateurs de la semaine',
    alarms: 'Alarmes actives',
  },
  text: {
    dametis: 'MyDametis.',
    dametisCorporate: 'MyDametis Corporate.',
    welcome: 'Bienvenue sur ',
    intro: 'Bonjour {{name}}',
    onSite: 'Vous êtes sur le site ',
    ofGroup: ', du groupe ',
    onGroup: 'Vous êtes sur le groupe ',
    alarmCount_none: 'Aucune alarme',
    alarmCount_one: '{{count}} alarme',
    alarmCount_other: '{{count}} alarmes',
    active_none: "n'est active.",
    active_one: 'est active.',
    active_other: 'sont actives.',
    alarmAck: 'Acquittée',
    alarmNotAck: 'Non acquittée',
    noReport: 'Aucun rapport',
    versionStart: {
      v_success: 'Votre',
      v_default: 'Votre',
      v_warning: 'Votre',
      v_danger: 'La synchronisation avec votre',
    },
    versionDametisBox: {
      v_success: 'box Dametis',
      v_default: 'box Dametis',
      v_warning: 'box Dametis',
      v_danger: 'box Dametis',
    },
    versionEnd: {
      v_success: 'est synchronisée.',
      v_default: 'est en cours de synchronisation.',
      v_warning: 'est actuellement hors ligne.',
      v_danger: 'a échoué.',
    },
  },
  button: {
    save: 'Enregistrer',
    cancel: 'Annuler',
    seeAll: 'Voir toutes les alarmes',
    changeReport: 'Changer de rapport',
  },
};
