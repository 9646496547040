import { ArrowRight, Folder } from '@mui/icons-material';
import { Grid, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FolderInfo, Shortcut, UUID } from '@dametis/core';

import { getFolderColor } from 'components/Lego/helpers/getFolderColor';
import { ListItemButtonWithArrow } from 'components/VNC/components/List/LegoList/ListItemButtonWithArrow';

import { FolderExplorerProps } from './FolderExplorer';
import ListItemEntityIcon from './ListItemEntityIcon/ListItemEntityIcon';
import { getExplorerItemName } from './getExplorerItemName';
import { EntitiesByCategory, ExplorerFolder, ExplorerItem, ExplorerItemType, IsAvailableShortcutCategory } from './types';

export interface ExplorerFolderListProps extends Pick<FolderExplorerProps, 'displayedShortcut' | 'isItemDisabled'> {
  item: ExplorerFolder;
  onSelectItem: (newSelectedItem: ExplorerItem) => void;
  selectedItemPathIds: UUID[];
  isLast: boolean;
  entitiesByCategory: EntitiesByCategory;
}

const ExplorerFolderList: FC<ExplorerFolderListProps> = ({
  item,
  onSelectItem,
  selectedItemPathIds,
  isLast,
  entitiesByCategory,
  displayedShortcut,
  isItemDisabled,
}) => {
  const { t } = useTranslation('lego');
  const theme = useTheme();

  const filteredShortcuts = useMemo(
    () =>
      item.content.shortcuts.filter(
        shortcut =>
          shortcut.uuid &&
          IsAvailableShortcutCategory(shortcut.category) &&
          (displayedShortcut ?? []).includes(shortcut.category) &&
          entitiesByCategory[shortcut.category]?.[shortcut.uuid],
      ),
    [displayedShortcut, item.content.shortcuts, entitiesByCategory],
  );

  const sortedFolders = useMemo(
    () => [...item.content.folders].sort((folderA, folderB) => folderA.name.localeCompare(folderB.name)),
    [item.content.folders],
  );

  const sortedShortcuts = useMemo(
    () =>
      [...filteredShortcuts].sort((shortcutA, shortcutB) =>
        getExplorerItemName({ type: ExplorerItemType.SHORTCUT, content: shortcutA }, entitiesByCategory, t).localeCompare(
          getExplorerItemName({ type: ExplorerItemType.SHORTCUT, content: shortcutB }, entitiesByCategory, t),
        ),
      ),
    [filteredShortcuts, t, entitiesByCategory],
  );

  const handleClickFolder = useCallback(
    (folder: FolderInfo) => () => {
      onSelectItem({ type: ExplorerItemType.FOLDER, content: folder });
    },
    [onSelectItem],
  );

  const handleClickShortcut = useCallback(
    (shortcut: Shortcut) => () => {
      onSelectItem({ type: ExplorerItemType.SHORTCUT, content: shortcut });
    },
    [onSelectItem],
  );

  return (
    <Grid
      item
      xs
      sx={{
        overflow: 'auto',
        minWidth: 300,
        px: 0.5,
        ...(!isLast && { borderRight: `1px solid ${theme.palette.divider}` }),
      }}
    >
      <List>
        {sortedFolders.map(folder => (
          <ListItem key={folder.uuid} disablePadding>
            <ListItemButtonWithArrow
              disabled={isItemDisabled ? isItemDisabled({ type: ExplorerItemType.FOLDER, content: folder }) : undefined}
              selected={selectedItemPathIds.includes(folder.uuid)}
              onClick={handleClickFolder(folder)}
            >
              <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
                <Folder fontSize="small" sx={{ color: getFolderColor(folder, theme) }} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ noWrap: true }}>{folder.name}</ListItemText>
              <ArrowRight />
            </ListItemButtonWithArrow>
          </ListItem>
        ))}
        {sortedShortcuts.map(shortcut => (
          <ListItem key={shortcut.uuid} disablePadding>
            <ListItemButtonWithArrow
              disabled={isItemDisabled ? isItemDisabled({ type: ExplorerItemType.SHORTCUT, content: shortcut }) : undefined}
              selected={!!shortcut.uuid && selectedItemPathIds.includes(shortcut.uuid)}
              onClick={handleClickShortcut(shortcut)}
            >
              <ListItemIcon sx={{ mr: 2, minWidth: 'unset' }}>
                <ListItemEntityIcon entitiesByCategory={entitiesByCategory} fontSize="small" shortcut={shortcut} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ noWrap: true }}>
                {getExplorerItemName({ type: ExplorerItemType.SHORTCUT, content: shortcut }, entitiesByCategory, t)}
              </ListItemText>
            </ListItemButtonWithArrow>
          </ListItem>
        ))}
        {sortedFolders.length === 0 && sortedShortcuts.length === 0 && (
          <ListItem>
            <Typography variant="subtitle2">{t('text.emptyFolder')}</Typography>
          </ListItem>
        )}
      </List>
    </Grid>
  );
};

export default ExplorerFolderList;
