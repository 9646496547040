import { Done } from '@mui/icons-material';
import { Chip, chipClasses, ChipProps, styled } from '@mui/material';

import { setColorLightness } from 'functions/color';

interface Props extends Omit<ChipProps, 'variant' | 'className'> {
  selected?: boolean;
}

const ChipSelectItem = styled(({ selected, icon, ...props }: Props) => (
  <Chip color="default" icon={icon || (selected && <Done fontSize="medium" />)} variant={selected ? 'filled' : 'outlined'} {...props} />
))(({ theme }) => ({
  cursor: 'pointer',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: 12,
  width: 'fit-content',
  paddingInline: '1px',
  marginBlock: '1px',
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  [`& .${chipClasses.icon}`]: {
    fontSize: 18,
    color: `${theme.palette.primary.main}`,
  },
  [`&.${chipClasses.filled}`]: {
    paddingInline: '0px',
    backgroundColor: setColorLightness(theme.palette.primary.main, 95),
    color: theme.palette.primary.dark,
    border: `2px solid ${theme.palette.primary.main}`,
    fontWeight: theme.typography.fontWeightMedium,
  },
  '&:hover': {
    cursor: 'pointer',
  },
}));

export default ChipSelectItem;
