import { styled, autocompleteClasses, outlinedInputClasses, svgIconClasses, Autocomplete, AutocompleteProps } from '@mui/material';

const StyledAutocompleteFilter = styled(Autocomplete)(({ theme, value, size }) => ({
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderRadius: 20,
    border: `1.5px solid ${theme.palette.daBlue.light}!important`,
  },
  [`& .${autocompleteClasses.endAdornment} .${svgIconClasses.root}`]: {
    color: `${theme.palette.daBlue.light}`,
  },
  [`& span.${autocompleteClasses.tagSizeSmall}`]: {
    fontSize: '0.75rem',
    color: theme.palette.grey[1400],
    backgroundColor: theme.palette.grey[400],
    borderRadius: theme.shape.borderRadius,
    textShadow: 'none',
    marginLeft: theme.spacing(1),
    paddingInline: `${theme.spacing(0.5)}`,
  },
  ...(size === 'xs' && {
    [`& .${autocompleteClasses.input}`]: {
      padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)} ${theme.spacing(0.25)} ${theme.spacing(1)}!important`,
    },
    [`& .${autocompleteClasses.inputRoot}`]: {
      paddingLeft: `${theme.spacing((value as any)?.length > 0 ? 0.25 : 0.5)}!important`,
      paddingTop: `${theme.spacing((value as any)?.length > 0 ? 0.25 : 0.5)}!important`,
      paddingBottom: `${theme.spacing((value as any)?.length > 0 ? 0.25 : 0.5)}!important`,
    },
  }),
}));

const AutocompleteFilter = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => <StyledAutocompleteFilter {...props} />;

export default AutocompleteFilter;
