/* eslint-disable */
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export enum EntityTitleCategory {
  PLAYGROUND = 'playground',
  PROJECT = 'project',
  REPORT = 'report',
  ALARM = 'alarm',
  SYNOPTIC = 'synoptic',
  MODEL = 'model',
}

export interface UseEntityTitleOptions {
  category?: `${EntityTitleCategory}`;
  restoreOnUnmount?: boolean;
}

const DEFAULT_USE_TITLE_OPTIONS: UseEntityTitleOptions = {
  restoreOnUnmount: false,
  category: undefined,
};

const useEntityTitle = (name: string, options: UseEntityTitleOptions = DEFAULT_USE_TITLE_OPTIONS) => {
  const { t } = useTranslation('global');

  const prevTitleRef = useRef(document.title);
  const composedTitle = useMemo(
    () => (name ? t('text.pageTitleEntity', { name, context: options?.category }) : null),
    [name, t, options?.category],
  );

  if (composedTitle && document.title !== composedTitle) document.title = composedTitle;

  useEffect(() => {
    if (options && options.restoreOnUnmount) {
      return () => {
        document.title = prevTitleRef.current;
      };
    } else {
      return;
    }
  }, []);
};

export default typeof document !== 'undefined' ? useEntityTitle : (_title: string) => {};
