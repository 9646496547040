import { Box, List } from '@mui/material';
import { FC, useMemo } from 'react';

import { useSelector } from 'store';
import { RouteScope, RouteWithIndex, SubRoute as ISubRoute } from 'types/drawer';
import { useLayoutStore } from 'zustand/stores/layout';

import SubRoute from './SubRoute';

interface Props {
  route: RouteWithIndex;
  subRoutes: ISubRoute[];
}

const SubRoutes: FC<Props> = ({ route, subRoutes }) => {
  const corporate = useSelector(state => !state.auth.selectedSite);

  const isTouch = useLayoutStore(state => state.isTouch);

  const filteredSubRoutes = useMemo(
    () =>
      subRoutes.filter(
        subRoute => (!subRoute.touchOnly || isTouch) && subRoute.scopes.includes(corporate ? RouteScope.GROUP : RouteScope.SITE),
      ),
    [subRoutes, isTouch, corporate],
  );

  return (
    <Box sx={theme => ({ pt: theme.others.pagePaddingTop, pb: theme.others.pagePaddingBottom })}>
      <List>
        {filteredSubRoutes.map(subRoute => (
          <SubRoute key={subRoute.name} permission={subRoute.permission} route={route} subRoute={subRoute} />
        ))}
      </List>
    </Box>
  );
};

export default SubRoutes;
