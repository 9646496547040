import { ModifierType } from '@dametis/core';

import { AdvancedEntryMode, AdvancedEntryPreviewMode } from 'store/slices/advancedEntry';
import { ADVANCED_ENTRY_VARIABLES_MAX_COUNT } from 'types/variables';

export default {
  title: {
    advancedEntry: 'Corrección de datos',
    steps: {
      variables: 'Variables',
      selectors: 'Condiciones',
      transformers: 'Modificadores',
      preview: 'Vista previa de las modificaciones',
    },
  },
  button: {
    save: 'Aplicar',
    addVariable: 'Agregar una variable',
    addDeviceVariables: 'Agregar del dispositivo',
    editMode: {
      [AdvancedEntryMode.DELETE]: 'Eliminar',
      [AdvancedEntryMode.EDIT]: 'Edición',
    },
    transformerMode: {
      [ModifierType.MULTIPLIER]: 'Multiplicador',
      [ModifierType.LINEAR_INTERPOLATION]: 'Interpolación lineal',
      [ModifierType.OFFSET]: 'Desplazamiento',
    },
    previewMode: {
      [AdvancedEntryPreviewMode.CHART]: 'Gráfico',
      [AdvancedEntryPreviewMode.GRID]: 'Cuadrícula',
    },
    removeVariable: 'Eliminar la variable',
    clearVariables: 'Borrar variables',
  },
  table: {
    column: {
      date: 'Fecha',
      data: 'Datos actuales',
      patch: 'Datos modificados',
    },
    row: {
      noRows: 'Ningún dato',
    },
    deleted: '(eliminado)',
  },
  text: {
    selectPatchMode: 'Seleccione si desea modificar o eliminar datos',
    selectVariables: 'Seleccione variables a corregir',
    // fetchLimitWarning: "Solo se muestran parte de los datos afectados",
    fetchLimitWarning: 'Solo se muestran las últimas 10 000 filas',
    patch: {
      noSelection: 'Ningún dato seleccionado',
      init: 'Todos los datos',
      dateBetween: ' entre el {{after}} y el {{before}}',
      after: ' después del {{after}}',
      before: ' antes del {{before}}',
      valueBetween: ' comprendidas entre {{min}} y {{max}}',
      min: ' superiores a {{min}}',
      max: ' inferiores a {{max}}',
      edit: ' serán modificados',
      delete: ' serán eliminados',
    },
    legend: {
      base: 'Datos actuales',
      edited: 'Datos modificados',
      deleted: 'Datos eliminados',
    },
  },
  input: {
    after: 'Después (inclusive)',
    before: 'Antes (exclusivo)',
    min: 'Superior a',
    max: 'Inferior a',
    offset: 'Desplazamiento',
    multiplier: 'Multiplicador',
    linearInterpolation: {
      in1: 'In1',
      out1: 'Out1',
      in2: 'In2',
      out2: 'Out2',
    },
    error: {
      minDate: 'La fecha debe ser posterior a',
      maxDate: 'La fecha debe ser anterior a',
      invalidDate: 'Fecha inválida',
    },
  },
  label: {
    delete: 'Eliminado',
  },
  toast: {
    advancedEntryEditSuccess: 'Las modificaciones están siendo procesadas',
    // advancedEntryEditSuccess: "Modificaciones aplicadas con éxito",
    advancedEntryDeleteSuccess: 'Los datos están siendo eliminados',
    // advancedEntryDeleteSuccess: "Datos eliminados con éxito",
  },
  tooltip: {
    prevChart: 'Gráfico anterior',
    nextChart: 'Gráfico siguiente',
    performanceIssue: `Solo se muestran las ${ADVANCED_ENTRY_VARIABLES_MAX_COUNT} primeras variables, pero los cambios se aplicarán a todas las variables`,
  },
};
