import { useMemo } from 'react';

import { featureFlags, WipFeatures, WipFlags } from 'config/featureFlags';
import { useSelector } from 'store';

export const useFeatureFlags = (featureKey: WipFeatures) => {
  const WIPeMaaS = useSelector(state => state.auth.WIPeMaaS);
  const acl = useSelector(state => state.auth.user.acl);

  const isAdmin = useMemo(() => acl.HasGlobalRole('SUPER_ADMIN'), [acl]);

  return useMemo(() => {
    switch (featureFlags[featureKey]) {
      case WipFlags.ENABLED:
        return true;
      case WipFlags.DISABLED:
        return false;
      case WipFlags.WIP:
        return isAdmin;
      case WipFlags.WIP_EMAAS:
        return WIPeMaaS;
      default:
        return false;
    }
  }, [isAdmin, WIPeMaaS, featureKey]);
};
