import { useTheme } from '@mui/material';
import { Series, TooltipFormatterContextObject } from 'highcharts';
import { FC } from 'react';

type Props = Omit<TooltipFormatterContextObject, 'x'> & {
  seriesItem: Series;
};

const NetworkgraphSeriesTooltip: FC<Props> = ({ seriesItem, series, point }) => {
  const theme = useTheme();

  console.log(theme);
  console.log('seriesItem', seriesItem);
  console.log('point', point);
  console.log('point', series);

  return <>NETWORKGRAPH</>;
};

export default NetworkgraphSeriesTooltip;
