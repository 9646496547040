import { useTheme } from '@mui/material';
import { Axis, XAxisOptions } from 'highcharts';
import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { useDaChartContext } from '../DaChartContext';

import XAxisProvider from './XAxisContext';
import { getXAxisOptions } from './xAxisOptions';

const xAxisOptionsDefaultProp: XAxisOptions = {};

export interface XAxisProps {
  xAxisOptions?: XAxisOptions;
}

const XAxis: FC<PropsWithChildren<XAxisProps>> = ({ xAxisOptions = xAxisOptionsDefaultProp, children = undefined }) => {
  // const { t } = useTranslation('dachart');
  const theme = useTheme();

  const { highcharts } = useDaChartContext();

  const [axis, setAxis] = useState<Axis | null>(null);

  const uuid = useMemo(() => uuidv4(), []);
  const options = useMemo(() => getXAxisOptions(xAxisOptions, theme), [xAxisOptions, theme]);

  const initAxis = useCallback(() => {
    if (!highcharts) return;
    setAxis(highcharts.chart.addAxis({ id: uuid, ...options }, true));
  }, [highcharts, uuid, options]);

  useEffect(() => {
    initAxis();
  }, [initAxis]);

  return <XAxisProvider axis={axis}>{children}</XAxisProvider>;
};

export default XAxis;

// export default memo(XAxis, ({ xAxisOptions: oldYAxisOptions }, { xAxisOptions: nextYAxisOptions }) =>
//   deepEqual(oldYAxisOptions, nextYAxisOptions),
// );
