import { StandardTaskStatus } from '@dametis/core';

export default {
  title: {
    tasks: 'Tasks',
    redirection: 'Redirection',
  },
  button: {
    cancel: 'Cancel',
    continue: 'Continue',
    reset: 'Reset',
  },
  tooltip: {},
  toggle: {},
  input: {
    label: {
      template: 'Template',
      project: 'Project...',
      collaborators: 'Collaborators...',
    },
  },
  label: {
    template: 'Template',
  },
  timeUnit: {},
  text: {
    defaultTemplate: 'Default template',
    unassigned: 'Unassigned',
    noTasks: 'No tasks',
    noOptions: 'No options',
    redirection: 'You will be redirected to the site',
    projectsLength_zero: 'Aucun projet',
    projectsLength_one: '1 projet',
    projectsLength_other: '{{count}} projets',
    noTemplate: 'Projects without template',
    allTemplates: 'All templates',
    noProject: 'No project',
  },
  placeholder: {
    allProjects: 'All projects',
  },
  tasks: {
    status: {
      [`variant_${StandardTaskStatus.TODO}`]: 'To do',
      [`variant_${StandardTaskStatus.ONGOING}`]: 'On going',
      [`variant_${StandardTaskStatus.TESTING}`]: 'Testing',
      [`variant_${StandardTaskStatus.DONE}`]: 'Done',
    },
  },
};
