import { ModifierType } from '@dametis/core';

import { AdvancedEntryMode, AdvancedEntryPreviewMode } from 'store/slices/advancedEntry';
import { ADVANCED_ENTRY_VARIABLES_MAX_COUNT } from 'types/variables';

export default {
  title: {
    advancedEntry: 'Data correction',
    steps: {
      variables: 'Variables',
      selectors: 'Conditions',
      transformers: 'Modifiers',
      preview: "Changes' preview",
    },
  },
  button: {
    save: 'Apply',
    addVariable: 'Add variable',
    addDeviceVariables: 'Add from device',
    editMode: {
      [AdvancedEntryMode.DELETE]: 'Deleting',
      [AdvancedEntryMode.EDIT]: 'Editing',
    },
    transformerMode: {
      [ModifierType.MULTIPLIER]: 'Multiplier',
      [ModifierType.LINEAR_INTERPOLATION]: 'Linear interpolation',
      [ModifierType.OFFSET]: 'Offset',
    },
    previewMode: {
      [AdvancedEntryPreviewMode.GRID]: 'Grid',
      [AdvancedEntryPreviewMode.CHART]: 'Chart',
    },
    removeVariable: 'Remove variable',
    clearVariables: 'Clear variables',
  },
  table: {
    column: {
      date: 'Date',
      data: 'Current data',
      patch: 'Patched data',
    },
    row: {
      noRows: 'No data',
    },
    deleted: '(deleted)',
  },
  text: {
    selectPatchMode: 'Select if you either want to edit or delete data',
    selectVariables: 'Select variables to correct',
    // fetchLimitWarning: 'You are only seeing a part of the affected data',
    fetchLimitWarning: 'You are only seeing the last 10,000 lines',
    patch: {
      noSelection: 'No data selected',
      init: 'All data',
      dateBetween: ' between {{after}} and {{before}}',
      after: ' after {{after}}',
      before: ' before {{before}}',
      valueBetween: ' between {{min}} and {{max}}',
      min: ' greater than {{min}}',
      max: ' less than {{max}}',
      edit: ' will be modified',
      delete: ' will be deleted',
    },
    legend: {
      base: 'Current data',
      edited: 'Edited data',
      deleted: 'Deleted data',
    },
  },
  input: {
    after: 'After (included)',
    before: 'Before (excluded)',
    min: 'Over',
    max: 'Below',
    offset: 'Offset',
    multiplier: 'Multiplier',
    linearInterpolation: {
      in1: 'In1',
      out1: 'Out1',
      in2: 'In2',
      out2: 'Out2',
    },
    error: {
      minDate: 'The date must be after',
      maxDate: 'The date must be before',
      invalidDate: 'Invalid date',
    },
  },
  label: {
    delete: 'Delete',
  },
  toast: {
    advancedEntryEditSuccess: 'Modifications are being processed',
    //  advancedEntryDeleteSuccess: 'Changes successfully applied',
    advancedEntryDeleteSuccess: 'The data is being deleted',
    // advancedEntryDeleteSuccess: 'Data successfully deleted',
  },
  tooltip: {
    prevChart: 'Previous chart',
    nextChart: 'Next chart',
    performanceIssue: `Only the first ${ADVANCED_ENTRY_VARIABLES_MAX_COUNT} entry variables are displayed, but changes will be applied to all variables`,
  },
};
