import { FC, useCallback, useContext } from 'react';

import { AUTO_GROUPBY, VarCalc } from '@dametis/core';

import { getDefaultOperatorForGroupBy } from 'functions/tada/getOperator';

import GroupByInput, { GroupByInputProps as IGroupByInputProps } from '../../../../UI/GroupByInput/GroupByInput';
import { PropsContext } from '../../../context';

interface Props {
  variable: Partial<VarCalc>;
  onVariableChange: (variable: Partial<VarCalc>) => void;
}

const GroupBySelect: FC<Props> = ({ variable, onVariableChange }) => {
  const { calculatedVariableMode, GroupByInputProps } = useContext(PropsContext);

  const changeGroupBy = useCallback<IGroupByInputProps['onGroupByChange']>(
    groupBy => {
      onVariableChange({ groupBy, operator: getDefaultOperatorForGroupBy(groupBy, variable.operator) });
    },
    [onVariableChange, variable.operator],
  );

  return (
    <div>
      <GroupByInput
        enableCheckbox
        enablePointToPoint
        defaultGroupBy={calculatedVariableMode ? undefined : AUTO_GROUPBY}
        enableAuto={!calculatedVariableMode}
        groupBy={variable.groupBy}
        onGroupByChange={changeGroupBy}
        {...GroupByInputProps}
      />
    </div>
  );
};

export default GroupBySelect;
