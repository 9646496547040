import { useTheme } from '@mui/material';
import { Series, TooltipFormatterContextObject } from 'highcharts';
import { FC } from 'react';

import SeriesArrow from '../UI/SeriesArrow';
import SeriesBullet from '../UI/SeriesBullet';
import SeriesContainer from '../UI/SeriesContainer';
import SeriesLabel from '../UI/SeriesLabel';
import SeriesValue from '../UI/SeriesValue';

type Props = Omit<TooltipFormatterContextObject, 'x'> & {
  seriesItem: Series;
};

const SankeySeriesTooltip: FC<Props> = ({ seriesItem, series, point }) => {
  const theme = useTheme();

  /**
   * if our series (seriesItem) is not the hovered one (series), we do not display anything
   */
  if (seriesItem.userOptions.id !== series.userOptions.id) {
    return <></>;
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (point.options.isNode) {
    return (
      <SeriesContainer color={point.color.toString()}>
        <SeriesBullet color={point.color.toString()} />
        <SeriesLabel style={{ fontWeight: 600, flexGrow: 1 }}>{point.options.id}</SeriesLabel>
      </SeriesContainer>
    );
  }
  return (
    <SeriesContainer color={point.color.toString()}>
      <SeriesBullet color={point.color.toString()} />
      <SeriesLabel style={{ flexGrow: 1 }}>{point.options.from}</SeriesLabel>
      <SeriesArrow color={point.color.toString()} />
      <SeriesLabel style={{ flexGrow: 1 }}>{point.options.to}</SeriesLabel>
      <div style={{ marginLeft: theme.spacing(0.5) }}>
        <SeriesValue point={point} series={seriesItem} />
      </div>
    </SeriesContainer>
  );
};

export default SankeySeriesTooltip;
