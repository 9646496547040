export default {
  title: {
    editData: 'Edition de données',
  },
  button: {
    refresh: 'Rafraîchir',
    send: 'Envoyer',
    details: 'Détails',
    history: 'Historique',
    revert: 'Restaurer',
    retry: 'Réessayer',
  },
  label: {
    totalSize: 'Taille Totale',
    editMode: "Mode d'édition",
    variableKind: 'Type de variable',
    minValue: 'Valeur Minimum',
    maxValue: 'Valeur Maximum',
    limit: 'Limite',
    source: 'Source',
  },
  toast: {
    RevertOperationSuccess: 'Revert Operation Success',
    RevertOperationError: 'Revert Operation Error',
    RetryOperationSuccess: 'Retry Operation Success',
    RetryOperationError: 'Retry Operation Error',
    EditDataSuccess: 'Edit Data Success',
    EditDataError: 'Edit Data Error',
    DeleteDataSuccess: 'Delete Data Success',
    DeleteDataError: 'Delete Data Error',
  },
};
