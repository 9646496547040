import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ListTab } from 'components/VNC/types';
import { useDispatch, useSelector } from 'store';
import { VncFilters } from 'zustand/states/vnc';
import { useVncStore } from 'zustand/stores/vnc';

import { PropsContext } from '../../context';
import { StyledDrawer } from '../styled';

import KindsFilters from './KindsFilters';
import PhysicalQuantitiesFilters from './PhysicalQuantitiesFilters';
import SitesFilters from './SitesFilters';
import StandardBlocksFilters from './StandardBlocksFilters';
import TagsFilters from './TagsFilters';

export interface FilterElementProps {
  expanded: boolean;
  setExpanded: (open: false | keyof VncFilters) => void;
  accordionsNumber?: number;
}

export interface FilterElement {
  component: FC<FilterElementProps>;
  props: FilterElementProps;
}

export interface FiltersProps {
  activeListTab: ListTab;
}

const Filters: FC<FiltersProps> = ({ activeListTab }) => {
  const dispatch = useDispatch();

  const { availableFilters, defaultFilters } = useContext(PropsContext);

  const groupId = useSelector(state => state.auth.selectedGroup.uuid);
  const siteId = useSelector(state => state.auth.selectedSite?.uuid);

  const getFilters = useVncStore(state => state.getFilters);
  const setFiltersValues = useVncStore(state => state.setFiltersValues);
  const getResults = useVncStore(state => state.getResults);

  const [expanded, setExpanded] = useState<false | keyof VncFilters>('physicalQuantities');

  const filterElements: FilterElement[] = useMemo(() => {
    const elements = [];
    elements.push({ component: PhysicalQuantitiesFilters, props: { expanded: expanded === 'physicalQuantities', setExpanded } });
    if (activeListTab === ListTab.VARIABLES) {
      elements.push({ component: TagsFilters, props: { expanded: expanded === 'tags', setExpanded } });
    }
    if (!siteId) {
      elements.push({ component: SitesFilters, props: { expanded: expanded === 'sites', setExpanded } });
    }
    if (activeListTab === ListTab.VARIABLES) {
      elements.push({ component: KindsFilters, props: { expanded: expanded === 'kinds', setExpanded } });
    }
    // if (activeListTab === ListTab.VARIABLES && Boolean(covarianceVariable)) {
    //   elements.push({ component: InfluentVariablesFilters, props: { expanded: expanded === 'influentVariables', setExpanded } });
    // }
    if (activeListTab === ListTab.BLOCKS) {
      elements.push({ component: StandardBlocksFilters, props: { expanded: expanded === 'standardBlocks', setExpanded } });
      elements.push({ component: TagsFilters, props: { expanded: expanded === 'tags', setExpanded } });
    }
    return elements;
  }, [expanded, siteId, activeListTab]);

  const fetchFilters = useCallback(async () => {
    dispatch(getFilters(availableFilters, defaultFilters));
    await dispatch(getResults());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFilters, setFiltersValues, dispatch, getResults, groupId, siteId]);

  useEffect(() => {
    void fetchFilters();
  }, [fetchFilters]);

  return (
    <StyledDrawer anchor="left">
      {filterElements.map((element, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <element.component key={index} {...element.props} accordionsNumber={filterElements.length} />
      ))}
    </StyledDrawer>
  );
};

export default Filters;
