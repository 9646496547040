import { Add, ExpandMore, NavigateNext } from '@mui/icons-material';
import { Paper, Typography } from '@mui/material';
import { TreeItem, SimpleTreeView, treeItemClasses } from '@mui/x-tree-view';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSlateStatic } from 'slate-react';

import { BatchInfo } from '@dametis/core';

import { useSelector } from 'store';

import ActionButton from '../Buttons/ActionButton/ActionButton';

import { insertBatchInSlate } from './slate/insertBatchInSlate';

const BatchTree: FC = () => {
  const { t } = useTranslation('batches');
  const editor = useSlateStatic();

  const batchList = useSelector(state => state.batch.allBatches);

  const [tree, setTree] = useState(null);

  const handleAddBatch = useCallback(
    (e, batch: BatchInfo) => {
      e.preventDefault();
      e.stopPropagation();
      insertBatchInSlate(editor, { batchUuid: batch.uuid });
    },
    [editor],
  );

  const genTreeItems = useCallback(
    (batches: BatchInfo[]) => {
      const elements = [];
      batches.forEach(batch => {
        const { uuid, name, children } = batch;
        elements.push(
          children && (
            <TreeItem
              key={uuid}
              itemId={uuid}
              label={
                <Paper
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: theme => theme.spacing(1),
                    pl: theme => theme.spacing(4.5),
                  }}
                >
                  <Typography color="inherit" variant="h6">
                    {name}
                  </Typography>
                  <ActionButton size="small" startIcon={<Add />} onClick={e => handleAddBatch(e, batch)}>
                    {t('global:tooltip.add')}
                  </ActionButton>
                </Paper>
              }
              sx={{
                [`& .${treeItemClasses.content}`]: {
                  position: 'relative',
                  backgroundColor: 'transparent !important',
                },
                [`& .${treeItemClasses.label}`]: {
                  alignItems: 'center',
                },
                [`& .${treeItemClasses.iconContainer}`]: {
                  width: 0,
                  position: 'absolute',
                  left: theme => theme.spacing(3.4),
                  zIndex: 1,
                  color: theme => theme.palette.grey[800],
                },
                [`& .${treeItemClasses.groupTransition}`]: {
                  paddingLeft: theme => theme.spacing(2),
                  marginLeft: theme => theme.spacing(3.5),
                  borderLeft: theme => `solid 2px ${theme.palette.grey[200]}90`,
                  position: 'relative',
                  listStyle: 'none',
                },
              }}
            >
              {children.length > 0 ? genTreeItems(children) : null}
            </TreeItem>
          ),
        );
      });
      return elements;
    },
    [handleAddBatch, t],
  );

  useEffect(() => {
    const treeItems = [];
    batchList.forEach(b => {
      treeItems.push(genTreeItems([b]));
    });
    setTree(treeItems);
  }, [batchList, genTreeItems]);

  return (
    tree && (
      <SimpleTreeView
        disableSelection
        slots={{
          collapseIcon: ExpandMore,
          expandIcon: NavigateNext,
        }}
      >
        {tree}
      </SimpleTreeView>
    )
  );
};

export default BatchTree;
