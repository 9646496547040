import { createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useMemo } from 'react';

import { FixedVariable, ReferencedBy, SourceColumn, VariableInfo } from '@dametis/core';

export type MappingSettingsContextState = MappingSettingsProviderProps;
// export interface MappingSettingsContextState extends MappingSettingsProviderProps {}

export interface MappingSettingsProviderProps {
  referencedBy: ReferencedBy;
  useHeader: boolean;
  usedSources: SourceColumn[];
  dateTimeSource: SourceColumn | null;
  setDateTimeSource: Dispatch<SetStateAction<SourceColumn | null>>;
  referenceSource: SourceColumn | null;
  setReferenceSource: Dispatch<SetStateAction<SourceColumn | null>>;
  valueSource: SourceColumn | null;
  setValueSource: Dispatch<SetStateAction<SourceColumn | null>>;
  skippedColumns: (SourceColumn | null)[];
  setSkippedColumns: Dispatch<SetStateAction<(SourceColumn | null)[]>>;
  fixedVariables: FixedVariable[];
  setFixedVariables: Dispatch<SetStateAction<FixedVariable[]>>;
  dateTimeZone: string | null;
  setDateTimeZone: Dispatch<SetStateAction<string | null>>;
  dateTimeFormat: string | null;
  setDateTimeFormat: Dispatch<SetStateAction<string | null>>;
  variablesByReferences: Record<string, VariableInfo>;
}

export const initialState: MappingSettingsContextState = {
  referencedBy: null,
  useHeader: false,
  usedSources: [],
  dateTimeSource: null,
  setDateTimeSource: undefined,
  referenceSource: null,
  setReferenceSource: undefined,
  valueSource: null,
  setValueSource: undefined,
  skippedColumns: [],
  setSkippedColumns: undefined,
  fixedVariables: [],
  setFixedVariables: undefined,
  dateTimeZone: '',
  setDateTimeZone: undefined,
  dateTimeFormat: '',
  setDateTimeFormat: undefined,
  variablesByReferences: {},
};

export const MappingSettingsContext = createContext<MappingSettingsContextState>(initialState);

const MappingSettingsProvider: FC<PropsWithChildren<MappingSettingsProviderProps>> = ({
  referencedBy,
  useHeader,
  usedSources,
  dateTimeSource,
  setDateTimeSource,
  referenceSource,
  setReferenceSource,
  valueSource,
  setValueSource,
  skippedColumns,
  setSkippedColumns,
  fixedVariables,
  setFixedVariables,
  dateTimeZone,
  setDateTimeZone,
  dateTimeFormat,
  setDateTimeFormat,
  variablesByReferences,
  children = undefined,
}) => {
  const contextValues = useMemo(
    () => ({
      referencedBy,
      useHeader,
      usedSources,
      dateTimeSource,
      setDateTimeSource,
      referenceSource,
      setReferenceSource,
      valueSource,
      setValueSource,
      skippedColumns,
      setSkippedColumns,
      fixedVariables,
      setFixedVariables,
      dateTimeZone,
      setDateTimeZone,
      dateTimeFormat,
      setDateTimeFormat,
      variablesByReferences,
    }),
    [
      referencedBy,
      useHeader,
      usedSources,
      dateTimeSource,
      setDateTimeSource,
      referenceSource,
      setReferenceSource,
      valueSource,
      setValueSource,
      skippedColumns,
      setSkippedColumns,
      fixedVariables,
      setFixedVariables,
      dateTimeZone,
      setDateTimeZone,
      dateTimeFormat,
      setDateTimeFormat,
      variablesByReferences,
    ],
  );

  return <MappingSettingsContext.Provider value={contextValues}>{children}</MappingSettingsContext.Provider>;
};

export const useMappingSettingsContext = () => {
  const context = useContext(MappingSettingsContext);

  if (!context) {
    throw Error('useMappingSettingsContext must be used inside a MappingSettingsProvider');
  }

  return context;
};

export default MappingSettingsProvider;
